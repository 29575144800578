<template>
    <div id="app" class="add-group-box">
        <!--App端-->
        <div class="group-box-app" v-show="appShow">
            <div class="box-top" style="position: relative;">
                <div class="line-box-w100-app font-color"
                    style="    position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                    精准教研邀请你加入专家库并加入《{{ showName }}》{{ type === '1' ? '的听课' : type === '2' ? '的评课' : (type === '4' || type
                        === '5')
                        ? '的协同备课' :
                        '的培训任务' }}
                </div>
            </div>
            <div class="box-bottom">

                <!--手机号-->
                <div class="line-box-w90-app">
                    <div class="line-info-img float-l">
                        <img src="../../assets/joinGroup/phone.png">
                    </div>
                    <div class="line-info-input float-l">
                        <input type="text" v-model="joinForm.username" placeholder="请输入11位手机号码" />
                    </div>
                </div>
                <div class="line-box-seize"></div>
                <!--验证码-->
                <div class="line-box-w90-app">
                    <div class="line-info-img float-l">
                        <img src="../../assets/joinGroup/code.png">
                    </div>
                    <div class="line-info-input-code float-l">
                        <input maxlength="6" type="text" v-model="joinForm.code" placeholder="请输入验证码" />
                    </div>
                    <div class="line-info-code-btn float-l">
                        <button style="white-space: nowrap;" class="get-code-button" @click="validateAndGetCode">{{ msgText
                        }}</button>
                    </div>
                </div>
                <div class="line-box-seize"></div>
                <!--姓名-->
                <div class="line-box-w90-app">
                    <div class="line-info-img float-l">
                        <img src="../../assets/joinGroup/name.png">
                    </div>
                    <div class="line-info-input float-l">
                        <input type="text" v-model="joinForm.expertName" placeholder="请输入专家姓名" />
                    </div>
                </div>
                <div class="line-box-seize"></div>

                <!--学段-->
                <div class="line-box-w90-app">
                    <div class="line-info-img float-l">
                        <img src="../../assets/joinGroup/nick.png">
                    </div>
                    <div class="line-info-input float-l" style="display: flex;align-items: center;">
                        <el-cascader placeholder="请选择学段学科" style="width:100%;height:100%;" v-model="joinForm.xdxk"
                            :options="archiveXdXkList"
                            :props="{ children: 'childrenList', value: 'dictKey', label: 'dictValue' }" />
                    </div>
                </div>
                <div class="line-box-seize"></div>
                <!--职级-->
                <div class="line-box-w90-app">
                    <div class="line-info-img float-l">
                        <img src="../../assets/joinGroup/nick.png">
                    </div>
                    <div class="line-info-input float-l" style="display: flex;align-items: center;">
                        <el-select style="width:100%;height:100%;" v-model="joinForm.rankInfo" placeholder="请选择职级">
                            <el-option v-for="sz in technicalTitleList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                    </div>
                </div>
                <div class="line-box-seize"></div>
                <div class="line-box-w90-app-noshadow">
                    <input type="button" class="add-group-btn" @click="userJionQun" value="加入" />
                </div>
            </div>
        </div>
        <!--加群成功蒙层-->
        <div class="cover" v-if="coverBox">
            <div class="success-box">
                <div class="success-info-box">
                    <div class="success-img"></div>
                    <div class="success-info">
                        <div class="info01">加入成功！</div>
                        <!-- <div class="info02">管理员正在审核，请耐心等待</div> -->
                    </div>
                    <div class="success-info">
                        <div class="know-btn-box">
                            <input type="button" class="add-group-btn" value="我知道了" @click="closeBox" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const MSGINIT = "获取验证码";
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
let pcShow = true;
let appShow = false;
var url = 'https://zbh.zbform.com/'
import { requestCode } from "@/api/user/login";
import { dictQueryDict, expertCheck, expertAddAndJoin, evaluationInfo, evaluationJoin, getLessonTitle, lessonJoin, trainInfo, trainJoin } from '@/api/admin/public'

export default ({
    name: "userLogin",
    data() {
        return {
            id: '',
            type: '',   // 1听课 2评课 3师资 4协同备课-协备人员 5协同备课-专家
            msgText: MSGINIT,
            msgTime: MSGTIME,
            msgKey: false,
            coverBox: false,
            qunInfo: {},
            joinForm: {
                username: "",
                code: "",
                rankInfo: "",
                xdxk: [],
                expertName: "",
            },
            codeForm: {
                mobile: "",
                type: ""
            },
            showName: '',
            archiveXdXkList: [],
            technicalTitleList: [],
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'overflow-y: hidden;')
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        if (flag) {
            this.pcShow = false;
            this.appShow = true;
        } else {
            this.pcShow = true;
            this.appShow = false;
        }
    },
    created() {
        this.id = this.getQueryVariable("id");
        this.type = this.getQueryVariable("type");
        this.joinForm.username = this.getQueryVariable("userName");
        this.queryInfo()
        this.getDictQueryDict()
    },
    methods: {
        queryInfo() {
            if (this.type === '1' || this.type === '2') {
                evaluationInfo({ lessonId: this.id }).then(res => {
                    this.showName = res.data.data.title
                })
            } else if (this.type === '4' || this.type === '5') {
                getLessonTitle({ lessonId: this.id }).then(res => {
                    this.showName = res.data.data
                })
            } else {
                trainInfo({ trainId: this.id }).then(res => {
                    this.showName = res.data.data.title
                })
            }
        },
        getDictQueryDict() {
            dictQueryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            dictQueryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
        },
        validateAndGetCode() {
            if (this.msgKey) return;
            let username = this.joinForm.username
            if (this.validatenull(username)) {
                this.$message.success('请输入手机号码')
                return
            }
            if (!this.isMobile(username)) {
                this.$message.warning("手机号码格式不正确")
                return
            }
            this.codeForm.mobile = this.joinForm.username
            this.getCode()
        },
        getCode() {
            /*发送验证码*/
            requestCode({ username: this.joinForm.username }).then(res => {
                if (res.data.code === 1) {
                    this.$message.success('验证码发送成功！')
                } else {
                    this.$message.error("验证码发送失败")
                }
            })
            this.msgText = MSGSCUCCESS.replace("${time}", String(this.msgTime));
            this.msgKey = true;
            const time = setInterval(() => {
                this.msgTime--;
                this.msgText = MSGSCUCCESS.replace("${time}", String(this.msgTime));
                if (this.msgTime === 0) {
                    this.msgTime = MSGTIME;
                    this.msgText = MSGINIT;
                    this.msgKey = false;
                    clearInterval(time);
                }
            }, 1000);
        },
        closeBox() {
            this.coverBox = false
        },
        userJionQun() {
            /*校验数据*/
            let username = this.joinForm.username
            let code = this.joinForm.code
            if (this.validatenull(username)) {
                this.$message.error("请输入手机号码")
                return
            }
            if (!this.isMobile(username)) {
                this.$message.error("手机号码格式不正确")
                return
            }
            if (this.validatenull(code)) {
                this.$message.error("请输入验证码")
                return
            }
            if (!this.joinForm.expertName) {
                this.$message.error("请输入专家姓名")
                return
            }
            if (this.joinForm.xdxk.length == 0) {
                this.$message.error("请选择学段学科")
                return
            }
            if (!this.joinForm.rankInfo) {
                this.$message.error("请选择职级")
                return
            }
            var obj ={
                code:this.joinForm.code,
                contactInfo:this.joinForm.username,
                expertName:this.joinForm.expertName,
                lessonId:this.id,
                rankInfo:this.joinForm.rankInfo,
                period:'',
                subject:''
            }
            obj.period = this.joinForm.xdxk[0]
            obj.subject = this.joinForm.xdxk[1]
            expertAddAndJoin(obj).then(res => {
                if (res.data.code === 1) {
                    this.$message.success('加入成功！')
                    this.coverBox = true
                } else {
                    this.$message.error(res.data.msg)
                }
            })

            // var xhrs = new XMLHttpRequest();
            // xhrs.open('POST', url + '/authservice/admin/joinQunApply', false);
            // xhrs.setRequestHeader("Content-Type", "application/json;charset=utf-8");
            // xhrs.setRequestHeader("secret","MCop2ofOHLDjpSnea7cIBJWZe5nabD1QxT3Bp97BPGmz8zGpur7GjylqXC0qNdgH");
            // xhrs.setRequestHeader("Access-Control-Allow-Origin", "*");
            // xhrs.send(JSON.stringify(this.joinForm));
            // if (xhrs.readyState == 4) {
            // 	if (xhrs.status == 200) {
            // 		var datas = JSON.parse(xhrs.responseText);
            // 		if (datas.code == 1) {
            // 			vant.Toast.success("申请成功，请耐心等待审核！")
            // 			this.coverBox = true
            // 		} else {
            // 			vant.Toast.fail(datas.msg)
            // 		}
            // 	}
            // }
        },
        /**
         * 手机号码
         * @param {*} s
         */
        isMobile(s) {
            return /^1[0-9]{10}$/.test(s)
        },
        validatenull(val) {
            if (typeof val === 'boolean') {
                return false
            }
            if (typeof val === 'number') {
                return false
            }
            if (val instanceof Array) {
                if (val.length === 0) return true
            } else if (val instanceof Object) {
                if (JSON.stringify(val) === '{}') return true
            } else {
                if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '')
                    return true
                return false
            }
            return false
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return null;
        },
    }
})
</script >
  <!--样式-->
<style lang="scss" scoped>
/deep/ .el-cascader .el-input {
    height: 100%;
}

/deep/ .el-input__wrapper {
    border: 0 !important;
    box-shadow: none !important;
}

/deep/ .el-cascader .el-input.is-focus .el-input__wrapper {
    box-shadow: none !important;
}

/deep/ .el-select__wrapper {
    height: 100%;
    box-shadow: none !important;
}

.get-code-button {
    min-width: 100%;
    height: 70%;
    color: #fff !important;
    background: linear-gradient(90deg, #27e1d2, #6078ea);
    border-radius: 20px;
    border: none;
    margin-top: 6%;
}

.add-group-box {
    width: 100%;
    height: 100%;
    position: relative;
    //   background-image: url("../../assets/joinGroup/add-group-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.box-pc {
    width: 500px;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 39px;
    box-shadow: 0px 0px 5px rgba(108, 108, 108, 0.4);
}

.group-box-pc {
    width: 92%;
    height: 94%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.group-box-app {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 5px rgba(108, 108, 108, 0.4);
    background: #ffffff;
}

.box-top {
    width: 100%;
    height: 30%;
    //   background-image: url("../../assets/joinGroup/top.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.box-bottom {
    width: 100%;
    height: 68%;
    background-image: url("../../assets/joinGroup/bottom.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.line-box-w90 {
    width: 80%;
    height: 10%;
    margin: auto;
    box-shadow: 0px 0px 8px rgba(69, 86, 95, 0.4);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
}

.line-box-w90-app {
    width: 80%;
    height: 46px;
    margin: auto;
    box-shadow: 0px 0px 8px rgba(69, 86, 95, 0.4);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
}

.line-box-w90-app-noshadow {
    width: 80%;
    height: 36px;
    margin: auto;
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
}

.line-btn-w90 {
    width: 80%;
    height: 10%;
    line-height: 46px;
    margin: auto;
    border-radius: 36px;
    cursor: pointer;
}

.line-btn-w90>input {
    width: 100%;
    height: 100%;
    margin: auto;
    border: none;
    border-radius: 36px;
    cursor: pointer;
}

.add-group-btn {
    width: 100%;
    height: 100%;
    color: #ffffff;
    border-radius: 36px;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2), to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    font-size: 22px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.know-btn-box {
    width: 90%;
    height: 40%;
    padding-top: 20px;
    font-size: 22px;
    font-weight: 500;
    margin: auto;
}

.line-info-img {
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line-info-img>img {
    height: 32px;
    width: 32px;
}

.line-info-input {
    height: 100%;
    width: 85%;
}

.line-info-input>input {
    height: 90% !important;
    width: 92%;
    border: none;
    padding-left: 5%;
}

.line-info-input-code {
    height: 100%;
    width: 45%;
}

.line-info-input-code>input {
    height: 90%;
    width: 95%;
    border: none;
    padding-left: 5%;
}

.line-info-code-btn {
    height: 100%;
    width: 35%;
    padding-left: 5%;
}

.get-code-button {
    width: 100%;
    height: 70%;
    color: #ffffff !important;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2), to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    border-radius: 20px;
    border: none;
    margin-top: 6%;
}

.float-l {
    float: left;
}

.line-box-w100 {
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.line-box-w100-app {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.line-box-seize {
    width: 100%;
    height: 6%;
}

.font-color {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2), to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    -webkit-background-clip: text;
    color: transparent;
    /*text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;*/
}

.cover {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 3;
    position: absolute;
}

.success-box {
    width: 320px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 30px;
}

.refuse-img-box {
    width: 280px;
    height: 280px;
    margin: auto;
    padding-top: 20px;
}

.refuse-img-box>img {
    width: 280px;
    height: 280px;
}

.refuse-info-box {
    width: 280px;
    height: 50px;
    margin: auto;
    line-height: 50px;
    color: #333333;
    text-align: center;
    font-size: 14px;
}

.success-img {
    width: 320px;
    height: 200px;
    background-image: url("../../assets/joinGroup/add-group-success.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.success-info {
    width: 320px;
    height: 100px;
}

.info01 {
    width: 320px;
    height: 70px;
    line-height: 90px;
    color: #333333;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.info02 {
    width: 320px;
    height: 30px;
    line-height: 30px;
    color: #bfbfbf;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

/*Input样式*/
input::-webkit-input-placeholder {
    color: #ccc;
}

input:-moz-placeholder {
    color: #ccc;
}

input::-moz-placeholder {
    color: #ccc;
}

input:-ms-input-placeholder {
    color: #ccc;
}
</style>
