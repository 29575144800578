import request from "@/utils/request";
// 新增课程章节
export const  trainCourseChapter =(data) =>{
    return request({
        url: "/businessservice/course/train/courseChapter",
        method:"post",
        data: data
    })
}
// 创建培训课程
export const  trainCourseCreate =(data) =>{
    return request({
        url: "/businessservice/course/train/createCourse",
        method:"post",
        data: data
    })
}
// 删除课程或章节
export const  trainCourseDel =(query) =>{
    return request({
        url: "/businessservice/course/train/delete",
        method:"get",
        params: query
    })
}
// 删除课程视频
export const  trainDeleteVideo =(query) =>{
    return request({
        url: "/businessservice/course/train/deleteVideo",
        method:"get",
        params: query
    })
}
// 查询培训课详细信息
export const  trainCourseInfo =(query) =>{
    return request({
        url: "/businessservice/course/train/info",
        method:"get",
        params: query
    })
}
// 查询全部培训课程列表
export const  trainListFamous =(query) =>{
    return request({
        url: "/businessservice/course/train/listTrainCourses",
        method:"get",
        params: query
    })
}
// 查询已发布培训课程列表
export const  trainListReleaseCourse =(query) =>{
    return request({
        url: "/businessservice/course/train/listReleaseCourse",
        method:"get",
        params: query
    })
}
// 查询培训课程全部信息(包含层级、视频)
export const  TrainCoursesListTreeFamous =(query) =>{
    return request({
        url: "/businessservice/course/train/listTreeTrainCourses",
        method:"get",
        params: query
    })
}
// 分页查询名师优课
export const  trainPageCourses =(data) =>{
    return request({
        url: "/businessservice/course/train/pageCourses",
        method:"post",
        data: data
    })
}
// 发布课程
export const  trainCourseRelease =(query) =>{
    return request({
        url: "/businessservice/course/train/releaseCourse",
        method:"get",
        params: query
    })
}
// 更新培训课程
export const  trainCourseUpdate =(data) =>{
    return request({
        url: "/businessservice/course/train/updateCourse",
        method:"post",
        data: data
    })
}
//修改章节名称
export const  trainCourseUpdateChapter =(data) =>{
    return request({
        url: "/businessservice/course/train/updateChapter",
        method:"post",
        data: data
    })
}
// 上传课程封面
export const  trainCourseUploadCover =(data) =>{
    return request({
        url: "/businessservice/course/train/uploadCover",
        method:"post",
        data: data
    })
}
// 批量上传名师优课视频
export const  trainCourseUploadTrainVideos =(data) =>{
    return request({
        url: "/businessservice/course/train/uploadTrainVideos",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}
// 批量上传视频附件
export const  trainCourseUploadVideoFile =(data) =>{
    return request({
        url: "/businessservice/course/train/uploadVideoFile",
        method:"post",
        data: data
    })
}

// 重命名课程视频
export const  trainrenameVideo =(data) =>{
    return request({
        url: "/businessservice/course/train/renameVideo",
        method:"post",
        data: data
    })
}


// 条件查询已发布课程列表(基础信息)
export const  listCourseCondition =(data) =>{
    return request({
        url: "/businessservice/course/train/listCourseCondition",
        method:"post",
        data: data
    })
}