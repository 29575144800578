<template>
    <div class="my-charts">
        <div :id="id"></div>
    </div>
</template>


<script>
import * as echarts from "echarts";


export default {
    name: "barEcharts",
    props: {
        id:{
            type: String,
            default:"bar_echarts"
        },
        dataList:{
            type: Array,
            default: []
        }
    },
    watch:{
        id(newValue,oldValue){
            this.id = newValue;
        },
        dataList(newValue,oldValue){
            this.dataList = newValue;
            this.init()
        }
    },
    data() {
        return {
            barChart: null, // 柱状图
            chartOption:{
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        show: true,
                        interval:0,
                        rotate:20,
                        // width:50,
                        // overflow:'breakAll',
                        // formatter:function(value)
                        // {
                        //     var ret = "";//拼接加\n返回的类目项
                        //     var maxLength = 8;//每项显示文字个数
                        //     var valLength = value.length;//X轴类目项的文字个数
                        //     var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                        //     if (rowN > 1)//如果类目项的文字大于3,
                        //     {
                        //         for (var i = 0; i < rowN; i++) {
                        //             var temp = "";//每次截取的字符串
                        //             var start = i * maxLength;//开始截取的位置
                        //             var end = start + maxLength;//结束截取的位置
                        //             //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                        //             temp = value.substring(start, end) + "\n";
                        //             ret += temp; //凭借最终的字符串
                        //         }
                        //         return ret;
                        //     }
                        //     else {
                        //         return value;
                        //     }
                        // }
                    },
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: true,
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barMaxWidth: 50,
                        color: [
                            '#0195ff',
                        ],
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            }

        };
    },
    computed: {},
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        init() {
            const itemList = []
            const valList = []
            this.dataList.forEach(item => {
                itemList.push(item.itemName);
                valList.push(item.answerCount);
                console.log('名称：'+item.itemName+'数值：'+item.answerCount)
            })
            this.barChart = echarts.init(document.getElementById(this.id));
            this.chartOption.series[0].data = []
            this.chartOption.series[0].data = valList;
            this.chartOption.xAxis.data = []
            this.chartOption.xAxis.data = itemList
            this.barChart.setOption(this.chartOption);
        }
    }
};
</script>


<style scoped lang="scss">
.my-charts {
    display: flex;
    > div {
        width: 100%;
        height: 300px;
        //border: 1px solid #ccc;
    }
    div + div {
        margin-left: 10px;
    }
}
</style>