<template>
    <div style="height: 100%;width: 96%;margin: auto;" v-show="isShow">
        <div class="news-box">
            <h2 style="text-align: center;">{{ form.title }}</h2>
            <div style="display: flex;width: 100%;margin-bottom: 10px">
                <div style="width: 70%;color: #666666;">发布时间：{{ timestampToTime(form.releaseTime) }}</div>
                <div style="width: 30%;color: #666666;margin: auto;justify-content: flex-end;text-align: right;padding-right: 10px">
                    阅读人数：{{ form.readNum }}
                </div>
            </div>

            <div v-html="form.appContent">
            </div>
        </div>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import {shareBannerInfo,shareNewsInfo,readShare} from "@/api/admin/share"
export default {
    name: "newShare",
    components: {},
    data() {
        return {
            isShow:false,
            shareId:'', //分享参数
            type:'', //分享类型 0-新闻  1-banner
            form:{},  //数据
            timestampToTime,
        };
    },
    created() {},
    mounted() {
        this.shareId = this.$route.query.id;
        this.type = this.$route.query.type;
        this.getShareInfo();
    },
    computed: {},
    methods: {
        getShareInfo() {
            console.log("id=="+this.shareId)
            console.log("type=="+this.type)
            if (this.shareId===''||this.shareId===undefined||this.shareId===null){
                this.$message.error("分享信息有误")
                return;
            }
            if (this.type==='0'){
                shareNewsInfo({id:this.shareId}).then(res => {
                    if (res.data.code===1){
                        this.form = res.data.data;
                        this.isShow = true;
                        readShare({id:this.shareId,type:this.type}).then(res=>{})
                    }else {
                        this.$message.error(res.data.msg);
                    }
                })
            }
            if (this.type==='1'){
                shareBannerInfo({id:this.shareId}).then(res => {
                    if (res.data.code===1){
                        this.form = res.data.data;
                        this.isShow = true;
                        readShare({id:this.shareId,type:this.type}).then(res=>{})
                    }else {
                        this.$message.error(res.data.msg);
                    }
                })
            }

        }
    },

}

</script>

<style scoped>
.news-box{
    height:100vh;
    overflow: auto;
    scrollbar-width: none;
}

.news-box::-webkit-scrollbar {
    display: none;
}
</style>