<template>
    <div class="aMapMain">
        <div v-if="isDisplayAMap">
            <div class="aMap" v-if="!isDetail">
                <el-amap @click="clickMap" @init="initMap" :events="events" :plugin="plugin" ref="mapRef" :min-zoom="8"
                         :max-zoom="22" :center="center" :zoom="zoom">
                    <div class="ccittxt">
                        <el-cascader v-model="activeCity" clearable @change="changeCity" placeholder="搜索城市" :options="options" :show-all-levels="false" />
                    </div>
                    <el-amap-search-box placeholder="请输入地址" :city="city" :on-search-result="onSearchResult"
                                        :search-option="searchOption" @select="selectPoi" @choose="selectPoi" v-if="isShowAMap" />
                    <el-amap-marker :position="componentMarker.position" :content="componentMarker.content" />
                </el-amap>
            </div>
            <div class="aMap" v-else>
                <el-amap ref="mapRef" :min-zoom="8"
                         :max-zoom="22" :center="center" :zoom="zoom">
                    <el-amap-marker :position="componentMarker.position" :content="componentMarker.content" />
                </el-amap>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AMap',
    props: {
        toParentsMap: {
            type: Object, // 父组件传来的默认数据
            default: {}
        },
        isShowAMap: {
            type: Boolean, // 控制是否展示搜索框
            default: null
        },
        isDisplayAMap: {
            type: Boolean, // 控制是否展示地图试图
            default: true
        },
        isDetail: {
            type: Boolean,
            default: false
        },
        oldCenter: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            options: [
                {
                    "citys": [
                        {
                            "cityName": "北京市"
                        }
                    ],
                    "provinceName": "北京市"
                },
                {
                    "citys": [
                        {
                            "cityName": "天津市"
                        }
                    ],
                    "provinceName": "天津市"
                },
                {
                    "citys": [
                        {
                            "cityName": "上海市"
                        }
                    ],
                    "provinceName": "上海市"
                },
                {
                    "citys": [
                        {
                            "cityName": "重庆市"
                        }
                    ],
                    "provinceName": "重庆市"
                },
                {
                    "citys": [
                        {
                            "cityName": "石家庄市"
                        },
                        {
                            "cityName": "唐山市"
                        },
                        {
                            "cityName": "秦皇岛市"
                        },
                        {
                            "cityName": "邯郸市"
                        },
                        {
                            "cityName": "邢台市"
                        },
                        {
                            "cityName": "保定市"
                        },
                        {
                            "cityName": "张家口市"
                        },
                        {
                            "cityName": "承德市"
                        },
                        {
                            "cityName": "沧州市"
                        },
                        {
                            "cityName": "廊坊市"
                        },
                        {
                            "cityName": "衡水市"
                        },
                        {
                            "cityName": "辛集市"
                        },
                        {
                            "cityName": "晋州市"
                        },
                        {
                            "cityName": "新乐市"
                        },
                        {
                            "cityName": "遵化市"
                        },
                        {
                            "cityName": "迁安市"
                        },
                        {
                            "cityName": "武安市"
                        },
                        {
                            "cityName": "南宫市"
                        },
                        {
                            "cityName": "沙河市"
                        },
                        {
                            "cityName": "涿州市"
                        },
                        {
                            "cityName": "定州市"
                        },
                        {
                            "cityName": "安国市"
                        },
                        {
                            "cityName": "高碑店市"
                        },
                        {
                            "cityName": "平泉市"
                        },
                        {
                            "cityName": "泊头市"
                        },
                        {
                            "cityName": "任丘市"
                        },
                        {
                            "cityName": "黄骅市"
                        },
                        {
                            "cityName": "河间市"
                        },
                        {
                            "cityName": "霸州市"
                        },
                        {
                            "cityName": "三河市"
                        },
                        {
                            "cityName": "深州市"
                        }
                    ],
                    "provinceName": "河北省"
                },
                {
                    "citys": [
                        {
                            "cityName": "太原市"
                        },
                        {
                            "cityName": "大同市"
                        },
                        {
                            "cityName": "阳泉市"
                        },
                        {
                            "cityName": "长治市"
                        },
                        {
                            "cityName": "晋城市"
                        },
                        {
                            "cityName": "朔州市"
                        },
                        {
                            "cityName": "晋中市"
                        },
                        {
                            "cityName": "运城市"
                        },
                        {
                            "cityName": "忻州市"
                        },
                        {
                            "cityName": "临汾市"
                        },
                        {
                            "cityName": "吕梁市"
                        },
                        {
                            "cityName": "古交市"
                        },
                        {
                            "cityName": "高平市"
                        },
                        {
                            "cityName": "介休市"
                        },
                        {
                            "cityName": "永济市"
                        },
                        {
                            "cityName": "河津市"
                        },
                        {
                            "cityName": "原平市"
                        },
                        {
                            "cityName": "侯马市"
                        },
                        {
                            "cityName": "霍州市"
                        },
                        {
                            "cityName": "孝义市"
                        },
                        {
                            "cityName": "汾阳市"
                        },
                        {
                            "cityName": "怀仁市"
                        }
                    ],
                    "provinceName": "山西省"
                },
                {
                    "citys": [
                        {
                            "cityName": "呼和浩特市"
                        },
                        {
                            "cityName": "包头市"
                        },
                        {
                            "cityName": "乌海市"
                        },
                        {
                            "cityName": "赤峰市"
                        },
                        {
                            "cityName": "通辽市"
                        },
                        {
                            "cityName": "鄂尔多斯市"
                        },
                        {
                            "cityName": "呼伦贝尔市"
                        },
                        {
                            "cityName": "巴彦淖尔市"
                        },
                        {
                            "cityName": "乌兰察布市"
                        },
                        {
                            "cityName": "霍林郭勒市"
                        },
                        {
                            "cityName": "满洲里市"
                        },
                        {
                            "cityName": "牙克石市"
                        },
                        {
                            "cityName": "扎兰屯市"
                        },
                        {
                            "cityName": "额尔古纳市"
                        },
                        {
                            "cityName": "根河市"
                        },
                        {
                            "cityName": "丰镇市"
                        },
                        {
                            "cityName": "乌兰浩特市"
                        },
                        {
                            "cityName": "阿尔山市"
                        },
                        {
                            "cityName": "二连浩特市"
                        },
                        {
                            "cityName": "锡林浩特市"
                        }
                    ],
                    "provinceName": "内蒙古自治区"
                },
                {
                    "citys": [
                        {
                            "cityName": "沈阳市"
                        },
                        {
                            "cityName": "大连市"
                        },
                        {
                            "cityName": "鞍山市"
                        },
                        {
                            "cityName": "抚顺市"
                        },
                        {
                            "cityName": "本溪市"
                        },
                        {
                            "cityName": "丹东市"
                        },
                        {
                            "cityName": "锦州市"
                        },
                        {
                            "cityName": "营口市"
                        },
                        {
                            "cityName": "阜新市"
                        },
                        {
                            "cityName": "辽阳市"
                        },
                        {
                            "cityName": "盘锦市"
                        },
                        {
                            "cityName": "铁岭市"
                        },
                        {
                            "cityName": "朝阳市"
                        },
                        {
                            "cityName": "葫芦岛市"
                        },
                        {
                            "cityName": "新民市"
                        },
                        {
                            "cityName": "瓦房店市"
                        },
                        {
                            "cityName": "庄河市"
                        },
                        {
                            "cityName": "海城市"
                        },
                        {
                            "cityName": "东港市"
                        },
                        {
                            "cityName": "凤城市"
                        },
                        {
                            "cityName": "凌海市"
                        },
                        {
                            "cityName": "北镇市"
                        },
                        {
                            "cityName": "盖州市"
                        },
                        {
                            "cityName": "大石桥市"
                        },
                        {
                            "cityName": "灯塔市"
                        },
                        {
                            "cityName": "调兵山市"
                        },
                        {
                            "cityName": "开原市"
                        },
                        {
                            "cityName": "北票市"
                        },
                        {
                            "cityName": "凌源市"
                        },
                        {
                            "cityName": "兴城市"
                        }
                    ],
                    "provinceName": "辽宁省"
                },
                {
                    "citys": [
                        {
                            "cityName": "长春市"
                        },
                        {
                            "cityName": "吉林市"
                        },
                        {
                            "cityName": "四平市"
                        },
                        {
                            "cityName": "辽源市"
                        },
                        {
                            "cityName": "通化市"
                        },
                        {
                            "cityName": "白山市"
                        },
                        {
                            "cityName": "松原市"
                        },
                        {
                            "cityName": "白城市"
                        },
                        {
                            "cityName": "榆树市"
                        },
                        {
                            "cityName": "德惠市"
                        },
                        {
                            "cityName": "蛟河市"
                        },
                        {
                            "cityName": "桦甸市"
                        },
                        {
                            "cityName": "舒兰市"
                        },
                        {
                            "cityName": "磐石市"
                        },
                        {
                            "cityName": "公主岭市"
                        },
                        {
                            "cityName": "双辽市"
                        },
                        {
                            "cityName": "梅河口市"
                        },
                        {
                            "cityName": "集安市"
                        },
                        {
                            "cityName": "洮南市"
                        },
                        {
                            "cityName": "大安市"
                        },
                        {
                            "cityName": "临江市"
                        },
                        {
                            "cityName": "延吉市"
                        },
                        {
                            "cityName": "图们市"
                        },
                        {
                            "cityName": "敦化市"
                        },
                        {
                            "cityName": "珲春市"
                        },
                        {
                            "cityName": "龙井市"
                        },
                        {
                            "cityName": "和龙市"
                        },
                        {
                            "cityName": "扶余市"
                        }
                    ],
                    "provinceName": "吉林省"
                },
                {
                    "citys": [
                        {
                            "cityName": "哈尔滨市"
                        },
                        {
                            "cityName": "齐齐哈尔市"
                        },
                        {
                            "cityName": "黑河市"
                        },
                        {
                            "cityName": "大庆市"
                        },
                        {
                            "cityName": "伊春市"
                        },
                        {
                            "cityName": "鹤岗市"
                        },
                        {
                            "cityName": "佳木斯市"
                        },
                        {
                            "cityName": "双鸭山市"
                        },
                        {
                            "cityName": "七台河市"
                        },
                        {
                            "cityName": "鸡西市"
                        },
                        {
                            "cityName": "牡丹江市"
                        },
                        {
                            "cityName": "绥化市"
                        },
                        {
                            "cityName": "尚志市"
                        },
                        {
                            "cityName": "五常市"
                        },
                        {
                            "cityName": "讷河市"
                        },
                        {
                            "cityName": "北安市"
                        },
                        {
                            "cityName": "五大连池市"
                        },
                        {
                            "cityName": "嫩江市"
                        },
                        {
                            "cityName": "铁力市"
                        },
                        {
                            "cityName": "同江市"
                        },
                        {
                            "cityName": "富锦市"
                        },
                        {
                            "cityName": "虎林市"
                        },
                        {
                            "cityName": "密山市"
                        },
                        {
                            "cityName": "绥芬河市"
                        },
                        {
                            "cityName": "海林市"
                        },
                        {
                            "cityName": "宁安市"
                        },
                        {
                            "cityName": "安达市"
                        },
                        {
                            "cityName": "肇东市"
                        },
                        {
                            "cityName": "海伦市"
                        },
                        {
                            "cityName": "穆棱市"
                        },
                        {
                            "cityName": "东宁市"
                        },
                        {
                            "cityName": "抚远市"
                        },
                        {
                            "cityName": "漠河市"
                        }
                    ],
                    "provinceName": "黑龙江省"
                },
                {
                    "citys": [
                        {
                            "cityName": "南京市"
                        },
                        {
                            "cityName": "徐州市"
                        },
                        {
                            "cityName": "连云港市"
                        },
                        {
                            "cityName": "宿迁市"
                        },
                        {
                            "cityName": "淮安市"
                        },
                        {
                            "cityName": "盐城市"
                        },
                        {
                            "cityName": "扬州市"
                        },
                        {
                            "cityName": "泰州市"
                        },
                        {
                            "cityName": "南通市"
                        },
                        {
                            "cityName": "镇江市"
                        },
                        {
                            "cityName": "常州市"
                        },
                        {
                            "cityName": "无锡市"
                        },
                        {
                            "cityName": "苏州市"
                        },
                        {
                            "cityName": "常熟市"
                        },
                        {
                            "cityName": "张家港市"
                        },
                        {
                            "cityName": "太仓市"
                        },
                        {
                            "cityName": "昆山市"
                        },
                        {
                            "cityName": "江阴市"
                        },
                        {
                            "cityName": "宜兴市"
                        },
                        {
                            "cityName": "溧阳市"
                        },
                        {
                            "cityName": "扬中市"
                        },
                        {
                            "cityName": "句容市"
                        },
                        {
                            "cityName": "丹阳市"
                        },
                        {
                            "cityName": "如皋市"
                        },
                        {
                            "cityName": "启东市"
                        },
                        {
                            "cityName": "海安市"
                        },
                        {
                            "cityName": "高邮市"
                        },
                        {
                            "cityName": "仪征市"
                        },
                        {
                            "cityName": "兴化市"
                        },
                        {
                            "cityName": "泰兴市"
                        },
                        {
                            "cityName": "靖江市"
                        },
                        {
                            "cityName": "东台市"
                        },
                        {
                            "cityName": "邳州市"
                        },
                        {
                            "cityName": "新沂市"
                        }
                    ],
                    "provinceName": "江苏省"
                },
                {
                    "citys": [
                        {
                            "cityName": "杭州市"
                        },
                        {
                            "cityName": "宁波市"
                        },
                        {
                            "cityName": "湖州市"
                        },
                        {
                            "cityName": "嘉兴市"
                        },
                        {
                            "cityName": "舟山市"
                        },
                        {
                            "cityName": "绍兴市"
                        },
                        {
                            "cityName": "衢州市"
                        },
                        {
                            "cityName": "金华市"
                        },
                        {
                            "cityName": "台州市"
                        },
                        {
                            "cityName": "温州市"
                        },
                        {
                            "cityName": "丽水市"
                        },
                        {
                            "cityName": "建德市"
                        },
                        {
                            "cityName": "慈溪市"
                        },
                        {
                            "cityName": "余姚市"
                        },
                        {
                            "cityName": "平湖市"
                        },
                        {
                            "cityName": "海宁市"
                        },
                        {
                            "cityName": "桐乡市"
                        },
                        {
                            "cityName": "诸暨市"
                        },
                        {
                            "cityName": "嵊州市"
                        },
                        {
                            "cityName": "江山市"
                        },
                        {
                            "cityName": "兰溪市"
                        },
                        {
                            "cityName": "永康市"
                        },
                        {
                            "cityName": "义乌市"
                        },
                        {
                            "cityName": "东阳市"
                        },
                        {
                            "cityName": "临海市"
                        },
                        {
                            "cityName": "温岭市"
                        },
                        {
                            "cityName": "瑞安市"
                        },
                        {
                            "cityName": "乐清市"
                        },
                        {
                            "cityName": "龙港市"
                        },
                        {
                            "cityName": "龙泉市"
                        },
                        {
                            "cityName": "玉环市"
                        }
                    ],
                    "provinceName": "浙江省"
                },
                {
                    "citys": [
                        {
                            "cityName": "合肥市"
                        },
                        {
                            "cityName": "芜湖市"
                        },
                        {
                            "cityName": "蚌埠市"
                        },
                        {
                            "cityName": "淮南市"
                        },
                        {
                            "cityName": "马鞍山市"
                        },
                        {
                            "cityName": "淮北市"
                        },
                        {
                            "cityName": "铜陵市"
                        },
                        {
                            "cityName": "安庆市"
                        },
                        {
                            "cityName": "黄山市"
                        },
                        {
                            "cityName": "滁州市"
                        },
                        {
                            "cityName": "阜阳市"
                        },
                        {
                            "cityName": "宿州市"
                        },
                        {
                            "cityName": "六安市"
                        },
                        {
                            "cityName": "亳州市"
                        },
                        {
                            "cityName": "池州市"
                        },
                        {
                            "cityName": "宣城市"
                        },
                        {
                            "cityName": "巢湖市"
                        },
                        {
                            "cityName": "桐城市"
                        },
                        {
                            "cityName": "天长市"
                        },
                        {
                            "cityName": "明光市"
                        },
                        {
                            "cityName": "界首市"
                        },
                        {
                            "cityName": "宁国市"
                        },
                        {
                            "cityName": "广德市"
                        },
                        {
                            "cityName": "潜山市"
                        },
                        {
                            "cityName": "无为市"
                        }
                    ],
                    "provinceName": "安徽省"
                },
                {
                    "citys": [
                        {
                            "cityName": "厦门市"
                        },
                        {
                            "cityName": "福州市"
                        },
                        {
                            "cityName": "南平市"
                        },
                        {
                            "cityName": "三明市"
                        },
                        {
                            "cityName": "莆田市"
                        },
                        {
                            "cityName": "泉州市"
                        },
                        {
                            "cityName": "漳州市"
                        },
                        {
                            "cityName": "龙岩市"
                        },
                        {
                            "cityName": "宁德市"
                        },
                        {
                            "cityName": "福清市"
                        },
                        {
                            "cityName": "邵武市"
                        },
                        {
                            "cityName": "武夷山市"
                        },
                        {
                            "cityName": "建瓯市"
                        },
                        {
                            "cityName": "永安市"
                        },
                        {
                            "cityName": "石狮市"
                        },
                        {
                            "cityName": "晋江市"
                        },
                        {
                            "cityName": "南安市"
                        },
                        {
                            "cityName": "龙海市"
                        },
                        {
                            "cityName": "漳平市"
                        },
                        {
                            "cityName": "福安市"
                        },
                        {
                            "cityName": "福鼎市"
                        }
                    ],
                    "provinceName": "福建省"
                },
                {
                    "citys": [
                        {
                            "cityName": "南昌市"
                        },
                        {
                            "cityName": "九江市"
                        },
                        {
                            "cityName": "景德镇市"
                        },
                        {
                            "cityName": "鹰潭市"
                        },
                        {
                            "cityName": "新余市"
                        },
                        {
                            "cityName": "萍乡市"
                        },
                        {
                            "cityName": "赣州市"
                        },
                        {
                            "cityName": "上饶市"
                        },
                        {
                            "cityName": "抚州市"
                        },
                        {
                            "cityName": "宜春市"
                        },
                        {
                            "cityName": "吉安市"
                        },
                        {
                            "cityName": "瑞昌市"
                        },
                        {
                            "cityName": "共青城市"
                        },
                        {
                            "cityName": "庐山市"
                        },
                        {
                            "cityName": "乐平市"
                        },
                        {
                            "cityName": "瑞金市"
                        },
                        {
                            "cityName": "德兴市"
                        },
                        {
                            "cityName": "丰城市"
                        },
                        {
                            "cityName": "樟树市"
                        },
                        {
                            "cityName": "高安市"
                        },
                        {
                            "cityName": "井冈山市"
                        },
                        {
                            "cityName": "贵溪市"
                        }
                    ],
                    "provinceName": "江西省"
                },
                {
                    "citys": [
                        {
                            "cityName": "济南市"
                        },
                        {
                            "cityName": "青岛市"
                        },
                        {
                            "cityName": "聊城市"
                        },
                        {
                            "cityName": "德州市"
                        },
                        {
                            "cityName": "东营市"
                        },
                        {
                            "cityName": "淄博市"
                        },
                        {
                            "cityName": "潍坊市"
                        },
                        {
                            "cityName": "烟台市"
                        },
                        {
                            "cityName": "威海市"
                        },
                        {
                            "cityName": "日照市"
                        },
                        {
                            "cityName": "临沂市"
                        },
                        {
                            "cityName": "枣庄市"
                        },
                        {
                            "cityName": "济宁市"
                        },
                        {
                            "cityName": "泰安市"
                        },
                        {
                            "cityName": "滨州市"
                        },
                        {
                            "cityName": "菏泽市"
                        },
                        {
                            "cityName": "胶州市"
                        },
                        {
                            "cityName": "平度市"
                        },
                        {
                            "cityName": "莱西市"
                        },
                        {
                            "cityName": "临清市"
                        },
                        {
                            "cityName": "乐陵市"
                        },
                        {
                            "cityName": "禹城市"
                        },
                        {
                            "cityName": "安丘市"
                        },
                        {
                            "cityName": "昌邑市"
                        },
                        {
                            "cityName": "高密市"
                        },
                        {
                            "cityName": "青州市"
                        },
                        {
                            "cityName": "诸城市"
                        },
                        {
                            "cityName": "寿光市"
                        },
                        {
                            "cityName": "栖霞市"
                        },
                        {
                            "cityName": "海阳市"
                        },
                        {
                            "cityName": "龙口市"
                        },
                        {
                            "cityName": "莱阳市"
                        },
                        {
                            "cityName": "莱州市"
                        },
                        {
                            "cityName": "蓬莱市"
                        },
                        {
                            "cityName": "招远市"
                        },
                        {
                            "cityName": "荣成市"
                        },
                        {
                            "cityName": "乳山市"
                        },
                        {
                            "cityName": "滕州市"
                        },
                        {
                            "cityName": "曲阜市"
                        },
                        {
                            "cityName": "邹城市"
                        },
                        {
                            "cityName": "新泰市"
                        },
                        {
                            "cityName": "肥城市"
                        },
                        {
                            "cityName": "邹平市"
                        }
                    ],
                    "provinceName": "山东省"
                },
                {
                    "citys": [
                        {
                            "cityName": "郑州市"
                        },
                        {
                            "cityName": "开封市"
                        },
                        {
                            "cityName": "洛阳市"
                        },
                        {
                            "cityName": "平顶山市"
                        },
                        {
                            "cityName": "安阳市"
                        },
                        {
                            "cityName": "鹤壁市"
                        },
                        {
                            "cityName": "新乡市"
                        },
                        {
                            "cityName": "焦作市"
                        },
                        {
                            "cityName": "濮阳市"
                        },
                        {
                            "cityName": "许昌市"
                        },
                        {
                            "cityName": "漯河市"
                        },
                        {
                            "cityName": "三门峡市"
                        },
                        {
                            "cityName": "南阳市"
                        },
                        {
                            "cityName": "商丘市"
                        },
                        {
                            "cityName": "周口市"
                        },
                        {
                            "cityName": "驻马店市"
                        },
                        {
                            "cityName": "信阳市"
                        },
                        {
                            "cityName": "荥阳市"
                        },
                        {
                            "cityName": "新郑市"
                        },
                        {
                            "cityName": "登封市"
                        },
                        {
                            "cityName": "新密市"
                        },
                        {
                            "cityName": "偃师市"
                        },
                        {
                            "cityName": "孟州市"
                        },
                        {
                            "cityName": "沁阳市"
                        },
                        {
                            "cityName": "卫辉市"
                        },
                        {
                            "cityName": "辉县市"
                        },
                        {
                            "cityName": "长垣市"
                        },
                        {
                            "cityName": "林州市"
                        },
                        {
                            "cityName": "禹州市"
                        },
                        {
                            "cityName": "长葛市"
                        },
                        {
                            "cityName": "舞钢市"
                        },
                        {
                            "cityName": "义马市"
                        },
                        {
                            "cityName": "灵宝市"
                        },
                        {
                            "cityName": "项城市"
                        },
                        {
                            "cityName": "巩义市"
                        },
                        {
                            "cityName": "邓州市"
                        },
                        {
                            "cityName": "永城市"
                        },
                        {
                            "cityName": "汝州市"
                        },
                        {
                            "cityName": "济源市"
                        }
                    ],
                    "provinceName": "河南省"
                },
                {
                    "citys": [
                        {
                            "cityName": "武汉市"
                        },
                        {
                            "cityName": "十堰市"
                        },
                        {
                            "cityName": "襄阳市"
                        },
                        {
                            "cityName": "荆门市"
                        },
                        {
                            "cityName": "孝感市"
                        },
                        {
                            "cityName": "黄冈市"
                        },
                        {
                            "cityName": "鄂州市"
                        },
                        {
                            "cityName": "黄石市"
                        },
                        {
                            "cityName": "咸宁市"
                        },
                        {
                            "cityName": "荆州市"
                        },
                        {
                            "cityName": "宜昌市"
                        },
                        {
                            "cityName": "随州市"
                        },
                        {
                            "cityName": "丹江口市"
                        },
                        {
                            "cityName": "老河口市"
                        },
                        {
                            "cityName": "枣阳市"
                        },
                        {
                            "cityName": "宜城市"
                        },
                        {
                            "cityName": "钟祥市"
                        },
                        {
                            "cityName": "京山市"
                        },
                        {
                            "cityName": "汉川市"
                        },
                        {
                            "cityName": "应城市"
                        },
                        {
                            "cityName": "安陆市"
                        },
                        {
                            "cityName": "广水市"
                        },
                        {
                            "cityName": "麻城市"
                        },
                        {
                            "cityName": "武穴市"
                        },
                        {
                            "cityName": "大冶市"
                        },
                        {
                            "cityName": "赤壁市"
                        },
                        {
                            "cityName": "石首市"
                        },
                        {
                            "cityName": "洪湖市"
                        },
                        {
                            "cityName": "松滋市"
                        },
                        {
                            "cityName": "宜都市"
                        },
                        {
                            "cityName": "枝江市"
                        },
                        {
                            "cityName": "当阳市"
                        },
                        {
                            "cityName": "恩施市"
                        },
                        {
                            "cityName": "利川市"
                        },
                        {
                            "cityName": "仙桃市"
                        },
                        {
                            "cityName": "天门市"
                        },
                        {
                            "cityName": "潜江市"
                        }
                    ],
                    "provinceName": "湖北省"
                },
                {
                    "citys": [
                        {
                            "cityName": "长沙市"
                        },
                        {
                            "cityName": "衡阳市"
                        },
                        {
                            "cityName": "张家界市"
                        },
                        {
                            "cityName": "常德市"
                        },
                        {
                            "cityName": "益阳市"
                        },
                        {
                            "cityName": "岳阳市"
                        },
                        {
                            "cityName": "株洲市"
                        },
                        {
                            "cityName": "湘潭市"
                        },
                        {
                            "cityName": "郴州市"
                        },
                        {
                            "cityName": "永州市"
                        },
                        {
                            "cityName": "邵阳市"
                        },
                        {
                            "cityName": "怀化市"
                        },
                        {
                            "cityName": "娄底市"
                        },
                        {
                            "cityName": "耒阳市"
                        },
                        {
                            "cityName": "常宁市"
                        },
                        {
                            "cityName": "浏阳市"
                        },
                        {
                            "cityName": "津市市"
                        },
                        {
                            "cityName": "沅江市"
                        },
                        {
                            "cityName": "汨罗市"
                        },
                        {
                            "cityName": "临湘市"
                        },
                        {
                            "cityName": "醴陵市"
                        },
                        {
                            "cityName": "湘乡市"
                        },
                        {
                            "cityName": "韶山市"
                        },
                        {
                            "cityName": "资兴市"
                        },
                        {
                            "cityName": "武冈市"
                        },
                        {
                            "cityName": "邵东市"
                        },
                        {
                            "cityName": "洪江市"
                        },
                        {
                            "cityName": "冷水江市"
                        },
                        {
                            "cityName": "涟源市"
                        },
                        {
                            "cityName": "吉首市"
                        },
                        {
                            "cityName": "宁乡市"
                        }
                    ],
                    "provinceName": "湖南省"
                },
                {
                    "citys": [
                        {
                            "cityName": "广州市"
                        },
                        {
                            "cityName": "深圳市"
                        },
                        {
                            "cityName": "清远市"
                        },
                        {
                            "cityName": "韶关市"
                        },
                        {
                            "cityName": "河源市"
                        },
                        {
                            "cityName": "梅州市"
                        },
                        {
                            "cityName": "潮州市"
                        },
                        {
                            "cityName": "汕头市"
                        },
                        {
                            "cityName": "揭阳市"
                        },
                        {
                            "cityName": "汕尾市"
                        },
                        {
                            "cityName": "惠州市"
                        },
                        {
                            "cityName": "东莞市"
                        },
                        {
                            "cityName": "珠海市"
                        },
                        {
                            "cityName": "中山市"
                        },
                        {
                            "cityName": "江门市"
                        },
                        {
                            "cityName": "佛山市"
                        },
                        {
                            "cityName": "肇庆市"
                        },
                        {
                            "cityName": "云浮市"
                        },
                        {
                            "cityName": "阳江市"
                        },
                        {
                            "cityName": "茂名市"
                        },
                        {
                            "cityName": "湛江市"
                        },
                        {
                            "cityName": "英德市"
                        },
                        {
                            "cityName": "连州市"
                        },
                        {
                            "cityName": "乐昌市"
                        },
                        {
                            "cityName": "南雄市"
                        },
                        {
                            "cityName": "兴宁市"
                        },
                        {
                            "cityName": "普宁市"
                        },
                        {
                            "cityName": "陆丰市"
                        },
                        {
                            "cityName": "恩平市"
                        },
                        {
                            "cityName": "台山市"
                        },
                        {
                            "cityName": "开平市"
                        },
                        {
                            "cityName": "鹤山市"
                        },
                        {
                            "cityName": "四会市"
                        },
                        {
                            "cityName": "罗定市"
                        },
                        {
                            "cityName": "阳春市"
                        },
                        {
                            "cityName": "化州市"
                        },
                        {
                            "cityName": "信宜市"
                        },
                        {
                            "cityName": "高州市"
                        },
                        {
                            "cityName": "吴川市"
                        },
                        {
                            "cityName": "廉江市"
                        },
                        {
                            "cityName": "雷州市"
                        }
                    ],
                    "provinceName": "广东省"
                },
                {
                    "citys": [
                        {
                            "cityName": "南宁市"
                        },
                        {
                            "cityName": "桂林市"
                        },
                        {
                            "cityName": "柳州市"
                        },
                        {
                            "cityName": "梧州市"
                        },
                        {
                            "cityName": "贵港市"
                        },
                        {
                            "cityName": "玉林市"
                        },
                        {
                            "cityName": "钦州市"
                        },
                        {
                            "cityName": "北海市"
                        },
                        {
                            "cityName": "防城港市"
                        },
                        {
                            "cityName": "崇左市"
                        },
                        {
                            "cityName": "百色市"
                        },
                        {
                            "cityName": "河池市"
                        },
                        {
                            "cityName": "来宾市"
                        },
                        {
                            "cityName": "贺州市"
                        },
                        {
                            "cityName": "岑溪市"
                        },
                        {
                            "cityName": "桂平市"
                        },
                        {
                            "cityName": "北流市"
                        },
                        {
                            "cityName": "东兴市"
                        },
                        {
                            "cityName": "凭祥市"
                        },
                        {
                            "cityName": "合山市"
                        },
                        {
                            "cityName": "靖西市"
                        },
                        {
                            "cityName": "平果市"
                        },
                        {
                            "cityName": "荔浦市"
                        }
                    ],
                    "provinceName": "广西壮族自治区"
                },
                {
                    "citys": [
                        {
                            "cityName": "海口市"
                        },
                        {
                            "cityName": "三亚市"
                        },
                        {
                            "cityName": "三沙市"
                        },
                        {
                            "cityName": "儋州市"
                        },
                        {
                            "cityName": "文昌市"
                        },
                        {
                            "cityName": "琼海市"
                        },
                        {
                            "cityName": "万宁市"
                        },
                        {
                            "cityName": "东方市"
                        },
                        {
                            "cityName": "五指山市"
                        }
                    ],
                    "provinceName": "海南省"
                },
                {
                    "citys": [
                        {
                            "cityName": "成都市"
                        },
                        {
                            "cityName": "广元市"
                        },
                        {
                            "cityName": "绵阳市"
                        },
                        {
                            "cityName": "德阳市"
                        },
                        {
                            "cityName": "南充市"
                        },
                        {
                            "cityName": "广安市"
                        },
                        {
                            "cityName": "遂宁市"
                        },
                        {
                            "cityName": "内江市"
                        },
                        {
                            "cityName": "乐山市"
                        },
                        {
                            "cityName": "自贡市"
                        },
                        {
                            "cityName": "泸州市"
                        },
                        {
                            "cityName": "宜宾市"
                        },
                        {
                            "cityName": "攀枝花市"
                        },
                        {
                            "cityName": "巴中市"
                        },
                        {
                            "cityName": "达州市"
                        },
                        {
                            "cityName": "资阳市"
                        },
                        {
                            "cityName": "眉山市"
                        },
                        {
                            "cityName": "雅安市"
                        },
                        {
                            "cityName": "崇州市"
                        },
                        {
                            "cityName": "邛崃市"
                        },
                        {
                            "cityName": "都江堰市"
                        },
                        {
                            "cityName": "彭州市"
                        },
                        {
                            "cityName": "江油市"
                        },
                        {
                            "cityName": "什邡市"
                        },
                        {
                            "cityName": "广汉市"
                        },
                        {
                            "cityName": "绵竹市"
                        },
                        {
                            "cityName": "阆中市"
                        },
                        {
                            "cityName": "华蓥市"
                        },
                        {
                            "cityName": "峨眉山市"
                        },
                        {
                            "cityName": "万源市"
                        },
                        {
                            "cityName": "简阳市"
                        },
                        {
                            "cityName": "西昌市"
                        },
                        {
                            "cityName": "康定市"
                        },
                        {
                            "cityName": "马尔康市"
                        },
                        {
                            "cityName": "隆昌市"
                        },
                        {
                            "cityName": "射洪市"
                        },
                        {
                            "cityName": "会理市"
                        }
                    ],
                    "provinceName": "四川省"
                },
                {
                    "citys": [
                        {
                            "cityName": "贵阳市"
                        },
                        {
                            "cityName": "六盘水市"
                        },
                        {
                            "cityName": "遵义市"
                        },
                        {
                            "cityName": "安顺市"
                        },
                        {
                            "cityName": "毕节市"
                        },
                        {
                            "cityName": "铜仁市"
                        },
                        {
                            "cityName": "清镇市"
                        },
                        {
                            "cityName": "赤水市"
                        },
                        {
                            "cityName": "仁怀市"
                        },
                        {
                            "cityName": "凯里市"
                        },
                        {
                            "cityName": "都匀市"
                        },
                        {
                            "cityName": "兴义市"
                        },
                        {
                            "cityName": "福泉市"
                        },
                        {
                            "cityName": "盘州市"
                        },
                        {
                            "cityName": "兴仁市"
                        }
                    ],
                    "provinceName": "贵州省"
                },
                {
                    "citys": [
                        {
                            "cityName": "昆明市"
                        },
                        {
                            "cityName": "曲靖市"
                        },
                        {
                            "cityName": "玉溪市"
                        },
                        {
                            "cityName": "丽江市"
                        },
                        {
                            "cityName": "昭通市"
                        },
                        {
                            "cityName": "普洱市"
                        },
                        {
                            "cityName": "临沧市"
                        },
                        {
                            "cityName": "保山市"
                        },
                        {
                            "cityName": "安宁市"
                        },
                        {
                            "cityName": "宣威市"
                        },
                        {
                            "cityName": "芒市"
                        },
                        {
                            "cityName": "瑞丽市"
                        },
                        {
                            "cityName": "大理市"
                        },
                        {
                            "cityName": "楚雄市"
                        },
                        {
                            "cityName": "个旧市"
                        },
                        {
                            "cityName": "开远市"
                        },
                        {
                            "cityName": "蒙自市"
                        },
                        {
                            "cityName": "弥勒市"
                        },
                        {
                            "cityName": "景洪市"
                        },
                        {
                            "cityName": "文山市"
                        },
                        {
                            "cityName": "香格里拉市"
                        },
                        {
                            "cityName": "腾冲市"
                        },
                        {
                            "cityName": "水富市"
                        },
                        {
                            "cityName": "澄江市"
                        },
                        {
                            "cityName": "泸水市"
                        }
                    ],
                    "provinceName": "云南省"
                },
                {
                    "citys": [
                        {
                            "cityName": "西安市"
                        },
                        {
                            "cityName": "延安市"
                        },
                        {
                            "cityName": "铜川市"
                        },
                        {
                            "cityName": "渭南市"
                        },
                        {
                            "cityName": "咸阳市"
                        },
                        {
                            "cityName": "宝鸡市"
                        },
                        {
                            "cityName": "汉中市"
                        },
                        {
                            "cityName": "榆林市"
                        },
                        {
                            "cityName": "商洛市"
                        },
                        {
                            "cityName": "安康市"
                        },
                        {
                            "cityName": "韩城市"
                        },
                        {
                            "cityName": "华阴市"
                        },
                        {
                            "cityName": "兴平市"
                        },
                        {
                            "cityName": "彬州市"
                        },
                        {
                            "cityName": "神木市"
                        },
                        {
                            "cityName": "子长市"
                        }
                    ],
                    "provinceName": "陕西省"
                },
                {
                    "citys": [
                        {
                            "cityName": "兰州市"
                        },
                        {
                            "cityName": "嘉峪关市"
                        },
                        {
                            "cityName": "金昌市"
                        },
                        {
                            "cityName": "白银市"
                        },
                        {
                            "cityName": "天水市"
                        },
                        {
                            "cityName": "酒泉市"
                        },
                        {
                            "cityName": "张掖市"
                        },
                        {
                            "cityName": "武威市"
                        },
                        {
                            "cityName": "庆阳市"
                        },
                        {
                            "cityName": "平凉市"
                        },
                        {
                            "cityName": "定西市"
                        },
                        {
                            "cityName": "陇南市"
                        },
                        {
                            "cityName": "玉门市"
                        },
                        {
                            "cityName": "敦煌市"
                        },
                        {
                            "cityName": "临夏市"
                        },
                        {
                            "cityName": "合作市"
                        },
                        {
                            "cityName": "华亭市"
                        }
                    ],
                    "provinceName": "甘肃省"
                },
                {
                    "citys": [
                        {
                            "cityName": "西宁市"
                        },
                        {
                            "cityName": "海东市"
                        },
                        {
                            "cityName": "格尔木市"
                        },
                        {
                            "cityName": "德令哈市"
                        },
                        {
                            "cityName": "玉树市"
                        },
                        {
                            "cityName": "茫崖市"
                        }
                    ],
                    "provinceName": "青海省"
                },
                {
                    "citys": [
                        {
                            "cityName": "拉萨市"
                        },
                        {
                            "cityName": "日喀则市"
                        },
                        {
                            "cityName": "昌都市"
                        },
                        {
                            "cityName": "林芝市"
                        },
                        {
                            "cityName": "山南市"
                        },
                        {
                            "cityName": "那曲市"
                        }
                    ],
                    "provinceName": "西藏自治区"
                },
                {
                    "citys": [
                        {
                            "cityName": "银川市"
                        },
                        {
                            "cityName": "石嘴山市"
                        },
                        {
                            "cityName": "吴忠市"
                        },
                        {
                            "cityName": "中卫市"
                        },
                        {
                            "cityName": "固原市"
                        },
                        {
                            "cityName": "灵武市"
                        },
                        {
                            "cityName": "青铜峡市"
                        }
                    ],
                    "provinceName": "宁夏回族自治区"
                },
                {
                    "citys": [
                        {
                            "cityName": "乌鲁木齐市"
                        },
                        {
                            "cityName": "克拉玛依市"
                        },
                        {
                            "cityName": "吐鲁番市"
                        },
                        {
                            "cityName": "哈密市"
                        },
                        {
                            "cityName": "喀什市"
                        },
                        {
                            "cityName": "阿克苏市"
                        },
                        {
                            "cityName": "库车市"
                        },
                        {
                            "cityName": "和田市"
                        },
                        {
                            "cityName": "阿图什市"
                        },
                        {
                            "cityName": "阿拉山口市"
                        },
                        {
                            "cityName": "博乐市"
                        },
                        {
                            "cityName": "昌吉市"
                        },
                        {
                            "cityName": "阜康市"
                        },
                        {
                            "cityName": "库尔勒市"
                        },
                        {
                            "cityName": "伊宁市"
                        },
                        {
                            "cityName": "奎屯市"
                        },
                        {
                            "cityName": "塔城市"
                        },
                        {
                            "cityName": "乌苏市"
                        },
                        {
                            "cityName": "阿勒泰市"
                        },
                        {
                            "cityName": "霍尔果斯市"
                        },
                        {
                            "cityName": "石河子市"
                        },
                        {
                            "cityName": "阿拉尔市"
                        },
                        {
                            "cityName": "图木舒克市"
                        },
                        {
                            "cityName": "五家渠市"
                        },
                        {
                            "cityName": "北屯市"
                        },
                        {
                            "cityName": "铁门关市"
                        },
                        {
                            "cityName": "双河市"
                        },
                        {
                            "cityName": "可克达拉市"
                        },
                        {
                            "cityName": "昆玉市"
                        },
                        {
                            "cityName": "胡杨河市"
                        }
                    ],
                    "provinceName": "新疆维吾尔自治区"
                },
                {
                    "citys": [
                        {
                            "cityName": "香港特别行政区"
                        }
                    ],
                    "provinceName": "香港"
                },
                {
                    "citys": [
                        {
                            "cityName": "澳门特别行政区"
                        }
                    ],
                    "provinceName": "澳门"
                },
                {
                    "citys": [
                        {
                            "cityName": "台北市"
                        },
                        {
                            "cityName": "新北市"
                        },
                        {
                            "cityName": "桃园市"
                        },
                        {
                            "cityName": "台中市"
                        },
                        {
                            "cityName": "台南市"
                        },
                        {
                            "cityName": "高雄市"
                        },
                        {
                            "cityName": "基隆市"
                        },
                        {
                            "cityName": "新竹市"
                        },
                        {
                            "cityName": "嘉义市"
                        }
                    ],
                    "provinceName": "台湾省"
                }
            ],
            activeCity:[],
            isShow: false,
            center: [116.397428, 39.90923],
            searchOption: {
                city: '全国',
                citylimit: true
            },
            componentMarker: {
                position: [116.397428, 39.90923],
                visible: true,
                draggable: false
            },
            toParentsMapInfo: {},
            zoom: 12,
            geocoder: null,
            geolocation: null,
            citySearch: null,
            city: ''
        }
    },
    created() {
        this.options.forEach(e=>{
            e.value = e.provinceName
            e.label = e.provinceName
            if(e.citys){
                e.citys.forEach(j=>{
                    j.value = j.cityName
                    j.label = j.cityName
                })
                e.children = e.citys
            }
        })
        // console.log(this.options);
    },
    mounted() {
    },
    methods: {
        changeCity(e){
            if(e){
                this.city =e[1]
            }else{
                this.city = '全国'
            }
        },
        clickMap(e) {
            console.log(e);

            this.center = [e.lnglat.lng, e.lnglat.lat]
            this.componentMarker.position = [e.lnglat.lng, e.lnglat.lat]
            var geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: 'all',
            })
            var _this = this;
            this.geocoder.getAddress(this.center, function (status, result) {
                console.log(3, result)
                //根据坐标获取位置
                if (status === 'complete' && result.info === 'OK') {
                    _this.$emit('getGeocoder', { center: _this.center, formattedAddress: result.regeocode.formattedAddress })
                }
            })
        },
        initMap(e) {
            // console.log(e)
            var _this = this;
            AMap.plugin('AMap.Geocoder', () => {
                this.geocoder = new AMap.Geocoder({
                })
            })
            if (this.oldCenter.length == 0) {
                AMap.plugin('AMap.CitySearch', () => {
                    this.citySearch = new AMap.CitySearch({
                    })
                    this.citySearch.getLocalCity(function (status, result) {
                        console.log(status, result)
                        var ts = result.rectangle.split(';')
                        var ts1 = ts[0].split(',')
                        var ts2 = ts[1].split(',')
                        var newArray = []
                        newArray[0] = Number(ts1[0]) + ((Number(ts2[0]) - Number(ts1[0])) / 2)
                        newArray[1] = Number(ts1[1]) + ((Number(ts2[1]) - Number(ts1[1])) / 2)

                        // console.log(Number(ts2[0]) - Number(ts1[0]), ts2)
                        // console.log(newArray)

                        if (status === 'complete' && result.info === 'OK') {
                            if (result && result.city && result.bounds) {
                                _this.city = result.city
                                _this.center = newArray
                                _this.componentMarker.position = newArray
                                _this.$emit('getGeocoder', { center: _this.center, formattedAddress: '' })
                                _this.activeCity = [result.province,result.city]
                                // AMap.setBounds(result.bounds);
                            }
                        } else {

                        }
                    })
                })
            }

        },
        initMapInfo() {
            // console.log(this.center)
            this.toParentsMapInfo.value = this.props.toParentsMap
        },
        onSearchResult(pois) {
            // console.log(pois)
        },
        selectPoi(e) {
            // console.log(e)
            let poi = e.poi
            if (poi.address.length > 0 && poi.location != undefined &&
              poi.location != null && poi.location != '') {
                let position = [e.poi.location.lng, e.poi.location.lat]
                this.center = position
                this.componentMarker.position = position
                this.toParentsMapInfo = {
                    id: poi.id,
                    lng: poi.location.lng, //经度
                    lat: poi.location.lat, //纬度
                    districtCode: poi.adcode, //区编码
                    //address: poi.address, //地址
                    //district: poi.district, //省名称 市名称 区名称
                    //name: poi.name, //查询地址
                    province: '', //省地址
                    city: '', //市地址
                    zone: '', //区地址
                    detailedAddress: '' //详细地址
                }

                let reg = /.+?(省|市|自治区|自治州|盟|旗|县|区)/g // 截取地图地址
                let detailedAddress = poi.district + poi.address + poi.name
                let districtList = detailedAddress.match(reg)
                if (districtList.length < 3) {
                    this.toParentsMapInfo.city = districtList[0]
                    this.toParentsMapInfo.zone = districtList[1]
                } else {
                    this.toParentsMapInfo.city = districtList[1]
                    this.toParentsMapInfo.zone = districtList[2]
                }
                this.toParentsMapInfo.province = districtList[0]
                this.toParentsMapInfo.detailedAddress = detailedAddress

                // console.log(this.toParentsMapInfo)
                this.$emit('getGeocoder', { center: this.center, formattedAddress: detailedAddress })
            } else {
                this.$message.error('输入的位置定位失败, 请输入详细位置进行定位!')
                // proxy.$modal.msgWarning('输入的位置定位失败, 请输入详细位置进行定位!')
            }
        }
    }
}
</script>

<style lang="scss">
.amap-sug-result {
    z-index: 2099;
}

.amap-copyright {
    height: 24px;
}

.el-vue-search-box-container {
    border: 1px solid #e2e2e2;
    height: 30px!important;
    box-shadow: none;
    left: 100px !important;
}

.el-vue-search-box-container{
    position: absolute;
    margin-top: -14px;
    input{
        height: 30px!important;
    }
}

.aMapMain {
    width: 100%;
    .aMap {
        width: 100%;
        height: 350px;
    }
    .aMapAddress {
        text-align: left;
        p {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    .isDisplayAMap {
        width: 100%;
        height: 36px;

        .el-vue-amap {
            display: none;
        }

        .el-vue-search-box-container {
            position: initial;
            width: 100%;
        }
    }
}

.ccittxt {
    position: absolute;
    top: 1px;
    height: 35px;
    z-index: 10;
}

.ccittxt .el-cascader {
    height: 100%;
    width:100px;
}

.ccittxt .el-cascader .el-input {
    height: 100%;

}


</style>