<template>
    <div class="base-container" style="overflow-y: hidden!important;">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isPtStatistics == 1"><a>一体化教研统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-else><a>听评教研</a></el-breadcrumb-item>
                <el-breadcrumb-item>听评课详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="content-box" >
            <div class="eva-left-box">
                <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
                    <el-descriptions :column="3" class="detailFF" title="基本信息">
                        <el-descriptions-item width="33.33%" label="市区县">
                            <el-tree-select disabled :props="defaultProps"
                                            v-model="form.countyId" :default-expand-all="true" :data="orgList" check-strictly
                                            :render-after-expand="true" />
                        </el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="学校">{{ form.schoolName }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="学段">{{ form.periodName }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="年级">{{ form.gradeName }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="学科">{{ form.subjectName }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="课程标题">{{ form.title }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="听评课性质">
                            <span v-if="form.lessonNature === '1' ||
                                        form.lessonNature === null ||
                                        form.lessonNature === '' ">普通</span>
                            <span v-if="form.lessonNature === '2'">名师工作室</span>
                            <span v-if="form.lessonNature === '3'">名校长工作室</span>
                            <span v-if="form.lessonNature === '4'">群组任务</span>
                        </el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="工作室" v-if="form.groupName">{{ form.groupName }}</el-descriptions-item>
                        <el-descriptions-item width="33.33%" label="类型">
                            <span v-if="form.type === 0">推门</span>
                            <span v-if="form.type === 1">线上</span>
                            <span v-if="form.type === 2">本地视频</span>
                        </el-descriptions-item>
                        <el-descriptions-item width="33.33%" v-if="form.type === 1" label="听课地址">
                            <el-button @click="goTk" type="primary" class="eva-btn" icon="">去听课</el-button>
                        </el-descriptions-item>
                        <!--                        <el-descriptions-item width="33.33%" label="授课教师"></el-descriptions-item>-->
                    </el-descriptions>
                    <el-form-item v-if="form.type === 0" class="fs14" label-width="68px" label="听课地址">
                        {{ form.address }}
                    </el-form-item>
                    <el-row v-if="form.scaleList && form.scaleList.length !== 0">
                        <el-col :span="12" >
                            <el-form-item class="fs14" label-width="68px" label="听评量表" prop="scaleList">
                                <div v-for="item in form.scaleList" style="color: #409efe;width: 100%;">
                                    <div class="cursor" @click="preview(item)">{{ item.title }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item class="fs14" label-width="68px"  label="任务时段">
                                {{ timestampToTime(form.beginTime) }}   至   {{timestampToTime(form.endTime) }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item class="fs14" label-width="98px"  label="评课截止时间">
                                {{ timestampToTime(form.rateEndTime)}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="68px" label="授课教师">
                                {{ form.lectureTeacherName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="68px" label="评课组长" prop="" v-if="form.leadTeacherName">
                                <div>{{ form.leadTeacherName }}</div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item v-if="form.guideExpertTxt" class="fs14" label-width="68px" label="指导专家" prop="">
                                <expendText :text="form.guideExpertTxt"></expendText>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item v-if="form.listenTeacherTxt" class="fs14" label-width="68px" label="听课教师" prop="">
                        <expendText :text="form.listenTeacherTxt"></expendText>
                    </el-form-item>
                    <el-form-item v-if="form.rateTeacherTxt" class="fs14" label-width="68px" label="评课教师" prop="">
                        <expendText :text="form.rateTeacherTxt"></expendText>
                    </el-form-item>


                    <el-row v-if="isShow && form.status != 3">
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="82px" label="听课邀请码" prop="">
                                <div style="position: relative;" class="csbbb1">
                                    <img alt="" @click.stop="handleDownload()"
                                         title="下载" src="../../assets/icon/img/download.png" class="eicon"
                                         style="width:15px;height:15px;right: 5px;top: 5px;cursor: pointer;z-index: 99;"/>
                                    <vueQr ref="dyplg" :text="qrCode.shareTkUrl"
                                           :size="qrCode.size"
                                           :margin="qrCode.margin"/>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="82px" label="评课邀请码" prop="">
                                <div style="position: relative;" class="csbbb1">
                                    <img alt="" @click.stop="handleDownload1()"
                                         title="下载" src="../../assets/icon/img/download.png" class="eicon"
                                         style="width:15px;height:15px;right: 5px;top: 5px;cursor: pointer;z-index: 99;"/>
                                    <vueQr ref="dyplg1" :text="qrCode.sharePkUrl"
                                           :size="qrCode.size"
                                           :margin="qrCode.margin"/>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="82px" label="专家邀请码" prop="">
                                <div style="position: relative;" class="csbbb1">
                                    <img alt="" @click.stop="handleDownload2()"
                                         title="下载" src="../../assets/icon/img/download.png" class="eicon"
                                         style="width:15px;height:15px;right: 5px;top: 5px;cursor: pointer;z-index: 99;"/>
                                    <vueQr ref="dyplg2" :text="qrCode.shareZjUrl"
                                           :size="qrCode.size"
                                           :margin="qrCode.margin"/>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                        <el-form-item label-width="0" label="" v-show="form.type == 0">
                            <aMap isDetail v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter"
                                  :isShowAMap="true">
                            </aMap>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
            <div class="eva-right-box">
                <div class="discuss-title-box">
                    &nbsp;&nbsp;听评课讨论区
                </div>
                <div class="discuss-sub-box" >
                    <div class="discuss-info">
                        <el-input
                          type="textarea"
                          @focus="focusInput"
                          resize="none"
                          placeholder="请输入讨论内容"
                          v-model="discussWordContent"
                          autofocus
                          class="discuss-info-input"
                        >
                        </el-input>
                    </div>
                    <div class="discuss-btn-box">
                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/evaluation/discussAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadFileSuccess"
                                       :headers="{ token }"
                                       accept=".doc,.docx,.ppt,.pptx,.pdf"
                                       :before-upload="beforeFileUpload">

                                <img class="icon-upload-img" alt="上传附件" src="../../assets/icon/img/upload_file1.png">
                            </el-upload>
                        </div>

                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/evaluation/discussAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadImageSuccess"
                                       :headers="{ token }"
                                       accept=".png,.jpeg,.jpg"
                                       :before-upload="beforeImageUpload">
                                <img class="icon-upload-img" alt="上传图片" src="../../assets/icon/img/upload_img1.png">
                            </el-upload>
                        </div>
                        <el-button type="primary" style="margin-left: 120px;" round @click="submitDiscuss">
                            发表
                        </el-button>
                    </div>
                </div>
                <el-scrollbar class="discuss-show-box">
                    <div class="discuss-one" v-for="item in discussList">
                        <div class="discuss-user-avatar">
                            <img alt="无" @error="onImageError" :src="item.avatar">
                        </div>
                        <div class="discuss-user-content">
                            <div class="user-info">
                                <div class="flex-item user-true-name">{{item.createUserName}}</div>
                                <div class="flex-item user-role">
                                    <div class="role2" v-show="item.roleType===0">授课人</div>
                                    <div class="role2" v-show="item.roleType===1">评课人</div>
                                    <div class="role2" v-show="item.roleType===2">听课人</div>
                                    <div class="role2" v-show="item.roleType===3">评课组长</div>
                                    <div class="role1" v-show="item.roleType===4">专家</div>
                                </div>
                                <div class="flex-item content-time">{{timestampToTime(item.createTime)}}</div>
                            </div>
                            <div class="content-info" v-if="item.discussType===1">
                                {{item.content}}
                            </div>
                            <div class="img-info" v-if="item.discussType===2">
                                <el-image alt="" :src="item.imgContent"
                                          :preview-src-list="item.imgList" ></el-image>
                            </div>
                            <div class="audio-info" v-if="item.discussType===3">
                                <audio controls controlslist="nodownload noplaybackrate" ref="audio">
                                    <source type="audio/mpeg" :src="item.audioContent" />
                                </audio>
                            </div>
                            <div class="file-info" v-if="item.discussType===4">
                                <div class="file-box">
                                    <div class="file-icon">
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-pdf@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('doc',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-doc@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('ppt',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-ppt@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleUnKnow" class="icon-image" v-if="!comStr('ppt',item.file.fileType)&&!comStr('doc',item.file.fileType)&&!comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-unknown@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                    </div>
                                    <div class="file-detail">
                                        <div class="name-box" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">{{item.file.fileName}}</div>
                                        <div class="size-box">
                                            <div class="size-num">{{item.file.fileSize}}</div>
                                            <div class="file-view">
                                                <img alt="" src="@/assets/icon/img/download.png" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="discussList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>

        <el-dialog :title="editNum != null ? '编辑评价维度' : '新增评价维度'" v-model="addQuse" width="60%">
            <el-form ref="quseform" :model="addQuseForm" :rules="addQuesrules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="addQuseForm.type == 3 ? '考核项目' : '题目名称'" prop="questionName">
                            <el-input type="textarea" v-model="addQuseForm.questionName" placeholder="最多1000个字"
                                      maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="题型" prop="type">
                            <el-select style="width: 100%;" v-model="addQuseForm.type" placeholder="请选择课题级别">
                                <el-option v-for="item in quesTypeList" :key="item.value" :label="item.label"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 3 || addQuseForm.type == 4">
                    <el-col :span="12">
                        <el-form-item label="选项分数" prop="totalScore">
                            <el-input-number step-strictly v-model="addQuseForm.totalScore" :min="0" :max="100" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 1 || addQuseForm.type == 2">
                    <el-col>
                        <el-form-item label="选项" prop="quesXXList">
                            <el-table ref="quesXXList" border :data="addQuseForm.itemList" style="width: 100%"
                                      max-height="250">
                                <el-table-column prop="itemName" label="选项文字">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.itemName" placeholder="最多32个字" maxlength="32" />
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemValue" label="选项分数">
                                    <template #default="scope">
                                        <el-input-number step-strictly v-model="scope.row.itemValue" :min="0" :max="100" />
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="200">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                                   @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                                   @click.prevent="addQuesRow(scope.$index)">
                                            增加
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                                   @click.prevent="moveUpRow(scope.$index)">
                                            上移
                                        </el-button>
                                        <el-button link type="primary" size="small" @click.prevent="downRow(scope.$index)">
                                            下移
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button plain type="primary" class="mt10" style="width: 100%"
                                       @click="onAddItem">增加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addQuesSumbit">确 定</el-button>
                    <el-button @click="addQuse = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                  : ''
                            }}]</span><span v-if="item.type == 4 && item.totalScore"
                                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                                item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                            }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                    item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                      '文字内容'
                                      : ''
                                }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                    item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
        <el-dialog @close="gbIfram" :title="'听课'" v-model="isTk" width="80%" top="50px">
            <iframe id="cktpkfx" style="width: 100%;height:calc(100vh - 250px)" :src="form.address"
                    frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { timestampToTime } from "@/utils/date";
import aMap from '@/components/aMap/index' //引入封装好的组件
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg, orgListTree } from "@/api/admin/system";
import { rateScaleList, evaluationCreate, evaluationInfo,
    evaluationUpdate, rateScaleInfo,submitEvaDiscuss,listEvaDiscuss} from "@/api/admin/evaluation";
import html2canvas from "html2canvas";
import {ElLoading} from "element-plus";
import { serviceUrl,serviceWebUrl } from "@/utils/global"

export default {
    name: "demoAdd",
    components: {aMap, vueQr},
    data() {
        return {
            serviceUrl,
            orgList: [],
            timestampToTime,
            toParentsMap: {},
            qrCode: {
                shareTkUrl: '',
                sharePkUrl: '',
                shareZjUrl: '',
                size: 120,
                margin:10,
                logoMargin:5,
                logoSrc: "https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/jz_logo.png",
            },
            isShow: false,
            oldCenter: [],
            rateScaleList: [],
            lessonId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                countyId: '',
                schoolId: '',
                type: 0,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                lectureTeacher: '',
                rateTeachers: '',
                rateTeacherList: [],
                guideExpertList:[],
                scaleIds: '',
                address: '',
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            previewData: {},
            previewShow: false,
            groupList: [],
            isPtStatistics: 0,
            isTk: false,
            discussType:1, //研讨内容类型：1-文字 2-图片 3-音频 4-附件
            discussWordContent:'',
            wordContent: '',//文字评论
            imgContent: '',//图片评论
            fileContent: '',//附件评论
            discussList:[],
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
        this.getRateScaleList();
    },
    mounted() {
        if (this.$route.query.lessonId) {
            this.lessonId = this.$route.query.lessonId
            this.qrCode.shareTkUrl = serviceWebUrl+'/inviteMember?id=' + this.lessonId + '&type=1'
            this.qrCode.sharePkUrl = serviceWebUrl+'/inviteMember?id=' + this.lessonId + '&type=2'
            this.qrCode.shareZjUrl = serviceWebUrl+'/inviteMember?id=' + this.lessonId + '&type=6'
            // console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isPtStatistics) {
            this.isPtStatistics = this.$route.query.isPtStatistics
        }
    },
    unmounted() {},
    computed: {},
    methods: {
        goTk() {
            // window.open(this.form.address)
            // window.open(this.form.address,'location=no','',false);
            var newDate = new Date().getTime();
            console.log(newDate);
            console.log(this.form.beginTime)
            console.log(this.form.endTime)
            if (newDate > this.form.beginTime && newDate < this.form.endTime) {
                this.isTk = true;
                setTimeout(() => {
                    var iframe = document.getElementById("cktpkfx"); // 获取到要关闭的iframe元素
                    iframe.setAttribute("src", this.form.address);
                })
            } else {
                this.$message.error('已过/未到任务时段中')
            }
            // if (this.form.status == 3) {

            // }

        },
        gbIfram() {
            var iframe = document.getElementById("cktpkfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 2) {
                listTeacherGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            } else if (e == 3) {
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getGeocoder(e) {
            console.log(e);
            if (this.form.type == 0) {
                this.form.lat = e.center[1];
                this.form.lng = e.center[0];

                this.form.address = e.formattedAddress;
            }

        },
        getRateScaleList() {
            rateScaleList().then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                evaluationInfo({ lessonId: this.lessonId }).then(res => {
                    this.changeLessonNature(res.data.data.lessonNature)
                    this.form = res.data.data;
                    var rateTeacherTxt = [];
                    this.form.rateTeacherList.forEach(e => {
                        rateTeacherTxt.push(e.trueName)
                    })
                    this.form.rateTeacherTxt = rateTeacherTxt.join('、')

                    var listenTeacherTxt = []
                    this.form.listenTeacherList.forEach(e => {
                        listenTeacherTxt.push(e.trueName)
                    })
                    this.form.listenTeacherTxt = listenTeacherTxt.join('、')

                    var guideExpertTxt = []
                    this.form.guideExpertList.forEach(e => {
                        guideExpertTxt.push(e.trueName)
                    })
                    this.form.guideExpertTxt = guideExpertTxt.join('、')


                    this.countyList.forEach(e => {
                        if (this.form.countyId == e.orgId) {
                            this.form.countName = e.orgName;
                        }
                    })
                    // if (this.form.scaleList.length != 0) {
                    //     this.form.scaleObj = this.form.scaleList[0]
                    // }

                    orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                        this.schoolList = res.data.data;
                        this.schoolList.forEach(e => {
                            if (e.orgId == this.form.schoolId) {
                                this.form.schoolName = e.orgName;
                            }
                        })
                    })

                    if (this.form.type == 0) {
                        this.oldCenter = [this.form.lng, this.form.lat]

                    }
                    if (this.form.scaleList.length != 0) {
                        this.form.scaleIds = this.form.scaleList[0].scaleId;
                    }

                    // console.log(this.periodList)
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                    this.isShow = true;
                    if (this.form.type === 0) {
                        setTimeout(() => {
                            this.$refs.zdyMap.center = this.oldCenter;
                            this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                        }, 100)
                    }
                    this.getDisList()
                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
            // if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2) {
            this.defaultProps = {
                children: 'childrenList',
                label: 'orgName',
                value: 'orgId'
            }
            orgListTree().then(res => {
                res.data.data.forEach(e => {
                    if (e.childrenList) {
                        e.childrenList = e.childrenList.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        e.childrenList.forEach(m => {
                            if (m.childrenList) {
                                m.childrenList = m.childrenList.filter(itemB => {
                                    return itemB.orgType != 5;
                                })
                            }
                        })
                    }
                })
                this.orgList = res.data.data;
                this.$forceUpdate()
            })
            // } else {
            //     this.defaultProps = {
            //         children: '',
            //         label: 'orgName',
            //         value: 'orgId'
            //     }
            //     userListOrgUsers().then(res => {
            //         res.data.data.orgList.forEach(e => {
            //         })
            //         this.orgList = res.data.data.orgList;
            //         this.$forceUpdate()
            //     })
            // }
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.bannerUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.form.rateTeachers = this.form.rateTeacherList.toString()
            // this.form.scaleIds = this.form.scaleList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    if (obj.type == 1) {
                        obj.lat = ''
                        obj.lng = ''
                    }
                    delete obj.rateTeacherList
                    // delete obj.scaleList

                    if (this.lessonId) {
                        evaluationUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/evaluation', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        evaluationCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/evaluation', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },
        // 取消按钮
        cancel() {
            if (this.isPtStatistics == 1) {
                this.replace('/ptStatistics')
            } else {
                this.replace('/evaluation')
            }
            // this.jAFileList = [];

            // this.$emit('isTg', true)
        },
        handleDownload() {
            console.log(this.$refs.dyplg);
            html2canvas(this.$refs.dyplg.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-听课二维码';
                // // 下载
                eleLink.click();
            })
        },
        handleDownload1() {
            console.log(this.$refs.dyplg1);
            html2canvas(this.$refs.dyplg1.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-评课二维码';
                // // 下载
                eleLink.click();
            })
        },
        handleDownload2() {
            console.log(this.$refs.dyplg2);
            html2canvas(this.$refs.dyplg2.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-专家二维码';
                // // 下载
                eleLink.click();
            })
        },
        handleUploadImageSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.imgContent = uploadFile.response.data;
                this.discussType = 2
                this.submitDiscuss()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeImageUpload(rawFile){
            if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleUploadFileSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.fileContent = uploadFile.response.data;
                this.discussType = 4
                this.submitDiscuss()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeFileUpload(rawFile){
            return true
        },
        focusInput(){
            this.closeInput = false
        },
        handleFIleStatus(status){
            if (status === 1) {

            }else {
                this.$message.info("文件正在处理，请稍后刷新预览")
            }
        },
        handleUnKnow(){
            this.$message.info("该文件类型暂不支持预览")
        },
        comStr(baseStr,type){
            return type!==''&& type.toUpperCase().includes(baseStr.toUpperCase());
        },
        gainFileImgList(baseUrl,page,){
            const list = [];
            for (let i = 1; i <=page ; i++) {
                list.push(baseUrl+'/' + i+'.png');
            }
            return list;
        },
        getDisList() {
            listEvaDiscuss({ lessonId: this.lessonId }).then(res => {
                this.discussList = res.data.data;
            })
        },
        submitDiscuss(){
            const loading = ElLoading.service({ fullscreen: true })
            const obj = {
                lessonId: this.form.lessonId,
                content: this.wordContent,
                imgContent: this.imgContent,
                fileContent: this.fileContent,
            };
            //文字评论
            if (this.discussType===1){
                this.wordContent = this.discussWordContent
                if (this.wordContent===''){
                    this.$message.info("讨论内容不能为空")
                    return
                }
                obj.content=this.wordContent;
                obj.imgContent = '';
                obj.fileContent = ''
            }
            //图片评论
            if (this.discussType===2){
                if (this.imgContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = this.imgContent;
                obj.fileContent = ''
            }
            //附件评论
            if (this.discussType===4){
                if (this.fileContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = '';
                obj.fileContent = this.fileContent
            }
            submitEvaDiscuss(obj).then(res =>{
                if (res.data.code === 1) {
                    this.$message.success("发表成功");
                    this.wordContent = ''
                    this.discussWordContent = ''
                    this.imgContent = ''
                    this.fileContent = ''
                    this.discussType = 1
                    this.getDisList();
                } else {
                    this.$message.error("操作失败")
                    this.discussType = 1
                }
            })
            loading.close();
        },
    }
};
</script>
<style scoped>
.base-container {
    padding: 0 0 0 0;
    overflow-y: hidden!important;
}
.el-main{
    overflow-y: hidden!important;
}
.content-box{
    height: calc(100vh - 10px);
    display: flex;
    .eva-left-box{
        padding: 15px 0 15px 0;
        flex: 1;
    }
    .eva-right-box{
        padding: 0 5px 15px 5px;
        width: 260px;
        height: 90%;
        margin-left: 10px;
        //background-color: rgba(255,255,255,.9);
        margin-top: 15px;
    }
}

.discuss-title-box{
    height: 30px;
    line-height: 30px;
    background-color: rgba(255,255,255,.9);
}

.discuss-sub-box{
    width: 100%;
    background-color: rgba(255,255,255,.9);
    //border-radius: 8px;
    padding: 10px 10px 0 8px;
    height: 90px;
    .discuss-info{
        display: inline-block;
        width: 250px;
        >>>.el-textarea__inner::placeholder{
            font-size: 12px;
        }
        >>>.el-textarea__inner{
            font-size: 12px;
        }
    }

    .discuss-btn-box{
        display: flex;
        padding: 0 6px;
        margin-top: 10px;
        .el-button--primary{
            width: 56px;
            height: 26px;
            font-size: 12px;
            line-height: 30px!important;
            text-align: center;
            &:hover{
                background-color: #0d55a4;
            }
        }
        .limit{
            width: 26px;
            height: 26px;
            background-color: #0d55a4;
            font-size: 12px;
            color: #999aaa;
            border-radius: 13px;
            margin-right: 10px;
            .icon-upload-img{
                width: 20px;
                height: 20px;
                margin: 3px 0 0 3px;
            }
        }
    }
}

.discuss-show-box{
    width: 260px!important;
    margin: auto;
    height: calc(100vh - 280px)!important;
    padding: 10px 0 0 0;
    background-color: rgba(255,255,255,.9);
}
/deep/ .el-scrollbar__thumb {
    background-color: #ec623e;
}
/deep/.el-scrollbar__wrap {
    overflow-x: hidden;
}
.discuss-one{
    display: flex;
    padding-bottom: 10px;
    padding-left: 5px;
    background-color: rgba(255,255,255,.9);
    .discuss-user-avatar{
        width: 40px;
        img{
            width: 30px!important;
            height: 30px;
            border-radius: 15px!important;
        }
    }
    .discuss-user-content{
        width: 220px;
        font-size: 14px;
        .user-info{
            display: flex;
            height: 25px;
            padding-right: 10px;
            .flex-item:last-child{
                margin-left: auto;
            }
            .user-true-name{
                font-size: 12px;
                //font-weight: bold;
                //width: 40px;
                height: 25px;
                line-height: 25px;
                text-align: left;
                margin-right: 10px;
                align-content: flex-start;
            }
            .user-role{
                font-size: 10px;
                div{
                    text-align: center;
                }
                .role1{
                    padding: 1px 4px 1px 4px;
                    background-color: #0d55a4;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #FFFFFF;
                }
                .role2{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1f1f1;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #666666;
                }
                .role3{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1a792;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #0d55a4;
                }
            }
            .content-time{
                line-height: 25px;
                font-size: 12px;
                align-content: flex-end;
            }
        }
        .content-info{
            padding: 2px 10px 0 0;
            font-size: 11px;
            color: #525B68;
            line-height: 16px;
            word-wrap:break-word;
            word-break:break-all;

        }
        .img-info{
            padding: 2px 10px 0 0;
            display: flex;
            width: 180px;
            flex-wrap: wrap;
            .el-image{
                width: 120px;
                height: auto;
                margin: 0 5px 5px 0;
            }
        }
        .audio-info{
            padding: 2px 10px 0 0;
            audio{
                width: 210px;
                height: 40px;
            }
        }
        .file-info{
            padding: 2px 10px 0 0;
            .file-box{
                width: 210px;
                height: 44px;
                background-color: #f1f3f4;
                border-radius: 5px;
                display: flex;
                .file-icon{
                    width: 40px;
                    height: 40px;
                    img{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                    .icon-image{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                }
                .file-detail{
                    width: 160px;
                    height: 40px;
                    padding: 2px 0 2px 0;
                    div{
                        width: 160px;
                        height: 20px;
                    }
                    .name-box{
                        line-height: 24px;
                        font-size: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        &:hover{
                            color: #0d55a4;
                        }
                    }
                    .size-box{
                        display: flex;
                        font-size: 10px;
                        line-height: 20px;
                        .file-view{
                            width: 15px;
                            height: 15px;
                            //background-color: #0d55a4;
                            border-radius: 5px;
                            margin-top: 5px;
                            img{
                                width: 13px;
                                height: 13px;
                                margin: 1px 0 0 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }

}


.eva-btn{
    height: 26px!important;
    width: 60px!important;
    font-size: 12px;
    >>>.el-button--primary{
        font-size: 12px;
        text-align: center;
    }
}


.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    //border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}
.csbbb1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //padding: 0 5px;
    position: relative;
}
.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
.csbbb1:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-select__suffix {
    display: none;
}

.detailFF /deep/ .el-select {
    width: 60% !important;
}

.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select__wrapper.is-disabled .el-select__selected-item {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
}
</style>
