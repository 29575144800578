<template>
    <div class="app-container">
        <el-card v-has-btn="'1,2,3'" class="mb15">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增专家</el-button>
                    <el-button type="primary" @click="handleHMD">黑名单</el-button>
                    <el-button type="primary" @click="handleTj">专家统计</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.period" placeholder="请选择学科">
                                <el-option v-for="item in archiveXdXkList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subject" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="性别">
                            <el-select clearable @change="getList()" v-model="queryParams.gender" placeholder="请选择">
                                <el-option :label="'男'" :value="0" />
                                <el-option :label="'女'" :value="1" />

                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="在编">
                            <el-select clearable @change="getList()" v-model="queryParams.isPayroll" placeholder="请选择">
                                <el-option :label="'是'" :value="1" />
                                <el-option :label="'否'" :value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="最高学历">
                            <el-select clearable @change="getList()" v-model="queryParams.qualification" placeholder="请选择">
                                <el-option v-for="item in qualificationList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="职级">
                            <el-select clearable @change="getList()" v-model="queryParams.rankInfo" placeholder="请选择">
                                <el-option v-for="item in technicalTitleList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey + ''" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="标签">
                            <el-select clearable @change="getList()" v-model="queryParams.expertTag" placeholder="请选择">
                                <el-option v-for="item in expertTagList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.expertName" placeholder="请输入专家姓名" class="input-with-select">
                                <template #append>
                                    <el-button @click="getList()" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                          :height="`calc(100vh - 360px)`" v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="150" label="专家姓名" prop="expertName">
                        <template #default="scope">
                            <div :title="scope.row.expertName" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.expertName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="性别" prop="gender">
                        <template #default="scope">
                            {{ scope.row.gender == 1 ? '女' : scope.row.gender == 0 ? '男' : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="是否在编" prop="isPayroll">
                        <template #default="scope">
                            <div v-if="scope.row.isPayroll == 0">否</div>
                            <div v-if="scope.row.isPayroll == 1">是</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" label="出生日期" prop="birthDate">
                        <template #default="scope">
                            <div class="text-one" v-if="scope.row.birthDate">{{
                                    timestampToTime(scope.row.birthDate).substring(0, 10) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" label="评定时间" prop="assessDate">
                        <template #default="scope">
                            <div class="text-one" v-if="scope.row.assessDate">{{
                                    timestampToTime(scope.row.assessDate).substring(0, 7) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" label="联系方式" prop="contactInfo">
                        <template #default="scope">
                            <div class="text-one" :title="scope.row.contactInfo">{{ scope.row.contactInfo }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="300" label="工作单位" prop="workUnit">
                        <template #default="scope">
                            <div class="text-one" :title="scope.row.workUnit">{{ scope.row.workUnit }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" width="320" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button v-has-btn="'1,2,3'" size="mini" type="primary" icon="el-icon-edit"
                                       @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-button v-if="scope.row.isBlack != 1" v-has-btn="'1,2,3'" size="mini" type="primary"
                                       @click="handleTop(scope.row)">拉黑
                            </el-button>
                            <el-button style="background: #5c89ff;color:#fff;" v-if="scope.row.isBlack == 1"
                                       v-has-btn="'1,2,3'" size="mini" type="primary" @click="handleTop(scope.row)">取消拉黑
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                           icon-color="#626AEF" title="确定确定删除该专家" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'1,2,3'" size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>

        <el-dialog draggable :title="'黑名单'" v-model="isBlack" width="80%">
            <el-row>
                <div class="bre9e" style="width: 100%;">
                    <el-table header-cell-class-name="bgf2" show-overflow-tooltip :height="`50vh`" :data="blackList"
                              highlight-current-row>
                        <!-- <el-table-column type="selection" width="55" /> -->
                        <el-table-column type="index" label="序号" width="60" />
                        <el-table-column width="120" label="姓名" prop="expertName">
                            <template #default="scope">
                                <div :title="scope.row.expertName" style="color: #409efe;" class="text-one cursor"
                                     @click="goDetail(scope.row)">
                                    {{ scope.row.expertName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="120" label="性别" prop="gender">
                            <template #default="scope">
                                {{ scope.row.gender == 1 ? '女' : scope.row.gender == 0 ? '男' : '' }}
                            </template>
                        </el-table-column>
                        <el-table-column width="80" label="是否在编" prop="isPayroll">
                            <template #default="scope">
                                <div v-if="scope.row.isPayroll == 0">否</div>
                                <div v-if="scope.row.isPayroll == 1">是</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="180" label="出生日期" prop="birthDate">
                            <template #default="scope">
                                <div class="text-one" v-if="scope.row.birthDate">{{
                                        timestampToTime(scope.row.birthDate).substring(0, 10) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="180" label="评定时间" prop="assessDate">
                            <template #default="scope">
                                <div class="text-one" v-if="scope.row.assessDate">{{
                                        timestampToTime(scope.row.assessDate).substring(0, 7) }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" width="300" label="操作" class-name="small-padding fixed-width">
                            <template #default="scope">
                                <div style="display: flex;" class="disnone">
                                    <el-button size="mini" type="primary" icon="" @click="handleTop(scope.row)">移出黑名单
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-row>
        </el-dialog>
        <el-dialog draggable :title="'专家统计'" v-model="isZjtj" width="80%">
            <div style="width: 100%;">
                <div style="width: 100%;overflow-x: auto;" class="mb30 mt10">
                    <div
                      :style="'width: ' + 200 * expertTagList.length + 'px;display: flex;overflow: auto;flex-grow: 0;flex-shrink: 0;'">
                        <div v-for="item in expertTagList"
                             style="width: 200px;display: flex;flex-direction: column;align-items: center;">
                            <div style="font-size:24px;">{{ item.num }}人</div>
                            <div style="font-size:18px;">{{ item.dictValue }}</div>
                            <div class="mt5" style="color: #caccbe;">教师占比{{ (item.num/expertList.length * 100).toFixed(2) }}%</div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;display: flex;flex-wrap: wrap;">
                    <div style="width: 18%;margin: 0 1%;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="xbbox">
                                <div class="xbtt">
                                    <img src="../../assets/image/zj-1.png" style="width: 20px;height: 20px;" alt="">
                                    <div class="ml5" style="font-size: 16px;">性别</div>
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>男</div>
                                        <div>{{ tjShowObj.sx.oneNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.sx.oneBfb" :color="'#5990e7'" :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>女</div>
                                        <div>{{ tjShowObj.sx.twoNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.sx.twoBfb" :color="'#fcc866'" :show-text="false" />
                                </div>
                                <div style="height: 55px;"></div>
                            </div>
                        </el-card>
                    </div>
                    <div style="width: 18%;margin: 0 1%;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="xbbox">
                                <div class="xbtt">
                                    <img src="../../assets/image/zj-2.png" style="width: 20px;height: 20px;" alt="">
                                    <div class="ml5" style="font-size: 16px;">年龄</div>
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>35岁以下</div>
                                        <div>{{ tjShowObj.age.oneNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.age.oneBfb" :color="'#5990e7'" :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>35-50岁</div>
                                        <div>{{ tjShowObj.age.twoNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.age.twoBfb" :color="'#fcc866'" :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>50岁以上</div>
                                        <div>{{ tjShowObj.age.threeNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.age.threeBfb" :color="'#51c5a3'"
                                                 :show-text="false" />
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <div style="width: 18%;margin: 0 1%;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="xbbox">
                                <div class="xbtt">
                                    <img src="../../assets/image/zj-3.png" style="width: 20px;height: 20px;" alt="">
                                    <div class="ml5" style="font-size: 16px;">学历</div>
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>硕士及以上</div>
                                        <div>{{ tjShowObj.qualification.oneNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.qualification.oneBfb" :color="'#5990e7'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>本科</div>
                                        <div>{{ tjShowObj.qualification.twoNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.qualification.twoBfb" :color="'#fcc866'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>专科及以下</div>
                                        <div>{{ tjShowObj.qualification.threeNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.qualification.threeBfb" :color="'#51c5a3'"
                                                 :show-text="false" />
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <div style="width: 18%;margin: 0 1%;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="xbbox">
                                <div class="xbtt">
                                    <img src="../../assets/image/zj-4.png" style="width: 20px;height: 20px;" alt="">
                                    <div class="ml5" style="font-size: 16px;">政治面貌</div>
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>共产党员</div>
                                        <div>{{ tjShowObj.politicalStatus.oneNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.politicalStatus.oneBfb" :color="'#5990e7'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>群众</div>
                                        <div>{{ tjShowObj.politicalStatus.twoNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.politicalStatus.twoBfb" :color="'#fcc866'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>其它</div>
                                        <div>{{ tjShowObj.politicalStatus.threeNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.politicalStatus.threeBfb" :color="'#51c5a3'"
                                                 :show-text="false" />
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <div style="width: 18%;margin: 0 1%;">
                        <el-card :body-style="{ padding: '0px' }">
                            <div class="xbbox">
                                <div class="xbtt">
                                    <img src="../../assets/image/zj-5.png" style="width: 20px;height: 20px;" alt="">
                                    <div class="ml5" style="font-size: 16px;">职称</div>
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>高级及以上</div>
                                        <div>{{ tjShowObj.rankInfo.oneNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.rankInfo.oneBfb" :color="'#5990e7'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>一级教师</div>
                                        <div>{{ tjShowObj.rankInfo.twoNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.rankInfo.twoBfb" :color="'#fcc866'"
                                                 :show-text="false" />
                                </div>
                                <div style="width: 70%;" class="mb30">
                                    <div class="xbct-tp">
                                        <div>二级及以下</div>
                                        <div>{{ tjShowObj.rankInfo.threeNum }}人</div>
                                    </div>
                                    <el-progress :percentage="tjShowObj.rankInfo.threeBfb" :color="'#51c5a3'"
                                                 :show-text="false" />
                                </div>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { famousCourseListFamous, syncCourse, famousCourseRelease, famousCourseInfo, famousCourseDel, famousCourseListTreeFamous, famousCourseTop, famousCoursePageFamousCourses } from "@/api/admin/famousCourse"
import { trainListAll, trainRelease, trainStop } from "@/api/admin/train"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import { expertDelete, expertPage, expertBlackCancel, expertBlackList, expertListAll } from "@/api/admin/expert"
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList, listTeacherGroup, listHeadmasterGroup, groupListAuthGroup } from "@/api/admin/public";

export default {
    name: "expert",
    components: {
    },
    data() {
        return {
            isBlack: false,
            isZjtj: false,
            blackList: [],
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            archiveXdXkList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            courseClassifyList: [],
            statusList: [
                { name: '初始', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [

            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                expertName: '',
                expertTag: '',
                gender: '',
                isPayroll: '',
                period: '',
                qualification: '',
                subject: '',
                rankInfo: '',
                pageNum: 1,
                pageSize: 10,
            },
            groupList: [],
            qualificationList: [],
            technicalTitleList: [],
            expertTagList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            selectList: [],
            expertList: [],
            tjShowObj: {
                sx: {
                    oneNum: 0,
                    oneBfb: 0,
                    twoNum: 0,
                    twoBfb: 0,
                },
                age: {
                    oneNum: 0,
                    oneBfb: 0,
                    twoNum: 0,
                    twoBfb: 0,
                    threeNum: 0,
                    threeBfb: 0,
                },
                qualification: {
                    oneNum: 0,
                    oneBfb: 0,
                    twoNum: 0,
                    twoBfb: 0,
                    threeNum: 0,
                    threeBfb: 0,
                },
                politicalStatus: {
                    oneNum: 0,
                    oneBfb: 0,
                    twoNum: 0,
                    twoBfb: 0,
                    threeNum: 0,
                    threeBfb: 0,
                },
                rankInfo: {
                    oneNum: 0,
                    oneBfb: 0,
                    twoNum: 0,
                    twoBfb: 0,
                    threeNum: 0,
                    threeBfb: 0,
                }
            }
        };
    },
    created() {
        this.getGroup();
        this.getQueryDict()
    },
    activated() {
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getGroup() {
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            groupListAuthGroup({ groupNature: 2 }).then(res => {
                res.data.data.forEach(e => {
                    this.groupList.push(e)
                })
                groupListAuthGroup({ groupNature: 3 }).then(response => {
                    response.data.data.forEach(j => {
                        this.groupList.push(j)
                    })
                    this.getList();
                })
            })
        },
        getQueryDict() {
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            queryDict({ code: 'qualification' }).then(res => {
                this.qualificationList = res.data.data;
            })
            queryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
            queryDict({ code: 'expert_tag' }).then(res => {
                this.expertTagList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.archiveXdXkList.forEach(j => {
                if (this.queryParams.period == j.dictKey) {
                    this.subjectList = j.childrenList;
                }
            })
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            this.loading = true;

            expertPage(this.queryParams).then(res => {
                this.total = res.data.data.total;
                this.loading = false;
                this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            expertDelete({ expertId: row.expertId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        goDetail(row) {
            let routeUrl = this.$router.resolve({
                path: "/expertck",
                query: {
                    expertId: row.expertId,
                    userId: JSON.parse(this.$storage.get("userInfo")).userId
                }
            });
            window.open(routeUrl.href, '_blank')
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        handleTj() {
            expertListAll().then(res => {
                this.expertTagList.forEach(e => {
                    e.num = 0
                })
                this.expertList = res.data.data;
                this.tjShowObj.sx.oneNum = 0
                this.tjShowObj.sx.twoNum = 0
                this.tjShowObj.age.oneNum = 0
                this.tjShowObj.age.twoNum = 0
                this.tjShowObj.age.threeNum = 0
                this.tjShowObj.qualification.oneNum = 0
                this.tjShowObj.qualification.twoNum = 0
                this.tjShowObj.qualification.threeNum = 0
                this.tjShowObj.politicalStatus.oneNum = 0
                this.tjShowObj.politicalStatus.twoNum = 0
                this.tjShowObj.politicalStatus.threeNum = 0
                this.tjShowObj.rankInfo.oneNum = 0
                this.tjShowObj.rankInfo.twoNum = 0
                this.tjShowObj.rankInfo.threeNum = 0

                this.expertList.forEach(e => {
                    if (e.gender == 0) {
                        this.tjShowObj.sx.oneNum++;
                    }
                    if (e.gender == 1) {
                        this.tjShowObj.sx.twoNum++;
                    }
                    console.log(this.calculateAge(e.birthDate));
                    if (this.calculateAge(e.birthDate) < 35) {
                        this.tjShowObj.age.oneNum++;
                    }
                    if (this.calculateAge(e.birthDate) >= 35 && this.calculateAge(e.birthDate) <= 50) {
                        this.tjShowObj.age.twoNum++;
                    }
                    if (this.calculateAge(e.birthDate) > 50) {
                        this.tjShowObj.age.threeNum++;
                    }
                    if (e.qualification >= 5) {
                        this.tjShowObj.qualification.oneNum++;
                    }
                    if (e.qualification == 4) {
                        this.tjShowObj.qualification.twoNum++;
                    }
                    if (e.qualification < 4) {
                        this.tjShowObj.qualification.threeNum++;
                    }
                    if (e.politicalStatus == 2) {
                        this.tjShowObj.politicalStatus.oneNum++;
                    }
                    if (e.politicalStatus == 12) {
                        this.tjShowObj.politicalStatus.twoNum++;
                    }
                    if (e.politicalStatus != 2 && e.politicalStatus != 12) {
                        this.tjShowObj.politicalStatus.threeNum++;
                    }
                    if (e.rankInfo >= 4) {
                        this.tjShowObj.rankInfo.oneNum++;
                    }
                    if (e.rankInfo == 3) {
                        this.tjShowObj.rankInfo.twoNum++;
                    }
                    if (e.rankInfo < 3) {
                        this.tjShowObj.rankInfo.threeNum++;
                    }
                    var expertList = []
                    expertList = e.expertTag.split(',')
                    expertList.forEach(m => {
                        this.expertTagList.forEach(n => {
                            if (m == n.dictKey) {
                                n.num++;
                            }
                        })
                    })
                })
                this.tjShowObj.sx.oneBfb = this.tjShowObj.sx.oneNum / this.expertList.length * 100;
                this.tjShowObj.sx.twoBfb = this.tjShowObj.sx.twoNum / this.expertList.length * 100;
                this.tjShowObj.age.oneBfb = this.tjShowObj.age.oneNum / this.expertList.length * 100;
                this.tjShowObj.age.twoBfb = this.tjShowObj.age.twoNum / this.expertList.length * 100;
                this.tjShowObj.age.threeBfb = this.tjShowObj.age.threeNum / this.expertList.length * 100;
                this.tjShowObj.qualification.oneBfb = this.tjShowObj.qualification.oneNum / this.expertList.length * 100;
                this.tjShowObj.qualification.twoBfb = this.tjShowObj.qualification.twoNum / this.expertList.length * 100;
                this.tjShowObj.qualification.threeBfb = this.tjShowObj.qualification.threeNum / this.expertList.length * 100;
                this.tjShowObj.politicalStatus.oneBfb = this.tjShowObj.politicalStatus.oneNum / this.expertList.length * 100;
                this.tjShowObj.politicalStatus.twoBfb = this.tjShowObj.politicalStatus.twoNum / this.expertList.length * 100;
                this.tjShowObj.politicalStatus.threeBfb = this.tjShowObj.politicalStatus.threeNum / this.expertList.length * 100;
                this.tjShowObj.rankInfo.oneBfb = this.tjShowObj.rankInfo.oneNum / this.expertList.length * 100;
                this.tjShowObj.rankInfo.twoBfb = this.tjShowObj.rankInfo.twoNum / this.expertList.length * 100;
                this.tjShowObj.rankInfo.threeBfb = this.tjShowObj.rankInfo.threeNum / this.expertList.length * 100;
                this.isZjtj = true;
            })
        },
        handleHMD() {
            this.blackList = [];
            expertBlackList().then(res => {
                this.blackList = res.data.data;
                this.isBlack = true;
            })
        },
        /** 新增按钮操作 */
        handleAdd() {
            let routeUrl = this.$router.resolve({
                path: '/expertAdd',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: {}
            })
            window.open(routeUrl.href, '_blank')
        },
        handleTop(row) {
            expertBlackCancel({ expertId: row.expertId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                    expertBlackList().then(res => {
                        this.blackList = res.data.data;
                    })
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            let routeUrl = this.$router.resolve({
                path: "/experted",
                query: {
                    expertId: row.expertId,
                }
            });
            window.open(routeUrl.href, '_blank')
        },
        calculateAge(birthTimestamp) {
            const today = new Date();
            const birthDate = new Date(birthTimestamp);
            let age = today.getFullYear() - birthDate.getFullYear();
            // 如果今年的生日还没有过，则年龄减一
            if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.xbbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.xbtt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.xbct-tp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}
</style>