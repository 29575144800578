<!--师资培训数据统计-->
<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-form ref="queryForm" :model="queryParams" :inline="true" label-width="68px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="培训类型">
                            <el-select clearable @change="trainStatsList" v-model="queryParams.type" placeholder="请选择培训类型">
                                <el-option v-for="item in typeList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="统计类型">
                            <el-select clearable @change="trainStatsList" v-model="queryParams.statType" placeholder="请选择统计类型">
                                <el-option v-for="item in statList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始时间">
                            <el-date-picker style="width: 100%;" :disabled-date="disabledDate"
                                            v-model="queryParams.selTime"
                                            format="YYYY/MM/DD" value-format="x" clearable
                                            @change="this.trainStatsList"
                                            @clear = "this.clearTime"
                                            type="daterange"
                                            range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>


            <div class="bre9e mt15" style="width: 100%;">
                    <el-table header-cell-class-name="bgf2"
                              show-overflow-tooltip
                              height="calc(100vh - 200px)"
                              border
                              v-loading="loading" :data="trainList"
                              highlight-current-row>
                        <el-table-column type="index" align="center" label="序号" width="65" />
                        <el-table-column  align="center" label="培训任务标题" prop="title" width="260">
                            <template #default="scope">
                                <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor">
                                    {{ scope.row.title }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="培训类型" prop="type" width="140">
                            <template #default="scope">
                                <div v-if="scope.row.type == 0">直播直播</div>
                                <div v-if="scope.row.type == 1">录播培训</div>
                                <div v-if="scope.row.type == 2">现场培训</div>
                                <div v-if="scope.row.type == 3">录播培训</div>
                            </template>
                        </el-table-column>
                        <el-table-column  align="center" label="培训时间" width="270">
                            <template #default="scope">
                                <div>
                                    {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column  align="center" label="人员信息" width="100">
                            <template #default="scope">
                                <div v-if="scope.row.type!=2">
                                    应到：{{ scope.row.userList.length }}
                                </div>
                                <div v-if="scope.row.type==2">
                                    应到：{{ scope.row.userList.length }} <br>实到：{{ scope.row.teaCount }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" align="center" label="统计查看" width="140">
                            <template #default="scope">
<!--                                <div style="display: flex;flex-direction: column;align-items: center;">-->
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <el-button  plain size="small"
                                                style="display: block;margin: 5px 0;"
                                                v-if="scope.row.type == 2 &&
                                                (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)&&
                                                (this.queryParams.statType==null||this.queryParams.statType==''||this.queryParams.statType.includes('0'))"
                                               @click="handleClockIn(scope.row)">
                                        签到统计
                                    </el-button>
                                    <el-button plain size="small"
                                               style="display: block;margin: 5px 0;"
                                               v-if="scope.row.homeworkList && scope.row.homeworkList.length >0 &&
                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)&&
                                                (this.queryParams.statType==null||this.queryParams.statType==''||this.queryParams.statType.includes('1'))"
                                               @click="handleHomework(scope.row)">
                                        作业统计
                                    </el-button>
                                    <el-button  plain size="small" style="display: block;margin: 5px 0;"
                                                v-if="scope.row.satisfiedScale &&
                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)&&
                                                (this.queryParams.statType==null||this.queryParams.statType==''||this.queryParams.statType.includes('2'))"
                                                @click="handleSatisfiedScale(scope.row)">
                                        满意度调查
                                    </el-button>
                                    <el-button  plain size="small"
                                                style="display: block;margin: 5px 0;"
                                                v-if="scope.row.scaleIds &&
                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)&&
                                                (this.queryParams.statType==null||this.queryParams.statType==''||this.queryParams.statType.includes('3'))"
                                               @click="handleRateScale(scope.row)">
                                        其他量表
                                    </el-button>
                                </div>

                            </template>

<!--                            <template #default="scope">-->
<!--                                <el-popover placement="bottom" trigger="click" width="40px" popper-style="padding: 5px 0 0">-->
<!--                                    <template #reference>-->
<!--                                        <el-button>查看</el-button>-->
<!--                                    </template>-->
<!--                                    <template #default>-->
<!--&lt;!&ndash;                                        <div class="text-item">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <el-text type="primary" @click="handleTrainPeople(scope.row)">人员详情</el-text>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                        <div class="text-item" v-if="scope.row.type == 2 &&-->
<!--                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)">-->
<!--                                            <el-text type="primary" @click="handleClockIn(scope.row)">签到统计</el-text>-->
<!--                                        </div>-->
<!--                                        <div class="text-item" v-if="scope.row.homeworkList && scope.row.homeworkList.length >0 &&-->
<!--                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)">-->
<!--                                            <el-text type="primary" @click="handleHomework(scope.row)">作业统计</el-text>-->
<!--                                        </div>-->
<!--                                        <div class="text-item">-->
<!--                                            <el-text type="primary">满意度调查</el-text>-->
<!--                                        </div>-->
<!--                                        <div class="text-item" v-if="scope.row.scaleIds &&-->
<!--                                            (this.userRole==1||this.userRole==2||scope.row.roleType==1||scope.row.roleType==2)">-->
<!--                                            <el-text type="primary" @click="handleRateScale(scope.row)">其他量表</el-text>-->
<!--                                        </div>-->

<!--                                    </template>-->
<!--                                </el-popover>-->
<!--                            </template>-->
                        </el-table-column>
                    </el-table>
                </div>

        </el-card>

        <el-dialog :title="'【'+trainTitle+'】人员信息'" v-model="isShowPeople" width="582px">
            <el-table :span-method="userSpanMethod"
                      show-overflow-tooltip
                      height="calc(100vh - 240px)" border
                      :data="userList" highlight-current-row>
                <el-table-column width="300px" label="相关组织" prop="orgName">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.orgName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="100px" align="center" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="150px" align="center" label="手机号码" prop="username">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.username }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>

        </el-dialog>

        <el-dialog :title="'【'+trainTitle+'】签到统计'" v-model="isShowClockIn" width="580px">
            <el-table :span-method="objectSpanMethod" show-overflow-tooltip class=""
                      height="calc(100vh - 240px)" border
                      :data="clockInList" highlight-current-row>
                <el-table-column width="100" align="center" :label="'姓名'" prop="title">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" align="center" label="签到时间" prop="signInTime">
                    <template #default="scope">
                        <div class="">{{ timestampToTime(scope.row.signInTime) }}</div>
                    </template>
                </el-table-column>
                <el-table-column width="140" align="center" label="签退时间" prop="signOutTime">
                    <template #default="scope">
                        <div class="">{{ timestampToTime(scope.row.signOutTime) }}</div>
                    </template>
                </el-table-column>
                <el-table-column width=""  align="center" label="状态" prop="signStatus">
                    <template #default="scope">
                        <div class="" v-if="scope.row.signStatus == 1">
                            签到未签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 2">
                            未签到签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 3">
                            正常签到
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="'【'+trainTitle+'】作业统计'" v-model="isShowHomework" width="580px">
            <el-table :span-method="homeworkSpanMethod" show-overflow-tooltip class=""
                      height="calc(100vh - 240px)" border
                      :data="homeworkFinishList" >
                <el-table-column width="100" align="center" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="200" align="center" label="作业标题" prop="homeworkTitle">
<!--                    <template #default="scope">-->
<!--                        <div :title="scope.row.homeworkTitle" style="color: #409efe;" class="text-one cursor">-->
<!--                            {{ scope.row.homeworkTitle }}-->
<!--                        </div>-->
<!--                    </template>-->
                </el-table-column>
                <el-table-column width="100" align="center" label="完成状态" prop="isFinish">
                    <template #default="scope">
                        <el-tag class="homework_tag" v-if="scope.row.isFinish == 1">
                            已完成
                        </el-tag>
                        <el-tag class="homework_tag_no" v-if="scope.row.isFinish == 0">
                            未完成
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column width=""  align="center" label="操作" prop="">
                    <template #default="scope">
                        <div class="cursor" style="color: #409efe" @click="checkHomework(scope.row)" v-if="scope.row.isFinish == 1">
                            查看
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="" v-model="isHomeworkFinishShow" width="650px" :show-close="false">
            <el-descriptions :column="3" class="mt-10" title="作业查看" border>
                    <template #extra>
                        <el-tag class="homework_tag cursor"
                                @click="this.isHomeworkFinishShow=!this.isHomeworkFinishShow" >关闭</el-tag>
                    </template>
                    <el-descriptions-item align="center" width="80px">
                        <template #label>
                            <div class="cell-item">
                                姓名
                            </div>
                        </template>
                        {{homeworkInfo.trueName}}
                    </el-descriptions-item>
                    <el-descriptions-item align="center">
                        <template #label>
                            <div class="cell-item">
                                手机号
                            </div>
                        </template>
                        {{homeworkInfo.username}}
                    </el-descriptions-item>
                    <el-descriptions-item align="center">
                        <template #label>
                            <div class="cell-item">
                                完成时间
                            </div>
                        </template>
                        {{timestampToTime(homeworkInfo.finishTime)}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="3">
                        <template #label>
                            <div class="cell-item">
                                提交内容
                            </div>
                        </template>
                        {{homeworkInfo.finishContent}}
                    </el-descriptions-item>
                    <el-descriptions-item align="center">
                        <template #label>
                            <div class="cell-item">
                                作业附件
                            </div>
                        </template>
                        {{homeworkInfo.finishFileList.length!=0?homeworkInfo.finishFileList.length!=0:'无'}}
                    </el-descriptions-item>
                </el-descriptions>
        </el-dialog>

        <el-dialog  v-model="isRateScaleShow" width="640px" :show-close="false">
            <el-descriptions :column="3" :title="'【'+trainTitle+'】量表统计'" border style="margin-top: -15px">
                <template #extra>
                    <el-dropdown v-if="scaleStatsList && scaleStatsList.length > 0" slot="dropdown">
                        <el-tag type="primary" style="height: 24px;border: 1px solid #1f89de;line-height: 24px;color: #1f89de">
                            ⇅ {{scaleTitle}}
                        </el-tag>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :disabled="scaleId==item.scaleId" v-for="item in scaleStatsList" @click="changeScale(item)">{{item.title}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-tag style="margin-left: 10px" class="homework_tag cursor" @click="this.isRateScaleShow=!this.isRateScaleShow" >关闭</el-tag>
                </template>
            </el-descriptions>
            <el-tabs v-model="activeTabName" @tab-click="handleTabsClick" type="border-card"
                     style="margin-top: -5px">
                <el-tab-pane label="统计结果" name="0" >
                    <div v-if="questionList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <div v-for="(item,index) in questionList" :style="index==0?'margin-top:-10px;':''">
                        <h3 style="margin: 10px 0 10px 0;">
                            <span>
                                {{ index + 1 }}.{{item.questionName }}
                            </span>
                            <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                [{{item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容' : '' }}]
                            </span>
                        </h3>
                        <el-table v-if="item.type==1 || item.type==2"
                                  :data="item.answerList"
                                  :row-class-name="hideStat"
                                  border fit>
                            <el-table-column  align="center" label="选项" prop="itemName"/>
<!--                            <el-table-column  align="center" label="有效人数" prop="peoCount">-->
<!--                                <div>{{item.count}}</div>-->
<!--                            </el-table-column>-->
                            <el-table-column  align="center" label="小计" prop="answerCount"/>
                            <el-table-column  align="center" label="比例" prop="rate">
                                <template #default="scope">
                                    <div>{{scope.row.rate * 100}}%</div>
                                </template>
                            </el-table-column>
                            <template #append>
                                <div style="display: flex">
                                    <div style="width: 100%;height: 40px;line-height: 40px;font-weight: bold;text-align: center;">
                                        本题有效填写人次：{{item.count}}
                                    </div>
                                </div>
                            </template>
                        </el-table>
                        <div class="chart-tab" v-if="item.type!=3 && item.type!=4">
                            <ul class="fr">
                                <li @click="handlePieQuestion(item.questionId)" :class="this.pieQuIds.includes(item.questionId)?'liSel':''">
                                    饼状
                                </li>
                                <li @click="handleBarQuestion(item.questionId)" :class="this.barQuIds.includes(item.questionId)?'liSel':''">
                                    柱状
                                </li>
                            </ul>
                        </div>

                        <pieEcharts v-if="item.type!=3 && item.type!=4 && this.pieQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_pie'"
                                    :dataList="item.answerList"/>

                        <barEcharts v-if="item.type!=3 && item.type!=4 && this.barQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_bar'"
                                    :dataList="item.answerList"/>


                        <el-table v-if="item.type==3" :data="item.aveScore" border fit>
                            <el-table-column  align="center" label="打分人数" prop="trueName">
                                <div>{{item.count}}</div>
                            </el-table-column>
                            <el-table-column  align="center" label="平均分" >
                                <template #default="scope">
                                    <div>{{scope.row}}</div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-table v-if="item.type==4" :data="item.userAnswerList" border fit>
                            <el-table-column  align="center" label="姓名" prop="trueName">
                                <template #default="scope">
                                    <div>{{scope.row.isHide==0?scope.row.trueName:'匿名'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column  align="center" label="内容" prop="answerName" />
                        </el-table>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="详细数据" name="1" >
                    <div v-if="userRecordList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <el-collapse v-if="userRecordList.length!==0" v-model="activeNames" accordion>
                        <el-collapse-item v-for="(item,index) in userRecordList" :name="index+1"
                                          @click.native="handleChange(index)">
                            <template #title>
                                <h3 style="margin: 0 0;" :class="activeIndex==index?'user-record-title active':'user-record-title'">
                                    {{item.isHide==0?(index+1)+'.'+item.trueName:(index+1) +'.匿名'}} 提交时间: {{timestampToTime(item.submitTime)}}
                                </h3>
                            </template>
                            <div v-for="(q,i) in item.questionList">
                                <h3 style="margin: 10px 0 10px 0;color:#909399 ">
                                    <span>
                                        {{ i + 1 }}.{{q.questionName }}
                                    </span>
                                    <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                        [{{q.type == 1 ? '单选' : q.type == 2 ? '多选' : q.type == 3 ? '评分' : q.type == 4 ? '文字内容' : '' }}]
                                    </span>
                                </h3>
                                <div style="margin-left: 10px">
                                    {{q.questionAnswer}}
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

        <el-dialog  v-model="isSatisfiedShow" width="640px" :show-close="false">
            <el-descriptions :column="3" :title="'【'+trainTitle+'】满意度调查统计'" border style="margin-top: -15px">
                <template #extra>
                    <el-tag style="margin-left: 10px" class="homework_tag cursor" @click="this.isRateScaleShow=!this.isRateScaleShow" >关闭</el-tag>
                </template>
            </el-descriptions>
            <el-tabs v-model="activeTabName" @tab-click="handleTabsClick" type="border-card"
                     style="margin-top: -5px">
                <el-tab-pane label="统计结果" name="0" >
                    <div v-if="questionList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <div v-for="(item,index) in questionList" :style="index==0?'margin-top:-10px;':''">
                        <h3 style="margin: 10px 0 10px 0;">
                            <span>
                                {{ index + 1 }}.{{item.questionName }}
                            </span>
                            <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                [{{item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容' : '' }}]
                            </span>
                        </h3>
                        <el-table v-if="item.type==1 || item.type==2"
                                  :data="item.answerList"
                                  :row-class-name="hideStat"
                                  border fit>
                            <el-table-column  align="center" label="选项" prop="itemName"/>
                            <!--                            <el-table-column  align="center" label="有效人数" prop="peoCount">-->
                            <!--                                <div>{{item.count}}</div>-->
                            <!--                            </el-table-column>-->
                            <el-table-column  align="center" label="小计" prop="answerCount"/>
                            <el-table-column  align="center" label="比例" prop="rate">
                                <template #default="scope">
                                    <div>{{scope.row.rate * 100}}%</div>
                                </template>
                            </el-table-column>
                            <template #append>
                                <div style="display: flex">
                                    <div style="width: 100%;height: 40px;line-height: 40px;font-weight: bold;text-align: center;">
                                        本题有效填写人次：{{item.count}}
                                    </div>
                                </div>
                            </template>
                        </el-table>
                        <div class="chart-tab" v-if="item.type!=3 && item.type!=4">
                            <ul class="fr">
                                <li @click="handlePieQuestion(item.questionId)" :class="this.pieQuIds.includes(item.questionId)?'liSel':''">
                                    饼状
                                </li>
                                <li @click="handleBarQuestion(item.questionId)" :class="this.barQuIds.includes(item.questionId)?'liSel':''">
                                    柱状
                                </li>
                            </ul>
                        </div>

                        <pieEcharts v-if="item.type!=3 && item.type!=4 && this.pieQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_pie'"
                                    :dataList="item.answerList"/>

                        <barEcharts v-if="item.type!=3 && item.type!=4 && this.barQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_bar'"
                                    :dataList="item.answerList"/>


                        <el-table v-if="item.type==3" :data="item.aveScore" border fit>
                            <el-table-column  align="center" label="打分人数" prop="trueName">
                                <div>{{item.count}}</div>
                            </el-table-column>
                            <el-table-column  align="center" label="平均分" >
                                <template #default="scope">
                                    <div>{{scope.row}}</div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-table v-if="item.type==4" :data="item.userAnswerList" border fit>
                            <el-table-column  align="center" label="姓名" prop="trueName">
                                <template #default="scope">
                                    <div>{{scope.row.isHide==0?scope.row.trueName:'匿名'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column  align="center" label="内容" prop="answerName" />
                        </el-table>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="详细数据" name="1" >
                    <div v-if="userRecordList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <el-collapse v-if="userRecordList.length!==0" v-model="activeNames" accordion>
                        <el-collapse-item v-for="(item,index) in userRecordList" :name="index+1"
                                          @click.native="handleChange(index)">
                            <template #title>
                                <h3 style="margin: 0 0;" :class="activeIndex==index?'user-record-title active':'user-record-title'">
                                    {{item.isHide==0?(index+1)+'.'+item.trueName:(index+1) +'.匿名'}} 提交时间: {{timestampToTime(item.submitTime)}}
                                </h3>
                            </template>
                            <div v-for="(q,i) in item.questionList">
                                <h3 style="margin: 10px 0 10px 0;color:#909399 ">
                                    <span>
                                        {{ i + 1 }}.{{q.questionName }}
                                    </span>
                                    <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                        [{{q.type == 1 ? '单选' : q.type == 2 ? '多选' : q.type == 3 ? '评分' : q.type == 4 ? '文字内容' : '' }}]
                                    </span>
                                </h3>
                                <div style="margin-left: 10px">
                                    {{q.questionAnswer}}
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>


</template>

<script>
import {timestampToTime} from "@/utils/date";
import {statsSatisfiedRate, statsTrain, statsTrainRate} from "@/api/admin/stats"
import {statsTrainSign} from "@/api/admin/statistics";
import { listTrainHomeworkFinish } from "@/api/admin/homework"
import pieEcharts from "@/components/echart/pieEcharts";
import barEcharts from "@/components/echart/barEcharts";

export default {
    name: "trainStat",
    components: { pieEcharts,barEcharts },
    data() {
        return {
            loading: false,
            isShowPeople:false,
            isShowClockIn:false,
            isShowHomework:false,
            isHomeworkFinishShow:false,
            isRateScaleShow:false,
            isSatisfiedShow:false,
            isCheckHomework:false,//查看或批阅
            activeType: '全部',
            activeTabName:'0',
            trainTitle:'',
            userRole:this.$storage.get("role"),
            timestampToTime,
            // 查询参数
            queryParams: {
                type:null,
                statType:null,
                beginTime:null,
                endTime:null,
                selTime: [],
            },
            trainList:[], //师资培训列表
            userList:[], //当前培训人
            clockInList:[], //打卡情况
            homeworkInfo:{},
            homeworkFinishList:[],
            scaleId:'',
            scaleTitle:'',
            activeNames:'',
            activeIndex:null,
            userRecordList:[], //当前量表填写记录
            questionList:[],  //当前量表问题统计
            scaleStatsList:[], //量表 统计数据
            pieQuIds:'', //饼图问题ID
            barQuIds:'', //柱状图问题ID
            typeList: [
                { name: '直播培训', value: 0 },
                { name: '录播培训', value: 1 },
                { name: '现场培训', value: 2 },
            ],
            statList: [
                { name: '签到统计', value: "0" },
                { name: '作业统计', value: "1" },
                { name: '满意度调查', value: "2" },
                { name: '其他量表', value: "3" },
            ],
        };
    },
    created() {
        this.trainStatsList();
    },
    mounted() {},
    activated() {},
    watch: {$route(to, from) {},},
    methods: {
        changeActiveType() {
            console.log(this.activeType)
            if (this.activeType == '全部') {
                this.queryParams.type = null;
            } else if (this.activeType == '线上直播') {
                this.queryParams.type = 0;
            } else if (this.activeType == '课程培训') {
                this.queryParams.type = 1;
            }else if (this.activeType == '线下培训') {
                this.queryParams.type = 2;
            }else if (this.activeType == '自传视频') {
                this.queryParams.type = 3;
            }
            this.trainStatsList();
        },
        disabledDate(current) {
            return current && current > Number(new Date())
        },
        clearTime(){
            this.queryParams.selTime = []
            this.queryParams.beginTime = null
            this.queryParams.endTime = null
            this.trainStatsList()
        },
        trainStatsList(){
            if (this.queryParams.selTime){
                this.queryParams.beginTime = this.queryParams.selTime[0];
                this.queryParams.endTime = this.queryParams.selTime[1];
            }
            statsTrain(this.queryParams).then(res => {
                if (res.data.code == 1){
                    this.trainList = res.data.data;
                }
            })
        },
        handleTrainPeople(row){
            this.isShowPeople = true;
            this.trainTitle = row.title;
            this.userList = row.userList;
        },
        handleClockIn(row){
            this.trainTitle = row.title;
            this.clockInList = [];
            const userId = ''
            if (this.userRole == 1 || this.userRole == 2){

            } else {
                userId = JSON.parse(this.$storage.get("userInfo")).userId
            }
            statsTrainSign({ trainId: row.trainId, userId: userId }).then(res => {
                if (res.data.code == 1){
                    res.data.data.forEach(e => {
                        if (e.signList && e.signList.length != 0) {
                            e.signList.forEach((j, n) => {
                                if (n == 0) {
                                    this.clockInList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: e.signList.length })
                                } else {
                                    this.clockInList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: 0 })
                                }
                            })
                        }else{
                            this.clockInList.push({ trueName: e.trueName, signStatus: '', signInTime: '', signOutTime: '', col: 1 })
                        }

                    })
                    this.isShowClockIn = true;
                }else {
                    this.$message.error('请求出错');
                }

            })
        },
        handleHomework(row){
            this.trainTitle = row.title;
            this.homeworkList = [];

            listTrainHomeworkFinish({trainId:row.trainId}).then(res => {
                if (res.data.code == 1){
                    this.homeworkFinishList = res.data.data;
                    this.isShowHomework = true
                }else {
                    this.$message.error("请求出错")
                }
            })
        },
        checkHomework(row){
            this.homeworkInfo = row;
            this.isHomeworkFinishShow = true;
        },
        handleRateScale(row){
            this.trainTitle = row.title;
            this.isRateScaleShow = true;
            this.activeIndex = null
            this.userRecordList = []
            this.questionList = []
            this.pieQuIds = ''
            this.barQuIds = ''
            statsTrainRate({trainId:row.trainId}).then(res => {
                if (res.data.code == 1){
                    this.scaleStatsList = res.data.data;
                    if (this.scaleStatsList){
                        this.scaleId = this.scaleStatsList[0].scaleId;
                        this.scaleTitle = this.scaleStatsList[0].title;
                        this.userRecordList = this.scaleStatsList[0].userRecordList;
                        this.questionList = this.scaleStatsList[0].questionList;
                        this.pieQuIds = ''
                        this.barQuIds = ''
                        this.questionList.forEach(q  => {
                            this.pieQuIds = this.pieQuIds+q.questionId
                        })
                    }
                    this.isRateScaleShow = true;
                }else {
                    this.$message.error("请求出错")
                }
            })
        },
        handleSatisfiedScale(row){
            this.trainTitle = row.title;
            this.isRateScaleShow = true;
            this.activeIndex = null ;
            this.userRecordList = []
            this.questionList = []
            this.pieQuIds = ''
            this.barQuIds = ''
            statsSatisfiedRate({trainId:row.trainId}).then(res => {
                if (res.data.code == 1){
                    this.scaleStatsList = res.data.data;
                    if (this.scaleStatsList){
                        this.scaleId = this.scaleStatsList[0].scaleId;
                        this.scaleTitle = this.scaleStatsList[0].title;
                        this.userRecordList = this.scaleStatsList[0].userRecordList;
                        this.questionList = this.scaleStatsList[0].questionList;

                        this.questionList.forEach(q  => {
                            this.pieQuIds = this.pieQuIds+q.questionId
                        })
                    }
                    this.isRateScaleShow = true;
                }else {
                    this.$message.error("请求出错")
                }
            })
        },
        changeScale(item){
            this.scaleId = item.scaleId;
            this.scaleTitle = item.title;
            this.userRecordList = item.userRecordList;
            this.questionList = item.questionList;
            this.pieQuIds = ''
            this.barQuIds = ''
            this.questionList.forEach(q  => {
                this.pieQuIds = this.pieQuIds+q.questionId
            })
            console.log("饼图显示："+this.pieQuIds)
        },
        handleChange(index){
            this.activeIndex = index
        },
        /* 表格合并列和行 */
        objectSpanMethod(e) {
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        userSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.flitterData(this.userList).one[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col,
                };
            }
        },
        flitterData(arr) {
            let spanOneArr = [];
            let concatOne = 0;
            arr.forEach((item, index) => {
                if (index === 0) {
                    spanOneArr.push(1);
                } else {
                    if (item.orgId === arr[index - 1].orgId) {
                        spanOneArr[concatOne] += 1;
                        spanOneArr.push(0);
                    } else {
                        spanOneArr.push(1);
                        concatOne = index;
                    }
                }
            });
            return {
                one: spanOneArr,
            };
        },
        homeworkSpanMethod({ row, column, rowIndex, columnIndex }){
            if (columnIndex === 0) {
                const _row = this.flitterHomeworkData(this.homeworkFinishList).one[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col,
                };
            }
        },
        flitterHomeworkData(arr) {
            let spanOneArr = [];
            let concatOne = 0;
            arr.forEach((item, index) => {
                if (index === 0) {
                    spanOneArr.push(1);
                } else {
                    if (item.userId === arr[index - 1].userId) {
                        spanOneArr[concatOne] += 1;
                        spanOneArr.push(0);
                    } else {
                        spanOneArr.push(1);
                        concatOne = index;
                    }
                }
            });
            return {
                one: spanOneArr,
            };
        },
        handleTabsClick(item){
            this.activeTab = item.name;
        },
        tableSummary(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '有效填写人次';
                    return;
                }
                if (index === 1) {
                    const values = data.map(item => Number(item[column.property]));
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    return;
                }

            });
            return sums
        },
        hideStat({ row, rowIndex }){
            if (row.answerCount===0){
                // return 'hidden-row'
                return '';
            }
            return '';
        },
        handlePieQuestion(qId){
            if (!this.pieQuIds.includes(qId)){
                this.pieQuIds = this.pieQuIds+qId;
            }
            if (this.barQuIds.includes(qId)){
                this.barQuIds = this.barQuIds.replace(qId,'');
            }
        },

        handleBarQuestion(qId){
            if (!this.barQuIds.includes(qId)){
                this.barQuIds = this.barQuIds +qId;
            }
            if (this.pieQuIds.includes(qId)){
                this.pieQuIds = this.pieQuIds.replace(qId,'');
            }
        }


    }
}
</script>

<style scoped>
.text-item {
    height: 40px;
    line-height: 26px!important;
    margin: 0 5px;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
}

.text-item .el-text {
    color: #606266;
    cursor: pointer;
    user-select: none;
}

.text-item:last-child {
    border: none;
}

.el-text:hover{
    color: #1f89de;
}
.cell-item {
    display: flex;
    align-items: center;
}
.homework_tag{
    color: rgb(64, 158, 254);
    border: 1px solid rgb(64, 158, 254);
    background-color: #ecf5ff;
}
.homework_tag_no{
    color: #909399;
    border: 1px solid #e9e9eb;
    background-color: #f4f4f5;
}
.user-record-title{
    font-size: 1.17em;
    color: #606266;
}
.active{
    color: #1f89de;
}

/deep/ .el-table__body tr.hidden-row>td {
    display: none !important;
}



.chart-tab{
    display: flex;
    text-align: center;
    margin: 5px 0;
    color: #333;
    //float: right;
}
.chart-tab ul{
    background: #ebebeb;
    padding: 0 2px;
    list-style: none;
    border: none;
    margin: 0;
    display: block;
}

.chart-tab ul li{
    float: left;
    height: 28px;
    line-height: 26px;
    text-align: center;
    background: #fff;
    border-radius: 2px;
    margin: 2px 1px;
    cursor: pointer;
    padding: 0 4px
}

.fr{
    float: right;
}
.liSel{
    background: #fff;
    box-shadow: 0 1px 2px 0 #d9d9d9;
    border-radius: 1px;
    color: #1f89de;
    font-weight: bold;
}
.txt-icon{
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400 !important;
    text-decoration: none;
}
.txt-vam{
    vertical-align: middle;
}
</style>