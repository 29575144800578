import request from "@/utils/request";

//查询Banner分享信息
export const  shareBannerInfo =(query) =>{
    return request({
        url: "/authservice/yg/banner/shareInfo",
        method:"get",
        params: query
    })
}

//查询新闻资讯分享信息
export const  shareNewsInfo =(query) =>{
    return request({
        url: "/authservice/yg/news/shareInfo",
        method:"get",
        params: query
    })
}

//查询新闻资讯分享信息
export const  readShare =(data) =>{
    return request({
        url: "/authservice/yg/share/read",
        method:"post",
        data: data
    })
}