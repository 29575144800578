<!--

  ========== 一体化教研 - 听评课  高水平学能规划中心 ==========

-->
<template>
    <div class="app-container">
        <el-card class="mb15">
            <el-row>
                <el-col :span="8" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增听评课</el-button>
                    <el-button v-has-btn="'1,2,3,4'" type="primary" @click="handleLbAdd">新增听评课量表</el-button>
                </el-col>
                <el-col :span="16" class="mb0" v-if="role == 1 || role == 2">
                    <div class="pull-right"
                         style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox @change="getList()" v-model="isSHowMine" size="large" />
                        <div class="ml5" style="white-space: nowrap;">我参与的</div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getList()" v-model="queryParams.type" placeholder="请选择">
                                <el-option v-for="item in typeList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="备课性质">
                            <el-select clearable @change="getList()" v-model="queryParams.lessonNature" placeholder="请选择">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入听评课标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2"
                          show-overflow-tooltip @current-change="handleCurrentChange" height="calc(100vh - 390px)"
                          v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="90" label="任务状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">未提请</div>
                            <div v-if="scope.row.status == 1">初始</div>
                            <div v-if="scope.row.status == 2">进行中</div>
                            <div v-if="scope.row.status == 3">已结束</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" label="课程标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="听评课性质" prop="lessonNature">
                        <template #default="scope">
                            <div v-if="scope.row.lessonNature == 1||scope.row.lessonNature == null||scope.row.lessonNature == ''" class="text-one">普通</div>
                            <div v-if="scope.row.lessonNature == 2" class="text-one">名师工作室</div>
                            <div v-if="scope.row.lessonNature == 3" class="text-one">名校长工作室</div>
                            <div v-if="scope.row.lessonNature == 4" class="text-one">群组任务</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="类型" prop="type">
                        <template #default="scope">
                            <div v-if="scope.row.type == 0">推门</div>
                            <div v-if="scope.row.type == 1">线上</div>
                            <div v-if="scope.row.type == 2">录播视频</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="授课老师" prop="lectureTeacherName" />
                    <el-table-column min-width="200" label="听课地点" prop="address">
                        <template #default="scope">
                            <div :title="scope.row.address" class="text-one">{{ scope.row.address }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="任务时段" prop="time">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="评课截止时间" prop="rateEndTime">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.rateEndTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="学段•年级•学科">
                        <template #default="scope">
                            <el-text>
                                {{scope.row.periodName}}•{{scope.row.gradeName}}•{{scope.row.subjectName}}
                            </el-text>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column width="80" label="学段" prop="periodName" />-->
                    <!--                    <el-table-column width="80" label="年级" prop="gradeName" />-->
                    <!--                    <el-table-column width="160" label="学科" prop="subjectName" />-->

                    <el-table-column width="150" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="100" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popover placement="bottom" trigger="click" :width="80" popper-style="padding: 5px 0 0">
                                <template #reference>
                                    <el-button>操作</el-button>
                                </template>
                                <template #default>
                                    <div>
                                        <div class="text-item" v-has-btn="'1,2,3'" v-if="scope.row.status !== 3">
                                            <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>
                                        </div>
                                        <div class="text-item" v-has-btn="'4,5,6'" v-if="scope.row.isCreate&&scope.row.status !== 3">
                                            <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>
                                        </div>
                                        <div class="text-item" v-if="scope.row.status === 1">
                                            <el-text type="primary" @click="handleRelease(scope.row)">发布评课</el-text>
                                        </div>
                                        <div class="text-item" v-if="scope.row.status !== 0 && scope.row.role === 3">
                                            <el-text type="primary" @click="handleSummary(scope.row)">评课总结</el-text>
                                        </div>
                                        <!--                                        <div-->
                                        <!--                                            class="text-item"-->
                                        <!--                                            v-has-btn="'1,2,3'"-->
                                        <!--                                            v-if="(scope.row.status == 2 || scope.row.status == 3) && scope.row.scaleList.length != 0 && scope.row.lessonNature == 1"-->
                                        <!--                                        >-->
                                        <!--                                            <el-text type="primary" @click="handleComplete(scope.row)">-->
                                        <!--                                                {{ scope.row.status == 2 ? '去完成' : '去查看' }}-->
                                        <!--                                            </el-text>-->
                                        <!--                                        </div>-->
                                        <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                            <div>
                                                <div v-if="scope.row.scaleList && scope.row.scaleList.length != 0">
                                                    <div @click="handleComplete(item, scope.row)" :title="item.title"
                                                         class="filtxt text-one cursor" v-for="(item, index) in scope.row.scaleList">
                                                        {{ index + 1 }}. {{ item.title }}
                                                    </div>
                                                </div>
                                            </div>
                                            <template #reference>
                                                <div v-if="scope.row.scaleList
                                                && scope.row.isRateTeacher
                                              && (scope.row.status === 1 || scope.row.status === 2|| scope.row.status === 3)
                                              && scope.row.scaleList.length !== 0"
                                                     class="text-item">
                                                    <el-text type="primary">听评量表</el-text>
                                                </div>
                                            </template>
                                        </el-popover>
                                        <!--                                        <div-->
                                        <!--                                            class="text-item"-->
                                        <!--                                            v-has-btn="'4,5'"-->
                                        <!--                                            v-if="(scope.row.status == 2 || scope.row.status == 3) && scope.row.scaleList.length != 0 && scope.row.lessonNature != 1"-->
                                        <!--                                        >-->
                                        <!--                                            <el-text type="primary" @click="handleComplete(scope.row)">-->
                                        <!--                                                {{ scope.row.status == 2 ? '去完成' : '去查看' }}-->
                                        <!--                                            </el-text>-->
                                        <!--                                        </div>-->
                                        <!--                                        <div-->
                                        <!--                                            class="text-item"-->
                                        <!--                                            v-has-btn="'6'"-->
                                        <!--                                            v-if="(scope.row.status == 2 || scope.row.status == 3) && scope.row.isRateTeacher && scope.row.scaleList.length != 0"-->
                                        <!--                                        >-->
                                        <!--                                            <el-text type="primary" @click="handleComplete(scope.row)">-->
                                        <!--                                                {{ scope.row.status == 2 ? '去完成' : '去查看' }}-->
                                        <!--                                            </el-text>-->
                                        <!--                                        </div>-->
                                        <div
                                          class="text-item"
                                          v-has-btn="'1,2,3'"
                                          v-if="scope.row.status == 2 && scope.row.lessonNature == 1">
                                            <el-text type="primary" @click="handleStop(scope.row)">停止评课</el-text>
                                        </div>
                                        <div
                                          class="text-item"
                                          v-has-btn="'4,5'"
                                          v-if="scope.row.status == 2 && scope.row.lessonNature != 1">
                                            <el-text type="primary" @click="handleStop(scope.row)">停止评课</el-text>
                                        </div>
                                        <div class="text-item" v-has-btn="'1,2,3'">
                                            <el-popconfirm
                                              confirm-button-text="是"
                                              cancel-button-text="否"
                                              :icon="InfoFilled"
                                              icon-color="#626AEF"
                                              title="确定删除该听评课"
                                              @confirm="handleDelete(scope.row)">
                                                <template #reference>
                                                    <el-text type="danger">删除</el-text>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy" v-if="!isSHowMine">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>

        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <previewData :disabled="disabled" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
            </previewData>
            <template #footer v-if="!disabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitPreviewForm">确 定</el-button>
                    <el-button @click="closePreview">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog title="听评课总结"
                   v-model="isShowSum"
                   width="44%"
                   align-center
                   class="sum-info">
            <el-form v-model="summaryForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="教学内容" prop="teachContent">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写教学内容方面总结"
                              v-model="summaryForm.teachContent"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="教学方法" prop="teachMethod">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写教学方法方面总结"
                              v-model="summaryForm.teachMethod"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="课堂氛围" prop="classroomMood">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写课堂氛围方面总结"
                              v-model="summaryForm.classroomMood"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div style="font-weight: bold;margin-bottom: 10px">教师行为</div>
                </el-row>
                <!--                <el-divider content-position="center">教师行为</el-divider>-->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="激发兴趣" prop="teaInterest">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写激发兴趣方面总结"
                              v-model="summaryForm.teaInterest"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="达标意识" prop="teaAware">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写达标意识方面总结"
                              v-model="summaryForm.teaAware"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="引导体验" prop="teaExperience">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写引导体验方面总结"
                              v-model="summaryForm.teaExperience"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="评价反馈" prop="teaFeedback">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写评价反馈方面总结"
                              v-model="summaryForm.teaFeedback"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="讲授指导" prop="teaGuide">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写讲授指导方面总结"
                              v-model="summaryForm.teaGuide"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div style="font-weight: bold;margin-bottom: 10px">学生行为</div>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="参与状态" prop="stuStatus">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写参与状态方面总结"
                              v-model="summaryForm.stuStatus"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="参与广度" prop="stuScope">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写参与广度相关总结"
                              v-model="summaryForm.stuScope"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有效思维" prop="stuThink">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写有效思维方面总结"
                              v-model="summaryForm.stuThink"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作效果" prop="stuResult">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写合作效果方面总结"
                              v-model="summaryForm.stuResult"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="目标达成" prop="stuAim">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写目标达成方面总结"
                              v-model="summaryForm.stuAim"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="师生关系" prop="teaStuRelation">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写师生关系方面总结"
                              v-model="summaryForm.teaStuRelation"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="其他内容" prop="otherContent">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写其他总结内容"
                              v-model="summaryForm.otherContent"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitSummary">确 定</el-button>
                    <el-button @click="closeSummary">取 消</el-button>
                </div>
            </template>

        </el-dialog>
    </div>
</template>

<script>
import { evaluationPageLessons, evaluationListJoin, evaluationDel, evaluationRelease,
    evaluationStop, rateScaleInfo, rateRecordScore, rateRecordScoreInfo,
    infoLessonSummary,createOrUpdateLessonSummary} from "@/api/admin/evaluation"
import {lessonSubmit, delLessonFile } from "@/api/admin/lesson"
import { exportData } from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import previewData from "@/components/previewData.vue";

export default {
    name: "scenarioMain",
    components: {
        previewData
    },
    data() {
        return {
            role: this.$storage.get("role"),
            isSHowMine: false,
            isHide: false,
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '未提请', value: 0 },
                { name: '初始', value: 1 },
                { name: '进行中', value: 2 },
                { name: '已结束', value: 3 },
            ],
            typeList: [
                { name: '推门', value: 0 },
                { name: '线上', value: 1 },
            ],
            timestampToTime,
            // 遮罩层
            loading: false,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                lessonNature: '',
                pageNum: 1,
                pageSize: 10,
            },
            natureList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            previewData: {},
            previewShow: false,
            lessonId: '',
            disabled: false,

            isShowSum:false, //听评课总结
            summaryForm:{
                lessonId:"",
                teachContent:"",//教学内容
                teachMethod:"",//教学方法
                classroomMood:"",//课堂氛围

                teaInterest:"",//教师行为-激发兴趣
                teaAware:"",//教师行为-达标意识
                teaExperience:"",//教师行为-引导体验
                teaFeedback:"",//教师行为-评价反馈
                teaGuide:"",//教师行为-讲授指导

                stuStatus:"",//学生行为-参与状态
                stuScope:"",//学生行为-参与广度
                stuThink:"",//学生行为-有效思维
                stuResult:"",//学生行为-合作效果
                stuAim:"",//学生行为-目标达成

                teaStuRelation:"",//师生关系
                otherContent:"",//其他内容
            },
        };
    },
    created() {
        if (this.$storage.get("role") == 6) {
            this.isSHowMine = true;
        }
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (!row.row.isMine) {
                return 'hidden-row';
            }
            return '';
        },
        submitPreviewForm() {
            var obj = {};
            obj.scaleId = this.$refs.editData.previewData.scaleId;
            obj.lessonId = this.lessonId;
            obj.answerList = [];
            if (this.$refs.editData.isHide) {
                obj.isHide = 1;
            } else {
                obj.isHide = 0;
            }
            var totalScore = 0;
            this.$refs.editData.previewData.questionList.forEach(e => {
                if (e.type == 2) {
                    var txt = e.questionAnswer.toString()
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: txt
                    })
                    e.questionAnswer.forEach(m=>{
                        e.itemList.forEach(j=>{
                            if(m == j.itemId){
                                totalScore += Number(j.itemValue)
                            }
                        })
                    })
                } else {
                    if(e.type == 1){
                        e.itemList.forEach(j=>{
                            if(e.questionAnswer == j.itemId){
                                totalScore += Number(j.itemValue)
                            }
                        })
                    }
                    if(e.type == 3){
                        totalScore += Number(e.questionAnswer)
                    }
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: e.questionAnswer
                    })
                }

            })
            obj.totalScore = totalScore;
            rateRecordScore(obj).then(res => {
                if (res.data.code == 1) {
                    this.previewShow = false;
                    this.isHide = false;
                    this.$message.success('提交成功')
                    this.getList();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
            console.log(this.previewData);
            console.log(obj);

        },
        closePreview() {
            this.previewShow = false;
        },
        handleCompleteOld(item) {
            this.disabled = false;
            if (item.status == 3) {
                this.disabled = true
            }
            console.log(item);
            this.lessonId = item.lessonId;
            this.isHide = false;
            if (item.isRate == 1) {
                rateRecordScoreInfo({ lessonId: this.lessonId, scaleId: item.scaleList[0].scaleId }).then(res => {
                    if (res.data.data) {
                        if (res.data.data.info.isHide == 1) {
                            this.isHide = true;
                        }
                    }

                    this.previewData = res.data.data.info;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        } else {
                            // e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            } else {
                rateScaleInfo({ scaleId: item.scaleList[0].scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = [];
                        } else {
                            e.questionAnswer = '';
                        }

                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            }
        },
        handleComplete(item, row) {
            this.disabled = false;
            // if (row.status == 2) {
            //     this.disabled = true
            // }
            this.lessonId = row.lessonId;
            this.isHide = false;
            if (item.isSub == 1) {
                rateRecordScoreInfo({ lessonId: this.lessonId, scaleId: item.scaleId }).then(res => {
                    if (res.data.data.info.isHide == 1) {
                        this.isHide = true;
                    }
                    this.previewData = res.data.data.info;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        } else {
                            // e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            } else {
                rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = [];
                        } else {
                            e.questionAnswer = '';
                        }

                    })
                    this.previewShow = true;
                })
            }

        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.loading = true
            this.dataList = [];
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (!this.isSHowMine) {
                evaluationPageLessons(this.queryParams).then(res => {
                    this.total = res.data.data.total;
                    res.data.data.list.forEach(e => {
                        var textList = [];
                        if (e.scaleList) {
                            e.scaleList.forEach(j => {
                                textList.push(j.title)
                            })
                        } else {
                            e.scaleList = [];
                        }
                        console.log(textList)
                        // e.rateTeacherText = textList.join('、')
                        // console.log(e.rateTeacherText)
                        // if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                        //     this.selectRow = e
                        // }
                        e.scaleText = textList.join('、')
                        console.log(e.scaleText)
                        if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                            this.selectRow = e
                        }
                    })
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.dataList.forEach(e => {
                        var isMine = false;

                        if (e.createUser == userId) {
                            e.isCreate = true;
                            isMine = true;
                        }
                        if (e.lectureTeacher) {
                            if (e.lectureTeacher == userId) {
                                isMine = true;
                            }
                        }
                        e.listenTeacherList.forEach(j => {
                            if (j.userId == userId) {
                                isMine = true;
                            }
                        })
                        e.isRateTeacher = false;
                        e.rateTeacherList.forEach(j => {
                            if (j.userId == userId) {
                                e.isRateTeacher = true;
                                isMine = true;
                            }
                        })
                        if (e.leadTeacher == userId) {
                            e.isRateTeacher = true;
                            isMine = true;
                        }
                        e.isMine = isMine;
                    })
                    console.log(this.selectRow)
                    this.loading = false;
                });
            } else {
                evaluationListJoin().then(res => {
                    if (this.queryParams.periodKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.periodKey == this.queryParams.periodKey
                        })
                    }
                    if (this.queryParams.lessonNature !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.lessonNature == this.queryParams.lessonNature
                        })
                    }
                    if (this.queryParams.gradeKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.gradeKey == this.queryParams.gradeKey
                        })
                    }
                    if (this.queryParams.subjectKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.subjectKey == this.queryParams.subjectKey
                        })
                    }
                    if (this.queryParams.status !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == this.queryParams.status
                        })
                    }
                    if (this.queryParams.type !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.type == this.queryParams.type
                        })
                    }
                    if (this.queryParams.title !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.title.toString().indexOf(this.queryParams.title) != -1
                        })
                    }
                    res.data.data.forEach(e => {
                        var textList = [];
                        if (e.scaleList) {
                            e.scaleList.forEach(j => {
                                textList.push(j.title)
                            })
                        } else {
                            e.scaleList = [];
                        }
                        console.log(textList)
                        e.scaleText = textList.join('、')
                        console.log(e.scaleText)
                        if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                            this.selectRow = e
                        }
                    })
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    this.dataList = JSON.parse(JSON.stringify(res.data.data));
                    this.dataList.forEach(e => {
                        var isMine = false;

                        if (e.createUser == userId) {
                            e.isCreate = true;
                            isMine = true;
                        }
                        if (e.lectureTeacher) {
                            if (e.lectureTeacher == userId) {
                                isMine = true;
                            }
                        }
                        e.listenTeacherList.forEach(j => {
                            if (j.userId == userId) {
                                isMine = true;
                            }
                        })
                        e.isRateTeacher = false;
                        e.rateTeacherList.forEach(j => {
                            if (j.userId == userId) {
                                e.isRateTeacher = true;
                                isMine = true;
                            }
                        })
                        if (e.leadTeacher == userId) {
                            e.isRateTeacher = true;
                            isMine = true;
                        }
                        e.isMine = isMine;
                    })
                    console.log(this.selectRow)
                    this.loading = false;
                })
            }
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            evaluationDel({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/evaluationDetail",
                query: {
                    lessonId: row.lessonId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/evaluationAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
                query: {
                    scaleType: 1
                }
            });
        },
        handleRelease(row) {
            if (row.scaleList.length == 0) {
                this.$message.error("听评课量表不能为空")
                return
            }
            // if (row.gaFileList.length == 0) {
            //     this.$message.error("请先上传公案课件")
            //     return
            // }
            evaluationRelease({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            evaluationStop({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/evaluationAdd",
                query: {
                    lessonId: row.lessonId,
                }
            });
        },
        handleSummary(row){
            this.isShowSum = true;
            infoLessonSummary({ lessonId: row.lessonId }).then(res => {
                if (res.data.code === 1) {
                    this.summaryForm = res.data.data;
                    this.isShowSum = true;
                }else {
                    this.summaryForm.lessonId = row.lessonId;
                }
            })
        },
        closeSummary(){
            this.isShowSum = false;
        },
        focusInput () {
            this.closeInput = false
        },
        submitSummary(){
            if (this.summaryForm.teachContent===''
              &&this.summaryForm.teachMethod===''
              &&this.summaryForm.classroomMood===''

              &&this.summaryForm.teaInterest===''
              &&this.summaryForm.teaAware===''
              &&this.summaryForm.teaExperience===''
              &&this.summaryForm.teaFeedback===''
              &&this.summaryForm.teaGuide===''

              &&this.summaryForm.stuStatus===''
              &&this.summaryForm.stuScope===''
              &&this.summaryForm.stuThink===''
              &&this.summaryForm.stuResult===''
              &&this.summaryForm.stuAim===''

              &&this.summaryForm.teaStuRelation===''
              &&this.summaryForm.otherContent===''){
                this.$message.info("总结内容至少须填写一项")
                return
            }


            createOrUpdateLessonSummary(this.summaryForm).then(res => {
                if (res.data.code === 1) {
                    this.$message.success("总结成功");
                    this.isShowSum = false;
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
    }
};
</script>
<style scoped>


.sum-info{
    >>>.el-textarea__inner::placeholder{
        font-size: 12px;
    }
    >>>.el-textarea__inner{
        font-size: 12px;
    }
}

/deep/ .el-textarea__inner::placeholder{
    font-size: 12px;
}


/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-table /deep/ .hidden-row {
    display: none;
}

.text-item {
    height: 45px;
    margin: 0 10px;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
}

.text-item .el-text {
    color: #606266;
    cursor: pointer;
    user-select: none;
}

.text-item:last-child {
    border: none;
}
</style>
