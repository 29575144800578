<template>
    <div class="" style="height:calc(100vh - 80px);overflow-y:auto;">
        <div class="part_two">
            <div class="part_main" style="display: flex;justify-content: space-between;">
                <div class="index_main">
                    <div class="part_zx" style="margin-top: 5px;display: flex;justify-content: space-between;">
                        <div class="zx_list"
                            style="float: none;width: 25%;height: 235px;background-color: #fff;padding: 15px;border-radius: 7px;overflow: hidden;">
                            <div class="zx_bt" style="border-bottom:#bfbfbf 1px solid;">
                                <ul>
                                    <div style="display: flex;justify-content:space-between;">
                                        <div style="display: flex;align-items:center;">
                                            <img style="width: 20px;height:20px;" src="../../assets/image/stu-new.png"
                                                alt="">
                                            <div style="margin-left: 10px;color:#666666;font-size:15px;font-weight: bold;">
                                                工作简报</div>
                                        </div>
                                        <a @click="toMore(1)" style="float: right;color:#1f89de;font-weight: bold;"
                                            class="cursor" target="_blank">更多&gt;</a>
                                    </div>
                                </ul>
                            </div>
                            <div class="zx_nr">
                                <div class="zx_nrs">
                                    <ul>
                                        <li v-show="index < 7" class="cursor" @click="goDetail(item, 1)"
                                            v-for="(item, index) in formData.gzjbList"><a :title="item.title"><i
                                                    style="width: 100%;">{{ item.title }}</i>
                                                <!-- <span>{{ timestampToTime(item.createTime) }}</span> -->
                                            </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="banner"
                            style="width: 70%;margin-left:1%;float: none;border-radius: 7px;overflow: hidden;">
                            <div class="flexslider">
                                <div class="flex-viewport" style="overflow: hidden; position: relative;">
                                    <el-carousel :interval="3000" arrow="always">
                                        <template v-for="item in formData.newsList" :key="item">
                                            <el-carousel-item class="cursor" @click="goDetail(item, 2)">
                                                <div style="position: relative;width: 100%;height: 100%;">
                                                    <img style="width: 100%;height:100%;object-fit: cover;"
                                                        :src="item.cover" alt="">
                                                    <!-- <div style="position: absolute;line-height: 20px;bottom: 28px;left: 0;right: 0;z-index: 999;background: rgb(20, 127, 207);padding: 10px;color: #fff;"
                                                        class="text-one">{{ item.title }}</div> -->
                                                </div>
                                            </el-carousel-item>
                                        </template>
                                    </el-carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="part_ms"
                    style="width: 32%;height: 240px;padding:15px;background:#fff;border-radius: 7px;overflow: hidden;">
                    <div class="part_tit2">
                        <img style="width: 20px;height:20px;" src="../../assets/image/stu-gzd.png">
                        <h2 style="margin-left: 10px;color:#666666;font-size:15px;font-weight: bold;">关注度排行榜</h2>
                        <!-- <a href="/home/lists/lists.html" target="_blank">更多&gt;&gt;</a> -->
                    </div>
                    <div class="ms_main" style="margin-top: 0 !important;">
                        <ul>
                            <a class="cursor" @click="goPage(item)" v-show="index < 5"
                                v-for="(item, index) in formData.studioList">
                                <li style="padding: 1.8% 0;">
                                    <i :id="'num' + (index + 1)">{{ index + 1 }}</i>
                                    <h2>{{ item.groupName }}</h2>
                                    <div class="gz"><span>{{ item.careNum }}</span>人关注</div>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="part_one">
            <div class="part_main" style="display: flex;justify-content: space-between;">
                <div class="index_main">
                    <!-- <div class="part_tit">
                        <h2>名师工作室</h2>
                        <a class="cursor" @click="toMore(2)">更多&gt;&gt;</a>
                    </div> -->
                    <div class="sjxz"
                        style="background-color: #fff;padding: 15px;border-radius: 7px;overflow: hidden;position: relative;">
                        <div style="position:absolute;right: 10px;top: 5px;">
                            <a @click="toMore(2)" style="color:#1f89de;font-weight: bold;" class="cursor"
                                target="_blank">更多&gt;</a>
                        </div>
                        <div class="sjxz_bot">
                            <div class="sjzx_bot_nr" style="padding-top: 0;">
                                <ul>
                                    <li @click="goPage(item)" v-for="(item, index) in formData.studioList"
                                        v-show="index < 15"><a class="cursor"
                                            style="display: flex;flex-direction: column;align-items: center;">
                                            <div style="width:100px;height:100px;border-radius:100px;overflow: hidden;">
                                                <img :src="item.groupAvatar"
                                                    style="width:100px;height:100px;border-radius:100%">
                                            </div>
                                            <p>{{ item.groupName }}</p>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="part_three" style="background-color: #fff;border-radius: 7px;padding-top:15px;">
                        <div class="part_main slider_stage">
                            <div class="part_tit3">
                                <div style="display: flex;justify-content:space-between;">
                                    <div style="display: flex;align-items:center;">
                                        <img style="width: 20px;height:20px;" src="../../assets/image/stu-cgzs.png">
                                        <div style="margin-left: 10px;color:#666666;font-size:15px;font-weight: bold;">成果展示
                                        </div>
                                    </div>
                                    <a class="cursor" style="float: right;color:#1f89de;font-weight: bold;"
                                        @click="toMore(4)">更多&gt;</a>
                                </div>
                            </div>
                            <div class="ts_main" id="scrol_4" style="width: 100%;">
                                <div class="ts_mains_new current" style="display: flex;flex-wrap: wrap;width: 100%;">
                                    <el-card style="width: 22%;margin:2% 1%;" @click="goDetail(item, 1)"
                                        v-for="(item, index) in formData.cgzsList" :key="item" v-show="index < 4"
                                        :body-style="{ padding: '0px', marginBottom: '1px' }">
                                        <img style="height: 100px;width:100%;" :src="item.cover"
                                            class="image multi-content" />
                                        <div style="padding: 5px 10px">
                                            <div class="text-one" style="text-align: left;">{{ item.title }}</div>
                                            <div class="bottom card-header">
                                                <div style="color: #4d7eff;">
                                                    &gt;详情
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                    <!-- <li class="cursor" @click="goDetail(item)" v-for="(item, index) in formData.cgzsList"
                                        v-show="index < 4">
                                        <a :title="item.title" class="ts_center" target="_blank">
                                            <div class="ts_cen_tp" style="border: 0;">
                                                <img style="width: 100%;height:180px;object-fit: cover;" :src="item.cover">
                                            </div>
                                            <div class="ts_cen_wz">
                                                <div class="cen_wz">
                                                    <h3 style="width: 100%;">{{ item.title }}</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </li> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="part_ms" style="width: 32%;padding:15px;background:#fff;border-radius: 7px;">
                    <div class="part_tit2">
                        <div style="display: flex;justify-content:space-between;">
                            <div style="display: flex;align-items:center;">
                                <img style="width: 20px;height:20px;" src="../../assets/image/stu-jswz.png">
                                <div style="margin-left: 10px;color:#666666;font-size:15px;font-weight: bold;">教师文章</div>
                            </div>
                            <a class="cursor" style="float: right;color:#1f89de;font-weight: bold;"
                                @click="toMore(3)">更多&gt;</a>
                        </div>
                    </div>
                    <div class="gzs" style="margin-top: 0;">
                        <ul>
                            <li class="cursor" @click="goDetail(item, 1)" v-for="(item, index) in formData.jswzList"
                                v-show="index < 9">
                                <a :title="item.title" target="_blank">
                                    <div class="gzs_top">
                                        <div class="gzs_wz">
                                            <div class="gzs_wzt text-one" style="width: 60%;color: #666;">{{ item.title }}
                                            </div>
                                            <div class="gzs_wzb" style="width: 40%;">{{ timestampToTime(item.createTime) }}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="part_three">
            <div class="part_main slider_stage">
                <div class="part_tit3">
                    <h2>成果展示</h2>
                    <ul id="tese">
                    </ul>
                    <a class="cursor" @click="toMore(4)">更多&gt;&gt;</a>
                </div>
                <div class="ts_main" id="scrol_4">
                    <div class="ts_mains_new current">
                        <li class="cursor" @click="goDetail(item)" v-for="(item, index) in formData.cgzsList"
                            v-show="index < 4">
                            <a :title="item.title" class="ts_center" target="_blank">
                                <div class="ts_cen_tp" style="border: 0;">
                                    <img style="width: 100%;height:180px;object-fit: cover;" :src="item.cover">
                                </div>
                                <div class="ts_cen_wz">
                                    <div class="cen_wz">
                                        <h3 style="width: 100%;">{{ item.title }}</h3>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </div> -->
        <el-dialog v-model="isZx" :title="studioTitle" width="1150px">
            <div class="ts_main" id="scrol_4" style="width: 100%;">
                <div class="ts_mains_new current">
                    <li class="cursor" @click="goDetail(item, 1)" v-for="(item, index) in studioList">
                        <a :title="item.title" class="ts_center" target="_blank">
                            <div class="ts_cen_tp" style="border: 0;">
                                <img style="width: 100%;height:200px;object-fit: cover;" :src="item.cover">
                            </div>
                            <div class="ts_cen_wz">
                                <div class="cen_wz">
                                    <h3 style="width: 100%;">{{ item.title }}</h3>
                                </div>
                            </div>
                        </a>
                    </li>
                </div>
            </div>
        </el-dialog>
        <el-dialog v-model="isWz" :title="studioTitle" width="800px">
            <el-row v-for="item in studioList" style="padding: 10px 0;">
                <el-col :span="15">
                    <div @click="goDetail(item, 1)" style="color: #1194fc;" class="text-one cursor">{{ item.title }}</div>
                </el-col>
                <el-col :span="2">
                    <div>{{ item.trueName }}</div>
                </el-col>
                <el-col :span="4">
                    <div>{{ timestampToTime(item.createTime) }}</div>
                </el-col>
                <el-col :span="3">
                    <div style="text-align:center">浏览{{ item.readNum }}</div>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog v-model="isGz" :title="studioTitle" width="800px">
            <div class="sjxz">
                <div class="sjxz_bot">
                    <div class="sjzx_bot_nr" style="padding-top:0">
                        <ul>
                            <li @click="goPage(item)" v-for="(item, index) in formData.studioList"><a class="cursor"><img
                                        :src="item.groupAvatar" style="width:100px;height:100px;border-radius:100%">
                                    <p>{{ item.groupName }}</p>
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { studioPrincipalHome, studioTeacherHome } from "@/api/admin/studio";
import { timestampToTime } from "@/utils/date";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            formData: {},
            timestampToTime,
            isZx: false,
            isWz: false,
            isGz: false,
            studioTitle: '',
            studioList: [],
        };
    },
    created() {
        this.getStudioPrincipalHome();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            // this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goPage(item) {
            this.$router.push({
                path: "/studioRoomDetail",
                query: {
                    groupId: item.groupId,
                }
            });
        },
        toMore(type) {
            if (type == 1) {
                this.studioList = this.formData.gzjbList;
                this.studioTitle = '工作简报';
                this.isZx = true;
            } else if (type == 2) {
                this.studioList = this.formData.studioList;
                this.studioTitle = '名师工作室';
                this.isGz = true;
            } else if (type == 3) {
                this.studioList = this.formData.jswzList;
                this.studioTitle = '教师文章';
                this.isWz = true;
            } else if (type == 4) {
                this.studioList = this.formData.cgzsList;
                this.studioTitle = '成果展示';
                this.isZx = true;
            }
        },
        goDetail(row, type) {
            if (type == 1) {
                this.$router.push({
                    path: "/studioDetail",
                    query: {
                        infoId: row.infoId,
                        isTeachStudioList: 1
                    }
                });
            } else {
                this.$router.push({
                    path: "/newsDetail",
                    query: {
                        data: JSON.stringify(row),
                        isTeachStudioList: 1
                    }
                });
            }
        },
        getStudioPrincipalHome() {
            studioTeacherHome().then(res => {
                this.formData = res.data.data;
                console.log(res);
            })
        }
    }
};
</script>

<style scoped>
.slider-menu-1 {
    position: absolute;
    left: 50%;
    top: 340px;
    width: 750px;
    height: 24px;
    margin-left: -400px;
    text-align: center;
    z-index: 50;
}

.slider-menu-1-box {
    width: 750px;
    margin: 0 auto;
}

.slider-menu-1 ul {
    padding-left: 0px;
    text-align: center;
}

.slider-menu-1 ul li {
    position: relative;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

.slider-menu-1 ul li a {
    display: block;
    width: 35px;
    height: 3px;
    margin: 0px 5px;
    background-color: #ccc
}

.slider-menu-1 ul li.current a {
    background: #fff
}

.ad,
.heads,
.part_main,
.z_ad {
    overflow: hidden;
}

#scrol_4 {
    width: max-content;
    /* height: 930px; */
    overflow: hidden;
}

#scrol_4 .ts_mains_new {
    width: 97%;
    float: left;
}

.ts_mains_new li {
    float: left;
    width: 23%;
    margin: 2% 1%
}

#scrol_4 .ts_mains_new:first-child {}

.ts_mains_new ul {
    width: 100%;
    overflow: hidden;
}


body,
html,
div,
p,
ol,
ul,
textarea,
form,
input,
h1,
h2,
h3,
h4,
h5,
dl,
dt,
dd,
img,
section,
article,
aside,
header,
footer,
nav,
dialog,
figure,
hgroup {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: "微软雅黑";
    font-size: 14px
}

body {
    overflow-x: hidden;
    background: #f9f9f9
}

a {
    text-decoration: none;
}

img {
    border: 0;
}

body,
html {
    width: 100%;
}

.clearfix:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.clearfix {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.top {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #3d95d5
}

.z_top {
    width: 100%;
    height: auto;
    line-height: 40px;
    overflow: hidden;
    background: #3d95d5
}

.part_one,
.part_two,
.part_three,
.zx_bt,
.zx_nr,
.zx_nrs,
.sjxz_top,
.sjxz_bot,
.sjxz_bot_bt,
.sjzx_bot_nr,
.ts_main,
.ts_mains {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.part_one,
.part_two,
.part_three {
    margin-top: 1.5%
}

.tops {
    width: 97%;
    margin: 0 auto;
}

.ad,
.heads,
.part_main,
.z_ad {
    width: 97%;
    margin: 0 auto;
}

.topl,
.topr li a {
    float: left;
    color: #fff;
    font-size: 15px
}

.topr {
    float: right;
}

.topr li,
.z_topr li {
    float: right;
    margin-left: 20px
}

.heads,
.search {
    padding: 1% 0;
}

.logo {
    float: left;
    width: 300px;
}

.navs {
    float: left;
    width: 37%;
    height: auto;
    margin: 0 4%;
}

.navs li {
    float: left;
    /* width: 25%;*/
    text-align: center;
    width: auto;
    padding: 0 5%
}

.navs li a {
    font-size: 22px;
    color: #1666a1;
}

.search {
    float: right;
    width: 24%;
    border: #bfbfbf 1px solid;
    padding: 0
}

.search img {
    float: left;
    display: block;
    width: 10%;
    margin: 0 3%
}

.search select {
    float: left;
    width: 24%;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    color: #999;
    font-size: 13px;
}

.search input {
    float: left;
    width: 50%;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    color: #999;
    font-size: 13px;
    margin-left: 1%;
    line-height: 30px;
}

.search>a {
    float: right;
    width: 25%;
    background: #3c96d5;
    display: block;
    line-height: 34px;
    text-align: center;
    color: #fff;
    font-size: 14px
}

.ad img,
.logo img,
.z_ad img {
    width: 100%;
}

.zb_ewm {
    width: 99%;
    border: #dddddd 4px solid;
    overflow: hidden;
    margin: 3% 0
}

.ewm_main {
    width: 98%;
    height: auto;
    margin: 0.8% auto;
    padding: 3% 0;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ewm_wz {
    width: 55%;
    overflow: hidden;
    font-size: 16px;
    color: #333;
    line-height: 30px
}

.ewm_tp {
    width: 10%;
    background: #fff;
    margin-left: 3%;
    padding: 1%
}

.ewm_tp img {
    width: auto;
    max-width: 98%;
    margin: 0 auto
}

.search a input {
    width: 100% !important;
    text-align: center !important
}

.show_wd img {
    display: block;
    width: auto;
    max-width: 100%;
    margin: 10px auto;
}

.z_ads {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    padding: 2% 0
}

.z_ad_left {
    float: left;
    width: 70%;
    margin-top: 4%
}

.z_ad_right {
    float: right;
    width: 20%;
    margin-top: 3%;
}

.z_ad_left h2 {
    color: #f97e00;
    font-size: 30px
}

.z_ad_left h3 {
    margin-top: 2%;
    color: #539dda;
    font-size: 18px
}

.z_ad_left h3 span {
    margin-left: 10px
}

.z_ad_right img {
    float: left;
    width: 37px;
    margin: 11px 5px 0 0;
}

.z_ad_wz h2 {
    font-size: 14px;
    color: #666;
    font-weight: normal;
}

.z_ad_wz i {
    font-size: 20px;
    font-style: normal;
    color: #37bd82;
    margin-left: 10px
}

.z_ad_wz {
    margin-left: 10px
}

.zx_bta {
    line-height: 30px;
    color: #999;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
}

#num1,
#num2,
#num3,
#num4,
#num5,
#num6,
#num7,
#num8,
#num9,
#num10 {
    background: none !important;
    font-size: 22px;
    font-weight: bold
}

#num1 {
    color: #ed574a;
}

#num2 {
    color: #f8b551;
}

#num3 {
    color: #32b16c;
}

#num4,
#num5,
#num6,
#num7,
#num8,
#num9,
#num10 {
    color: #86c8ff;
}

/*part*/
.index_main {
    float: left;
    width: 65%;
}

.part_ms {
    float: right;
    width: 32%;
}

.part_zx {
    margin-top: 3%;
    overflow: hidden;
}

.banner {
    float: left;
    width: 47%;
    height: 265px;
    overflow: hidden
}

.swiper_text {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    text-indent: 0.5em;
    width: 100%;
    background: #147fcf;
}

.zx_list {
    float: right;
    width: 50%;
    position: relative
}

.zx_bt {
    border-bottom: #0080ac 1px solid;
    padding-bottom: 2%;
}

.zx_bt li {
    float: left;
    width: auto;
    padding: 3px 10px;
    margin: 0 5%;
    color: #0080ac;
    font-size: 16px;
    cursor: pointer;
}

.zx_bt li.active {
    background: #0080ac;
    color: #fff
}

.zx_nrs {
    display: none;
    margin-top: 10px
}

.zx_nrs:first-child {
    display: block;
}

.zx_nrs li,
.zx_nrs li a {
    width: 100%;
    overflow: hidden;
    line-height: 28px;
    height: 28px;
}

/*.zx_nrs li:hover i,.zx_nrs li:hover span{ color:#0080ac}
*/
.zx_nrs li:hover {
    /* background: #3c96d5 url(../img/cirs.png) left no-repeat; */
    background-size: 5px;
    padding-left: 2%;
    background-position: 0 55%;
}

.zx_nrs li:hover i,
.zx_nrs li:hover span {
    color: #057db1
}

.zx_nrs li {
    /* background: url(../img/cir.png) left no-repeat; */
    background-size: 5px;
    padding-left: 2%;
    background-position: 0 55%;
}

.zx_nrs li i {
    float: left;
    width: 70%;
    color: #666;
    font-size: 14px;
    display: block;
    font-style: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.zx_nrs li span {
    float: right;
    width: 30%;
    display: block;
    text-align: center;
    color: #999;
    font-size: 13px
}

.part_zx,
.part_tit

/*,.sjzx_bot_nr li img*/
    {
    width: 100% !important;
    overflow: hidden;
}

.part_tit,
.part_tit2,
.part_tit3 {
    border-bottom: #bfbfbf 1px solid;
    padding-bottom: 1%;
}

.part_tit:after,
.part_tit2:after,
.part_tit3:after,
.ms_main ul li:after,
.foot_list:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.part_tit,
.part_tit2,
.part_tit3,
.ms_main ul li,
.foot_list {
    zoom: 1;
}

.part_tit img,
.part_tit2 img,
.part_tit3 img {
    float: left;
    width: 23px;
}

.part_tit h2,
.part_tit2 h2 {
    float: left;
    width: 70%;
    color: #057db1;
    font-size: 18px;
    font-weight: normal;
    margin-left: 1%
}

.part_tit a,
.part_tit2 a,
.part_tit3 a {
    float: right;
    width: 20%;
    text-align: right;
    color: #999;
    font-size: 14px;
}

.part_tit2 {
    padding-bottom: 2%
}

.ms_main {
    width: 100%;
    background: #fff;
    margin-top: 3%;
    padding: 2% 0
}

.ms_main ul {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
}

.ms_main ul li {
    width: 100%;
    overflow: hidden;
    padding: 2.8% 0
}

.ms_main a {
    display: block;
    border-bottom: #ddd 1px dashed;
}

.ms_main ul li i {
    float: left;
    font-style: normal;
    width: 25px;
    height: 23px;
    text-align: center;
    line-height: 23px;
    color: #fff;
    font-family: "宋体";
    font-size: 24px;
    border-radius: 50%;
    background: #4488c9
}

.ms_main ul li h2 {
    float: left;
    width: 54%;
    margin-left: 5%;
    font-weight: normal;
    font-size: 15px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden
}

.gz {
    float: right;
    width: 33%;
    text-align: right;
    font-size: 14px;
    color: #999
}

.gz span {
    color: #fd451f
}

#one {
    background: #d42522
}

#two {
    background: #fe4229
}

#three {
    background: #d3a126
}

.ms_main ul a:last-child {
    border-bottom: none
}

.sjxz_top,
.sjxz_top2 {
    background: #fff;
    border: #eee 1px solid;
    margin-top: 3%;
    padding: 2.5% 0
}

.sjxz_top2 {
    margin-top: 1%
}

.sjxz_topa,
.sjxz_topa2 {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
}

.sjxz_topb,
.sjxz_topc,
.sjxz_topd,
.sjxz_topb2,
.sjxz_topc2,
.sjxz_topd2 {
    width: 95%;
    margin: 0 auto;
    margin-top: 1%;
    overflow: hidden;
}

.sjxz_topa ul,
.sjxz_topb ul,
.sjxz_topc ul,
.sjxz_topa2 ul,
.sjxz_topb2 ul,
.sjxz_topc2 ul {
    float: left;
    width: 90%;
}

.sjxz_topa li,
.sjxz_topb li,
.sjxz_topc li,
.sjxz_topa2 li,
.sjxz_topb2 li,
.sjxz_topc2 li {
    float: left;
    color: #666;
    line-height: 25px;
    cursor: pointer;
    font-size: 14px;
    width: auto;
    padding: 0 7px;
}

.sjxz_topa2 li,
.sjxz_topb2 li,
.sjxz_topc2 li {
    margin: 0 8px
}

.sjxz_topa h2,
.sjxz_topb h2,
.sjxz_topc h2,
.sjxz_topd h2 {
    float: left;
    line-height: 25px;
    width: 10%;
    text-align: center;
    font-weight: normal;
    color: #666;
    font-size: 14px
}

.sjxz_topa li.active,
.sjxz_topb li.active,
.sjxz_topc li.active,
.sjxz_topa2 li.active,
.sjxz_topb2 li.active,
.sjxz_topc2 li.active {
    background: #037cb1;
    color: #fff
}

.sjxz_topa li.active a,
.sjxz_topb li.active a,
.sjxz_topc li.active a,
.sjxz_topa2 li.active a,
.sjxz_topb2 li.active a,
.sjxz_topc2 li.active a {
    color: #fff !important
}



.sjxz_topd input,
.sjxz_topd2 input {
    float: left;
    width: 25%;
    /*height: 25px;*/
    padding: 3px 0;
    padding-left: 0.5em;
    font-size: 13px;
    color: #666;
    outline: none;
    border: #bfbfbf 1px solid;
    background: #f9f9f9;
    border-right: none
}

.sjxz_topd2 input {
    width: 16%;
}

.sjxz_bot {
    margin-top: 1.5%
}

.sjzx_bot_bt,
.sjzx_bot_bt2 {
    padding-bottom: 2%;
    border-bottom: #bfbfbf 1px solid;
    overflow: hidden;
}

.sjzx_bot_bt2 {
    padding-bottom: 1%
}

.sjzx_bot_bt h2,
.sjzx_bot_bt2 h2 {
    float: left;
    width: 8%;
    font-weight: normal;
    color: #666;
    font-size: 15px
}

.sjzx_bot_bt2 h2 {
    width: 5%
}

.sjzx_bot_bt ul {
    float: left;
    width: 90%
}

.sjzx_bot_bt2 ul {
    float: left;
    width: 50%
}

.sjzx_bot_bt li,
.sjzx_bot_bt2 li {
    float: left;
    width: 20%;
    /* background: url(../img/ltb5.jpg) right no-repeat; */
}

.sjzx_bot_bt li,
.sjzx_bot_bt2 .abc {
    float: left;
    width: 16%;
    margin-right: 4%;
    /* background: url(../img/123456.jpg) right no-repeat; */
}

.sjzx_bot_bt li img,
.sjzx_bot_bt2 li img {
    width: 20px;
    display: block;
    margin-right: 5px
}

.sjzx_bot_bt li:first-child img,
.sjzx_bot_bt2 li:first-child img {
    margin-top: 4px;
}

.sjzx_bot_bt li span,
.sjzx_bot_bt2 li span {
    font-size: 14px;
    color: #666;
    padding-left: 3px;
}

.sjzx_bot_nr {
    padding-top: 2%;
}

.sjzx_bot_nr li {
    float: left;
    width: 17%;
    margin: 1% 1.5%;
    text-align: center
}

.sjzx_bot_nr li a {
    display: block;
    overflow: hidden;
}

.sjzx_bot_nr li p {
    padding-top: 7%;
    text-align: center;
    color: #666;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    height: 22px;
    line-height: 22px;
}

.gzs {
    width: 100%;
    overflow: hidden;
    background: #fff;
    margin-top: 6%;
    padding: 0% 0 3% 0
}

.gzs li {
    width: 94%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    border-bottom: #eee 1px dashed
}

.gzs_top,
.gzs_bot,
.gzs_wzb {
    width: 100%;
    overflow: hidden;
}

.gzs_tp,
.gzs_botl {
    float: left;
    width: 19%
}

.gzs_wz {
    width: 100%;
    padding: 3% 0;
    overflow: hidden
}

.gzs_botr {
    float: right;
    width: 78%
}

.gzs_top {
    position: relative;
}

.gzs_wzt span {
    float: left;
    width: 50%;
    color: #057db1;
    font-weight: normal;
    font-size: 15px
}

.gzs_wzt {
    float: left;
    width: 65%;
    color: #057db1;
    height: 18px;
    font-weight: normal;
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden
}

.gzs_wzt span {
    text-align: right;
    color: #666
}

.gzs_wzb {
    float: right;
    width: 35%;
    text-align: right;
    font-size: 14px;
    color: #666;
}

.gzs_bot {
    padding: 3% 0
}

.gzs_botl {
    font-size: 14px;
    color: #666;
}

.gzs_botr {
    font-size: 14px;
    color: #057db1;
}

.gzs_tp img {
    width: 100%
}

.part_tit3 h2 {
    float: left;
    width: 12%;
    color: #057db1;
    font-size: 18px;
    font-weight: normal;
    margin-left: 1%
}

#tese {
    float: left;
    width: 65%;
}

#tese li {
    float: left;
    width: auto;
    padding: 3px 10px;
    margin: 0 5px;
    color: #0080ac;
    font-size: 16px;
    cursor: pointer;
}

#tese li.active {
    background: #0080ac;
    color: #fff
}

.ts_mains {
    display: none;
}

.ts_mains:first-child {
    display: block;
}

.pages li.active a {
    background: #367dcf;
    border: none
}

.ts_mains li {
    float: left;
    width: 23%;
    margin: 2% 1%
}

.ts_center {
    display: block;
    width: 100%;
}

.ts_cen_tp {
    width: 100%;
    background: #fff;
    padding: 3% 0;
    border: #c9c9c9 1px solid
}

.ts_cen_tp img {
    display: block;
    margin: 0 auto;
    width: 94%
}

.ts_cen_wz,
.ts_cen_wz h2,
.cen_wz {
    width: 100%;
    overflow: hidden;
}

.ts_cen_wz h2,
.cen_wz h3,
.cen_wz h4 {
    font-size: 14px;
    color: #666;
    font-weight: normal;
}

.cen_wz h3 {
    float: left;
    width: 60%;
    color: #0080ac;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden
}

.cen_wz h4 {
    float: right;
    width: 40%;
    text-align: right;
    color: #bf8b5c;
}

.ts_cen_wz h2 {
    height: 30px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden
}

.part_three {
    padding-bottom: 1.5%
}

/*foot*/
.foot {
    width: 100%;
    background: #e8e8e8;
    overflow: hidden;
    padding: 1% 0
}

.foot_bq,
.foot_list,
.list_page,
.show_zx,
.ts_mains ul,
.list_zx,
.list_tit,
.list_tit2,
.zx_main,
.zx_lb,
.zx_lb li,
.show_bt,
.show_fb,
.show_wz {
    width: 100%;
    overflow: hidden;
}

.foot_list {
    width: 610px;
    margin: 0 auto;
}

.foot_bq,
.foot_list p {
    text-align: center;
    color: #666;
    font-size: 14px;
    float: left;
}

.foot_list p {
    margin-right: 20px;
    line-height: 35px
}

.glzx_xl {
    float: left;
    color: #fff;
    font-size: 14px;
    position: relative
}

.glzx_xl h2 {
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    /* background: url(../img/sj.png) right no-repeat */
}

.glzx_menu {
    display: none;
    position: absolute;
    width: 100%;
    background: #3d95d5;
    top: 40px;
    text-align: center;
    z-index: 100;
}

.menu,
.menu a {
    width: 100%;
    display: block;
    line-height: 30px !important;
    text-align: center;
}

.menu a {
    color: #fff;
    font-size: 13px !important;
}

.glzx_xl:hover .glzx_menu {
    display: block
}

/*list*/
.list_page {
    margin: 3% 0;
    text-align: center;
}

.list_page a {
    width: auto;
    line-height: 30px;
    color: #666;
    font-size: 14px;
    padding: 3px 10px;
    margin: 0 5px !important;
    text-align: center;
    border: #367dcf 1px solid;
}

.list_page span {
    /* background: #367dcf;*/
    color: #fff;
    padding: 3px 10px;
    margin: 0 5px !important;
    text-align: center;
    /* border: #367dcf 1px solid; */
}

.list_page .current {
    background: #367dcf;
    color: #fff
}

.fengcai li {
    float: left;
    float: left;
    width: 30%;
    height: 90px;
    margin: 1% 1.5%;
    padding: 2% 0;
    border: #c9c9c9 1px solid;
    background: #fff;
}

.fengcai li a {
    display: block;
    width: 90%;
    margin: 0 auto;
}

.fengcai li a:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.fengcai li a {
    zoom: 1;
}

.fc_you {
    float: right;
    width: 155px;
}

.fengcai li img {
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.fengcai li h2 {
    float: left;
    width: 100%;
    color: #666;
    font-size: 15px;
}

.fengcai li h4 {
    width: 100%;
    line-height: 20px;
    color: #666;
    font-size: 14px;
    font-weight: normal;
    padding-top: 4px;
    max-height: 44px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pages,
.page {
    width: 50%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pages li a {
    display: block;
    padding: 0 12px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

.pages li.active a {
    color: #fff
}

.page {
    width: 100%;
    margin-top: 0.5%
}

.page p {
    margin: 0 5px;
    color: #989898;
    font-size: 14px
}

.page span {
    color: #f00
}

.list_zx {
    width: 97%;
    margin: 1.5% auto 1% auto;
}

.list_nav {
    float: left;
    width: 20%;
    padding-bottom: 2%;
    border: #c9c9c9 1px solid;
    border-radius: 6px
}

.list_nav h2 {
    background: #0180b7;
    border-radius: 6px 6px 0 0;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    padding: 6% 0
}

.list_nav ul {
    width: 80%;
    overflow: hidden;
    margin: 8% auto
}

.list_nav li {
    width: 100%;
    border-bottom: #e7e7e7 1px dashed;
    padding: 5% 0
}

.list_nav li img {
    margin-right: 3%;
    margin-left: 20%
}

.list_nav li a {
    color: #747474;
    font-size: 18px;
    font-weight: bold;
}

.list_nav li.active a {
    color: #007fb4
}

.list_center {
    float: right;
    width: 77%;
}

.list_tit {
    border-bottom: #c9c9c9 2px solid;
    line-height: 40px
}

.list_tit2 {
    border-bottom: #c9c9c9 1px solid;
    line-height: 40px
}

.list_tit h2,
.list_tit2 h2 {
    float: left;
    width: auto;
    color: #007fb4;
    font-size: 18px;
    font-weight: normal;
    padding: 0 5px;
    border-bottom: #007fb4 1px solid;
    display: block;
}

.list_tit2 h2 {
    padding: 0 5px;
    border-bottom: #007fb4 1px solid;
}

.location {
    float: right;
    width: 80%;
    text-align: right;
    color: #989898;
    font-size: 14px
}

.zx_lb {
    border-bottom: #e7e7e7 1px dashed;
    padding-bottom: 2%
}

.zx_lb h2 {
    width: 100%;
    font-size: 15px;
    color: #000;
    padding-top: 3%;
    padding-bottom: 1%
}

.zx_lb li,
.z_list_zx li,
.z_list_fc li,
.z_list_zb li {
    /* background: url(../img/blue2.jpg) left no-repeat; */
    padding-left: 1%;
    background-position: 0 55%;
    line-height: 40px
}

.zx_lb li a,
.z_list_zx li a,
.z_list_fc li a,
.z_list_zb li a {
    display: block;
    overflow: hidden;
}

.zx_lb li i {
    float: left;
    /*margin-left: 2%; */
    font-style: normal;
    font-size: 15px;
    color: #007fb4;
    display: block;
}

.zx_lb li p {
    float: left;
    font-size: 15px;
    color: #666;
    width: 75%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.zx_lb li span,
.z_list_zx li span {
    float: right;
    font-size: 15px;
    color: #989898;
    width: 14%;
    text-align: center;
    display: block;
}

.zx_main {
    margin-bottom: 4%
}

.list_gzs {
    width: 100%;
    overflow: hidden;
}

.list_gzs ul {
    margin: 1.5% 0;
}

.list_gzs li {
    float: left;
    width: 23%;
    margin: 1% 0.9%;
    border: #c9c9c9 1px solid;
    background: #fff
}

.list_gzs li a {
    display: block;
    overflow: hidden;
}

.list_gzs_tit,
.list_gzs_nr {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
}

.list_gzs_nr {
    display: block;
}

.list_gzs_tit {
    padding: 10% 0 3% 0
}

.list_gzs_nr {
    padding: 5% 0 8% 0
}

.list_gzs_tit h2 {
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    color: #333
}

.list_gzs_tit span {
    height: 35px;
    line-height: 35px;
    display: block;
    padding: 2px 3px;
    background: #caa87b;
    color: #fff;
    font-size: 13px;
    margin-top: 2%;
}

.gzs_tx {
    float: left;
    width: 35%
}

.gzs_tx img {
    width: 80px;
    height: 80px;
    border-radius: 50%
}

.gzs_lbwz {
    margin-left: 5%;
    width: 60%
}

.gzs_lbwz p {
    color: #666;
    font-size: 14px;
    line-height: 22px
}

.oa_sr textarea {
    width: 100% !important;
    height: auto !important
}

/*show*/
.show_zx {
    margin-top: 1.5%
}

.show_bt {
    text-align: center;
    padding: 3% 0;
    font-size: 22px;
    color: #e0252c
}

.show_fb {
    text-align: center;
    padding-bottom: 3%;
}

.show_fb span {
    text-align: center;
    margin: 0 5px;
    color: #dfdfdf;
    font-size: 14px
}

.show_wz,
.show_wz p,
.show_wz_wd,
.show_wz_wd p {
    text-indent: 2em;
    color: #666;
    font-size: 16px;
    line-height: 30px;
}

.show_wz,
.show_wz_wd {
    width: 100%;
}

.show_wz img,
.show_wds {
    width: auto;
    max-width: 90%;
    display: block;
    margin: 0 auto
}

.show_wz_wd img {
    float: left;
    width: auto;
    max-width: 90%;
    display: block;
}

.show_more {
    text-align: right;
    margin: 3% 0;
    line-height: 32px;
    color: #989898;
    font-size: 14px
}

.show_more a {
    width: auto;
    display: block;
    float: right;
    padding: 0 30px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    background: #f2f0f1;
    color: #0180b7;
    border: #bfbfbf 1px solid;
    border-radius: 5px;
}

.sjxz_topa2 h2,
.sjxz_topb2 h2,
.sjxz_topc2 h2,
.sjxz_topd2 h2 {
    float: left;
    line-height: 25px;
    width: 7%;
    text-align: center;
    font-weight: normal;
    color: #666;
    font-size: 14px;
}

.show_wds p {
    line-height: 30px;
}

.show_wds img {
    margin: 1% auto;
    display: block;
    max-width: 100%;
    height: auto !important;
}






/*zindex*/
.z_nav {
    width: 97%;
    margin: 0 auto;
    height: 45px;
    margin-top: 0.5%;
    background: #3d95d5
}

.z_nav li {
    position: relative;
    float: left;
    /* width: 12.5%; */
    padding: 0 3.5%;
    text-align: center;
    line-height: 45px;
}

.z_nav li a {
    color: #fff;
    font-size: 17px;
    display: block
}

.cgzs_nav {
    position: absolute;
    width: 100%;
    left: 0;
    top: 45px;
    background: #3d95d5;
    display: none;
    z-index: 9999;
}

.nav_cg,
.nav_cg a {
    display: block;
    width: 100%;
    font-size: 15px !important;
    text-align: center;
    line-height: 40px !important;
}

.z_index_main {
    width: 97%;
    margin: 2% auto;
    overflow: hidden;
}

.z_index_left {
    float: left;
    width: 25%;
    border: #eee 1px solid;
}

.z_nav li:hover .cgzs_nav {
    display: block
}

.z_index_ljs,
.z_index_ltj {
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.z_ljs_tit,
.z_ljs_tit3 {
    width: 100%;
    overflow: hidden;
    background: #1d71af;
    padding: 4% 0;
    display: flex;
    justify-content: left;
    align-items: center;
}

.z_ljs_tit h2,
.z_ljs_tit3 h2 {
    float: left;
    width: 80%;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    margin-left: 5%
}

.z_ljs_tit a {
    float: right;
    display: block;
    width: 7%;
    margin-right: 5%;
    display: block;
}

.z_ljs_tit a img,
.ir_two_lb li img,
.jyhd_lnr_tp img {
    width: 100%
}

.ljs_topl img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.ir_two_lb img {
    width: 100% !important;
    height: 80% !important
}

.ir_two_lb .title {
    height: 30px;
    line-height: 30px;
    color: #666
}

.z_ljs_tit3 a {
    width: 25%;
    text-align: right;
    color: #fff;
    font-style: 14px;
    margin-right: 3%
}

.z_ljs_tit3 {
    background: #3d95d5
}

.z_index_right {
    float: right;
    width: 72%;
}

.z_ljs_top {
    width: 85%;
    margin: 7% auto;
    overflow: hidden;
}

.ljs_topl {
    float: left;
    width: 30%;
    margin-top: 3px;
}

.ljs_topr {
    float: left;
    width: 65%;
    margin-left: 5%
}

.ljs_topr h2,
.ljs_topr h3 {
    margin: 2% 0;
    font-weight: normal;
    color: #027db9;
    font-size: 22px
}

.ljs_topr h3 {
    color: #333;
    font-size: 13px
}

.ljs_topr a {
    width: auto;
    border-radius: 5px;
    margin: 2% 0;
    line-height: 25px;
    display: inline-block;
    padding: 0 10px;
    background: #f48d28;
    color: #fff;
    font-size: 15px;
    text-align: center;
}

.ljs_else {
    width: 100%;
    overflow: hidden;
    color: #333;
    font-size: 13px
}

.ljs_else span {
    color: #f48d28
}

.z_ljs_mid {
    width: 90%;
    margin: 3% auto;
    overflow: hidden;
    border-top: #e0e0e0 1px dashed;
    border-bottom: #e0e0e0 1px dashed
}

.z_ljs_mid li {
    float: left;
    width: 33.3%;
    text-align: center;
    padding: 3% 0
}

.z_ljs_mid li h2 {
    color: #1e72ae;
    font-size: 17px
}

.z_ljs_mid li h3 {
    color: #f65b2b;
    font-size: 17px
}

.z_ljs_mid li h4 {
    color: #1cc185;
    font-size: 17px
}

.z_ljs_mid li p {
    font-size: 14px;
    color: #666
}

.z_ljs_sq {
    width: 60%;
    display: block;
    margin: 10% auto;
    overflow: hidden;
    border-radius: 6px;
    background: #f65b2b;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
}

.z_index_ltj {
    margin-top: 8%
}

.ljs_main {
    width: 100%;
    background: #fff
}

.ljs_main ul {
    width: 94%;
    margin: 2% auto;
    overflow: hidden;
}

.ljs_main li {
    width: 100%;
    overflow: hidden;
    line-height: 30px;
    margin: 2% 0
}

.ljs_main li i {
    float: left;
    /* width: 30px; height: 37px; text-align: center;*/
    color: #666;
    font-size: 14px;
    font-style: normal;
    /* font-weight: bold;line-height: 25px;background: url(../img/else.jpg) center no-repeat; background-size: 100% 100% */
}

.ljs_main li a {
    float: left;
    width: 50%;
    color: #666;
    display: block;
    margin-left: 5px;
    font-style: 14px
}

.ljs_main li p {
    float: right;
    text-align: right;
    width: 36%;
    display: block;
    color: #666;
    font-style: 14px
}

.ljs_main li span {
    margin-right: 5px;
    color: #dd5b5b;
}

.first i {
    /* background: url(../img/first.jpg) center no-repeat !important; */
    background-size: 100% 100%
}

.second i {
    /* background: url(../img/second.jpg) center no-repeat !important; */
    background-size: 100% 100%
}

.third i {
    /* background: url(../img/third.jpg) center no-repeat !important; */
    background-size: 100% 100%
}

.first a {
    color: #fd6742 !important
}

.second a {
    color: #34c7c1 !important
}

.third a {
    color: #5f9ed4 !important
}

.z_ir_one,
.ir_two_lb,
.jsart {
    width: 100%;
    overflow: hidden;
    margin-top: 2%
}

.list_tit2 a {
    float: right;
    text-align: right;
    color: #999;
    font-size: 14px
}

.z_one_nr {
    width: 100%;
    margin-top: 3%;
    overflow: hidden;
}

.z_one_ban {
    float: left;
    width: 45%;
    height: 300px;
    overflow: hidden
}

.z_one_list {
    float: right;
    width: 52%;
    overflow: hidden;
}

.z_one_list li,
.jyhd li {
    width: 100%;
    line-height: 34px;
    /* background: url(../img/blue2.jpg) left no-repeat; */
}

.z_one_list li a,
.jsart_wz li a,
.jyhd li a {
    display: block;
    overflow: hidden;
}

.z_one_list li i {
    float: left;
    width: 70%;
    margin-left: 3%;
    font-style: normal;
    font-size: 13px;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}

.z_one_list li span {
    float: right;
    width: 25%;
    text-align: right;
    font-size: 13px;
    color: #999
}

.ir_two_lb li {
    float: left;
    width: 23%;
    height: 130px;
    margin: 1% 1% 0 1%;
}

.jsart {
    padding-bottom: 2%;
    border-bottom: #e1e1e1 1px dashed
}

.jsart:last-child {
    border-bottom: none;
    padding-bottom: 0
}

.jsart_tp {
    float: left;
    width: 20%;
    height: 120px;
    display: table;
    table-layout: fixed;
}

.jsart_tp a {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.jsart_tp img {
    display: block;
    width: auto;
    max-width: 100%;
    margin: 0 auto
}

.jsart_wz {
    float: right;
    width: 78%;
}

.jsart_wz li {
    width: 100%;
    line-height: 30px;
    overflow: hidden;
    /* background: url(../img/blue2.jpg) left no-repeat; */
    background-position: 0% 65%;
}

.jsart_wz li p {
    width: 57% !important;
    font-size: 14px !important
}

.jyhd li p {
    width: 45% !important;
    font-size: 14px !important
}

.jsart_wz li i,
.jyhd li i {
    color: #9c898d !important;
    margin-left: 3%;
    font-size: 14px !important
}

.jsart_wz li span {
    font-size: 14px !important;
}

.eye {
    float: right;
    width: 10%;
    color: #989898;
    font-style: 14px
}

.jyhd {
    width: 100%;
    overflow: hidden;
    margin-top: 2%;
}

.jyhd h2,
.jyhd h3,
.jyhd h4 {
    float: left;
    width: 10%;
    font-weight: normal;
    font-size: 14px
}

.jyhd h2 {
    color: #67ba74
}

.jyhd h3 {
    color: #ea9471
}

.jyhd h4 {
    color: #5782a4
}

.date {
    float: right;
    width: 25%;
    color: #989898;
    text-align: right;
    font-size: 14px
}

.jyhd_center {
    width: 97%;
    overflow: hidden;
    margin: 0 auto
}

.jyhd_right {
    float: right;
    width: 30%;
    background: #fff;
    border: #eee 1px solid;
}

.jyhd_left {
    float: left;
    width: 67%;
    background: #fff;
    border: #eee 1px solid;
}

.jyhd_rlb,
.jyhd_lnr li {
    width: 100%;
    overflow: hidden;
}

.jyhd_rlb ul {
    width: 95%;
    margin: 2% auto;
    overflow: hidden;
}

.jyhd_rlb li {
    width: 100%;
    overflow: hidden;
    border-bottom: #dedede 1px dashed;
    padding: 5% 0
}

.jyhd_rlb li:last-child {
    border-bottom: none
}

.jyhd_rlb li i {
    float: left;
    width: 20px;
    line-height: 20px;
    margin: 0 5px;
    background: #418ace;
    font-style: normal;
    color: #fff;
    font-style: 14px;
    border-radius: 50%;
    text-align: center;
}

.cone i {
    background: #d32524 !important
}

.ctwo i {
    background: #ff4027 !important
}

.cthr i {
    background: #cea227 !important
}

.jyhd_rlb li p {
    float: left;
    width: 90%;
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.jyhd_lbt,
.jyhd_lnr {
    width: 90%;
    height: auto;
    margin: 3% auto;
    overflow: hidden;
}

.jyhd_lnr {
    margin: 0 auto
}

.jyhd_lbt li {
    float: left;
    width: auto;
    border-radius: 6px;
}

.jyhd_lbt li a {
    padding: 0 15px;
    line-height: 25px;
    display: block;
    color: #666;
    font-size: 14px;
}

.jyhd_lbt li.active {
    background: #f59443
}

.jyhd_lbt li.active a {
    color: #fff
}

.jyhd_lbt li span {
    color: #666;
    font-style: 14px;
    margin: 0 15px
}

.jyhd_lnr li {
    border-bottom: #dcdcdc 1px solid;
    padding-bottom: 3%;
    margin-top: 3%
}

.jyhd_lnr_tp {
    float: left;
    width: 25%
}

.jyhd_lnr_wz {
    float: right;
    width: 72%
}

.lnr_wz_bt,
.lnr_wz_time,
.lnr_wz_desc,
.lnr_wz_ms {
    width: 100%;
    overflow: hidden;
}

.lnr_wz_bt h2 {
    float: left;
    font-weight: normal;
    color: #3d95d5;
    font-size: 16px;
}

.lnr_wz_bt h3,
.lnr_wz_bt h4,
.lnr_wz_bt h5 {
    float: left;
    width: auto;
    color: #fff;
    padding: 0 3px;
    display: block;
    font-weight: normal;
    font-size: 13px;
    margin-left: 3%
}

.lnr_wz_bt h3 {
    background: #ee5e00
}

.lnr_wz_bt h4 {
    background: #36d882
}

.lnr_wz_bt h5 {
    background: #bdbdbd
}

.lnr_wz_time span,
.lnr_wz_desc span,
.lnr_wz_ms span {
    color: #333
}

.lnr_wz_time,
.lnr_wz_desc,
.lnr_wz_ms {
    line-height: 30px;
    font-size: 14px;
    color: #666
}

.lnr_wz_desc p {
    float: left;
    margin-right: 50px
}

.positon {
    width: 100%;
    color: #989898;
    font-size: 14px;
    margin-bottom: 1.5%
}

.z_top {
    line-height: 50px
}

.z_topl {
    float: left;
    width: 50%
}

.z_topl li {
    float: left;
}

.z_topl li a,
.z_topl li span {
    font-size: 15px;
    color: #fff
}

.z_topl li span {
    margin: 0 10px
}

.z_topm {
    border: #fff 1px solid;
    height: 30px;
    margin: 10px 0
}

.z_topm input {
    float: left;
    width: 80%;
    height: 30px;
    text-indent: 1em;
    outline: none;
    border: none;
    background: none;
    color: #fff;
    font-size: 13px
}

.z_topm ::-webkit-input-placeholder {
    color: #fff;
}

.z_topm :-moz-placeholder {
    color: #fff;
}

.z_topm ::-moz-placeholder {
    color: #fff;
}

.z_topm :-ms-input-placeholder {
    color: #fff;
}

.z_topm a {
    float: right;
    width: 25px;
    height: 21px;
    margin-right: 2%;
    margin-top: 3%
}

.z_topm,
.z_topr {
    float: right;
}

.z_topr li a {
    color: #fff;
    font-size: 15px
}

.z_ad {
    margin-top: 1%;
    background-size: 100% 100%
}

.z_list_zx,
.z_list_fc,
.z_list_zb {
    width: 100%;
    overflow: hidden;
    margin-top: 1%
}

.z_list_fc li a,
.z_list_zb li a {
    display: flex;
    justify-content: center;
}

.z_list_zx li,
.z_list_fc li,
.z_list_zb li {
    border-bottom: #e7e7e7 1px dashed;
    height: 45px;
    line-height: 45px
}

.z_list_zx li i,
.z_list_fc li i,
.jsart_wz li i,
.jyhd li i {
    float: left;
    font-style: normal;
    font-size: 15px;
    color: #007fb4;
    width: 11%;
    display: block;
}

.z_list_fc li i {
    width: 10%;
    margin-left: 2%
}

.z_list_zx li p,
.z_list_fc li p,
.jsart_wz li p,
.jyhd li p,
.z_list_zb li p {
    float: left;
    overflow: hidden;
    font-size: 15px;
    margin-left: 2%;
    color: #666;
    width: 65%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.z_list_fc li p {
    width: 63%;
    margin-left: 0 !important;
}

.z_list_zb li p {
    width: 65%
}

.z_list_zx li p {
    width: 70%
}

.z_list_zx li i {
    width: 20%;
    margin-left: -4%
}

.z_list_zx li span {
    width: 12%
}

.z_list_zb li span {
    width: 30%;
    font-size: 15px;
    color: #666;
    margin-left: 3%
}







.jsart_wz li a {
    display: block;
}

.z_list_fc li span,
.jsart_wz li span {
    float: left;
    font-size: 15px;
    color: #989898;
    width: 43%;
    text-align: center;
    display: block;
}

.z_list_fc li span {
    width: 12% !important
}

.jsart_wz li span {
    width: 17% !important
}

.z_list_fc li h5 {
    float: right;
    width: 9%;
    margin-left: 1%;
    font-weight: normal;
    font-size: 15px;
    color: #989898;
}

.z_gzsjs {
    width: 100%;
    /*height: 580px;*/
    background: #fff;
    border: #eee 1px solid;
    overflow: hidden;
}

.z_gzsjs_ms {
    float: left;
    width: 25%;
    /*height: 100%; */
    background: #fdfdfd;
    border-right: #efefef 1px solid
}

.z_gzsjs_ms img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 15% auto 5% auto;
    border-radius: 50%
}

.z_gzsjs_ms ul {
    width: 85%;
    margin: 10% auto;
    overflow: hidden;
}

.z_gzsjs_ms li {
    width: 100%;
    height: auto;
    line-height: 25px;
    font-size: 14px;
    color: #b5a5a6
}

.z_gzsjs_ms li span {
    color: #333
}

.sqjr {
    width: 80%;
    background: #f65b2b;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 45px;
    display: block;
    border-radius: 5px;
    margin: 5% auto;
    overflow: hidden;
}

.z_gzsjs_js {
    float: right;
    width: 74.9%;
    /*height: 100%;*/
}

.gzsjs_js {
    width: 95%;
    margin: 0 auto;
}

.gzsjs_js h2 {
    color: #f27f02;
    font-size: 24px;
    margin: 3% auto
}

.gzsjs_js p {
    color: #333;
    font-size: 15px;
    line-height: 27px;
    text-indent: 2em
}

.js_plan {
    width: 100%;
    overflow: hidden;
    background: #fff;
    margin: 2% 0;
    border: #eee 1px solid;
}

.js_plan_bt,
.js_plan_nr {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
}

.js_plan_bt {
    border-bottom: #cbcbcb 1px solid;
    line-height: 50px;
    padding-top: 1%
}

.js_plan_bt h2 {
    float: left;
    width: auto;
    font-weight: normal;
    display: block;
    padding: 0 5px;
    font-size: 22px;
    color: #0285bb;
    border-bottom: #0285bb 1px solid
}

.js_plan_nr h2 {
    padding: 3% 0;
    text-align: center;
    font-size: 22px;
    font-weight: normal;
    color: #6b6b6b
}

.js_plan_nr {
    width: 90%;
    /*padding-bottom: 5%;*/
    padding-top: 2%;
    line-height: 30px !important;
}

.js_plan_nr p {
    color: #333;
    font-size: 15px;
    line-height: 30px !important;
    text-indent: 2em
}

.zx_form,
.zx_form li {
    width: 100%;
    overflow: hidden
}

.zx_form li {
    padding: 2% 0;
}

.zx_form li span {
    float: left;
    width: 12%;
    text-align: right;
    color: #666;
    font-size: 14px;
    line-height: 30px;
}

.zx_form li a,
.zx_form_js {
    float: left;
    width: 50%;
    color: #666;
    height: 30px;
    text-indent: 1em;
    line-height: 30px;
    font-size: 14px;
}

.zx_form_js {
    height: auto;
    padding: 1% 0;
    text-indent: 0
}

.list_nav_xl,
.nav_xl {
    width: 100%;
    text-align: center;
    line-height: 30px
}


.list_nav_xl {
    padding: 2% 0;
}

.nav_xl a {
    display: block;
    color: #747474 !important;
    font-size: 16px !important;
}

.nav_xl a:hover {
    display: block;
    color: #004799 !important;
    font-size: 16px !important;
}

.list_nav li:hover .list_nav_xl {
    display: block
}

.list_nav li:last-child {
    border-bottom: none
}




.flexslider {
    margin: 0px auto;
    position: relative;
    width: 100%;
    height: 265px;
    overflow: hidden;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
    height: 100%;
}

.flexslider .slides {
    zoom: 1;
    height: 100%;
}

.flexslider .slides li {
    position: relative;
}

.flexslider .slides li,
.flexslider .slides li .img {
    height: 100%;
}

.flexslider .slides li .img a {
    width: 100%;
    height: 100%;
    display: block;
}

.flexslider .slides li .img img {
    width: 100%;
    height: 100%;
}

.flex-direction-nav {
    display: none;
}

.flexslider .font-size-16 {
    background: #fd7e13;
    width: 100px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    position: absolute;
    right: 14%;
    bottom: 45px;
    z-index: 100;
    text-decoration: none;
}

.flex-control-nav {
    width: 98%;
    position: absolute;
    bottom: 10px;
    text-align: right;
}

.flex-control-nav li {
    margin: 0 2px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.flex-control-paging li a {
    /* background: url(../img/dot.png) no-repeat 0 -9px; */
    display: block;
    height: 9px;
    overflow: hidden;
    text-indent: 99em;
    width: 9px;
    cursor: pointer;
    margin-right: 4px;
}

.flex-control-paging li a.flex-active {
    background-position: 0 0;
}

.sliderZi {
    background: rgb(20, 127, 207);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
}

.sliderZi p {
    color: #ffffff;
    font-size: 12px;
    width: 75%;
    padding-left: 10px;
}

.overFlowHidden {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.towOverFlowHidden {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.index_nav {
    display: none;
}

.z_ad .mobzbg {
    display: none
}

.ts_cen_tp img {
    width: 235px;
    height: 175px;
}

.cont {
    display: none
}

.fanht {
    display: none
}

.ts_cen_tp img {
    width: 100%;
    height: 200px
}



@media only screen and (min-width: 100px) and (max-width: 768px) {

    .logo {
        width: 66%;
        margin-top: 2%
    }

    .logo img {
        max-width: 100%
    }

    .tops {
        width: 100%;
    }

    .topl {
        position: relative;
        left: 4px;
    }

    .topr {
        position: relative;
        right: 8px;
    }

    .navs {
        display: none;
    }

    body {
        overflow-x: scroll;
    }

    .index_nav {
        display: block;
    }

    .navbar-default {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .navbar-toggle {
        border-color: #ddd !important;
    }

    .heads,
    .part_main {
        width: 100%;
    }

    #bs-example-navbar-collapse-1 {
        position: absolute;
        width: 100%;
        background: #FFFFFF;
        z-index: 110;
    }

    .search {

        width: 96%;
        /*position: relative;*/
        margin: 2%;
        /*	margin-top: -15px;*/
    }

    .part_ms {
        float: none;
        width: 100%;
        /*	position: relative;
	left: 1%;
	margin: 10px 0; margin-bottom:5px;*/
    }

    .ms_main {
        width: 96%;
        margin: 2%;
    }

    .index_main {
        float: none;
        width: 100%;
    }

    .ms_main ul li {
        line-height: 34px;
        padding: 0;
    }

    #num1,
    #num2,
    #num3,
    #num4,
    #num5,
    #num6,
    #num7,
    #num8,
    #num9,
    #num10 {
        font-size: 18px;
    }

    .ms_main ul li i {
        height: 34px;
        line-height: 34px;
    }

    .ms_main ul li h2 {
        margin-left: 0;
    }

    .zx_list {
        float: none;
        width: 98%;
        position: relative;
        left: 1%;
        margin: 15px 0;
    }

    .sjzx_bot_nr li {
        width: 31%;
        margin: 1.5% 1%
    }

    .sjzx_bot_nr {
        margin-top: 0
    }

    .gzs {
        margin-top: 2%;
        width: 96%;
        margin-left: 2%
    }

    .part_tit3 h2 {
        width: auto;
    }

    .zx_bta {
        top: 250px;
        display: none
    }

    .banner {
        float: none;
        width: 96%;
        position: relative;
        margin: 2%;
        margin-top: 0;
    }

    .part_one,
    .part_two,
    .part_three {
        margin-top: 0;
    }

    .sliderZi {
        height: 35px;
        line-height: 35px;
    }

    .sliderZi p {
        padding-left: 5px;
        font-size: 14px;
    }

    .zx_bt li {
        margin: 0 1%;
        padding: 0;
        width: 31.3%;
        text-align: center;
        height: 36px;
        line-height: 36px;
        background: #ddd;
    }

    .dropdown-menu>li>a {
        line-height: 2;
        padding: 0;
        text-align: center
    }

    .zx_nrs {
        width: 96%;
        padding: 0 2%;
    }

    .zx_nrs li {
        background-position: 0 50%
    }

    .zx_nrs li i {
        padding-left: 1.5%
    }

    .zx_nrs li span {
        width: 28.5%
    }

    .part_tit2,
    .part_tit3 {
        width: 97%;
        margin: 0 1.5%;
    }

    .part_zx,
    .part_tit,
    .part_tit2,
    .part_tit3 {
        width: 97% !important;
        margin: 0 1.5%;
        margin-top: 4%
    }

    .sjxz_bot {
        margin-top: 0;
    }

    .part_zx {
        margin-top: 0px;
    }

    .ts_mains li {
        width: 31%;
    }

    .ts_main .ts_mains li {
        width: 47%;
    }

    .foot_bq,
    .foot_list p {
        float: none;
    }

    .foot_list p {
        line-height: 24px;
        margin-right: 0;
        font-size: 13px;
    }

    .ts_cen_tp img {
        width: 98% !important;
        position: relative;
        left: 1%;
    }

    .cen_wz h4 {
        width: 42%;
        font-size: 12px;
    }

    .cen_wz h3 {
        width: 58%;
    }

    .foot_list {
        width: 89%;
        position: relative;
        left: 50%;
        margin-left: -44.5%;
    }

    .ad {
        width: 100%;
        margin-top: 5px;
    }

    .flexslider .slides li .img img {
        width: 99.9%;
    }

    .list_zx {
        width: 100%;
    }

    .list_nav {
        float: none;
        width: 96%;
        /*	position: relative;
	left: 0.7%;*/
        margin: 2%;
        border: none;
        padding-bottom: 0
    }

    .list_nav h2 {
        display: none;
    }

    .list_nav ul {
        width: 100%;
        margin: 0px;
    }

    .list_nav li img {
        display: none
    }

    .list_nav li {
        width: 33.3%;
        float: left;
        border-bottom: 0px;
        padding: 0px;
        text-align: center;
        background: #ddd;
        line-height: 36px;
    }

    .list_nav li.active a {
        color: #fff;
        background: #007fb4;
        display: block;
        font-size: 16px;
    }

    .foot {
        padding-top: 3%
    }

    .list_center {
        float: none;
        position: relative;
        width: 98%;
        left: 1%;
        min-height: 350px;
    }

    .zx_lb li p {
        width: 60%;
    }

    .zx_lb li span {
        width: 28%;
    }

    .show_wz span {
        font-size: 15px !important;
    }

    .sjxz_topa ul,
    .sjxz_topb ul,
    .sjxz_topc ul,
    .sjxz_topa2 ul,
    .sjxz_topb2 ul,
    .sjxz_topc2 ul {
        width: 88%;
    }

    .sjxz_topa2 h2,
    .sjxz_topb2 h2,
    .sjxz_topc2 h2,
    .sjxz_topd2 h2 {
        width: auto;
    }

    .sjxz_topa2 ul div,
    .sjxz_topb2 ul div,
    .sjxz_topc2 ul div {
        width: 83% !important;
    }

    .sjxz_topa2 li,
    .sjxz_topb2 li,
    .sjxz_topc2 li {
        margin: 0 4px;
    }

    .sjxz_topd2 input {
        width: auto;
    }

    .sjzx_bot_bt2 h2 {
        width: 15%;
    }

    .sjzx_bot_bt2 ul {
        width: 80%;
    }

    .sjzx_bot_bt2 .abc {
        width: 25%;
        line-height: 40px;
        margin-right: 0;
        /* background: url(../img/123456.jpg) right 10% center no-repeat; */
    }

    .list_gzs li {
        width: 47%;
        margin: 1.15%
    }

    .list_gzs_tit {
        padding: 0;
        line-height: 28px;
    }

    .list_gzs_tit span {
        height: 30px;
        line-height: 30px;
    }

    .gzs_tx {
        float: none;
        margin: 0 auto;
        width: 80px;
    }

    .gzs_lbwz {
        width: 100%;
        margin-left: 0;
        padding-top: 5%;
    }

    .gzs_lbwz p {
        float: left;
        margin: 0 2%;
    }

    .list_page {
        width: 98%;
    }

    .z_ad_right {
        float: none;
        width: 100%;
    }

    .z_ad_wz {
        margin-left: 0;
    }

    .z_ad_left {
        float: none;
        margin-top: 0;
        float: left;
        width: 100%;
    }

    .z_ad_wz h2 {
        float: left;
        line-height: 35px;
    }

    .z_ad {
        width: 100%;
    }

    .z_nav {
        width: 100%;
        height: 80px;
    }

    .z_nav li a {
        font-size: 14px;
    }

    .z_nav li {
        width: 24.65%;
        border: 0.5px solid #ddd;
        border-collapse: collapse;
        line-height: 40px;
        padding: 0;
    }

    .z_index_main {
        width: 100%;
    }

    .z_index_left {
        float: none;
        width: 96%;
        /*position: relative;
	left: 1%;*/
        margin: 2%;
        border: none
    }

    .z_index_right {
        width: 96%;
        padding: 2%;
    }

    .z_one_ban {
        float: none;
        position: relative;
        width: 98%;
        left: 1%;
        height: 280px;
    }

    .z_one_list {
        float: none;
        width: 96%;
        padding: 0 2%;
    }

    .ir_two_lb li {
        width: 48%;
    }

    .jsart_tp {
        display: none;
    }

    .jsart_wz,
    .jyhd_center,
    .z_gzsjs_js {
        width: 100%;
        float: none;
    }

    .jsart_wz li p {
        width: 98% !important;
        margin-left: 2%;
        /* background: url(../img/blue2.jpg) left no-repeat; */
        padding-left: 3%;
        line-height: 30px;
    }

    .jsart_wz li {
        background: none;
        background: #fff;
        border-bottom: 1px solid #ededed;
        padding: 1.5% 0
    }

    .jsart_wz li i {
        width: 15%;
        line-height: 22px;
    }

    .jsart_wz li span {
        width: 26% !important;
        line-height: 22px;
    }

    .z_gzsjs_ms {
        width: 96%;
        padding: 2%;
        background: #f2f2f2;
        margin-bottom: 5%
    }

    .z_list_zx li p {
        width: 100%;
    }

    .z_list_zx li i {
        width: 45%;
    }

    .z_list_fc li p {
        width: 98%;
        padding-left: 2%
    }

    .z_list_zx li span {
        width: 45%;
        text-align: right
    }

    .z_list_fc li span {
        width: 28% !important;
    }

    .z_list_fc li i {
        width: 15%;
        margin-left: 0px;
    }

    .z_list_fc li h5 {
        width: 22%;
        font-size: 13px;
        text-align: right
    }

    .eye {
        width: 13%;
        line-height: 22px;
    }

    .sjzx_bot_bt2 {
        width: 96%;
        margin: 0 2%;
    }

    .list_tit {
        display: none
    }

    .zx_main {
        width: 96%;
        padding: 2%;
        padding-top: 0;
    }

    .list_center .list_tit2 {
        display: none
    }

    .show_zx {
        width: 96%;
        padding: 2%;
        line-height: 26px;
    }

    .show_zx img {
        max-width: 100%;
    }

    .show_wz span {
        font-size: 14px !important;
        line-height: 26px;
    }

    .z_ad_left h2 {
        font-size: 20px;
    }

    .z_ad .zbg {
        display: none
    }

    .z_ad .mobzbg {
        display: block
    }

    .z_ad_right img {
        max-width: 5%;
    }

    .z_ljs_tit,
    .z_ljs_tit3 {
        padding: 2.5% 0
    }

    .ljs_topr {
        margin-left: 0
    }

    .ljs_topl {
        width: 26%
    }

    .z_ljs_top {
        margin: 4% auto
    }

    .z_ljs_sq {
        margin: 5% auto
    }

    .z_index_ljs,
    .z_index_ltj {
        border: #eee 1px solid;
        width: auto
    }

    .z_index_ltj {
        margin-top: 4%
    }

    .positon {
        display: none
    }

    .z_gzsjs_ms img {
        margin: 3% auto
    }

    .z_gzsjs_ms ul {
        width: 100%;
        margin: 2% auto
    }

    .z_gzsjs_ms li {
        color: #333
    }

    .cgzs_nav {
        display: none
    }

    .nav_cg,
    .nav_cg a {
        display: none
    }

    .ts_cen_tp img {
        width: 100%;
        height: 110px
    }

    .sjzx_bot_bt li img,
    .sjzx_bot_bt2 li img {
        display: none;
    }

    .sjzx_bot_bt2 h2 {
        display: none
    }

    /*筛选*/
    .lt {
        float: left
    }

    .rt {
        float: right
    }

    .cont {
        width: 20%;
        float: left;
        display: block
    }

    #area {
        /* background: url(../img/up.png) no-repeat right 3% center; */
        background-size: 15%
    }

    .job-module .retrie {
        position: relative;
        z-index: 102;
    }

    .job-module .retrie dt {
        overflow: hidden
    }

    .job-module .retrie dt a {
        position: relative;
        float: left;
        width: 26%;
        line-height: 40px;
        color: #333;
        text-align: center;
    }

    .job-module .retrie dt #shaixuan {
        width: 100%;
        /* background: url(../img/shaixuan.png) no-repeat right 10% center; */
        background-size: 16%
    }

    .job-module .retrie dt #shaixuan img {
        height: 1em;
        vertical-align: middle;
        margin-right: 0.3em
    }

    .job-module .retrie dt #shaixuan.up {
        color: #333;
        /* background: url(../img/shaixuan.png) no-repeat right 16% center; */
        background-size: 16%
    }

    .job-module .retrie dd {
        position: relative;
    }

    .job-module .slide {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 104;
        display: none;
        width: 100%;
        overflow-y: auto;
        background-color: #fff;
        padding: 4% 0
    }

    .job-module .slide li {
        display: block;
        float: left;
        width: 30%;
        text-align: center;
        line-height: 30px;
        border: 1px solid #ddd;
        border-radius: 15px;
        margin: 1%;
    }

    .job-module .slide li a {
        display: block;
        background-color: transparent;
        color: #787878
    }

    .job-module .slide li a:hover,
    .job-module .slide li a.selected {
        color: #46b4fe;
        border: 1px solid #46b4fe;
        border-radius: 15px;
    }

    .job-module .slide li a.select {
        background-color: #fff;
    }

    .screen {
        background-color: #fff !important;
        color: #5B5B5B;
    }

    .screen .top {
        background: #ededed;
        padding: 0 2%;
        color: #666;
        font-size: 15px;
        width: 96%
    }

    .screen .ft {
        margin-top: 8%;
        overflow: hidden;
        margin-bottom: 5%;
    }

    .screen .ft input {
        float: right;
        color: #FFF;
        border: 0;
        font-size: 1.2em;
        padding: 0.5em 2em;
        border-radius: 20px;
        margin: 0 11%
    }

    .screen .ft .submit {
        margin-left: 1%;
        background: #3c96d5;
    }

    .screen .ft .reset {
        margin-left: 1%;
        background: none;
        color: #333;
        border: 1px solid #ccc
    }

    .shaixt {
        border-bottom: 1px solid #ddd;
        overflow: hidden;
        width: 96%;
        padding: 2%;
    }

    .sx_tit {
        line-height: 2.5em;
        /* background: url(../img/up.png) no-repeat right 3% center; */
        background-size: 6%;
        color: #333;
        font-size: 15px;
    }

    .shaixt li {
        float: left;
        width: 47%;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 15px;
        margin: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        font-size: 13px;
        background: none
    }

    .shaixt li a {
        color: #333
    }

    .shaixt .sxss {
        color: #333;
        line-height: 2.5em;
    }

    .shaixt .ssint input {
        border: 1px solid #ddd;
        height: 30px;
        line-height: 30px;
        width: 70%;
        padding-left: 3%;
        display: inline-block
    }

    .shaixt .ssint a {
        width: 22%;
        text-align: center;
        background: #3c96d5;
        color: #fff;
        display: inline-block;
        line-height: 32px;
    }

    .shaixt .xuanzbk {
        border: 1px solid #3d95d5;
        color: #3c96d5;
    }

    .shaixt ul {
        width: 100%;
    }

    .sjxz form {
        display: none
    }

    .cgzs26 {
        margin: 0 2%
    }

    .cgzs26 li.active {
        background: none
    }

    .cgzs26 li {
        width: 100%
    }

    .cgzs26 li .list_nav_xl {
        padding: 0;
    }

    .cgzs26 li .list_nav_xl a {
        padding: 0;
        background: none;
        display: block;
        text-align: center;
        float: left;
        margin: 1%;
        padding: 0 3%;
        width: auto;
        background: #eee;
    }

    .cgzs26 li .nav_xl {
        line-height: 40px;
        font-size: 15px;
        text-align: center;
    }

    .cgzs26 li.active .cgzsfl {
        display: none
    }

    .cgzs26 .cgzsfl {
        display: none
    }

    .list_nav_xl {
        padding: 0
    }

    .z_list_zx,
    .z_list_fc,
    .z_list_zb {
        width: 96%;
        padding: 0 2%
    }

    .z_list_zx li,
    .z_list_fc li,
    .z_list_zb li {
        line-height: 30px;
        height: auto;
        padding: 1.5% 0
    }

    .z_list_zx li,
    .z_list_fc li,
    .z_list_zb li {
        background-position: 0 25%;
    }

    .fengcai li {
        width: 96%;
        height: 75px;
    }

    .fc_you {
        float: left;
        width: 70%;
        padding-left: 3%
    }

    .z_list_fc li a,
    .z_list_zb li a {
        display: block
    }

    .z_list_zb li p {
        width: 100%
    }

    .ewm_tp {
        width: auto
    }

    /*返回*/
    .fanht {
        display: block;
        width: 100%;
        height: 48px;
        line-height: 48px;
        background: #3d95d5;
        overflow: hidden
    }

    .fanht img {
        width: 6%;
        display: block;
        float: left;
        margin-top: 2.8%;
        padding-left: 1%
    }

    .fanht p {
        text-align: center;
        float: left;
        color: #fff;
        font-size: 19px;
        font-weight: bold;
        width: 86%;
    }

    .list_nav li a {
        font-size: 16px;
    }

    .show_wz,
    .show_wz p,
    .show_wz_wd,
    .show_wz_wd p {
        text-indent: inherit;
        text-align: center
    }

    .sjzx_bot_bt li,
    .sjzx_bot_bt2 li {
        line-height: 40px;
    }

    .nav_xl a {
        font-size: 15px !important
    }

    .list_tit2 {
        margin-bottom: 2%;
    }

    .jsart {
        padding-bottom: 0;
        border-bottom: none;
        margin-top: 0
    }

    .z_ads {
        padding: 2% 0;
    }

    .fengcai li a {
        width: 96%;
    }

    .show_wz img,
    .show_wds {
        max-width: 96%
    }
}
</style>