<template>
    <div>
        <TbHeader></TbHeader>
        <el-row style="background: #f2f2f2;">
            <el-col :span="3"></el-col>
            <el-col :span="18" style="background-color: #fff;">
                <div ref="containerRef" style="height:calc(100vh - 60px);overflow-y:auto">
                    <div style="position: fixed;width: 74%;z-index: 999;" v-if="!isDisabled">
                        <el-row class="" style="padding: 15px 0;background: rgba(255, 255, 255,0.8);">
                            <!-- <el-col :span="10" class="mb0">
                                <el-form-item label="题目检索" class="ml15" label-width="80px">
                                    <el-input v-model="queryParams.title" placeholder="请输入要搜索的题目关键字"
                                        class="input-with-select">
                                        <template #append>
                                            <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="24" class="mr0 mb0">
                                <el-form-item class="pull-right">
                                    <el-button @click="save(0)" class="mr15" type="warning">保存</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="pd15 mb45" style="" :id="oneItem.smId" v-for="oneItem in allList">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">{{ oneItem.smTit }}</div>
                        </div>
                        <template v-for="item in oneItem.smList">
                            <div style="position: relative;">
                                <el-form class="mt40" :label-position="'left'" label-width="130px" style="max-width: 50%">
                                    <el-form-item label="头像:" prop="expertAvatar" v-if="item.key == 'expertAvatar'">
                                        <el-upload class="avatar-uploader" v-if="!isDisabled"
                                                   :action="serviceUrl+'/businessservice/expert/uploadFile'"
                                                   :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                                   :before-upload="beforeAvatarUpload">
                                            <img v-if="item.value" :src="item.value + '?_=' + Date.now()" class="avatar"
                                                 style="position:absolute" />
                                            <el-icon class="avatar-uploader-icon" style="font-size:50px;color:#8c939d;">
                                                <Plus />
                                            </el-icon>
                                        </el-upload>
                                        <div class="avatar-uploader" v-if="isDisabled" style="height: 100px;">
                                            <img v-if="item.value" :src="item.value + '?_=' + Date.now()" class="avatar"
                                                 style="position:absolute" />
                                        </div>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'expertName'">
                                        <el-input :disabled="isDisabled" placeholder="请填写" v-model="item.value"
                                                  maxlength="68" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'gender'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择">
                                            <el-option :key="0" :label="'男'" :value="0" />
                                            <el-option :key="1" :label="'女'" :value="1" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'birthDate'">
                                        <el-date-picker :disabled="isDisabled" v-model="item.value" type="date"
                                                        value-format="x" placeholder="请选择日期" style="width: 100%" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'politicalStatus'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in politicalClimateList" :key="sz.dictKey"
                                                       :label="sz.dictValue" :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'nation'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in nationList" :key="sz.dictKey" :label="sz.dictValue"
                                                       :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="periodSubjectList"
                                                  v-if="item.key == 'periodSubjectList'">
                                        <el-cascader :disabled="isDisabled" style="width:100%;" v-model="item.value"
                                                     :options="archiveXdXkList"
                                                     :props="{ children: 'childrenList', value: 'dictKey', label: 'dictValue' }" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="qualification"
                                                  v-if="item.key == 'qualification'">
                                        <el-select :disabled="isDisabled" filterable v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in qualificationList" :key="sz.dictKey"
                                                       :label="sz.dictValue" :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'isPayroll'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择">
                                            <el-option :key="0" :label="'否'" :value="0" />
                                            <el-option :key="1" :label="'是'" :value="1" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'workUnit'">
                                        <el-input :disabled="isDisabled" placeholder="请填写" v-model="item.value"
                                                  maxlength="68" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'contactInfo'">
                                        <el-input :disabled="isDisabled" placeholder="请填写" v-model="item.value"
                                                  maxlength="68" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'rankInfo'">
                                        <el-select :disabled="isDisabled" filterable v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in technicalTitleList" :key="sz.dictKey"
                                                       :label="sz.dictValue" :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="assessDate" v-if="item.key == 'assessDate'">
                                        <el-date-picker :disabled="isDisabled" value-format="x" style="width:100%;"
                                                        v-model="item.value" type="month" placeholder="请选择" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="orgName" v-if="item.key == 'orgName'">
                                        <el-select :disabled="isDisabled" filterable v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in awardOrgList" :key="sz.dictKey" :label="sz.dictValue"
                                                       :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" v-if="item.key == 'expertIntro'">
                                        <el-input :disabled="isDisabled" v-model="item.value" :rows="3" type="textarea"
                                                  placeholder="最多512个字" maxlength="512" />
                                    </el-form-item>
                                    <el-row v-if="item.key == 'expertTag'">
                                        <el-col :span="20" v-if="!isDisabled">
                                            <el-form-item :label="item.title">
                                                <el-select :disabled="isDisabled" style="width: 100%;" multiple v-model="item.value" placeholder="请选择标签">
                                                    <el-option v-for="item in expertTagList" :key="item.value"
                                                               :label="item.dictValue" :value="item.dictKey">
                                                        <span style="float: left">{{ item.dictValue }}</span>
                                                        <span @click.stop="delFx(item)"
                                                              style="float: right; color: #8492a6; font-size: 13px">
                                                            <el-icon>
                                                                <Delete />
                                                            </el-icon>
                                                        </span>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="20" v-if="isDisabled">
                                            <el-form-item :label="item.title">
                                                <el-select v-if="item.value && item.value.length != 0" :disabled="isDisabled" style="width: 100%;" multiple v-model="item.value" placeholder="请选择标签">
                                                    <el-option v-for="item in expertTagList" :key="item.value"
                                                               :label="item.dictValue" :value="item.dictKey">
                                                        <span style="float: left">{{ item.dictValue }}</span>
                                                        <span @click.stop="delFx(item)"
                                                              style="float: right; color: #8492a6; font-size: 13px">
                                                            <el-icon>
                                                                <Delete />
                                                            </el-icon>
                                                        </span>
                                                    </el-option>
                                                </el-select>
                                                <div v-else>无</div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="4" v-if="!isDisabled">
                                            <el-icon @click="openExpertTag" class="ml15 cursor" :size="30">
                                                <CirclePlus />
                                            </el-icon>
                                        </el-col>
                                    </el-row>


                                </el-form>
                                <!-- <div v-if="!item.tsList && (item.value != '' || item.value == 0) && item.value != null"
                                    style="position: absolute;bottom: 0;left: 55%;">
                                    <el-radio-group v-if="isDisabled && !isRead" @change="chooseBhg($event, item)"
                                        v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-if="(!isDisabled && item.auditStatus) || isRead" :disabled="true"
                                        v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <span @click="reaSm(item)" class="ml40 cursor" v-if="item.auditStatus == 2">
                                        <el-text type="primary">说明</el-text>
                                    </span>
                                </div> -->
                            </div>
                            <template v-if="item.tsList">
                                <div class="tip custom-block" v-if="item.bgShow">
                                    <div class="ml5" style="font-size: 17px;">
                                        {{ item.bgTit }}
                                    </div>
                                </div>
                                <div :class="item.bgTit ? 'ml30' : ''" style="font-size: 14px;">
                                    {{ item.title }}
                                </div>
                                <div class="cdx tip custom-block ml70 " style="width: 50%;" v-for="(ts, ti) in item.value">
                                    <div style="flex:1;position: relative;" class="ml15 mr10 cursor"
                                         @click="editZL(item, ti)">
                                        <p class="custom-block-title">{{ ts.oneTxt }}</p>
                                        <div class="text-one" :title="ts.twoTxt">{{ ts.twoTxt }}
                                        </div>
                                        <div v-if="item.value.length > 1"
                                             style="position: absolute;left: -50px;top: 50%;transform: translateY(-50%);font-size: 30px;color: #9E9E9E;">
                                            {{ ti + 1 }}
                                        </div>
                                    </div>
                                    <div class="cdxtpbx">
                                        <div style="width:100%;height:100%;" @click="editZL(item, ti)">
                                            <img class="cdxRgIcon mr10 cursor" src="../../assets/img/biaoq.png" alt="">
                                        </div>
                                        <div class="cdxDel" v-if="!isDisabled">
                                            <el-icon class="cursor ml5" @click="delZl(item.value, ti)">
                                                <Delete />
                                            </el-icon><span class="ml5 cursor" @click="delZl(item.value, ti)">点击删除资料</span>
                                        </div>
                                        <!-- <div
                                            style="position: absolute;bottom: 0;left: 100px;width:250px;top:50%;transform:translateY(-50%);">
                                            <el-radio-group v-if="isDisabled && !isRead" @change="chooseBhg($event, ts)"
                                                v-model="ts.auditStatus">
                                                <el-radio :value="1" size="large">合格</el-radio>
                                                <el-radio :value="2" size="large">不合格</el-radio>
                                            </el-radio-group>
                                            <el-radio-group v-if="(!isDisabled && ts.auditStatus) || isRead"
                                                :disabled="true" v-model="ts.auditStatus">
                                                <el-radio :value="1" size="large">合格</el-radio>
                                                <el-radio :value="2" size="large">不合格</el-radio>
                                            </el-radio-group>
                                            <span @click="reaSm(ts)" class="ml40 cursor" v-if="ts.auditStatus == 2">
                                                <el-text type="primary">说明</el-text>
                                            </span>
                                        </div> -->
                                    </div>

                                </div>
                                <div class="ml70 mt15" style="width: 50%;" v-if="!isDisabled"
                                     v-show="item.isOne ? item.value.length < 1 : true">
                                    <div class="pl10 mr10 cursor cdxAdd" @click="handleAddFile(item)"
                                         style="color:#afacacee">
                                        + 点击添加档案资料，如有多项符合条件，请逐项添加
                                    </div>
                                </div>
                                <div class="ml70 mt15" style="width: 50%;" v-if="isDisabled && item.value.length == 0">
                                    <div class="pl10 mr10 cdxAdd">
                                        此模块未填报
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <!-- <div class="pd10" style="margin-bottom: 40px;display: flex;justify-content: center;"
                        v-if="isDisabled && !isRead">
                        <el-button @click="submitCheck()" type="primary">完成审核</el-button>
                    </div> -->
                </div>
            </el-col>
            <el-col :span="3">
                <div style="top: 50%;position: absolute;width: 12.5%;transform: translateY(-50%);">
                    <el-anchor style="background: transparent;font-size:20px" :container="containerRef" type="underline"
                               direction="vertical" :offset="30" @click="handleClick">
                        <el-anchor-link :href="`#${oneItem.smId}`" :title="oneItem.smTit" v-for="oneItem in allList">
                        </el-anchor-link>
                    </el-anchor>
                </div>
            </el-col>
        </el-row>
        <el-dialog draggable :title="showModelObj.tit" v-model="showModelObj.isShow" width="700px">
            <el-form :label-position="'left'" ref="showModelObj" :model="showModelObj" :rules="schoolModelRules"
                     label-width="150px">
                <template v-for="item in showModelObj.activeCate.tsList">
                    <el-form-item :label="item.title" prop="achieveName" v-if="item.key == 'achieveName'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.achieveName" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="isTopic" v-if="item.key == 'isTopic'">
                        <el-select :disabled="isDisabled" v-model="showModelObj.txObj.isTopic" placeholder="请选择">
                            <el-option :key="0" :label="'否'" :value="0" />
                            <el-option :key="1" :label="'是'" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="contentType" v-if="item.key == 'contentType'">
                        <el-select :disabled="isDisabled" v-if="item.optionName" filterable
                                   v-model="showModelObj.txObj.contentType" placeholder="请选择">
                            <el-option v-if="item.optionName == 'content_theme_type'" v-for="sz in contentThemeTypeList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="role" v-if="item.key == 'role'">
                        <el-select :disabled="isDisabled" v-model="showModelObj.txObj.role" placeholder="请选择">
                            <el-option :key="0" :label="'主要负责人'" :value="0" />
                            <el-option :key="1" :label="'核心成员'" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="achieveLevel" v-if="item.key == 'achieveLevel'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.achieveLevel"
                                   placeholder="请选择">
                            <el-option v-for="sz in archiveAreaLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="prizeLevel" v-if="item.key == 'prizeLevel'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.prizeLevel"
                                   placeholder="请选择">
                            <el-option v-for="sz in awardLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="prizeTime" v-if="item.key == 'prizeTime'">
                        <el-date-picker :disabled="isDisabled" value-format="x" style="width: 100%;"
                                        v-model="showModelObj.txObj.prizeTime" type="month" placeholder="请选择" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="authOrg" v-if="item.key == 'authOrg'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.authOrg" placeholder="请选择">
                            <el-option v-for="sz in awardOrgList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="signUnit" v-if="item.key == 'signUnit'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.signUnit" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>

                    <el-form-item :label="item.title" prop="achieveCertList" v-if="item.key == 'achieveCertList'">
                        <div v-if="!item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.achieveCertList" class="csbbb cursor">
                                <el-icon v-if="!isDisabled" title="点击删除"
                                         @click="delImg(showModelObj.txObj.achieveCertList, index)" :size="15" class="eicon"
                                         style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击预览" :src="item.response.data" v-if="item.response"
                                     @click="ylImgs(showModelObj.txObj.achieveCertList, index)"
                                     style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="!item.accept && !isDisabled" v-model:file-list="showModelObj.txObj.achieveCertList"
                                   :headers="{ token }" multiple :show-file-list="false"
                                   :action="serviceUrl+'/businessservice/expert/uploadFile'" class="avatar-uploader"
                                   :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg" list-type="picture-card"
                                   :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                        <div v-if="item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.achieveCertList" class="csbbb cursor">
                                <el-icon v-if="!isDisabled" title="点击删除"
                                         @click="delImg(showModelObj.txObj.achieveCertList, index)" :size="15" class="eicon"
                                         style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击下载" src="../../assets/img/pdf.png" v-if="item.response"
                                     @click="downloadFile(item.response.data)"
                                     style="width: 100px;height: 100px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="item.accept && !isDisabled" v-model:file-list="showModelObj.txObj.achieveCertList"
                                   :headers="{ token }" multiple :show-file-list="false"
                                   :action="serviceUrl+'/businessservice/expert/uploadFile'" class="avatar-uploader"
                                   :before-upload="beforeJAUpload" :accept="item.accept" list-type="picture-card"
                                   :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                </template>
            </el-form>
            <template #footer v-if="!isDisabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="showModelSumbit">确 定</el-button>
                    <el-button @click="showModelObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'不合格说明'" v-model="buHgtxtObj.isShow" width="700px">
            <div class="mb5">
                请说明不合格的理由或修改建议
            </div>
            <el-input :disabled="!isDisabled || isRead" v-model="buHgtxtObj.txt" style="width: 100%" :rows="4"
                      type="textarea" placeholder="不合格说明" />
            <template #footer v-if="isDisabled && !isRead">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="showBhgSumbit">确 定</el-button>
                    <el-button @click="buHgtxtObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建标签'" v-model="topicClassifyShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="orgName">
                        <el-input v-model="classifyName" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitTopicClassifyForm">确 定</el-button>
                    <el-button @click="topicClassifyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" :initial-index="activeIndex" @close="this.isOpen = false;" />
    </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { archiveQueryArchive, archiveFillBatch, archiveFillDelete, archiveManagerCheckArchive, } from '@/api/admin/archive';
import { expertAddExpertTag, expertDeleteAchieve, expertUpdate, expertInfo, expertCreate, allList } from '@/api/admin/expert';
import { queryDict, deleteDict } from "@/api/admin/public";
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";
import { serviceUrl } from "@/utils/global"

export default {
    components: {
    },
    name: "fileFill",
    data() {
        return {
            serviceUrl,
            activeIndex: 0,
            isOpen: false,
            isDisabled: false,
            activeimgs: [],
            timestampToTime,
            userInfo: {},
            queryParams: {
                title: ''
            },
            buHgtxtObj: {
                isShow: false,
                txt: '',
                obj: {}
            },
            grXX: {
                gender: '',
                birthDate: '',
                politicalClimate: '',
                nation: '',
            },
            containerRef: null,
            showModelObj: {
                tit: '',
                isShow: false,
                activeCate: {},
                txObj: {},
            },
            schoolModelRules: [],
            delFileList: [],
            token: this.$storage.get("token"),
            politicalClimateList: [],
            nationList: [],
            archiveXdXkList: [],
            qualificationList: [],
            mandarinLevelList: [],
            coreTeacherTypeList: [],
            moralEduTypeList: [],
            classHonoraryTitleList: [],
            youngPioneersTypeList: [],
            archiveAreaLevelList: [],
            teachSupportLevelList: [],
            contentThemeTypeList: [],
            classTeachTypeList: [],
            awardLevelList: [],
            awardOrgList: [],
            archiveTopicStatusList: [],
            assistanceNatureList: [],
            schoolAreaList: [],
            archiveAchieveTypeList: [],
            assumeRoleList: [],
            opusTypeList: [],
            opusRoleList: [],
            expertTagList: [],
            achieveIdList: [],
            allList: allList,
            userId: '',
            isRead: false,
            expertId: '',
            classifyName:'',
            topicClassifyShow:false,
        }
    },
    computed: {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    created() {
        console.log(this.$route.query);
        if (this.$route.query.userId) {
            this.userId = this.$route.query.userId;
            this.isDisabled = true;
        }
        if (this.$route.query.isRead) {
            this.isRead = this.$route.query.isRead
        }
        if (this.$route.query.expertId) {
            this.expertId = this.$route.query.expertId
            this.getArchiveQuery();
        }
        this.getQueryDict();
    },
    mounted() {
        this.containerRef = this.$refs.containerRef;
    },
    methods: {
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.allList[0].smList[0].value = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        showBhgSumbit() {
            this.buHgtxtObj.obj.reason = this.buHgtxtObj.txt
            this.buHgtxtObj.isShow = false;
        },
        reaSm(item) {
            console.log(item);
            this.buHgtxtObj.txt = item.reason
            this.buHgtxtObj.obj = item;
            this.buHgtxtObj.isShow = true;
        },
        chooseBhg(e, item) {
            console.log(e, item);
            if (item.auditStatus == 2) {
                item.reason = ''
                this.buHgtxtObj.txt = '';
                this.buHgtxtObj.obj = item;
                this.buHgtxtObj.isShow = true;
            }
        },
        openExpertTag(){
            this.classifyName = ''
            this.topicClassifyShow = true;
        },
        submitTopicClassifyForm() {
            expertAddExpertTag({ tagName: this.classifyName }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryExpertTagDict();
                    this.$message.success('添加成功')
                    this.topicClassifyShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        showModelSumbit() {
            console.log(this.showModelObj);
            var obj = {
                oneTxt: '',
                twoTxt: '',
                topCategory: this.showModelObj.activeCate.topCategory,
                secondCategory: this.showModelObj.activeCate.secondCategory,
            }
            this.showModelObj.activeCate.tsList.forEach(e => {
                if (e.key == 'timeList') {
                    if (this.showModelObj.txObj[e.key].length != 0) {
                        obj.startTime = this.showModelObj.txObj[e.key][0]
                        obj.endTime = this.showModelObj.txObj[e.key][1]
                    }
                } else if (e.key == 'periodSubjectList') {
                    if (this.showModelObj.txObj[e.key].length != 0) {
                        obj.period = this.showModelObj.txObj[e.key][0]
                        obj.subject = this.showModelObj.txObj[e.key][1]
                    }
                } else if (e.key == 'achieveCertList') {
                    var fileList = [];
                    this.showModelObj.txObj[e.key].forEach(e => {
                        if (e.response.code == 1 || e.response.data) {
                            fileList.push(e.response.data);
                        }
                    })
                    obj[e.key.slice(0, -4)] = fileList.toString();
                } else {
                    obj[e.key] = this.showModelObj.txObj[e.key]
                }
            })
            // this.
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.secondCategory == this.showModelObj.activeCate.secondCategory && e.topCategory == this.showModelObj.activeCate.topCategory) {
                        obj.oneTxt = obj.achieveName
                        obj.twoTxt = obj.signUnit

                        if (!this.showModelObj.txObj.achieveId) {
                            obj.achieveId = '';
                            e.value.push(obj)
                        } else {
                            this.allList.forEach(cItem => {
                                cItem.smList.forEach(j => {
                                    if (j.tsList) {
                                        e.value.forEach(m => {
                                            if (m.achieveId == this.showModelObj.txObj.achieveId) {
                                                Object.assign(m, obj)
                                            }
                                        })
                                    }
                                })
                            })
                        }

                    }
                })
            })
            this.showModelObj.isShow = false
        },
        scSjm() {
            let date = (new Date()).valueOf(); //获取时间戳
            // console.log(new Date().getTime().toString().substring(4),date);
            let txt = '1234567890'; //生成的随机机器码
            let len = 13; //机器码有多少位
            let pwd = ''; //定义空变量用来接收机器码
            for (let i = 0; i < len; i++) {
                pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
            }
            return date + pwd
        },
        ylImgs(data, index) {
            this.activeIndex = index;
            this.activeimgs = []
            console.log(data);
            data.forEach(e => {
                this.activeimgs.push(e.response.data)
            })
            this.isOpen = true;
        },
        save(type) {
            var objList = []
            var archiveId = this.scSjm();
            var achieveIdList = [];
            var tjobj = {}
            var age, teachAge, workAge = ''
            var backbone = []
            var qualification = []
            var rankLevel = []
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'birthDate') {
                        age = e.value;
                    }
                    if (e.key == 'teachAge') {
                        teachAge = e.value;
                    }
                    if (e.key == 'workAge') {
                        workAge = e.value;
                    }
                    if (e.tsList) {
                        e.value.forEach((j, k) => {
                            var obj = {}
                            obj.achieveId = j.achieveId ? j.achieveId : '';
                            // obj.topCategory = e.topCategory
                            // obj.secondCategory = e.secondCategory
                            Object.assign(obj, j)
                            objList.push(obj)
                            achieveIdList.push(obj.achieveId)
                            if (e.topCategory == 2 && e.secondCategory == 1) {
                                backbone.push(obj)
                            }
                            if (e.topCategory == 1 && e.secondCategory == 5) {
                                qualification.push(obj)
                            }
                            if (e.topCategory == 1 && e.secondCategory == 10) {
                                rankLevel.push(obj)
                            }
                        })
                    } else {
                        if (e.key == 'periodSubjectList') {
                            if (e.value && e.value.length != 0) {
                                tjobj['period'] = e.value[0]
                                tjobj['subject'] = e.value[1]
                            }
                        }else if(e.key == 'expertTag'){
                            tjobj[e.key] = e.value.join(',')
                        }else {
                            tjobj[e.key] = e.value
                        }

                    }
                })
            })
            console.log(qualification);
            var maxQuali = 0
            qualification.forEach(e => {
                if (e.qualification > maxQuali) {
                    maxQuali = e.qualification
                }
            })
            var maxRank = 0
            rankLevel.forEach(e => {
                if (e.name > maxRank) {
                    maxRank = e.name
                }
            })
            console.log(tjobj);
            tjobj.achieveList = objList
            if (this.expertId) {
                tjobj.expertId = this.expertId
                console.log(this.achieveIdList, achieveIdList);
                expertUpdate(tjobj).then(res => {
                    if (res.data.code == 1) {
                        this.achieveIdList.forEach(e => {
                            if (achieveIdList.indexOf(e) === -1) {
                                expertDeleteAchieve({ achieveId: e })
                            }
                        })
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            } else {
                expertCreate(tjobj).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }

            // archiveFillBatch(tjobj).then(res => {
            //     if (res.data.code == 1) {
            //         this.$message.success('操作成功')
            //         this.achieveIdList.forEach(e => {
            //             if (achieveIdList.indexOf(e) === -1) {
            //                 archiveFillDelete({ archiveId: e })
            //             }
            //         })
            //     } else {
            //         this.$message.error('操作失败')
            //     }
            // })
        },
        calculateAge(birthTimestamp) {
            const today = new Date();
            const birthDate = new Date(birthTimestamp);
            let age = today.getFullYear() - birthDate.getFullYear();
            // 如果今年的生日还没有过，则年龄减一
            if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        submitCheck() {
            var objList = []
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.tsList) {
                        e.value.forEach((j, k) => {
                            var obj = {}
                            obj.archiveId = j.archiveId;
                            obj.auditStatus = j.auditStatus
                            obj.reason = j.reason
                            objList.push(obj)
                        })
                    } else {
                        var obj = {}
                        obj.archiveId = e.archiveId;
                        obj.auditStatus = e.auditStatus
                        obj.reason = e.reason
                        objList.push(obj)
                    }
                })
            })
            archiveManagerCheckArchive({ achieveList: objList, userId: this.userId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('审核成功')
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        getArchiveQuery() {
            this.achieveIdList = []
            expertInfo({ expertId: this.expertId ? this.expertId : '' }).then(res => {
                this.allList.forEach(oneItem => {
                    oneItem.smList.forEach(j => {

                        if (j.tsList) {
                            j.value = []
                            console.log(j, j.value);
                            res.data.data.achieveList.forEach(e => {
                                e.oneTxt = e.achieveName
                                e.twoTxt = e.signUnit

                                j.value.push(e)
                                this.achieveIdList.push(e.achieveId)
                            })
                        } else {
                            if (j.key == 'periodSubjectList') {
                                if (res.data.data.period) {
                                    j.value = [res.data.data.period, res.data.data.subject]
                                }
                            }else if(j.key == 'expertTag'){
                                if (res.data.data.expertTag) {
                                    j.value = res.data.data.expertTag.split(',')
                                }
                            }else {
                                j.value = res.data.data[j.key]
                            }
                        }
                    })
                })
            })
            console.log(this.achieveIdList);
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles, this.recommendImgList)
            if (file.response) {
                this.delFileList.push(file.response.data)
            }
        },
        delFx(item) {
            var _this = this;
            ElMessageBox.confirm(
              '是否删除该标签?',
              '删除',
              {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
              }
            ).then(() => {
                deleteDict({ dicId: item.dicId }).then(res => {
                    if (res.data.code == 1) {
                        if(_this.allList[0].smList[15].value.length != 0){
                            _this.allList[0].smList[15].value.forEach((e,i)=>{
                                if(e == item.dictKey){
                                    _this.allList[0].smList[15].value.splice(i, 1)
                                }
                            })
                        }
                        _this.$message.success('删除成功')
                        queryDict({ code: 'expert_tag' }).then(res => {
                            _this.expertTagList = res.data.data;
                        })
                    } else {
                        _this.$message.error('操作失败')
                    }
                })
            }).catch(() => {

            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf')) {
                this.$message.error('文件必须是.pdf格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleAddFile(item) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            item.tsList.forEach(e => {
                if (e.key.substr(-4) == 'List') {
                    this.showModelObj.txObj[e.key] = []
                } else {
                    this.showModelObj.txObj[e.key] = ''
                }

            })
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        handleClick(e) {
            console.log(e, this.containerRef, this.$refs.containerRef);
            console.log(this.$refs.containerRef);
            e.preventDefault()
        },
        delImg(imgList, index) {
            imgList.splice(index, 1)
        },
        delZl(zlList, index) {
            zlList.splice(index, 1)
        },
        editZL(item, index) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            var editObj = JSON.parse(JSON.stringify(item.value[index]))
            console.log(item, index, editObj)
            item.tsList.forEach(e => {
                if (e.key == 'timeList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.startTime) {
                        this.showModelObj.txObj[e.key] = [editObj.startTime, editObj.endTime]
                    }
                } else if (e.key == 'periodSubjectList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.period) {
                        this.showModelObj.txObj[e.key] = [editObj.period, editObj.subject]
                    }
                } else if (e.key == 'achieveCertList') {
                    this.showModelObj.txObj[e.key] = []
                    console.log(e, e.key.slice(0, -4), editObj[e.key.slice(0, -4)]);
                    if (editObj[e.key.slice(0, -4)]) {
                        var fileList = editObj[e.key.slice(0, -4)].split(',')
                        console.log(fileList);
                        fileList.forEach(j => {
                            this.showModelObj.txObj[e.key].push({
                                url: j,
                                response: {
                                    data: j
                                }
                            })
                        })
                    }
                } else {
                    this.showModelObj.txObj[e.key] = editObj[e.key]
                }
            })
            if (editObj.achieveId) {
                this.showModelObj.txObj.achieveId = editObj.achieveId
            }
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        downloadFile(fileUrl) {
            download(fileUrl)
        },
        getQueryDict() {
            queryDict({ code: 'political_climate' }).then(res => {
                this.politicalClimateList = res.data.data;
            })
            queryDict({ code: 'nation' }).then(res => {
                this.nationList = res.data.data;
            })
            queryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            queryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
            queryDict({ code: 'mandarin_level' }).then(res => {
                this.mandarinLevelList = res.data.data;
            })
            queryDict({ code: 'core_teacher_type' }).then(res => {
                this.coreTeacherTypeList = res.data.data;
            })
            queryDict({ code: 'moral_edu_type' }).then(res => {
                this.moralEduTypeList = res.data.data;
            })
            queryDict({ code: 'class_honorary_title' }).then(res => {
                this.classHonoraryTitleList = res.data.data;
            })
            queryDict({ code: 'young_pioneers_type' }).then(res => {
                this.youngPioneersTypeList = res.data.data;
            })
            queryDict({ code: 'archive_area_level' }).then(res => {
                this.archiveAreaLevelList = res.data.data;
            })
            queryDict({ code: 'teach_support_level' }).then(res => {
                this.teachSupportLevelList = res.data.data;
            })
            queryDict({ code: 'award_org' }).then(res => {
                this.awardOrgList = res.data.data;
            })
            queryDict({ code: 'school_area' }).then(res => {
                this.schoolAreaList = res.data.data;
            })
            queryDict({ code: 'content_theme_type' }).then(res => {
                this.contentThemeTypeList = res.data.data;
            })
            queryDict({ code: 'class_teach_type' }).then(res => {
                this.classTeachTypeList = res.data.data;
            })
            queryDict({ code: 'award_level' }).then(res => {
                this.awardLevelList = res.data.data;
            })
            queryDict({ code: 'qualification' }).then(res => {
                this.qualificationList = res.data.data;
            })
            queryDict({ code: 'assistance_nature' }).then(res => {
                this.assistanceNatureList = res.data.data;
            })
            queryDict({ code: 'archive_topic_status' }).then(res => {
                this.archiveTopicStatusList = res.data.data;
            })
            queryDict({ code: 'archive_achieve_type' }).then(res => {
                this.archiveAchieveTypeList = res.data.data;
            })
            queryDict({ code: 'assume_role' }).then(res => {
                this.assumeRoleList = res.data.data;
            })
            queryDict({ code: 'opus_type' }).then(res => {
                this.opusTypeList = res.data.data;
            })
            queryDict({ code: 'opus_role' }).then(res => {
                this.opusRoleList = res.data.data;
            })
            queryDict({ code: 'expert_tag' }).then(res => {
                this.expertTagList = res.data.data;
            })
        },
        getQueryExpertTagDict(){
            queryDict({ code: 'expert_tag' }).then(res => {
                this.expertTagList = res.data.data;
            })
        }
    }
}
</script>
<!--内联样式-->
<style lang="scss" scoped>
/deep/ .el-anchor__link {
    font-size: 16px !important;
}

/deep/ .el-form-item__label {
    color: #333 !important;
    // font-weight: bold;
    line-height: 32px;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader {
    padding: 0 5px;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
}
</style>
  