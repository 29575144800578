import request from "@/utils/request";
//删除作业附件
export const homeworkDelAnnex = (query) =>{
    return request({
        url: "/businessservice/homework/annexDelete",
        method:"get",
        params: query
    })
}

//创建作业
export const homeworkCreate = (data) =>{
    return request({
        url: "/businessservice/homework/create",
        method:"post",
        data: data
    })
}

//更新作业
export const homeworkUpdate = (data) =>{
    return request({
        url: "/businessservice/homework/update",
        method:"post",
        data: data
    })
}

//删除作业
export const homeworkDel = (query) =>{
    return request({
        url: "/businessservice/homework/delete",
        method:"get",
        params: query
    })
}

//删除作业
export const homeworkList = (query) =>{
    return request({
        url: "/businessservice/homework/list",
        method:"get",
        params: query
    })
}

//作业完成详情
export const homeworkFinishInfo = (data) =>{
    return request({
        url: "/businessservice/finishHomework/info",
        method:"post",
        data: data
    })
}
//完成作业
export const homeworkFinish = (data) =>{
    return request({
        url: "/businessservice/finishHomework/finish",
        method:"post",
        data: data
    })
}

//批阅作业
export const readOverHomework = (data) =>{
    return request({
        url: "/businessservice/finishHomework/readOver",
        method:"post",
        data: data
    })
}

//完成作业
export const homeworkFinishList = (query) =>{
    return request({
        url: "/businessservice/finishHomework/list",
        method:"post",
        params: query
    })
}

//师资培训作业统计
export const listTrainHomeworkFinish = (query) =>{
    return request({
        url: "/businessservice/homework/listTrainFinish",
        method:"get",
        params: query
    })
}

//师资培训单个作业完成情况
export const listHomeworkFinish = (query) =>{
    return request({
        url: "/businessservice/homework/listHomeworkFinish",
        method:"get",
        params: query
    })
}

