<template>
    <div class="" style="width: 100%;">
        <!--连接-->
        <div style="width: 100%;" class="">
            <el-link @click="openSelectFile" type="primary" :underline="false">+{{ showTitle }}</el-link>
        </div>
        <!--已选文件-->
        <div>
            <el-tag type="info" style="margin-right: 10px;margin-bottom: 5px;"
                    class="mx-1"
                    @close="tagClose(tag)"
                    v-if="checkFileList.length !== 0"
                    v-for="tag in checkFileList"
                    :key="tag.fileId"
                    closable>
                {{tag.fileName}}
            </el-tag>
        </div>
        <!--文件选择-->
        <el-dialog :title="showTitle" v-model="isShow" width="50%">
            <el-row>
                <el-col :span="8">
                    <div v-for="item in lessonList"
                         @click="selLesson(item)"
                         v-show="(preLessonId===''||preLessonId===null)||
                         (preLessonId!==''&&preLessonId!==null&&preLessonId===item.lessonId)"
                         :class="lessonId===item.lessonId?'lesson-title item-active':'lesson-title'">
                        {{item.title}}
                    </div>
                </el-col>
                <el-col :span="16">
                    <div style="margin-left: 10px;overflow-y: auto;">
                        <div @click="checkFileChange(item)"
                             v-for="item in fileList" class="cursor"
                             style="margin-bottom: 10px;">
                            <!-- disabled -->
                            <div class="disflex-al-ct">
                                <img v-if="!item.checked"
                                     style="width: 15px;height:15px;margin: 0 5px 0 10px;"
                                     src="../assets/img/uncheck.png" alt="">
                                <img v-if="item.checked"
                                     style="width: 15px;height:15px;margin: 0 5px 0 10px;"
                                     src="../assets/img/checked.png" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import {lessonListMainJoin} from "@/api/admin/lesson"
import {judgeStrIncludes} from "@/utils/str";
export default {
    name: "SelectLessonFile",
    props:{
        showTitle: {
            type: String,
            default: ''
        },
        prepareLessonId:{
            type: String,
            default: ''
        },
    },
    created() {
        this.getUserLesson();
        console.log("传值："+this.prepareLessonId);
    },
    watch:{
        prepareLessonId(newValue,oldValue){
            this.preLessonId = newValue;
            console.log("监听变化传值："+this.preLessonId);
        }
    },
    data(){
        return{
            judgeStrIncludes,
            isShow:false,
            lessonId:"", //  子组件选择备课ID
            preLessonId:"", //父组件传递备课ID
            lessonList:[],
            checkFileList:[],
            fileList:[],
            checkFileIds:"",
            lessonUserList:[],
        }
    },
    methods:{
        openSelectFile(){
            this.isShow = true;
        },
        getUserLesson(){
            lessonListMainJoin().then(res => {
                if (res.data.code === 1) {
                    this.lessonList = res.data.data;
                    console.log(this.lessonList);
                }
            })
        },
        selLesson(item){
            //切换备课，重置已选
            if (this.lessonId!==item.lessonId){
                this.checkFileList = []
            }
            this.lessonId = item.lessonId;
            this.fileList = item.fileList;
            this.fileList.forEach(e =>{
                e.checked = this.checkFileList.includes(e);
            })
            this.lessonUserList = item.userList;
            if (this.prepareLessonId===''||this.prepareLessonId===null){
                this.$emit('lessonTeamUser',this.lessonUserList)
            }

        },
        checkFileChange(item){
            if (item.checked === false) {
                item.checked = true;
                //未选中添加
                this.checkFileList.push(item);
            }else {
                item.checked = false;
                //已选中移除
                this.checkFileList.forEach((e, i) => {
                    if (item.lessonId === e.lessonId) {
                        this.checkFileList.splice(i, 1)
                    }
                })
            }
        },
        tagClose(tag){
            this.checkFileList.forEach((e, i) => {
                if (tag.lessonId === e.lessonId) {
                    this.checkFileList.splice(i, 1)
                }
            })
        },
    }
}
</script>

<style>
.lesson-title{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
    height: 40px;
    line-height: 30px;
    border-right: 2px solid #f4f4f4;
}
.item-active{
    color: #1f89de;
}
.lesson-title:hover{
     color: #1f89de;
}
</style>