<template>
    <div>
        <TbHeader></TbHeader>
        <el-row style="background: #f2f2f2;">
            <el-col :span="3"></el-col>
            <el-col :span="18" style="background-color: #fff;">
                <div ref="containerRef" style="height:calc(100vh - 60px);overflow-y:auto">
                    <div style="position: fixed;width: 74%;z-index: 999;" v-if="!isDisabled">
                        <el-row class="" style="padding: 15px 0;background: rgba(255, 255, 255,0.8);">
                            <el-col :span="24" class="mr0 mb0">
                                <el-form-item class="pull-right">
                                    <el-button @click="save(0)" class="mr15" type="warning">保存</el-button>
                                    <el-button @click="save(1)" class="mr15" type="primary">提交</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="pd15 mb45" style="" :id="oneItem.smId" v-for="oneItem in allList">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">{{ oneItem.smTit }}</div>
                        </div>
                        <template v-for="item in oneItem.smList">
                            <div style="position: relative;">
                                <template v-if="item.noShowLabel">
                                    <div class="tip custom-block" v-if="item.bgShow">
                                        <div class="ml5" style="font-size: 17px;">
                                            {{ item.bgTit }}
                                        </div>
                                    </div>
                                    <div :class="item.bgTit ? 'ml30' : ''" v-if="item.bgShow" style="font-size: 14px;">
                                        {{ item.title }}
                                    </div>
                                    <el-select :disabled="isDisabled" class="ml30 mt15" style="width:50%;"
                                               v-model="item.value" placeholder="请选择"
                                               v-if="item.key == 'isSelect' && item.noShowLabel">
                                        <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                                    </el-select>
                                </template>
                                <el-form v-if="!item.noShowLabel" class="mt40" :label-position="'left'" label-width="130px"
                                         style="max-width: 50%"  :rules="schoolModelRules">
                                    <el-form-item :label="item.title" prop="gender" v-if="item.key == 'gender'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择性别">
                                            <el-option :key="0" :label="'男'" :value="0" />
                                            <el-option :key="1" :label="'女'" :value="1" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="birthDate" v-if="item.key == 'birthDate'">
                                        <el-date-picker :disabled="isDisabled" v-model="item.value" type="date"
                                                        value-format="x" placeholder="请选择出生日期" style="width: 100%" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="politicalClimate" v-if="item.key == 'politicalClimate'">
                                        <el-select :disabled="isDisabled"  v-model="item.value" placeholder="请选择政治面貌">
                                            <el-option v-for="sz in politicalClimateList" :key="sz.dictKey"
                                                       :label="sz.dictValue" :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="nation" v-if="item.key == 'nation'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择民族">
                                            <el-option v-for="sz in nationList" :key="sz.dictKey" :label="sz.dictValue"
                                                       :value="sz.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="isSelect" v-if="item.key == 'isSelect'">
                                        <el-select :disabled="isDisabled" v-model="item.value" placeholder="请选择">
                                            <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="teachAge" v-if="item.key == 'teachAge'">
                                        <el-input-number :disabled="isDisabled" v-model="item.value" :min="1" :max="100"
                                                         :step="1" />
                                    </el-form-item>
                                    <el-form-item :label="item.title" prop="workAge" v-if="item.key == 'workAge'">
                                        <el-input-number :disabled="isDisabled" v-model="item.value" :min="1" :max="100"
                                                         :step="1" />
                                    </el-form-item>
                                </el-form>
                                <div v-if="item.value === 0" style="position: absolute;bottom: 0;left: 55%;">
                                    <el-radio-group v-if="isDisabled && !isRead" @change="chooseBhg($event, item)"
                                                    v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-if="(!isDisabled && item.auditStatus) || isRead" :disabled="true"
                                                    v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <span @click="reaSm(item)" class="ml40 cursor" v-if="item.auditStatus == 2">
                                        <el-text type="primary">说明</el-text>
                                    </span>
                                </div>
                                <div v-if="((!item.tsList) && (item.value != '' && item.value !== 0) && (item.value != null))"
                                     style="position: absolute;bottom: 0;left: 55%;">
                                    <el-radio-group v-if="isDisabled && !isRead" @change="chooseBhg($event, item)"
                                                    v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <el-radio-group v-if="(!isDisabled && item.auditStatus) || isRead" :disabled="true"
                                                    v-model="item.auditStatus">
                                        <el-radio :value="1" size="large">合格</el-radio>
                                        <el-radio :value="2" size="large">不合格</el-radio>
                                    </el-radio-group>
                                    <span @click="reaSm(item)" class="ml40 cursor" v-if="item.auditStatus == 2">
                                        <el-text type="primary">说明</el-text>
                                    </span>
                                </div>
                            </div>
                            <template v-if="item.tsList">
                                <div class="tip custom-block" v-if="item.bgShow">
                                    <div class="ml5" style="font-size: 17px;">
                                        {{ item.bgTit }}
                                    </div>
                                </div>
                                <div :class="item.bgTit ? 'ml30' : ''" style="font-size: 14px;">
                                    {{ item.title }}
                                </div>
                                <div class="cdx tip custom-block ml70 " style="width: 50%;" v-for="(ts, ti) in item.value">
                                    <div style="flex:1;position: relative;" class="ml15 mr10 cursor"
                                         @click="editZL(item, ti)">
                                        <p class="custom-block-title">{{ ts.oneTxt }}</p>
                                        <div class="text-one" :title="ts.twoTxt">{{ ts.twoTxt }}
                                        </div>
                                        <div v-if="item.value.length > 1"
                                             style="position: absolute;left: -50px;top: 50%;transform: translateY(-50%);font-size: 30px;color: #9E9E9E;">
                                            {{ ti + 1 }}
                                        </div>
                                    </div>
                                    <div class="cdxtpbx">
                                        <div style="width:100%;height:100%;" @click="editZL(item, ti)">
                                            <img class="cdxRgIcon mr10 cursor" src="../../assets/img/biaoq.png" alt="">
                                        </div>
                                        <div class="cdxDel" v-if="!isDisabled">
                                            <el-icon class="cursor ml5" @click="delZl(item.value, ti)">
                                                <Delete />
                                            </el-icon><span class="ml5 cursor" @click="delZl(item.value, ti)">点击删除资料</span>
                                        </div>
                                        <div
                                          style="position: absolute;bottom: 0;left: 100px;width:250px;top:50%;transform:translateY(-50%);">
                                            <el-radio-group v-if="isDisabled && !isRead" @change="chooseBhg($event, ts)"
                                                            v-model="ts.auditStatus">
                                                <el-radio :value="1" size="large">合格</el-radio>
                                                <el-radio :value="2" size="large">不合格</el-radio>
                                            </el-radio-group>
                                            <el-radio-group v-if="(!isDisabled && ts.auditStatus) || isRead"
                                                            :disabled="true" v-model="ts.auditStatus">
                                                <el-radio :value="1" size="large">合格</el-radio>
                                                <el-radio :value="2" size="large">不合格</el-radio>
                                            </el-radio-group>
                                            <span @click="reaSm(ts)" class="ml40 cursor" v-if="ts.auditStatus == 2">
                                                <el-text type="primary">说明</el-text>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="ml70 mt15" style="width: 50%;" v-if="!isDisabled"
                                     v-show="item.isOne ? item.value.length < 1 : true">
                                    <div class="pl10 mr10 cursor cdxAdd" @click="handleAddFile(item)"
                                         style="color:#afacacee">
                                        + 点击添加档案资料，如有多项符合条件，请逐项添加
                                    </div>
                                </div>
                                <div class="ml70 mt15" style="width: 50%;" v-if="isDisabled && item.value.length == 0">
                                    <div class="pl10 mr10 cdxAdd">
                                        此模块未填报
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="pd10" style="margin-bottom: 40px;display: flex;justify-content: center;"
                         v-if="isDisabled && !isRead">
                        <el-button @click="submitCheck()" type="primary">完成审核</el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="3">
                <div style="top: 50%;position: absolute;width: 12.5%;transform: translateY(-50%);">
                    <el-anchor style="background: transparent;font-size:20px" :container="containerRef" type="underline"
                               direction="vertical" :offset="30" @click="handleClick">
                        <el-anchor-link :href="`#${oneItem.smId}`" :title="oneItem.smTit" v-for="oneItem in allList">
                        </el-anchor-link>
                    </el-anchor>
                </div>
            </el-col>
        </el-row>

        <!--弹出填写-->
        <el-dialog draggable :title="showModelObj.tit" v-model="showModelObj.isShow" width="620px">
            <el-form :label-position="'right'" ref="showModelObj" :model="showModelObj"
                     :rules="schoolModelRules" label-width="150px">
                <template v-for="item in showModelObj.activeCate.tsList">
                    <el-form-item :label="item.title" prop="txObj.qualification" v-if="item.key == 'qualification'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.qualification"
                                   placeholder="请选择">
                            <el-option v-for="sz in qualificationList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.prizeLevel" v-if="item.key == 'prizeLevel'">
                        <el-select :disabled="isDisabled" v-if="item.optionName == 'mandarin_level'" filterable
                                   v-model="showModelObj.txObj.prizeLevel" placeholder="请选择">
                            <el-option v-for="sz in mandarinLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                        <el-select :disabled="isDisabled" v-if="item.optionName == 'award_level'" filterable
                                   v-model="showModelObj.txObj.prizeLevel" placeholder="请选择">
                            <el-option v-for="sz in awardLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.timeList" v-if="item.key == 'timeList'">
                        <el-date-picker :disabled="isDisabled" value-format="x"
                                        v-model="showModelObj.txObj.timeList"
                                        type="monthrange"
                                        unlink-panels
                                        range-separator="到"
                                        start-placeholder="开始时间" end-placeholder="结束时间" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.periodSubjectList" v-if="item.key == 'periodSubjectList'">
                        <el-cascader :disabled="isDisabled" style="width:100%;"
                                     v-model="showModelObj.txObj.periodSubjectList" :options="archiveXdXkList"
                                     :props="{ children: 'childrenList', value: 'dictKey', label: 'dictValue' }" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.startTime" v-if="item.key == 'startTime'">
                        <el-date-picker :disabled="isDisabled" value-format="x" style="width: 100%;"
                                        v-model="showModelObj.txObj.startTime" type="month" placeholder="请选择" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.orgName" v-if="item.key == 'orgName'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.orgName" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.name" v-if="item.key == 'name'">
                        <el-input :disabled="isDisabled" v-if="!item.optionName" v-model="showModelObj.txObj.name"
                                  placeholder="最多32个字" maxlength="32" />
                        <el-select :disabled="isDisabled" v-if="item.optionName == 'technical_title'" filterable
                                   v-model="showModelObj.txObj.name" placeholder="请选择">
                            <el-option v-for="sz in technicalTitleList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                        <el-select :disabled="isDisabled" v-if="item.optionName == 'bzrType'" filterable
                                   v-model="showModelObj.txObj.name" placeholder="请选择">
                            <el-option label="班主任" value="班主任" />
                            <el-option label="副班主任" value="副班主任" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.major" v-if="item.key == 'major'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.major" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.publisher" v-if="item.key == 'publisher'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.publisher" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.publishNo" v-if="item.key == 'publishNo'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.publishNo" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.relationName" v-if="item.key == 'relationName'">
                        <el-input :disabled="isDisabled" v-if="!item.optionName" v-model="showModelObj.txObj.relationName"
                                  :placeholder="item.placeholder ? item.placeholder : '最多32个字'" maxlength="32" />
                        <el-select :disabled="isDisabled" v-if="item.optionName && item.optionName == 'assume_role'"
                                   filterable v-model="showModelObj.txObj.relationName" placeholder="请选择">
                            <el-option v-for="sz in assumeRoleList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                        <el-select :disabled="isDisabled" v-if="item.optionName && item.optionName == 'opus_role'"
                                   filterable v-model="showModelObj.txObj.relationName" placeholder="请选择">
                            <el-option v-for="sz in opusRoleList" :key="sz.dictKey" :label="sz.dictValue"
                                       :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.address" v-if="item.key == 'address'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.address" placeholder="最多32个字"
                                  maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.content" v-if="item.key == 'content'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.content" :rows="3" type="textarea"
                                  placeholder="最多512个字" maxlength="512" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.remarks" v-if="item.key == 'remarks'">
                        <el-input :disabled="isDisabled" v-model="showModelObj.txObj.remarks" :rows="3" type="textarea"
                                  placeholder="最多512个字" maxlength="512" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.isSelect" v-if="item.key == 'isSelect'">
                        <el-select :disabled="isDisabled" v-if="item.optionList" v-model="showModelObj.txObj.isSelect"
                                   placeholder="请选择">
                            <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                        </el-select>
                        <el-select :disabled="isDisabled" v-else filterable v-model="showModelObj.txObj.isSelect"
                                   placeholder="请选择">
                            <el-option :key="0" :label="'是'" :value="'是'" />
                            <el-option :key="1" :label="'否'" :value="'否'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.schoolYear" v-if="item.key == 'schoolYear'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.schoolYear"
                                   placeholder="请选择">
                            <el-option v-for="item in (new Date().getFullYear() - 2009)" :key="item"
                                       :label="2009 + item + '学年'" :value="2009 + item + '学年'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.schoolTerm" v-if="item.key == 'schoolTerm'">
                        <el-select :disabled="isDisabled" v-if="!item.isNum" v-model="showModelObj.txObj.schoolTerm"
                                   placeholder="请选择">
                            <el-option :key="0" :label="'上学期'" :value="'上学期'" />
                            <el-option :key="1" :label="'下学期'" :value="'下学期'" />
                        </el-select>
                        <el-select :disabled="isDisabled" v-if="item.isNum" v-model="showModelObj.txObj.schoolTerm"
                                   placeholder="请选择">
                            <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.grade" v-if="item.key == 'grade'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.grade" placeholder="请选择">
                            <el-option v-for="item in (new Date().getFullYear() - 1964)" :key="item"
                                       :label="1969 + item + '级'" :value="1969 + item + '级'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.classInfo" v-if="item.key == 'classInfo'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.classInfo"
                                   placeholder="请选择">
                            <el-option v-for="item in 1000" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.num" v-if="item.key == 'num'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.num" placeholder="请选择">
                            <el-option v-for="item in 1000" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.typeName" v-if="item.key == 'typeName'">
                        <el-select :disabled="isDisabled" v-if="item.optionList" filterable
                                   v-model="showModelObj.txObj.typeName" placeholder="请选择">
                            <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                        </el-select>
                        <template v-else>
                            <el-select :disabled="isDisabled" v-if="item.optionName" filterable
                                       v-model="showModelObj.txObj.typeName" placeholder="请选择">
                                <el-option v-if="item.optionName == 'core_teacher_type'" v-for="sz in coreTeacherTypeList"
                                           :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'moral_edu_type'" v-for="sz in moralEduTypeList"
                                           :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'class_honorary_title'"
                                           v-for="sz in classHonoraryTitleList" :key="sz.dictKey" :label="sz.dictValue"
                                           :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'young_pioneers_type'"
                                           v-for="sz in youngPioneersTypeList" :key="sz.dictKey" :label="sz.dictValue"
                                           :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'class_teach_type'" v-for="sz in classTeachTypeList"
                                           :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'assistance_nature'" v-for="sz in assistanceNatureList"
                                           :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'archive_achieve_type'"
                                           v-for="sz in archiveAchieveTypeList" :key="sz.dictKey" :label="sz.dictValue"
                                           :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'opus_type'" v-for="sz in opusTypeList"
                                           :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                            </el-select>

                            <el-input :disabled="isDisabled" v-else v-model="showModelObj.txObj.typeName"
                                      :placeholder="item.placeholder ? item.placeholder : '最多32个字'" maxlength="32" />
                        </template>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.categoryName" v-if="item.key == 'categoryName'">
                        <el-select :disabled="isDisabled" v-if="item.optionName" filterable
                                   v-model="showModelObj.txObj.categoryName" placeholder="请选择">
                            <el-option v-if="item.optionName == 'content_theme_type'" v-for="sz in contentThemeTypeList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.levelName" v-if="item.key == 'levelName'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.levelName"
                                   placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_area_level'" v-for="sz in archiveAreaLevelList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                            <el-option v-if="item.optionName == 'teach_support_level'" v-for="sz in teachSupportLevelList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.publishType" v-if="item.key == 'publishType'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.publishType"
                                   placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_area_level'" v-for="sz in archiveAreaLevelList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.relationOrgName" v-if="item.key == 'relationOrgName'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.relationOrgName"
                                   placeholder="请选择">
                            <el-option v-if="item.optionName == 'award_org'" v-for="sz in awardOrgList" :key="sz.dictKey"
                                       :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.statusName" v-if="item.key == 'statusName'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.statusName"
                                   placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_topic_status'" v-for="sz in archiveTopicStatusList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.schoolArea" v-if="item.key == 'schoolArea'">
                        <el-select :disabled="isDisabled" filterable v-model="showModelObj.txObj.schoolArea"
                                   placeholder="请选择">
                            <el-option v-if="item.optionName == 'school_area'" v-for="sz in schoolAreaList"
                                       :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.annex1UrlList" v-if="item.key == 'annex1UrlList'">
                        <div v-if="!item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex1UrlList" class="csbbb cursor">
                                <el-icon v-if="!isDisabled" title="点击删除"
                                         @click="delImg(showModelObj.txObj.annex1UrlList, index)" :size="15" class="eicon"
                                         style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击预览" :src="item.response.data" v-if="item.response"
                                     @click="ylImgs(showModelObj.txObj.annex1UrlList, index)"
                                     style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="!item.accept && !isDisabled" v-model:file-list="showModelObj.txObj.annex1UrlList"
                                   :headers="{ token }" multiple :show-file-list="false"
                                   :action="serviceUrl+'/businessservice/archive/fill/uploadFile'" class="avatar-uploader"
                                   :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg" list-type="picture-card"
                                   :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                        <div v-if="item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex1UrlList" class="csbbb cursor">
                                <el-icon v-if="!isDisabled" title="点击删除"
                                         @click="delImg(showModelObj.txObj.annex1UrlList, index)" :size="15" class="eicon"
                                         style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击下载" src="../../assets/img/pdf.png" v-if="item.response"
                                     @click="downloadFile(item.response.data)"
                                     style="width: 100px;height: 100px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="item.accept && !isDisabled" v-model:file-list="showModelObj.txObj.annex1UrlList"
                                   :headers="{ token }" multiple :show-file-list="false"
                                   :action="serviceUrl+'/businessservice/archive/fill/uploadFile'" class="avatar-uploader"
                                   :before-upload="beforeJAUpload" :accept="item.accept" list-type="picture-card"
                                   :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="txObj.annex2UrlList" v-if="item.key == 'annex2UrlList'">
                        <div style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex2UrlList" class="csbbb cursor">
                                <el-icon v-if="!isDisabled" title="点击删除"
                                         @click="delImg(showModelObj.txObj.annex2UrlList, index)" :size="20" class="eicon"
                                         style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击预览" :src="item.response.data" v-if="item.response"
                                     @click="ylImgs(showModelObj.txObj.annex2UrlList, index)"
                                     style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="!isDisabled" v-model:file-list="showModelObj.txObj.annex2UrlList"
                                   :headers="{ token }" multiple :show-file-list="false"
                                   :action="serviceUrl+'/businessservice/archive/fill/uploadFile'" class="avatar-uploader"
                                   :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg" list-type="picture-card"
                                   :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                </template>
            </el-form>
            <template #footer v-if="!isDisabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="showModelSumbit">确 定</el-button>
                    <el-button @click="showModelObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'不合格说明'" v-model="buHgtxtObj.isShow" width="700px">
            <div class="mb5">
                请说明不合格的理由或修改建议
            </div>
            <el-input :disabled="!isDisabled || isRead" v-model="buHgtxtObj.txt" style="width: 100%" :rows="4"
                      type="textarea" placeholder="不合格说明" />
            <template #footer v-if="isDisabled && !isRead">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="showBhgSumbit">确 定</el-button>
                    <el-button @click="buHgtxtObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-image-viewer v-if="isOpen" :url-list="activeimgs" :initial-index="activeIndex" @close="this.isOpen = false;" />
    </div>
</template>
<script>
import { archiveQueryArchive, archiveFillBatch, archiveFillDelete, archiveManagerCheckArchive, allList } from '@/api/admin/archive';
import { queryDict } from "@/api/admin/public";
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";
import { serviceUrl } from "@/utils/global"

export default {
    components: {
    },
    name: "fileFill",
    data() {
        return {
            serviceUrl,
            activeIndex: 0,
            isOpen: false,
            isDisabled: false,
            activeimgs: [],
            timestampToTime,
            userInfo: {},
            queryParams: {
                title: ''
            },
            buHgtxtObj: {
                isShow: false,
                txt: '',
                obj: {}
            },
            grXX: {
                gender: '',
                birthDate: '',
                politicalClimate: '',
                nation: '',
            },
            containerRef: null,
            showModelObj: {
                tit: '',
                isShow: false,
                activeCate: {},
                txObj: {},
            },
            schoolModelRules: {
                gender: [{ required: true, validator: this.checkTeacherGenderFill, trigger: "change"  },],
                birthDate: [{ required: true, validator: this.checkTeacherBirthDateFill, trigger: "change"  },],
                politicalClimate: [{ required: true, validator: this.checkPoliticalClimateDateFill, trigger: "change"  },],
                nation: [{ required: true, validator: this.checkNationDateFill, trigger: "change"  },],
                teachAge: [{ required: true, validator: this.checkAgeNumFill, trigger: "change"  },],
                workAge: [{ required: true, validator: this.checkAgeNumFill, trigger: "change"  },],
                isSelect: [{ required: true, validator: this.checkAgeNumFill, trigger: "change"  },],
                'txObj.qualification': [{ required: true, message: "请选择学历", trigger: "change" },],
                'txObj.timeList': [{ required: true, message: "请选择时间", trigger: "change" },],
                'txObj.orgName': [{ required: true, message: "请输入院校或单位名称", trigger: "blur" },],
                'txObj.major': [{ required: true, message: "请输入专业", trigger: "blur" },],
                'txObj.isSelect': [{ required: true, message: "请选择一项", trigger: "change" },],
                'txObj.annex1UrlList': [{ required: true, message: "请上传相关文件", trigger: "change" },],
                'txObj.name': [{ required: true, message: "请选填此项内容", trigger: "change" },],
                'txObj.startTime': [{ required: true, message: "请选择时间", trigger: "change" },],
                'txObj.periodSubjectList': [{ required: true, message: "请选择学段学科", trigger: "change" },],
                'txObj.prizeLevel': [{ required: true, message: "请选择相关等级", trigger: "change" },],
                'txObj.content': [{ required: true, message: "请输入工作内容", trigger: "change" },],
                'txObj.typeName': [{ required: true, message: "请选填此项内容", trigger: "change" },],
                'txObj.levelName': [{ required: true, message: "请选填此项内容", trigger: "change" },],
                'txObj.relationOrgName': [{ required: true, message: "请选填此项内容", trigger: "change" },],
                'txObj.schoolArea': [{ required: true, message: "请选填此项内容", trigger: "change" },],
                'txObj.grade': [{ required: true, message: "请选填此项内容", trigger: "change" },],

            },
            delFileList: [],
            token: this.$storage.get("token"),
            politicalClimateList: [],
            nationList: [],
            archiveXdXkList: [],
            qualificationList: [],
            mandarinLevelList: [],
            coreTeacherTypeList: [],
            moralEduTypeList: [],
            classHonoraryTitleList: [],
            youngPioneersTypeList: [],
            archiveAreaLevelList: [],
            teachSupportLevelList: [],
            contentThemeTypeList: [],
            classTeachTypeList: [],
            awardLevelList: [],
            awardOrgList: [],
            archiveTopicStatusList: [],
            assistanceNatureList: [],
            schoolAreaList: [],
            archiveAchieveTypeList: [],
            assumeRoleList: [],
            opusTypeList: [],
            opusRoleList: [],
            archiveIdList: [],
            allList: allList,
            userId: '',
            isRead: false,
        }
    },
    computed: {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    created() {
        console.log(this.$route.query);
        if (this.$route.query.userId) {
            this.userId = this.$route.query.userId;
            this.isDisabled = true;
        }
        if (this.$route.query.isRead) {
            this.isRead = this.$route.query.isRead
        }
        this.getArchiveQuery();
        this.getQueryDict();
    },
    mounted() {
        this.containerRef = this.$refs.containerRef;
    },
    methods: {
        checkTeacherGenderFill(rule, value, callback){
            var gender = ''
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'gender') {
                        gender = e.value
                    }
                })
            })
            if (gender===''||gender==undefined) {
                callback(new Error('请选择性别'))
            } else {
                callback()
            }
        },
        checkTeacherBirthDateFill(rule, value, callback){
            var birthDate = ''
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'birthDate') {
                        birthDate = e.value
                    }
                })
            })
            if (birthDate===''||birthDate==undefined) {
                callback(new Error('请选择出生年月'))
            } else {
                callback()
            }
        },
        checkPoliticalClimateDateFill(rule, value, callback){
            var politicalClimate = ''
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'politicalClimate') {
                        politicalClimate = e.value
                    }
                })
            })
            if (politicalClimate===''||politicalClimate==undefined) {
                callback(new Error('请选择政治面貌'))
            } else {
                callback()
            }
        },
        checkNationDateFill(rule, value, callback){
            var nation = ''
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'nation') {
                        nation = e.value
                    }
                })
            })
            if (nation===''||nation==undefined) {
                callback(new Error('请选择政治面貌'))
            } else {
                callback()
            }
        },
        checkAgeNumFill(rule, value, callback){
            callback()
        },
        showBhgSumbit() {
            this.buHgtxtObj.obj.reason = this.buHgtxtObj.txt
            this.buHgtxtObj.isShow = false;
        },
        reaSm(item) {
            this.buHgtxtObj.txt = item.reason
            this.buHgtxtObj.obj = item;
            this.buHgtxtObj.isShow = true;
        },
        chooseBhg(e, item) {
            if (item.auditStatus == 2) {
                item.reason = ''
                this.buHgtxtObj.txt = '';
                this.buHgtxtObj.obj = item;
                this.buHgtxtObj.isShow = true;
            }
        },
        showModelSumbit() {
            this.$refs["showModelObj"].validate(valid => {
                if (valid) {
                    var obj = {
                        oneTxt: '',
                        twoTxt: '',
                        topCategory: this.showModelObj.activeCate.topCategory,
                        secondCategory: this.showModelObj.activeCate.secondCategory,
                    }
                    this.showModelObj.activeCate.tsList.forEach(e => {
                        if (e.key == 'timeList') {
                            if (this.showModelObj.txObj[e.key].length != 0) {
                                console.log("时间选择：{}",this.showModelObj.txObj[e.key])
                                obj.startTime = this.showModelObj.txObj[e.key][0]
                                obj.endTime = this.showModelObj.txObj[e.key][1]
                            }
                        } else if (e.key == 'periodSubjectList') {
                            if (this.showModelObj.txObj[e.key].length != 0) {
                                obj.period = this.showModelObj.txObj[e.key][0]
                                obj.subject = this.showModelObj.txObj[e.key][1]
                            }
                        } else if (e.key == 'annex1UrlList' || e.key == 'annex2UrlList') {
                            var fileList = [];
                            this.showModelObj.txObj[e.key].forEach(e => {
                                if (e.response.code == 1 || e.response.data) {
                                    fileList.push(e.response.data);
                                }
                            })
                            obj[e.key.slice(0, -4)] = fileList.toString();
                        } else {
                            obj[e.key] = this.showModelObj.txObj[e.key]
                        }
                    })
                    this.allList.forEach(oneItem => {
                        oneItem.smList.forEach(e => {
                            if (e.secondCategory == this.showModelObj.activeCate.secondCategory && e.topCategory == this.showModelObj.activeCate.topCategory) {
                                if ((e.topCategory == 1 && e.secondCategory == 5) || (e.topCategory == 1 && e.secondCategory == 6)) {
                                    obj.twoTxt = obj.orgName + ' ' + obj.major,
                                      this.qualificationList.forEach(j => {
                                          if (j.dictKey == obj.qualification) {
                                              obj.oneTxt = j.dictValue
                                          }
                                      })
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9) + '    ' + obj.orgName + '    ' + obj.major
                                    }
                                }
                                if ((e.topCategory == 1 && e.secondCategory == 10)) {
                                    obj.twoTxt = obj.orgName + ' ' + obj.major
                                    this.technicalTitleList.forEach(j => {
                                        if (j.dictKey == obj.name) {
                                            obj.oneTxt = j.dictValue
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                }
                                if ((e.topCategory == 1 && e.secondCategory == 11)) {
                                    this.archiveXdXkList.forEach(j => {
                                        if (obj.period == j.dictKey && obj.period) {
                                            j.childrenList.forEach(m => {
                                                if (obj.subject == m.dictKey) {
                                                    obj.oneTxt = j.dictValue + ' ' + m.dictValue
                                                }
                                            })
                                        }
                                    })
                                }
                                if ((e.topCategory == 1 && e.secondCategory == 12)) {
                                    obj.twoTxt = ''
                                    this.mandarinLevelList.forEach(j => {
                                        if (j.dictKey == obj.prizeLevel) {
                                            obj.oneTxt = j.dictValue
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                }
                                if (e.topCategory == 1 && (e.secondCategory == 13 || e.secondCategory == 14 || e.secondCategory == 15 || e.secondCategory == 16 || e.secondCategory == 17)) {
                                    obj.twoTxt = ' '
                                    obj.oneTxt = obj.orgName ? obj.orgName : obj.name
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                                    }
                                }
                                if ((e.topCategory == 2 && e.secondCategory == 1)) {
                                    obj.twoTxt = ''
                                    this.coreTeacherTypeList.forEach(j => {
                                        if (j.dictKey == obj.typeName) {
                                            obj.oneTxt = j.dictValue
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                }
                                if ((e.topCategory == 3 && (e.secondCategory == 1 || e.secondCategory == 2))) {
                                    obj.twoTxt = ''
                                    if (e.secondCategory == 1) {
                                        this.moralEduTypeList.forEach(j => {
                                            if (j.dictKey == obj.typeName) {
                                                obj.oneTxt = j.dictValue
                                            }
                                        })
                                    } else if (e.secondCategory == 2) {
                                        this.youngPioneersTypeList.forEach(j => {
                                            if (j.dictKey == obj.typeName) {
                                                obj.oneTxt = j.dictValue
                                            }
                                        })
                                    }
                                    this.archiveAreaLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + ' ')
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                                    }
                                    this.awardOrgList.forEach(j => {
                                        if (j.dictKey == obj.relationOrgName) {
                                            obj.twoTxt += (j.dictValue + ' ')
                                        }
                                    })
                                }
                                if ((e.topCategory == 3 && e.secondCategory == 3)) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = ''
                                    this.schoolAreaList.forEach(j => {
                                        if (j.dictKey == obj.schoolArea) {
                                            obj.twoTxt += (j.dictValue + ' ')
                                        }
                                    })
                                    obj.twoTxt += (obj.grade + ' ' + obj.classInfo)
                                }
                                if ((e.topCategory == 3 && (e.secondCategory == 4 || e.secondCategory == 5)) || (e.topCategory == 4 && (e.secondCategory == 6 || e.secondCategory == 7))) {
                                    obj.twoTxt = ' '
                                    obj.oneTxt = obj.name
                                    if (obj.startTime) {
                                        obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                }
                                if (e.topCategory == 3 && (e.secondCategory == 7 || e.secondCategory == 9 || e.secondCategory == 11)) {
                                    if (obj.startTime) {
                                        obj.oneTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                                    }
                                }
                                if (e.topCategory == 3 && (e.secondCategory == 8 || e.secondCategory == 10 || e.secondCategory == 12)) {
                                    obj.oneTxt = obj.schoolYear + ' ' + obj.schoolTerm
                                    obj.twoTxt = ' '
                                    if (obj.schoolArea) {
                                        this.schoolAreaList.forEach(j => {
                                            if (j.dictKey == obj.schoolArea) {
                                                obj.twoTxt = j.dictValue + ' ' + obj.grade
                                            }
                                        })
                                    } else {
                                        obj.twoTxt = obj.grade
                                    }
                                }
                                if ((e.topCategory == 4 && e.secondCategory == 1)) {
                                    this.schoolAreaList.forEach(j => {
                                        if (j.dictKey == obj.schoolArea) {
                                            obj.oneTxt = (j.dictValue)
                                        }
                                    })
                                    obj.twoTxt = obj.grade + ' ' + obj.classInfo + ' '
                                    this.archiveXdXkList.forEach(j => {
                                        if (obj.period == j.dictKey && obj.period) {
                                            j.childrenList.forEach(m => {
                                                if (obj.subject == m.dictKey) {
                                                    obj.twoTxt += m.dictValue
                                                }
                                            })
                                        }
                                    })
                                }
                                if ((e.topCategory == 4 && (e.secondCategory == 2 || e.secondCategory == 3 || e.secondCategory == 4 || e.secondCategory == 5))) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = ''
                                    this.contentThemeTypeList.forEach(j => {
                                        if (j.dictKey == obj.categoryName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    this.archiveAreaLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    this.awardLevelList.forEach(j => {
                                        if (j.dictKey == obj.prizeLevel) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                }
                                if (e.topCategory == 4 && (e.secondCategory == 8)) {
                                    obj.twoTxt = ''
                                    obj.oneTxt = obj.address
                                    if (obj.startTime) {
                                        obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                                    }
                                }
                                if (e.topCategory == 4 && (e.secondCategory == 9)) {
                                    obj.twoTxt = ''
                                    obj.oneTxt = obj.name
                                    this.teachSupportLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9) + ' '
                                    }
                                    obj.twoTxt += obj.orgName
                                }
                                if (e.topCategory == 4 && (e.secondCategory == 10)) {
                                    obj.oneTxt = obj.schoolYear
                                    obj.twoTxt = obj.typeName
                                }
                                if ((e.topCategory == 4 && e.secondCategory == 11) || (e.topCategory == 7 && e.secondCategory == 2)) {
                                    obj.oneTxt = obj.schoolYear
                                    obj.twoTxt = obj.classInfo
                                }
                                if (e.topCategory == 5 && e.secondCategory == 1) {
                                    this.assistanceNatureList.forEach(j => {
                                        if (j.dictKey == obj.typeName) {
                                            obj.oneTxt = j.dictValue
                                        }
                                    })
                                    obj.twoTxt = obj.num
                                }
                                if (e.topCategory == 5 && e.secondCategory == 2) {
                                    if (obj.startTime) {
                                        obj.oneTxt = timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                    obj.twoTxt = obj.typeName
                                }
                                if (e.topCategory == 5 && e.secondCategory == 3) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = obj.relationName + ' '
                                    this.archiveAreaLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + ' ')
                                        }
                                    })
                                    this.awardLevelList.forEach(j => {
                                        if (j.dictKey == obj.prizeLevel) {
                                            obj.twoTxt += j.dictValue
                                        }
                                    })
                                }
                                if ((e.topCategory == 5 && (e.secondCategory == 4 || e.secondCategory == 5)) || (e.topCategory == 8 && e.secondCategory == 1)) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = ''
                                    this.archiveAreaLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9)
                                    }
                                }
                                if (e.topCategory == 6 && (e.secondCategory == 1 || e.secondCategory == 2)) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = ''
                                    this.contentThemeTypeList.forEach(j => {
                                        if (j.dictKey == obj.categoryName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                                    }
                                    this.archiveTopicStatusList.forEach(j => {
                                        if (j.dictKey == obj.statusName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                }
                                if (e.topCategory == 6 && (e.secondCategory == 3 || e.secondCategory == 4)) {
                                    this.archiveAchieveTypeList.forEach(j => {
                                        if (j.dictKey == obj.typeName) {
                                            obj.oneTxt = j.dictValue
                                        }
                                    })
                                    if (e.secondCategory == 4) {
                                        obj.oneTxt = obj.name
                                    }
                                    obj.twoTxt = obj.name + ' '
                                    this.contentThemeTypeList.forEach(j => {
                                        if (j.dictKey == obj.categoryName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    this.assumeRoleList.forEach(j => {
                                        if (j.dictKey == obj.relationName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                }
                                if (e.topCategory == 6 && (e.secondCategory == 5 || e.secondCategory == 6)) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = obj.isSelect + '  '
                                    this.contentThemeTypeList.forEach(j => {
                                        if (j.dictKey == obj.categoryName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    this.opusTypeList.forEach(j => {
                                        if (j.dictKey == obj.typeName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                }
                                if (e.topCategory == 7 && (e.secondCategory == 1 || e.secondCategory == 3)) {
                                    obj.oneTxt = obj.name
                                    obj.twoTxt = ''
                                    this.contentThemeTypeList.forEach(j => {
                                        if (j.dictKey == obj.categoryName) {
                                            obj.twoTxt += (j.dictValue + '  ')
                                        }
                                    })
                                    this.archiveAreaLevelList.forEach(j => {
                                        if (j.dictKey == obj.levelName) {
                                            obj.twoTxt += (j.dictValue + ' ')
                                        }
                                    })
                                    if (obj.startTime) {
                                        obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                                    }
                                }
                                if (e.topCategory == 7 && e.secondCategory == 4) {
                                    obj.oneTxt = obj.isSelect
                                }
                                if (!this.showModelObj.txObj.archiveId) {
                                    obj.archiveId = this.scSjm();
                                    e.value.push(obj)
                                } else {
                                    this.allList.forEach(cItem => {
                                        cItem.smList.forEach(j => {
                                            if (j.tsList) {
                                                e.value.forEach(m => {
                                                    if (m.archiveId == this.showModelObj.txObj.archiveId) {
                                                        Object.assign(m, obj)
                                                    }
                                                })
                                            }
                                        })
                                    })
                                }

                            }
                        })
                    })
                    this.showModelObj.isShow = false
                }
            })

        },
        scSjm() {
            let date = (new Date()).valueOf(); //获取时间戳
            let txt = '1234567890'; //生成的随机机器码
            let len = 13; //机器码有多少位
            let pwd = ''; //定义空变量用来接收机器码
            for (let i = 0; i < len; i++) {
                pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
            }
            return date + pwd
        },
        ylImgs(data, index) {
            this.activeIndex = index;
            this.activeimgs = []
            console.log(data);
            data.forEach(e => {
                this.activeimgs.push(e.response.data)
            })
            this.isOpen = true;
        },
        save(type) {
            var objList = []
            var archiveId = this.scSjm();
            var archiveIdList = [];
            var tjobj = {}
            var age, teachAge, workAge, politicalClimate = ''
            var backbone = []
            var qualification = []
            var rankLevel = []
            var moralWork = []
            var teachWork = []
            var leadWork = []
            var backboneType = []
            var qualificationWork = []
            var scientificWork = []
            var continueEdu = []
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.key == 'politicalClimate') {
                        politicalClimate = e.value
                    }
                    if (e.key == 'birthDate') {
                        age = e.value;
                    }
                    if (e.key == 'teachAge') {
                        teachAge = e.value;
                    }
                    if (e.key == 'workAge') {
                        workAge = e.value;
                    }
                    if (e.tsList) {
                        if (e.value && e.value.length != 0) {
                            if (e.topCategory == 1) {
                                if (e.secondCategory == 5) {
                                    qualificationWork.push('1')
                                } else if (e.secondCategory == 6) {
                                    qualificationWork.push('2')
                                } else if (e.secondCategory == 10) {
                                    qualificationWork.push('3')
                                }
                            }
                            if (e.topCategory == 3) {
                                if (e.secondCategory == 1) {
                                    moralWork.push('1')
                                } else if (e.secondCategory == 2) {
                                    moralWork.push('2')
                                } else if (e.secondCategory == 3) {
                                    moralWork.push('3')
                                } else if (e.secondCategory == 4) {
                                    moralWork.push('4')
                                } else if (e.secondCategory == 5) {
                                    moralWork.push('5')
                                }
                            }
                            if (e.topCategory == 4) {
                                if (e.secondCategory == 2) {
                                    teachWork.push('1')
                                } else if (e.secondCategory == 3) {
                                    teachWork.push('2')
                                } else if (e.secondCategory == 4) {
                                    teachWork.push('3')
                                } else if (e.secondCategory == 5) {
                                    teachWork.push('4')
                                } else if (e.secondCategory == 6) {
                                    teachWork.push('5')
                                } else if (e.secondCategory == 7) {
                                    teachWork.push('6')
                                } else if (e.secondCategory == 8) {
                                    teachWork.push('7')
                                } else if (e.secondCategory == 9) {
                                    teachWork.push('8')
                                }
                            }
                            if (e.topCategory == 5) {
                                if (e.secondCategory == 2) {
                                    leadWork.push('1')
                                } else if (e.secondCategory == 3) {
                                    leadWork.push('2')
                                } else if (e.secondCategory == 4) {
                                    leadWork.push('3')
                                } else if (e.secondCategory == 5) {
                                    leadWork.push('4')
                                }
                            }
                            if (e.topCategory == 6) {
                                if (e.secondCategory == 1) {
                                    scientificWork.push('1')
                                } else if (e.secondCategory == 2) {
                                    scientificWork.push('2')
                                } else if (e.secondCategory == 3) {
                                    scientificWork.push('3')
                                } else if (e.secondCategory == 4) {
                                    scientificWork.push('4')
                                } else if (e.secondCategory == 5) {
                                    scientificWork.push('5')
                                }
                            }
                            if (e.topCategory == 7) {
                                if (e.secondCategory == 1) {
                                    continueEdu.push('1')
                                }
                            }
                        }

                        e.value.forEach((j, k) => {
                            var obj = {}
                            obj.archiveId = j.archiveId ? j.archiveId : (archiveId + e.topCategory + e.secondCategory + k);
                            obj.topCategory = e.topCategory
                            obj.secondCategory = e.secondCategory
                            Object.assign(obj, j)
                            objList.push(obj)
                            archiveIdList.push(obj.archiveId)
                            if (e.topCategory == 2 && e.secondCategory == 1) {
                                backbone.push(obj)
                            }
                            if (e.topCategory == 1 && e.secondCategory == 5) {
                                qualification.push(obj)
                            }
                            if (e.topCategory == 1 && e.secondCategory == 10) {
                                rankLevel.push(obj)
                            }
                            if (e.topCategory == 2 && e.secondCategory == 1) {
                                backboneType.push(obj.typeName)
                            }
                        })
                    } else {
                        var obj = {}
                        obj.archiveId = e.archiveId ? e.archiveId : (archiveId + e.topCategory + e.secondCategory);
                        obj.topCategory = e.topCategory
                        obj.secondCategory = e.secondCategory
                        obj[e.key] = e.value
                        objList.push(obj)
                        archiveIdList.push(obj.archiveId)
                    }
                })
            })
            // console.log(qualification);
            var maxQuali = 0
            qualification.forEach(e => {
                if (e.qualification > maxQuali) {
                    maxQuali = e.qualification
                }
            })
            var maxRank = 0
            rankLevel.forEach(e => {
                if (e.name > maxRank) {
                    maxRank = e.name
                }
            })
            if (age) {
                tjobj.age = this.calculateAge(age);
            } else {
                this.$message.error('请填写出生日期')
                return
            }
            if (teachAge) {
                tjobj.teachAge = teachAge;
            } else {
                this.$message.error('请填写教龄')
                return
            }
            if (workAge) {
                tjobj.workAge = workAge;
            } else {
                this.$message.error('请填写工龄')
                return
            }
            if (backbone.length != 0) {
                tjobj.isBackbone = 1
            } else {
                tjobj.isBackbone = 0
            }
            if (maxQuali != 0) {
                if (maxQuali == 1 || maxQuali == 2 || maxQuali == 3) {
                    tjobj.qualification = 2
                }
                if (maxQuali == 4) {
                    tjobj.qualification = 1
                }
                if (maxQuali == 5 || maxQuali == 6) {
                    tjobj.qualification = 0
                }
            } else {
                this.$message.error('请填写最高学历')
                return
            }
            if (maxRank != 0) {
                tjobj.rankLevel = maxRank
            } else {
                this.$message.error('请填写目前最高职称')
                return
            }
            // console.log(tjobj);
            tjobj.archiveList = objList
            tjobj.subType = type
            tjobj.politicalClimate = politicalClimate
            tjobj.moralWork = moralWork.join(',')
            tjobj.teachWork = teachWork.join(',')
            tjobj.backboneType = backboneType.join(',')
            tjobj.qualificationWork = qualificationWork.join(',')
            tjobj.leadWork = leadWork.join(',')
            tjobj.scientificWork = scientificWork.join(',')
            tjobj.continueEdu = continueEdu.join(',')
            archiveFillBatch(tjobj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.archiveIdList.forEach(e => {
                        if (archiveIdList.indexOf(e) === -1) {
                            archiveFillDelete({ archiveId: e })
                        }
                    })
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        calculateAge(birthTimestamp) {
            const today = new Date();
            const birthDate = new Date(birthTimestamp);
            let age = today.getFullYear() - birthDate.getFullYear();
            // 如果今年的生日还没有过，则年龄减一
            if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        submitCheck() {
            var objList = []

            let shouldBreak = false;
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.tsList) {
                        e.value.forEach((j, k) => {
                            var obj = {}
                            obj.archiveId = j.archiveId;
                            obj.auditStatus = j.auditStatus
                            obj.reason = j.reason
                            objList.push(obj)
                            if (obj.auditStatus != 1 && obj.auditStatus != 2) {
                                shouldBreak = true
                                return
                            }
                        })
                    } else {
                        if (e.value === 0 || e.value) {
                            var obj = {}
                            obj.archiveId = e.archiveId;
                            obj.auditStatus = e.auditStatus
                            obj.reason = e.reason
                            objList.push(obj)

                            if (obj.auditStatus != 1 && obj.auditStatus != 2) {
                                shouldBreak = true
                                return
                            }
                        }

                    }
                })
            })
            console.log(this.allList);
            console.log(objList);
            console.log(shouldBreak)
            if (shouldBreak) {
                this.$message.error('请全部审核完！')
            } else {
                archiveManagerCheckArchive({ archiveList: objList, userId: this.userId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success('审核成功')
                    } else {
                        this.$message.error('操作失败')
                    }
                })
            }

        },
        getArchiveQuery() {
            this.archiveIdList = []
            archiveQueryArchive({ userId: this.userId ? this.userId : '' }).then(res => {
                res.data.data.forEach(e => {
                    this.archiveIdList.push(e.archiveId)
                    this.allList.forEach(oneItem => {
                        oneItem.smList.forEach(j => {
                            if (e.secondCategory == j.secondCategory && e.topCategory == j.topCategory) {
                                if (j.tsList) {
                                    j.value.push(e)
                                } else {
                                    j.archiveId = e.archiveId;
                                    j.value = e[j.key]
                                    j.auditStatus = e.auditStatus;
                                    j.reason = e.reason
                                }
                            }
                        })
                    })

                    // archiveFillDelete({archiveId:e.archiveId })
                })
                console.log(this.allList);
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles, this.recommendImgList)
            if (file.response) {
                this.delFileList.push(file.response.data)
            }
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf')) {
                this.$message.error('文件必须是.pdf格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleAddFile(item) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            item.tsList.forEach(e => {
                if (e.key.substr(-4) == 'List') {
                    this.showModelObj.txObj[e.key] = []
                } else {
                    this.showModelObj.txObj[e.key] = ''
                }

            })
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        handleClick(e) {
            console.log(e, this.containerRef, this.$refs.containerRef);
            console.log(this.$refs.containerRef);
            e.preventDefault()
        },
        delImg(imgList, index) {
            imgList.splice(index, 1)
        },
        delZl(zlList, index) {
            zlList.splice(index, 1)
        },
        editZL(item, index) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            var editObj = JSON.parse(JSON.stringify(item.value[index]))
            console.log(item, index, editObj)
            item.tsList.forEach(e => {
                if (e.key == 'timeList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.startTime) {
                        this.showModelObj.txObj[e.key] = [editObj.startTime, editObj.endTime]
                    }
                } else if (e.key == 'periodSubjectList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.period) {
                        this.showModelObj.txObj[e.key] = [editObj.period, editObj.subject]
                    }
                } else if (e.key == 'annex1UrlList' || e.key == 'annex2UrlList') {
                    this.showModelObj.txObj[e.key] = []
                    console.log(e, e.key.slice(0, -4), editObj[e.key.slice(0, -4)]);
                    if (editObj[e.key.slice(0, -4)]) {
                        var fileList = editObj[e.key.slice(0, -4)].split(',')
                        console.log(fileList);
                        fileList.forEach(j => {
                            this.showModelObj.txObj[e.key].push({
                                url: j,
                                response: {
                                    data: j
                                }
                            })
                        })
                    }
                } else {
                    this.showModelObj.txObj[e.key] = editObj[e.key]
                }
            })
            if (editObj.archiveId) {
                this.showModelObj.txObj.archiveId = editObj.archiveId
            }
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        downloadFile(fileUrl) {
            download(fileUrl)
        },
        getQueryDict() {
            queryDict({ code: 'political_climate' }).then(res => {
                this.politicalClimateList = res.data.data;
            })
            queryDict({ code: 'nation' }).then(res => {
                this.nationList = res.data.data;
            })
            queryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            queryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
            queryDict({ code: 'mandarin_level' }).then(res => {
                this.mandarinLevelList = res.data.data;
            })
            queryDict({ code: 'core_teacher_type' }).then(res => {
                this.coreTeacherTypeList = res.data.data;
            })
            queryDict({ code: 'moral_edu_type' }).then(res => {
                this.moralEduTypeList = res.data.data;
            })
            queryDict({ code: 'class_honorary_title' }).then(res => {
                this.classHonoraryTitleList = res.data.data;
            })
            queryDict({ code: 'young_pioneers_type' }).then(res => {
                this.youngPioneersTypeList = res.data.data;
            })
            queryDict({ code: 'archive_area_level' }).then(res => {
                this.archiveAreaLevelList = res.data.data;
            })
            queryDict({ code: 'teach_support_level' }).then(res => {
                this.teachSupportLevelList = res.data.data;
            })
            queryDict({ code: 'award_org' }).then(res => {
                this.awardOrgList = res.data.data;
            })
            queryDict({ code: 'school_area' }).then(res => {
                this.schoolAreaList = res.data.data;
            })
            queryDict({ code: 'content_theme_type' }).then(res => {
                this.contentThemeTypeList = res.data.data;
            })
            queryDict({ code: 'class_teach_type' }).then(res => {
                this.classTeachTypeList = res.data.data;
            })
            queryDict({ code: 'award_level' }).then(res => {
                this.awardLevelList = res.data.data;
            })
            queryDict({ code: 'qualification' }).then(res => {
                this.qualificationList = res.data.data;
            })
            queryDict({ code: 'assistance_nature' }).then(res => {
                this.assistanceNatureList = res.data.data;
            })
            queryDict({ code: 'archive_topic_status' }).then(res => {
                this.archiveTopicStatusList = res.data.data;
            })
            queryDict({ code: 'archive_achieve_type' }).then(res => {
                this.archiveAchieveTypeList = res.data.data;
            })
            queryDict({ code: 'assume_role' }).then(res => {
                this.assumeRoleList = res.data.data;
            })
            queryDict({ code: 'opus_type' }).then(res => {
                this.opusTypeList = res.data.data;
            })
            queryDict({ code: 'opus_role' }).then(res => {
                this.opusRoleList = res.data.data;
            })
        }
    }
}
</script>
<!--内联样式-->
<style lang="scss" scoped>
/deep/ .el-anchor__link {
    font-size: 16px !important;
}

/deep/ .el-form-item__label {
    color: #333 !important;
    // font-weight: bold;
    line-height: 32px;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader {
    padding: 0 5px;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
}
</style>
