import request from "@/utils/request";
// 创建协同备课
export const  lessonCreate =(data) =>{
    return request({
        url: "/businessservice/lesson/create",
        method:"post",
        data: data
    })
}
// 删除协同备课文件
export const  delLessonFile =(query) =>{
    return request({
        url: "/businessservice/lesson/deleteFile",
        method:"get",
        params: query
    })
}
// 删除备课
export const  delLesson =(query) =>{
    return request({
        url: "/businessservice/lesson/delete",
        method:"get",
        params: query
    })
}
// 查询已存在公案文件
export const  lessonGaFiles =(query) =>{
    return request({
        url: "/businessservice/lesson/gaFiles",
        method:"get",
        params: query
    })
}
// 查询协同备课详细信息
export const  lessonInfo =(query) =>{
    return request({
        url: "/businessservice/lesson/info",
        method:"get",
        params: query
    })
}
// 查询所有协同备课
export const  lessonList =(query) =>{
    return request({
        url: "/businessservice/lesson/listAll",
        method:"get",
        params: query
    })
}
// 查询个人备课
export const  lessonListMy =(query) =>{
    return request({
        url: "/businessservice/lesson/listMy",
        method:"get",
        params: query
    })
}
// 查询共案课件
export const  lessonListCommonLessons =(query) =>{
    return request({
        url: "/businessservice/lesson/listCommonLessons",
        method:"get",
        params: query
    })
}
// 发布协同备课
export const  lessonRelease =(data) =>{
    return request({
        url: "/businessservice/lesson/release",
        method:"post",
        data: data
    })
}
// 停止协同备课
export const  lessonStop =(query) =>{
    return request({
        url: "/businessservice/lesson/stop",
        method:"get",
        params: query
    })
}
// 提请协同备课
export const  lessonSubmit =(query) =>{
    return request({
        url: "/businessservice/lesson/submit",
        method:"get",
        params: query
    })
}
// 更新协同备课
export const  lessonUpdate =(data) =>{
    return request({
        url: "/businessservice/lesson/update",
        method:"post",
        data: data
    })
}
// 上传协同备课文件
export const  lessonUpload =(data) =>{
    return request({
        url: "/businessservice/lesson/uploadFile",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 创建课题研讨
export const  discussCreate =(data) =>{
    return request({
        url: "/businessservice/discuss/create",
        method:"post",
        data: data
    })
}
// 删除课题研讨
export const  discussDel =(query) =>{
    return request({
        url: "/businessservice/discuss/delete",
        method:"get",
        params: query
    })
}
// 查询课题研讨列表(按文件及页码)
export const  discussList =(data) =>{
    return request({
        url: "/businessservice/discuss/list",
        method:"post",
        data: data
    })
}
// 修改课题研讨
export const  discussUpdate =(data) =>{
    return request({
        url: "/businessservice/discuss/update",
        method:"post",
        data: data
    })
}
// 协同备课审核通过
export const lessonApprove =(query) =>{
    return request({
        url: "/businessservice/lesson/approve",
        method:"get",
        params: query
    })
}
// 协同备课审核拒绝
export const lessonRefuse =(query) =>{
    return request({
        url: "/businessservice/lesson/refuse",
        method:"get",
        params: query
    })
}
// 查询待审核协同备课
export const  lessonListAudit =(query) =>{
    return request({
        url: "/businessservice/lesson/listAudit",
        method:"get",
        params: query
    })
}
// 分页查询协同备课
export const  lessonPageLessons =(data) =>{
    return request({
        url: "/businessservice/lesson/pageLessons",
        method:"post",
        data: data
    })
}
// 查询用户参与的协同备课
export const  lessonListJoin =(query) =>{
    return request({
        url: "/businessservice/lesson/listJoin",
        method:"get",
        params: query
    })
}

// 发布协同备课会议协同备课
export const  lessonMeeting =(data) =>{
    return request({
        url: "/businessservice/lesson/meeting",
        method:"post",
        data: data
    })
}

// 用户为主备人备课列表
export const  lessonListMainJoin =(query) =>{
    return request({
        url: "/businessservice/lesson/userMainJoin",
        method:"get",
        params: query
    })
}