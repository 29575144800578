<template>
    <div class="app-container">
        <el-card class="mb15">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增个人备课</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">

                    <el-col :span="24" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入备课标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                          height="calc(100vh - 420px)" v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" align="center" label="序号" width="60" />
                    <el-table-column width="120px" align="center" label="状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 1">初始</div>
                            <div v-if="scope.row.status == 6">已发布</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="备课标题" prop="title" width="240px">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" align="center" label="主备人" prop="leadUserName" />
                    <el-table-column width="180" align="center" label="课题级别" prop="lessonLevelName" />
                    <!--                    <el-table-column width="80" align="center" label="学段" prop="periodName" />-->
                    <!--                    <el-table-column width="80" align="center" label="年级" prop="gradeName" />-->
                    <!--                    <el-table-column width="160" align="center" label="学科" prop="subjectName" />-->
                    <el-table-column width="200" label="学段•年级•学科">
                        <template #default="scope">
                            <el-text>
                                {{scope.row.periodName}}•{{scope.row.gradeName}}•{{scope.row.subjectName}}
                            </el-text>
                        </template>
                    </el-table-column>
                    <el-table-column width="100px" align="center" fixed="right" label="操作"
                                     class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popover placement="bottom" trigger="click"
                                        :width="120" popper-style="padding: 5px 0 0">
                                <template #reference>
                                    <el-button>操作</el-button>
                                </template>
                                <template #default>
                                    <div>
                                        <div class="text-item">
                                            <el-text type="primary" @click="handleUpdate(scope.row)">开始备课</el-text>
                                        </div>
                                        <div class="text-item">
                                            <el-text type="primary" @click="handleRelease(scope.row)">发布</el-text>
                                        </div>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                       icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">
                                            <template #reference>
                                                <div class="text-item">
                                                    <el-text type="primary">删除</el-text>
                                                </div>
                                            </template>
                                        </el-popconfirm>
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                        <!--                        <template #default="scope">-->
                        <!--                            <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑-->
                        <!--                            </el-button>-->
                        <!--                            <el-button type="primary" v-if="scope.row.status == 1" style="margin-left: 12px;" size="mini"-->
                        <!--                                icon="" @click="handleRelease(scope.row)">发布-->
                        <!--                            </el-button>-->
                        <!--                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"-->
                        <!--                                icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">-->
                        <!--                                <template #reference>-->
                        <!--                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除-->
                        <!--                                    </el-button>-->
                        <!--                                </template>-->
                        <!--                            </el-popconfirm>-->
                        <!--                        </template>-->
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog :title="selectRow.title + '上传初案'" v-model="scCa" width="60%" @close="getList()">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.jaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 1, lessonType: 0, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 4, lessonType: 0, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.kjFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 2, lessonType: 0, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xtFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 3, lessonType: 0, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :title="selectRow.title + '上传修正案'" v-model="scYt" width="60%" @close="getList()">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytjaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 1, lessonType: 2, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytxaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 4, lessonType: 2, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytkjFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 2, lessonType: 2, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytxtFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'" :headers="{ token }"
                                       :on-remove="handleRemove"
                                       :data="{ fileType: 3, lessonType: 2, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :title="selectRow.title + '上传共案'" v-model="scGa" width="60%">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="共案封面" prop="bannerUrl">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/lesson/uploadCover'" :show-file-list="false"
                                       :on-success="handleAvatarSuccess" :headers="{ token }"
                                       :data="{ lessonId: selectRow.lessonId }" :before-upload="beforeAvatarUpload">
                                <img v-if="selectRow.coverUrl" :src="selectRow.coverUrl" class="avatar"
                                     style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.jaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'"
                                       :on-change="handleGAJaChange" :headers="{ token }" :on-remove="handleRemove"
                                       :data="{ fileType: 1, lessonType: 1, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xaFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'"
                                       :on-change="handleGAxaChange" :headers="{ token }" :on-remove="handleRemove"
                                       :data="{ fileType: 4, lessonType: 1, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.kjFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'"
                                       :on-change="handleGAkjChange" :headers="{ token }" :on-remove="handleRemove"
                                       :data="{ fileType: 2, lessonType: 1, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xtFiles" class="upload-demo"
                                       :action="serviceUrl+'/businessservice/lesson/uploadFile'"
                                       :on-change="handleGAxtChange" :headers="{ token }" :on-remove="handleRemove"
                                       :data="{ fileType: 3, lessonType: 1, lessionId: selectRow.lessonId }"
                                       accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="scGa = false">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="selectRow.title + '可查看人员'" v-model="ckPeople" width="60%">
            <el-checkbox v-model="zbr" label="主备人" size="large" />
            <el-checkbox v-model="xbr" label="协备人" size="large" />
        </el-dialog>
    </div>
</template>

<script>

import { newsDelete } from "@/api/admin/news";
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile, lessonListMy, delLesson, lessonPageLessons } from "@/api/admin/lesson"
import {exportData} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";
import { print } from "@/api/adminservice/form"
import { serviceUrl } from "@/utils/global"

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            serviceUrl,
            ckPeople: false,
            selectRow: {},
            scCa: false,
            scGa: false,
            scYt: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '初始', value: 1 },
                // { name: '一轮研讨', value: 2 },
                // { name: '初案修正', value: 3 },
                // { name: '二轮研讨', value: 4 },
                // { name: '共案修正', value: 5 },
                { name: '发布', value: 6 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                pageNum: 1,
                pageSize: 10,
                type:1
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            zbr: false,
            xbr: false,
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.jaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.jaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleJaChange(e, type) {
            console.log(this.selectRow.jaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.jaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxaChange(e, type) {
            console.log(this.selectRow.xaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.xaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            // console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.kjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.xtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleCA(row) {
            this.scCa = true;
            this.selectRow = row;
        },
        handleYT(row) {
            this.scYt = true;
            this.selectRow = row;
        },
        handleGA(row) {
            this.scGa = true;
            this.selectRow = row;
            if (this.selectRow.coverUrl) {

            } else {
                this.selectRow.coverUrl = ''
            }
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        hadleDz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status == 4 || this.selectObj.status == 5) {
                this.$message.error("发布和停用的数据不能铺设");
                return;
            }
            print({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.getList();
                } else {
                    this.$message.error("铺设失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            lessonPageLessons(this.queryParams).then(res => {
                // if (this.queryParams.periodKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.periodKey == this.queryParams.periodKey
                //     })
                // }
                // if (this.queryParams.gradeKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.gradeKey == this.queryParams.gradeKey
                //     })
                // }
                // if (this.queryParams.subjectKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.subjectKey == this.queryParams.subjectKey
                //     })
                // }
                // if (this.queryParams.status !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.status == this.queryParams.status
                //     })
                // }
                // if (this.queryParams.title !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.title.toString().indexOf(this.queryParams.title) != -1
                //     })
                // }
                this.total = res.data.data.total;

                res.data.data.list.forEach(e => {
                    var textList = [];
                    e.teamUserList.forEach(j => {
                        textList.push(j.trueName)
                    })
                    console.log(textList)
                    e.teamUserText = textList.join('、')
                    var guideUserList = []
                    e.guideUserList.forEach(j => {
                        guideUserList.push(j.trueName)
                    })
                    e.guideUserText = guideUserList.join('、')
                    console.log(e.teamUserText)
                    e.jaFiles.forEach(m => {
                        m.name = m.fileName
                        m.url = m.fileUrl
                    })
                    e.xaFiles.forEach(m => {
                        m.name = m.fileName
                        m.url = m.fileUrl
                    })
                    e.kjFiles.forEach(m => {
                        m.name = m.fileName
                        m.url = m.fileUrl
                    })
                    e.xtFiles.forEach(m => {
                        m.name = m.fileName
                        m.url = m.fileUrl
                    })
                    // e.ytjaFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.ytxaFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.ytkjFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.ytxtFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.gajaFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.gaxaFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.gakjFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    // e.gaxtFiles.forEach(m => {
                    //     m.name = m.fileName
                    //     m.url = m.fileUrl
                    // })
                    if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                        this.selectRow = e
                    }
                })
                var userId = JSON.parse(this.$storage.get("userInfo")).userId
                this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                this.dataList.forEach(e => {
                    // e.caFileList = ((e.jaFiles.concat(e.kjFiles)).concat(e.xtFiles)).concat(e.xaFiles)
                    // e.gaFileList = ((e.gajaFiles.concat(e.gakjFiles)).concat(e.gaxtFiles)).concat(e.gaxaFiles)
                    // e.ytFileList = ((e.ytjaFiles.concat(e.ytkjFiles)).concat(e.ytxtFiles)).concat(e.ytxaFiles)
                    e.isteamUser = false;
                    e.teamUserList.forEach(j => {
                        if (j.userId == userId) {
                            e.isteamUser = true;
                        }
                    })
                })
                console.log(this.selectRow)
                this.loading = false;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            delLesson({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/lessonDetail",
                query: {
                    lessonId: row.lessonId,
                    isMyLess: 1
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/lessonAdd",
                query: { isMyLess: 1 }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleRelease(row) {
            // this.selectRow = row;
            // this.ckPeople = true;
            lessonRelease({ lessonId: row.lessonId, viewUsers: row.leadUser }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            // if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
            //     this.$message.error("请先上传文件");
            //     return
            // }
            if (row.status == 3) {
                if (row.ytjaFiles.length == 0 && row.ytkjFiles.length == 0 && row.ytxtFiles.length == 0) {
                    this.$message.error("请先上传修正文件");
                    return
                }
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("开始成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            lessonStop({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/lessonAdd",
                query: {
                    lessonId: row.lessonId,
                    isMyLess: 1
                }
            });
        }
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}
</style>