import request from "@/utils/request";
// 添加专家标签（expert_tag）
export const expertAddExpertTag =(query) =>{
    return request({
        url: "/businessservice/expert/addExpertTag",
        method:"get",
        params: query
    })
}
// 专家黑名单列表
export const expertBlackList =(query) =>{
    return request({
        url: "/businessservice/expert/blackList",
        method:"get",
        params: query
    })
}
// 加入或取消黑名单
export const expertBlackCancel =(query) =>{
    return request({
        url: "/businessservice/expert/blackOrCancel",
        method:"get",
        params: query
    })
}
// 新增专家
export const expertCreate =(data) =>{
    return request({
        url: "/businessservice/expert/create",
        method:"post",
        data: data
    })
}
// 删除专家
export const expertDelete =(query) =>{
    return request({
        url: "/businessservice/expert/delete",
        method:"get",
        params: query
    })
}
// 删除专家成果
export const expertDeleteAchieve =(query) =>{
    return request({
        url: "/businessservice/expert/deleteAchieve",
        method:"get",
        params: query
    })
}
// 专家详情
export const expertInfo =(query) =>{
    return request({
        url: "/businessservice/expert/info",
        method:"get",
        params: query
    })
}
// 分页查询专家
export const expertPage =(data) =>{
    return request({
        url: "/businessservice/expert/page",
        method:"post",
        data: data
    })
}
// 查询全部专家列表
export const expertListAll =(query) =>{
    return request({
        url: "/businessservice/expert/listAll",
        method:"get",
        params: query
    })
}
// 更新专家
export const expertUpdate =(data) =>{
    return request({
        url: "/businessservice/expert/update",
        method:"post",
        data: data
    })
}
// 上传证书文件
export const expertUploadFile =(data) =>{
    return request({
        url: "/businessservice/expert/uploadFile",
        method:"post",
        data: data
    })
}
// 随机抽取专家
export const expertRandomExpert =(query) =>{
    return request({
        url: "/businessservice/expert/randomExpert",
        method:"get",
        params: query
    })
}
export const allList = [
    {
        smTit: '第一部分:专家信息',
        smId: 'part1',
        smList: [
            { topCategory: 1, secondCategory: 0, key: 'expertAvatar', value: '', title: '专家头像' },
            { topCategory: 1, secondCategory: 1, key: 'expertName', value: '', title: '姓名' },
            { topCategory: 1, secondCategory: 2, key: 'gender', value: '', title: '性别' },
            { topCategory: 1, secondCategory: 3, key: 'birthDate', value: '', title: '出生日期' },
            { topCategory: 1, secondCategory: 4, key: 'nation', value: '', title: '民族' },
            { topCategory: 1, secondCategory: 5, key: 'politicalStatus', value: '', title: '政治面貌' },
            { topCategory: 1, secondCategory: 6, key: 'periodSubjectList', value: [], title: '学段/学科' },
            // { topCategory: 1, secondCategory: 7, key: 'period', value: '', title: '学科' },
            { topCategory: 1, secondCategory: 7, key: 'qualification', value: '', title: '最高学历' },
            { topCategory: 1, secondCategory: 8, key: 'workUnit', value: '', title: '工作单位' },
            { topCategory: 1, secondCategory: 9, key: 'isPayroll', value: '', title: '是否在编' },
            { topCategory: 1, secondCategory: 10, key: 'contactInfo', value: '', title: '联系方式' },
            { topCategory: 1, secondCategory: 11, key: 'rankInfo', value: '', title: '专业技术职称' },
            { topCategory: 1, secondCategory: 12, key: 'assessDate', value: '', title: '评定时间' },
            { topCategory: 1, secondCategory: 13, key: 'orgName', value: '', title: '颁发机构' },
            { topCategory: 1, secondCategory: 14, key: 'expertIntro', value: '', title: '专家简介' },
            { topCategory: 1, secondCategory: 15, key: 'expertTag', value: [], title: '特征标签' },
            // { topCategory: 1, secondCategory: 9, key: 'isSelect', value: '', title: '是否从事一线教学', optionList: ['正在从事一线教学', '目前未从事一线教学'] },
        ]
    },
    {
        smTit: '第二部分:专家研究成果',
        smId: 'part2',
        smList: [
            {
                topCategory: 2, secondCategory: 1, key: '', tsList: [
                    { key: 'achieveName', title: '成果名称' },
                    { key: 'isTopic', title: '是否课题类成果' },
                    { key: 'contentType', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'role', title: '承担角色' },
                    { key: 'achieveLevel', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'prizeTime', title: '获奖时间' },
                    { key: 'authOrg', title: '认定机构', optionName: 'award_org' },
                    { key: 'signUnit', title: '证明材料签章单位' },
                    { key: 'achieveCertList', title: '证书' },
                ], value: [], title: '参加工作以来，教育教学成果获奖', bgTit: '教育教学成果获奖', bgShow: true
            },
        ]
    },
    
]