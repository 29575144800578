/*=================服务地址===================*/
// 本地环境
// export const  serviceUrl = "http://localhost:8200"
// export const  serviceWebUrl = "http://localhost:8201"

// 测试环境
// export const  serviceUrl = "http://39.101.177.240:8200"
// export const  serviceWebUrl = "http://39.101.177.240:8201"

// 开发环境
export const  serviceUrl = "https://www.liyunjy.com"
export const  serviceWebUrl = "https://www.liyunjy.com"


/*===========OSS配置===========*/
let OSS = require('ali-oss');
export let ossClient = new OSS({
    region: 'oss-cn-beijing',
    accessKeyId: 'LTAI5tCqpy6gH2Kfn5F7mS7H',
    accessKeySecret: 'aVdDcMLLrcWYW5WBePRglyd3oqNIF1',
    bucket: 'free-back'
})
