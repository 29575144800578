<!--协同备课、听评课数量时间分布图-->
<template>
    <div class="my-charts">
        <div :id="id"></div>
    </div>
</template>


<script>
import * as echarts from "echarts";

let lineChart = null;

export default {
    name: "yearLineEcharts",
    props: {
        id:{
            type: String,
            default:"year_line_echarts"
        },
        yearCode:{
            type: String,
            default: "202X"
        },
        echartsTitle:{
            type: String,
            default: "数据统计"
        },
        monthList:{
            type: Array,
            default:[]
        },
        oneCountList:{
            type: Array,
            default:[]
        },
        twoCountList:{
            type: Array,
            default:[]
        },
        statsType:{
            type: String,
            default:"0"
        },
    },
    watch:{
        id(newValue,oldValue){
            this.id = newValue;
            this.init()
        },
        yearCode(newValue,oldValue){
            this.yearCode = newValue;
            this.init()
        },
        echartsTitle(newValue,oldValue){
            this.echartsTitle = newValue;
            this.init()
        },
        monthList(newValue,oldValue){
            this.monthList = newValue;
            this.init()
        },
        oneCountList(newValue,oldValue){
            this.oneCountList = newValue;
            this.init()
        },
        twoCountList(newValue,oldValue){
            this.twoCountList = newValue;
            this.init()
        },
        statsType(newValue,oldValue){
            this.statsType = newValue;
            this.init()
        },
    },
    data() {
        return {
            chartOption:{
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                },
                color: [
                    '#ee6666',
                    '#91cc75',
                    '#3ba272',
                    '#fc8452',
                    '#9a60b4',
                    '#ea7ccc',
                ],
                legend: {
                    data: ['协同备课', '个人备课'],
                    right:'1%'
                },
                grid: {
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '个人备课',
                        type: 'line',
                        data: []
                    },
                    {
                        name: '协同备课',
                        type: 'line',
                        data: []
                    }
                ]
            }
        };
    },
    watch: {},
    computed: {},
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        init() {

            lineChart = echarts.init(document.getElementById(this.id));
            if (this.statsType == 'prepare_lesson'){
                this.chartOption.legend.data = ['协同备课', '个人备课']
                this.chartOption.series[0].name = '个人备课'
                this.chartOption.series[1].name = '协同备课'
            }
            if (this.statsType == 'eva_lesson'){
                this.chartOption.legend.data = ['线上听评', '推门听评']
                this.chartOption.series[0].name = '线上听评'
                this.chartOption.series[1].name = '推门听评'
            }

            this.chartOption.xAxis.data = this.monthList;
            this.chartOption.series[0].data = this.oneCountList;
            this.chartOption.series[1].data = this.twoCountList;
            this.chartOption.title.text = this.yearCode+'年'+this.echartsTitle+'时间分布';
            // this.chartOption.title.text = this.title+'时间分布';
            this.chartOption&&lineChart.setOption(this.chartOption);
        }
    }
};
</script>

<style scoped lang="scss">
.my-charts {
    flex-direction: column;
    padding: 10px;
    box-shadow: var(--el-box-shadow-light);
    div {
        width: 100%;
        height: 360px;
    }
}
</style>