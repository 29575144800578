<template>
    <div class="base-container" style="overflow-y: hidden!important;">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isSzpxStatistics == 1"><a>师资培训统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-else><a>师资培训</a></el-breadcrumb-item>
                <el-breadcrumb-item>培训任务详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="content-box" >
            <div class="eva-left-box">
                <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
                    <el-descriptions class="detailFF" title="基本信息" />
                    <el-row>
                        <el-col :span="24">
                            <el-form-item  class="avatar-uploader fs14"  label-width="100px" label="任务封面" prop="coverUrl">
                                <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" alt=""/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="100px" label="培训标题" prop="title">
                                {{ form.title }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="100px" label="培训类型" prop="">
                                <span v-if="form.type === 0">直播培训</span>
                                <span v-if="form.type === 1">录播培训[课程]</span>
                                <span v-if="form.type === 2">现场培训</span>
                                <span v-if="form.type === 3">录播培训[视频]</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item class="fs14" label-width="100px" label="培训性质" prop="">
                                <span v-if="form.trainNature === '1'">普通</span>
                                <span v-if="form.trainNature === '2'">名师工作室</span>
                                <span v-if="form.trainNature === '3'">名校长工作室</span>
                                <span v-if="form.trainNature === '4'">群组任务</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" v-if="form.trainLevelName">
                            <el-form-item class="fs14" label-width="100px" label="培训级别" prop="" >
                                {{ form.trainLevelName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item class="fs14" label-width="100px" label="任务时段" prop="" >
                                {{ timestampToTime(form.beginTime) }}至{{timestampToTime(form.endTime) }}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="8">
                            <el-form-item v-if="form.type === 2 && form.signInBeginTime" class="fs14" label-width="100px" label="签到时间" prop="" >
                                {{timestampToDate(form.signInBeginTime) }}至{{ timestampToDate(form.signInEndTime) }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item v-if="form.type === 2 && form.signOutBeginTime" class="fs14" label-width="100px" label="签退时间" prop="" >
                                {{timestampToDate(form.signOutBeginTime) }}至{{ timestampToDate(form.signOutEndTime)}}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="8" v-if="form.type === 2 && form.secondSignInBeginTime && form.signCount === 4">
                            <el-form-item class="fs14" label-width="100px" label="二次签到时间" prop="" >
                                {{ timestampToDate(form.secondSignInBeginTime) }}至{{timestampToDate(form.secondSignInEndTime) }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.type === 2 && form.secondSignOutBeginTime && form.signCount === 4">
                            <el-form-item class="fs14" label-width="100px" label="二次签退时间" prop="" >
                                {{ timestampToDate(form.secondSignOutBeginTime) }}至{{timestampToDate(form.secondSignOutEndTime) }}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="8" v-if="form.groupName">
                            <el-form-item class="fs14" label-width="100px" label="关联群组" prop="" >
                                {{ form.groupName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.type === 2 && form.signTotalCount">
                            <el-form-item class="fs14" label-width="100px" label="签到总次数" prop="" >
                                {{ form.signTotalCount }}
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item v-if="form.type === 2 && form.signInAddress" class="fs14" label-width="100px" label="签到地址" prop="" >
                        {{ form.signInAddress }}
                    </el-form-item>
                    <el-row>
                        <el-col :span="8" v-if="form.leadTeacherNames">
                            <el-form-item class="fs14" label-width="100px" label="班主任" prop="" >
                                <expendText :text="form.leadTeacherNames"></expendText>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.viceLeadTeacherNames">
                            <el-form-item class="fs14" label-width="100px" label="副班主任" prop="" >
                                <expendText :text="form.viceLeadTeacherNames"></expendText>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item class="fs14" label-width="100px" label="参与人员" prop="" v-if="form.joinUsersText">
                        <expendText :text="form.joinUsersText"></expendText>
                    </el-form-item>
                    <el-row v-if="form.type === 1">
                        <el-col :span="24">
                            <el-form-item class="fs14" label-width="100px" label="培训课程" prop="linkUrl">
                                <div @click="handleLookVideo(item)" v-for="item in form.courseList" style="color: #409efe;"
                                     class="cursor">
                                    {{ item.courseName }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="form.type === 0">
                        <el-col :span="24">
                            <el-form-item label-width="100px" class="fs14" label="线上地址" prop="linkUrl">
                                <el-button @click="goTk" type="primary" size="mini" icon="">去培训
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="form.type === 3">
                        <el-col :span="24">
                            <el-form-item label-width="100px" class="fs14" label="视频地址" prop="videoUrl">
                                <el-button @click="goVideo" type="primary" size="mini" icon="">去培训
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item class="fs14" label-width="100px" label="培训内容" prop="content">
                        {{ form.content }}
                    </el-form-item>
                    <el-col :span="12" v-if="form.scaleList && form.scaleList.length !== 0">
                        <el-form-item class="fs14" label-width="100px" label="培训量表" prop="scaleList">
                            <div v-for="item in form.scaleList" style="color: #409efe;width: 100%;">
                                <div class="cursor" @click="preview(item)">{{ item.title }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-row v-if="form.fileList && form.fileList.length !== 0">
                        <el-col :span="24">
                            <el-form-item class="fs14" label-width="100px" label="培训附件" prop="">
                                <div @click="xz(item)" v-for="item in form.fileList" class="cursor mb10">
                                    <div class="cursor" style="color: #409efe">{{ item.fileName }}</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" v-if="isShow && form.status !== 2">
                            <el-form-item class="fs14" label-width="100px" label="邀请二维码" prop="" >
                                <div style="position: relative;" class="csbbb">
                                    <img alt="" @click.stop="handleDownload"
                                         title="下载" src="../../assets/icon/img/download.png" class="eicon"
                                         style="width:15px;height:15px;right: 20px;top: 5px;cursor: pointer;z-index: 99;"/>
                                    <vueQr ref="dyplg" :text="qrCode.shareUrl"
                                           :size="qrCode.size"
                                           :margin="qrCode.margin" />
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="form.type === 2">
                            <el-form-item class="fs14" label-width="100px" label="签到范围(m)" prop="signInDistance">
                                {{ form.signInDistance }}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="" v-show="form.type === 2">
                        <aMap isDetail v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter"
                              :isShowAMap="true">
                        </aMap>
                    </el-form-item>
                </el-card>
            </div>
            <div class="eva-right-box" v-show="form.isComment==1">
                <div class="discuss-title-box">
                    &nbsp;&nbsp;师资培训交流区
                </div>
                <div class="discuss-sub-box" >
                    <div class="discuss-info">
                        <el-input
                          type="textarea"
                          @focus="focusInput"
                          resize="none"
                          placeholder="请输入讨论内容"
                          v-model="discussWordContent"
                          autofocus
                          class="discuss-info-input"
                        >
                        </el-input>
                    </div>
                    <div class="discuss-btn-box">
                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/train/discussAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadFileSuccess"
                                       :headers="{ token }"
                                       accept=".doc,.docx,.ppt,.pptx,.pdf"
                                       :before-upload="beforeFileUpload">

                                <img class="icon-upload-img" alt="上传附件" src="../../assets/icon/img/upload_file1.png">
                            </el-upload>
                        </div>

                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/train/discussAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadImageSuccess"
                                       :headers="{ token }"
                                       accept=".png,.jpeg,.jpg"
                                       :before-upload="beforeImageUpload">
                                <img class="icon-upload-img" alt="上传图片" src="../../assets/icon/img/upload_img1.png">
                            </el-upload>
                        </div>
                        <el-button type="primary" style="margin-left: 120px;" round @click="submitDiscuss">
                            发表
                        </el-button>
                    </div>
                </div>
                <el-scrollbar class="discuss-show-box">
                    <div class="discuss-one" v-for="item in discussList">
                        <div class="discuss-user-avatar">
                            <img alt="无" @error="onImageError" :src="item.avatar">
                        </div>
                        <div class="discuss-user-content">
                            <div class="user-info">
                                <div class="flex-item user-true-name">{{item.createUserName}}</div>
                                <div class="flex-item user-role">
                                    <div class="role2" v-show="item.roleType===0">参与人</div>
                                </div>
                                <div class="flex-item content-time">{{timestampToTime(item.createTime)}}</div>
                            </div>
                            <div class="content-info" v-if="item.discussType===1">
                                {{item.content}}
                            </div>
                            <div class="img-info" v-if="item.discussType===2">
                                <el-image alt="" :src="item.imgContent"
                                          :preview-src-list="item.imgList" ></el-image>
                            </div>
                            <div class="audio-info" v-if="item.discussType===3">
                                <audio controls controlslist="nodownload noplaybackrate" ref="audio">
                                    <source type="audio/mpeg" :src="item.audioContent" />
                                </audio>
                            </div>
                            <div class="file-info" v-if="item.discussType===4">
                                <div class="file-box">
                                    <div class="file-icon">
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-pdf@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('doc',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-doc@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('ppt',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-ppt@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleUnKnow" class="icon-image" v-if="!comStr('ppt',item.file.fileType)&&!comStr('doc',item.file.fileType)&&!comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-unknown@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                    </div>
                                    <div class="file-detail">
                                        <div class="name-box" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">{{item.file.fileName}}</div>
                                        <div class="size-box">
                                            <div class="size-num">{{item.file.fileSize}}</div>
                                            <div class="file-view">
                                                <img alt="" src="@/assets/icon/img/download.png" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="discussList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>

        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                  : ''
                            }}]</span><span v-if="item.type == 4 && item.totalScore"
                                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                                item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                            }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                    item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                      '文字内容'
                                      : ''
                                }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                    item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
        <el-dialog @close="gbIfram" :title="'去培训'" v-model="isTk" width="80%" top="50px">
            <iframe id="ckpxspfx" style="width: 100%;height:calc(100vh - 250px)" :src="form.linkUrl"
                    frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { timestampToTime, timestampToDate } from "@/utils/date";
import aMap from '@/components/aMap/index' //引入封装好的组件
import { queryDict, userList } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import {rateScaleInfo} from "@/api/admin/evaluation";
import { trainCreate, trainUpdate, trainInfo, trainDeleteFile ,
    listTrainDiscuss,submitTrainDiscuss} from "@/api/admin/train";
import html2canvas from "html2canvas";
import {ElLoading} from "element-plus";
import {serviceUrl,serviceWebUrl} from "@/utils/global";

export default {
    name: "demoAdd",
    components: {
        aMap,
        vueQr
    },
    data() {
        return {
            serviceUrl,
            timestampToTime,
            timestampToDate,
            fileList: [],
            toParentsMap: {},
            isShow: false,
            oldCenter: [],
            trainId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                coverUrl: '',
                type: 0,
                title: '',
                joinUsersList: [],
                signInBeginTime: '',
                signInEndTime: '',
                signOutBeginTime: '',
                signOutEndTime: '',
                signInAddress: '',
                signInDistance: 10,
                trainLevel: '',
                linkUrl: '',
                beginTime: '',
                endTime: '',
                content: ''
            },
            // 表单校验
            rules: {},
            delFileList: [],
            countyList: [],
            schoolList: [],
            previewData: {},
            previewShow: false,
            isSzpxStatistics: 0,
            isTk: false,
            qrCode: {
                shareUrl: '',
                size: 120,
                margin:10,
            },

            discussType:1, //研讨内容类型：1-文字 2-图片 3-音频 4-附件
            discussWordContent:'',
            wordContent: '',//文字评论
            imgContent: '',//图片评论
            fileContent: '',//附件评论
            discussList:[],
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.trainId) {
            this.trainId = this.$route.query.trainId
            this.qrCode.shareUrl = serviceWebUrl+'/inviteMember?id=' + this.trainId + '&type=3'
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isSzpxStatistics) {
            this.isSzpxStatistics = this.$route.query.isSzpxStatistics
        }
    },
    unmounted() {
        if (this.trainId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        trainDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    trainDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        handleDownload() {
            console.log(this.$refs.dyplg);
            html2canvas(this.$refs.dyplg.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-邀请二维码';
                // // 下载
                eleLink.click();
            })
        },
        goTk() {
            this.isTk = true;
            setTimeout(() => {
                var iframe = document.getElementById("ckpxspfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", this.form.linkUrl);
            })
        },
        goVideo() {
            this.isTk = true;
            setTimeout(() => {
                var iframe = document.getElementById("ckpxspfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", this.form.videoUrl);
            })
        },
        gbIfram() {
            var iframe = document.getElementById("ckpxspfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        handleLink(row) {
            window.open(row)
            // window.location.href=row;
        },
        handleLookVideo(row) {
            this.$router.push({
                path: "/trainCourseLookVideo",
                query: {
                    courseId: row.courseId,
                    trainId: this.trainId,
                    isLook: 1,
                    isTraVourse: 1
                }
            });
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'topic_level' }).then(resb => {
                this.lessonLevelList = resb.data.data;
                trainInfo({ trainId: this.trainId }).then(res => {
                    this.form = res.data.data;
                    this.lessonLevelList.forEach(e => {
                        if (e.dictKey == this.form.trainLevel) {
                            this.form.trainLevelName = e.dictValue
                        }
                    })

                    //班主任
                    const textLeadTeacherList = [];
                    this.form.leadTeacherList.forEach(e => {
                        textLeadTeacherList.push(e.trueName)
                    })
                    this.form.leadTeacherNames = textLeadTeacherList.join('、')
                    //副班主任
                    const textViceLeadTeacherList = [];
                    this.form.viceLeadTeacherList.forEach(e => {
                        textViceLeadTeacherList.push(e.trueName)
                    })
                    this.form.viceLeadTeacherNames = textViceLeadTeacherList.join('、')
                    //参与人
                    const textList = [];
                    this.form.joinUserList.forEach(e => {
                        textList.push(e.trueName)
                    })
                    this.form.joinUsersText = textList.join('、')
                    if (this.form.type === 2) {
                        this.oldCenter = [this.form.lng, this.form.lat]
                    }
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.isShow = true;
                    if (this.form.type === 2) {
                        setTimeout(() => {
                            this.$refs.zdyMap.center = this.oldCenter;
                            this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                        }, 100)
                    }
                    this.getDisList()
                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            // queryDict({ code: 'topic_level' }).then(res => {
            //     this.lessonLevelList = res.data.data;
            // })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.form.joinUsers = this.form.joinUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    delete obj.joinUsersList

                    if (this.trainId) {
                        trainUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/train', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        trainCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/train', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isSzpxStatistics == 1) {
                this.replace('/szpxStatistics')
            } else {
                this.replace('/train')
            }
            // this.$emit('isTg', true)
        },
        onImageError(event){
            event.target.src = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/load_fail_default.png';
        },
        handleUploadImageSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.imgContent = uploadFile.response.data;
                this.discussType = 2
                this.submitDiscuss()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeImageUpload(rawFile){
            if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleUploadFileSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.fileContent = uploadFile.response.data;
                this.discussType = 4
                this.submitDiscuss()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeFileUpload(rawFile){
            return true
        },
        focusInput(){
            this.closeInput = false
        },
        handleFIleStatus(status){
            if (status === 1) {

            }else {
                this.$message.info("文件正在处理，请稍后刷新预览")
            }
        },
        handleUnKnow(){
            this.$message.info("该文件类型暂不支持预览")
        },
        comStr(baseStr,type){
            return type!==''&& type.toUpperCase().includes(baseStr.toUpperCase());
        },
        gainFileImgList(baseUrl,page,){
            const list = [];
            for (let i = 1; i <=page ; i++) {
                list.push(baseUrl+'/' + i+'.png');
            }
            return list;
        },
        getDisList() {
            listTrainDiscuss({ trainId: this.trainId }).then(res => {
                this.discussList = res.data.data;
            })
        },
        submitDiscuss(){
            const loading = ElLoading.service({ fullscreen: true })
            const obj = {
                trainId: this.trainId,
                content: this.wordContent,
                imgContent: this.imgContent,
                fileContent: this.fileContent,
            };
            //文字评论
            if (this.discussType===1){
                this.wordContent = this.discussWordContent
                if (this.wordContent===''){
                    this.$message.info("讨论内容不能为空")
                    return
                }
                obj.content=this.wordContent;
                obj.imgContent = '';
                obj.fileContent = ''
            }
            //图片评论
            if (this.discussType===2){
                if (this.imgContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = this.imgContent;
                obj.fileContent = ''
            }
            //附件评论
            if (this.discussType===4){
                if (this.fileContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = '';
                obj.fileContent = this.fileContent
            }
            submitTrainDiscuss(obj).then(res =>{
                if (res.data.code === 1) {
                    this.$message.success("发表成功");
                    this.wordContent = ''
                    this.discussWordContent = ''
                    this.imgContent = ''
                    this.fileContent = ''
                    this.discussType = 1
                    this.getDisList();
                } else {
                    this.$message.error("操作失败")
                    this.discussType = 1
                }
            })
            loading.close();
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}


.base-container {
    padding: 0 0 0 0;
    overflow-y: hidden!important;
}
.el-main{
    overflow-y: hidden!important;
}


.content-box{
    height: calc(100vh - 10px);
    display: flex;
    .eva-left-box{
        padding: 15px 0 15px 0;
        flex: 1;
    }
    .eva-right-box{
        padding: 0 5px 15px 5px;
        width: 260px;
        height: 90%;
        margin-left: 10px;
        margin-top: 15px;
    }
}

.discuss-title-box{
    height: 30px;
    line-height: 30px;
    background-color: rgba(255,255,255,.9);
}

.discuss-sub-box{
    width: 100%;
    background-color: rgba(255,255,255,.9);
    //border-radius: 8px;
    padding: 10px 10px 0 8px;
    height: 90px;
    .discuss-info{
        display: inline-block;
        width: 250px;
        >>>.el-textarea__inner::placeholder{
            font-size: 12px;
        }
        >>>.el-textarea__inner{
            font-size: 12px;
        }
    }

    .discuss-btn-box{
        display: flex;
        padding: 0 6px;
        margin-top: 10px;
        .el-button--primary{
            width: 56px;
            height: 26px;
            font-size: 12px;
            line-height: 30px!important;
            text-align: center;
            &:hover{
                background-color: #1f89de;
            }
        }
        .limit{
            width: 26px;
            height: 26px;
            background-color: #1f89de;
            font-size: 12px;
            color: #999aaa;
            border-radius: 13px;
            margin-right: 10px;
            .icon-upload-img{
                width: 20px;
                height: 20px;
                margin: 3px 0 0 3px;
            }
        }
    }
}

.discuss-show-box{
    width: 260px!important;
    margin: auto;
    height: calc(100vh - 280px)!important;
    padding: 10px 0 0 0;
    background-color: rgba(255,255,255,.9);
}
/deep/ .el-scrollbar__thumb {
    background-color: #ec623e;
}
/deep/.el-scrollbar__wrap {
    overflow-x: hidden;
}
.discuss-one{
    display: flex;
    padding-bottom: 10px;
    padding-left: 5px;
    background-color: rgba(255,255,255,.9);
    .discuss-user-avatar{
        width: 40px;
        img{
            width: 30px!important;
            height: 30px;
            border-radius: 15px!important;
        }
    }
    .discuss-user-content{
        width: 220px;
        font-size: 14px;
        .user-info{
            display: flex;
            height: 25px;
            padding-right: 10px;
            .flex-item:last-child{
                margin-left: auto;
            }
            .user-true-name{
                font-size: 12px;
                //font-weight: bold;
                //width: 40px;
                height: 25px;
                line-height: 25px;
                text-align: left;
                margin-right: 10px;
                align-content: flex-start;
            }
            .user-role{
                font-size: 10px;
                div{
                    text-align: center;
                }
                .role1{
                    padding: 1px 4px 1px 4px;
                    background-color: #1f89de;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #FFFFFF;
                }
                .role2{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1f1f1;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #666666;
                }
                .role3{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1a792;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #1f89de;
                }
            }
            .content-time{
                line-height: 25px;
                font-size: 12px;
                align-content: flex-end;
            }
        }
        .content-info{
            padding: 2px 10px 0 0;
            font-size: 11px;
            color: #525B68;
            line-height: 16px;
            word-wrap:break-word;
            word-break:break-all;

        }
        .img-info{
            padding: 2px 10px 0 0;
            display: flex;
            width: 180px;
            flex-wrap: wrap;
            .el-image{
                width: 120px;
                height: auto;
                margin: 0 5px 5px 0;
            }
        }
        .audio-info{
            padding: 2px 10px 0 0;
            audio{
                width: 210px;
                height: 40px;
            }
        }
        .file-info{
            padding: 2px 10px 0 0;
            .file-box{
                width: 210px;
                height: 44px;
                background-color: #f1f3f4;
                border-radius: 5px;
                display: flex;
                .file-icon{
                    width: 40px;
                    height: 40px;
                    img{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                    .icon-image{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                }
                .file-detail{
                    width: 160px;
                    height: 40px;
                    padding: 2px 0 2px 0;
                    div{
                        width: 160px;
                        height: 20px;
                    }
                    .name-box{
                        line-height: 24px;
                        font-size: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        &:hover{
                            color: #1f89de;
                        }
                    }
                    .size-box{
                        display: flex;
                        font-size: 10px;
                        line-height: 20px;
                        .file-view{
                            width: 15px;
                            height: 15px;
                            //background-color: #1f89de;
                            border-radius: 5px;
                            margin-top: 5px;
                            img{
                                width: 13px;
                                height: 13px;
                                margin: 1px 0 0 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }

}


.eva-btn{
    height: 26px!important;
    width: 60px!important;
    font-size: 12px;
    >>>.el-button--primary{
        font-size: 12px;
        text-align: center;
    }
}

.avatar-uploader /deep/ .el-upload {
    border: none!important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.csbbb1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //padding: 0 5px;
    position: relative;
}



</style>