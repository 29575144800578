import request from "@/utils/request";
// 删除师资培训
export const trainDel = (query) => {
    return request({
        url: "/businessservice/train/delete",
        method: "get",
        params: query
    })
}
// 删除师资培训附件
export const trainDeleteFile = (query) => {
    return request({
        url: "/businessservice/train/deleteFile",
        method: "get",
        params: query
    })
}
// 查询师资培训详情
export const trainInfo = (query) => {
    return request({
        url: "/businessservice/train/info",
        method: "get",
        params: query
    })
}
// 查询全部师资培训
export const trainListAll = (query) => {
    return request({
        url: "/businessservice/train/listAll",
        method: "get",
        params: query
    })
}
// 分页查询师资培训
export const trainPageTrain = (data) => {
    return request({
        url: "/businessservice/train/pageTrain",
        method: "post",
        data: data
    })
}
// 查询用户参与师资培训
export const trainListJoin = (query) => {
    return request({
        url: "/businessservice/train/listJoin",
        method: "get",
        params: query
    })
}
// 创建师资培训
export const trainCreate = (data) => {
    return request({
        url: "/businessservice/train/create",
        method: "post",
        data: data
    })
}
// 发布培训
export const trainRelease = (query) => {
    return request({
        url: "/businessservice/train/release",
        method: "get",
        params: query
    })
}
// 停止培训
export const trainStop = (query) => {
    return request({
        url: "/businessservice/train/stop",
        method: "get",
        params: query
    })
}
// 更新师资培训
export const trainUpdate = (data) => {
    return request({
        url: "/businessservice/train/update",
        method: "post",
        data: data
    })
}
// 上传师资培训封面
export const trainUploadCover = (data) => {
    return request({
        url: "/businessservice/train/uploadCover",
        method: "post",
        data: data
    })
}
// 上传师资培训附件
export const trainUploadFile = (data) => {
    return request({
        url: "/businessservice/train/uploadFile",
        method: "post",
        data: data
    })
}

// 创建师资培训视频库资源
export const libraryCreate = (data) => {
    return request({
        url: "/businessservice/train/library/create",
        method: "post",
        data: data
    })
}
// 删除师资培训视频库资源
export const libraryDel = (query) => {
    return request({
        url: "/businessservice/train/library/delete",
        method: "get",
        params: query
    })
}
// 查询师资培训视频库资源详情
export const libraryInfo = (query) => {
    return request({
        url: "/businessservice/train/library/info",
        method: "get",
        params: query
    })
}
// 查询师资培训视频库资源列表
export const libraryListAll = (query) => {
    return request({
        url: "/businessservice/train/library/listAll",
        method: "get",
        params: query
    })
}
// 更新师资培训视频库资源
export const libraryUpdate = (query) => {
    return request({
        url: "/businessservice/train/library/update",
        method: "get",
        params: query
    })
}

// 提交听评课讨论
export const submitTrainDiscuss =(data) =>{
    return request({
        url: "/businessservice/train/createDiscuss",
        method:"post",
        data: data
    })
}

export const listTrainDiscuss =(query) =>{
    return request({
        url: "/businessservice/train/listDiscuss",
        method:"get",
        params: query
    })
}