import request from "@/utils/request";
// 创建听评课
export const  evaluationCreate =(data) =>{
    return request({
        url: "/businessservice/evaluation/create",
        method:"post",
        data: data
    })
}
// 删除听评课
export const  evaluationDel =(query) =>{
    return request({
        url: "/businessservice/evaluation/delete",
        method:"get",
        params: query
    })
}
// 查询所有听评课列表
export const  evaluationListAll =(query) =>{
    return request({
        url: "/businessservice/evaluation/listAll",
        method:"get",
        params: query
    })
}
// 分页查询听评课
export const  evaluationPageLessons =(data) =>{
    return request({
        url: "/businessservice/evaluation/pageLessons",
        method:"post",
        data: data
    })
}
// 查询用户参与的听评课列表
export const  evaluationListJoin =(query) =>{
    return request({
        url: "/businessservice/evaluation/listJoin",
        method:"get",
        params: query
    })
}
// 查询听评课详细信息
export const  evaluationInfo =(query) =>{
    return request({
        url: "/businessservice/evaluation/info",
        method:"get",
        params: query
    })
}
// 发布听评课
export const  evaluationRelease =(query) =>{
    return request({
        url: "/businessservice/evaluation/release",
        method:"get",
        params: query
    })
}
// 停止听评课
export const  evaluationStop =(query) =>{
    return request({
        url: "/businessservice/evaluation/stop",
        method:"get",
        params: query
    })
}
// 提请听评课
export const  evaluationSubmit =(query) =>{
    return request({
        url: "/businessservice/evaluation/submit",
        method:"get",
        params: query
    })
}

// 更新听评课
export const  evaluationUpdate =(data) =>{
    return request({
        url: "/businessservice/evaluation/update",
        method:"post",
        data: data
    })
}

// 创建听评课量表
export const  rateScaleCreate =(data) =>{
    return request({
        url: "/businessservice/rateScale/create",
        method:"post",
        data: data
    })
}

// 删除听评课量表
export const  rateScaleDel =(query) =>{
    return request({
        url: "/businessservice/rateScale/delete",
        method:"get",
        params: query
    })
}

// 查询听评课量表详情
export const  rateScaleInfo =(query) =>{
    return request({
        url: "/businessservice/rateScale/info",
        method:"get",
        params: query
    })
}

// 查询听评课量表列表
export const  rateScaleList =(query) =>{
    return request({
        url: "/businessservice/rateScale/list",
        method:"get",
        params: query
    })
}
// 提交评分
export const  rateRecordScore =(data) =>{
    return request({
        url: "/businessservice/rateRecord/score",
        method:"post",
        data: data
    })
}
// 查询评分详情
export const rateRecordScoreInfo =(data) =>{
    return request({
        url: "/businessservice/rateRecord/scoreInfo",
        method:"post",
        data: data
    })
}

// 提交听评课讨论
export const submitEvaDiscuss =(data) =>{
    return request({
        url: "/businessservice/evaluation/createDiscuss",
        method:"post",
        data: data
    })
}

export const listEvaDiscuss =(query) =>{
    return request({
        url: "/businessservice/evaluation/listDiscuss",
        method:"get",
        params: query
    })
}

export const infoLessonSummary =(query) =>{
    return request({
        url: "/businessservice/summary/info",
        method:"get",
        params: query
    })
}

export const createOrUpdateLessonSummary =(data) =>{
    return request({
        url: "/businessservice/summary/createOrUpdate",
        method:"post",
        data: data
    })
}