<template>
    <div class="bg-kq">
        <div class="top-tit">大同市教师均衡发展驾驶舱</div>
        <el-row>
            <el-col :span="12">
                <el-row>
                    <el-col :span="20">
                        <div id="grbk" style="width: 100%;height: 550px;">
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="ct-bt">
                            <div class="smtit">学校数量
                                <div class="lvt col-328">{{ formData.schoolCount }}所</div>
                            </div>
                            <div class="smtit">教师数量
                                <div class="lvt col-47e">{{ formData.teacherCount }}人</div>
                            </div>
                            <div class="smtit">高级职称教师数量
                                <div class="lvt col-6e6">{{ formData.heighTeacherCount }}人</div>
                            </div>
                            <div class="smtit">骨干教师数量
                                <div class="lvt col-47e">{{ formData.boneTeacherCount }}人</div>
                            </div>
                            <div class="smtit">档案提交人数
                                <div class="lvt col-328">{{ formData.teacherList.length }}人</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="12">
                        <div class="txbox">
                            <div class="smtitbt">
                                小学学段不同学科高级职称覆盖率
                            </div>
                            <div id="xxlglOption" style="height: 333px;width: 100%;">

                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="txbox">
                            <div class="smtitbt">
                                小学学段不同学校新老教师占比
                            </div>
                            <div id="xxxlzbOption" style="height: 333px;width: 100%;">

                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="12">
                        <div class="txbox">
                            <div class="smtitbt">
                                初中学段不同学科高级职称覆盖率
                            </div>
                            <div id="czlglOption" style="height: 333px;width: 100%;">

                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="txbox">
                            <div class="smtitbt">
                                中学学段不同学校新老教师占比
                            </div>
                            <div id="zxxlzbOption" style="height: 333px;width: 100%;">

                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row>
                    <el-col :span="10">
                        <div class="txbox">
                            <div class="smtitbt">
                                教师概况
                            </div>
                            <el-row>
                                <el-col :span="8">
                                    <div style="width: 100%;" class="xbtobx">
                                        <div id="xbOption" style="width: 100%;height: 100px;">
                                        </div>
                                        <div class="xbNan">
                                            <div class="ml10">男</div>
                                            <div class="ml10">
                                                {{ (this.xbOption.series[0].data[0].value /
                                                    (this.xbOption.series[0].data[0].value +
                                                        this.xbOption.series[0].data[1].value)).toFixed(2) * 100 }}%
                                            </div>
                                        </div>
                                        <div class="xbNan xbNan2">
                                            <div class="ml10">女</div>
                                            <div class="ml10">
                                                {{ (this.xbOption.series[0].data[1].value /
                                                    (this.xbOption.series[0].data[0].value +
                                                        this.xbOption.series[0].data[1].value)).toFixed(2) * 100 }}%
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div style="width: 100%;" class="xbtobx">
                                        <div id="xlOption" style="width: 100%;height: 100px;">
                                        </div>
                                        <div class="xbNan">
                                            <div class="ml10">硕士及以上</div>
                                            <div class="ml10">
                                                {{ (xlOption.series[0].data[0].value / xlOption.total).toFixed(2) * 100 }}%
                                            </div>
                                        </div>
                                        <div class="xbNan xbNan2">
                                            <div class="ml10">本科</div>
                                            <div class="ml10">
                                                {{ (xlOption.series[0].data[1].value / xlOption.total).toFixed(2) * 100 }}%
                                            </div>
                                        </div>
                                        <div class="xbNan xbNan3">
                                            <div class="ml10">专科及以下</div>
                                            <div class="ml10">
                                                {{ (xlOption.series[0].data[2].value / xlOption.total).toFixed(2) * 100 }}%
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div style="width: 100%;" class="xbtobx">
                                        <div id="zcOption" style="width: 100%;height: 100px;">
                                        </div>
                                        <div class="xbNan">
                                            <div class="ml10">高级及以上</div>
                                            <div class="ml10">{{ (zcOption.series[0].data[0].value /
                                                zcOption.total).toFixed(2) * 100 }}%</div>
                                        </div>
                                        <div class="xbNan xbNan2">
                                            <div class="ml10">一级教师</div>
                                            <div class="ml10">{{ (zcOption.series[0].data[1].value /
                                                zcOption.total).toFixed(2) * 100 }}%</div>
                                        </div>
                                        <div class="xbNan xbNan3">
                                            <div class="ml10">二级教师</div>
                                            <div class="ml10">{{ (zcOption.series[0].data[2].value /
                                                zcOption.total).toFixed(2) * 100 }}%</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div class="txbox">
                            <div class="smtitbt">
                                初中学段不同学校高职称高学历教师占比
                            </div>
                            <div class="tb-top">
                                <div style="text-align: left;" class="text-one">
                                    学校名称
                                </div>
                                <div class="text-one">
                                    教师数量
                                </div>
                                <div class="text-one">
                                    高级职称占比(%)
                                </div>
                                <div class="text-one">
                                    骨干教师占比(%)
                                </div>
                                <div class="text-one">
                                    高学历占比(%)
                                </div>
                            </div>
                            <div class="tb-ct">
                                <div style="text-align: right;">
                                    平均值>
                                </div>
                                <div>
                                    {{ czJsPj.teacherNum }}
                                </div>
                                <div>
                                    {{ czJsPj.zgNum }}
                                </div>
                                <div>
                                    {{ czJsPj.backboneNum }}
                                </div>
                                <div>
                                    {{ czJsPj.qualiNum }}
                                </div>
                            </div>
                            <div class="tb-bt-box">
                                <div class="tb-bt" v-for="item in czJszbList">
                                    <div style="text-align: left;">
                                        {{ item.schoolName }}
                                    </div>
                                    <div>
                                        {{ item.teacherNum }}
                                    </div>
                                    <div>
                                        {{ item.zgNum ? item.zgNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.backboneNum ? item.backboneNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.qualiNum ? item.qualiNum : 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="10">
                        <div class="txbox">
                            <div class="smtitbt">
                                教师年龄段分布
                            </div>
                            <div style="display: flex; justify-content: space-around;" class="mt15">
                                <div @click="chooseXd('all')" :class="activeType == 'all' ? 'active' : ''"
                                    class="szbtn cursor">总体
                                </div>
                                <div @click="chooseXd('xx')" :class="activeType == 'xx' ? 'active' : ''"
                                    class="szbtn cursor">小学
                                </div>
                                <div @click="chooseXd('cz')" :class="activeType == 'cz' ? 'active' : ''"
                                    class="szbtn cursor">初中
                                </div>
                                <div @click="chooseXd('gz')" :class="activeType == 'gz' ? 'active' : ''"
                                    class="szbtn cursor">高中
                                </div>
                            </div>
                            <div id="jsnldOption" style="height: 377px;width:100%;">

                            </div>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div class="txbox">
                            <div class="smtitbt">
                                小学学段不同学校高职称高学历教师占比
                            </div>
                            <div class="tb-top">
                                <div style="text-align: left;" class="text-one">
                                    学校名称
                                </div>
                                <div class="text-one">
                                    教师数量
                                </div>
                                <div class="text-one">
                                    高级职称占比(%)
                                </div>
                                <div class="text-one">
                                    骨干教师占比(%)
                                </div>
                                <div class="text-one">
                                    高学历占比(%)
                                </div>
                            </div>
                            <div class="tb-ct">
                                <div style="text-align: right;">
                                    平均值>
                                </div>
                                <div>
                                    {{ xxJsPj.teacherNum }}
                                </div>
                                <div>
                                    {{ xxJsPj.zgNum }}
                                </div>
                                <div>
                                    {{ xxJsPj.backboneNum }}
                                </div>
                                <div>
                                    {{ xxJsPj.qualiNum }}
                                </div>
                            </div>
                            <div class="tb-bt-box">
                                <div class="tb-bt" v-for="item in xxJszbList">
                                    <div style="text-align: left;">
                                        {{ item.schoolName }}
                                    </div>
                                    <div>
                                        {{ item.teacherNum }}
                                    </div>
                                    <div>
                                        {{ item.zgNum ? item.zgNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.backboneNum ? item.backboneNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.qualiNum ? item.qualiNum : 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="10">
                        <div class="txbox">
                            <div class="smtitbt">
                                教师年教龄分布
                            </div>
                            <div style="display: flex; justify-content: space-around;" class="mt15">
                                <div @click="choosejsjldXd('all')" :class="activejsjldType == 'all' ? 'active' : ''"
                                    class="szbtn cursor">总体</div>
                                <div @click="choosejsjldXd('xx')" :class="activejsjldType == 'xx' ? 'active' : ''"
                                    class="szbtn cursor">小学</div>
                                <div @click="choosejsjldXd('cz')" :class="activejsjldType == 'cz' ? 'active' : ''"
                                    class="szbtn cursor">初中</div>
                                <div @click="choosejsjldXd('gz')" :class="activejsjldType == 'gz' ? 'active' : ''"
                                    class="szbtn cursor">高中</div>
                            </div>
                            <div id="jsjldOption" style="height: 290px;width:100%;">

                            </div>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div class="txbox">
                            <div class="smtitbt">
                                高中学段不同学校高职称高学历教师占比
                            </div>
                            <div class="tb-top">
                                <div style="text-align: left;" class="text-one">
                                    学校名称
                                </div>
                                <div class="text-one">
                                    教师数量
                                </div>
                                <div class="text-one">
                                    高级职称占比(%)
                                </div>
                                <div class="text-one">
                                    骨干教师占比(%)
                                </div>
                                <div class="text-one">
                                    高学历占比(%)
                                </div>
                            </div>
                            <div class="tb-ct">
                                <div style="text-align: right;">
                                    平均值>
                                </div>
                                <div>
                                    {{ gzJsPj.teacherNum }}
                                </div>
                                <div>
                                    {{ gzJsPj.zgNum }}
                                </div>
                                <div>
                                    {{ gzJsPj.backboneNum }}
                                </div>
                                <div>
                                    {{ gzJsPj.qualiNum }}
                                </div>
                            </div>
                            <div class="tb-bt-box" style="height: 270px;">
                                <div class="tb-bt" v-for="item in gzJszbList">
                                    <div style="text-align: left;">
                                        {{ item.schoolName }}
                                    </div>
                                    <div>
                                        {{ item.teacherNum }}
                                    </div>
                                    <div>
                                        {{ item.zgNum ? item.zgNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.backboneNum ? item.backboneNum : 0 }}
                                    </div>
                                    <div>
                                        {{ item.qualiNum ? item.qualiNum : 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import echarts from "echarts";
import { dtArea, teacherArchive } from '@/api/admin/archive';

export default {
    name: "fileStatistics",
    components: {
    },
    data() {
        return {
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [],
            formData: {
                teacherList: []
            },
            activeType: 'all',
            activejsjldType: 'all',
            xxJszbList: [],
            xxJsPj: {
                teacherNum: 0,
                zgNum: 0,
                backboneNum: 0,
                qualiNum: 0
            },
            czJszbList: [],
            czJsPj: {
                teacherNum: 0,
                zgNum: 0,
                backboneNum: 0,
                qualiNum: 0
            },
            gzJszbList: [],
            gzJsPj: {
                teacherNum: 0,
                zgNum: 0,
                backboneNum: 0,
                qualiNum: 0
            },
            dtArea: dtArea,
            xbOption: {
                title: {
                    text: '性别',
                    left: 'center',
                    top: '38%',
                    textStyle: {
                        fontSize: 14,
                        color: '#fff',
                        align: 'center'
                    },
                },
                color: ['#54a1ff', '#4cf3ff'],
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: [
                            { value: 0, name: '男' },
                            { value: 0, name: '女' },
                        ]
                    }
                ]
            },
            xlOption: {
                title: {
                    text: '学历',
                    left: 'center',
                    top: '38%',
                    textStyle: {
                        fontSize: 14,
                        color: '#fff',
                        align: 'center'
                    },
                },
                color: ['#54a1ff', '#4cf3ff', '#6d6af9'],
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: [
                            { value: 0, name: '硕士及以上' },
                            { value: 0, name: '本科' },
                            { value: 0, name: '专科及以下' },
                        ]
                    }
                ],
                total: 0
            },
            zcOption: {
                title: {
                    text: '职称',
                    left: 'center',
                    top: '38%',
                    textStyle: {
                        fontSize: 14,
                        color: '#fff',
                        align: 'center'
                    },
                },
                color: ['#54a1ff', '#4cf3ff', '#6d6af9'],
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: [
                            { value: 0, name: '高级及以上' },
                            { value: 0, name: '一级教师' },
                            { value: 0, name: '二级教师' },
                        ]
                    }
                ]
            },
            grbkOption: {
                tooltip: {
                    trigger: 'item',
                    showDelay: 0,
                    transitionDuration: 0.2
                },
                geo: [
                ],
                series: [
                    {
                        type: 'map',
                        // roam: true,
                        map: 'DATONG',
                        label: { show: true, color: '#fff' },
                        //地图块渐变颜色设置
                        itemStyle: {
                            normal: {
                                areaColor: {
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        { offset: 0, color: '#00b1fe' },
                                        { offset: 1, color: '#0344cf' },
                                    ],
                                },
                                borderColor: '#06b2ef',
                                borderWidth: 1,
                            },
                            emphasis: {
                                areaColor: '#01aefb',
                                borderColor: '#fff',
                                borderWidth: 1,
                                shadowColor: 'rgba(0, 255, 255, 0.7)',
                                shadowBlur: 10,
                                label: { show: false },
                            },
                        },
                        data: [
                            { name: 'Alabama', value: 4822023 },
                        ]
                    }
                ]
            },
            xxlglOption: {
                color: ['#5eb3ff', '#5dfeff'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                },
                legend: {
                    right: '0',
                    top: '15',
                    textStyle: { color: '#fff' }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    // splitLine:{
                    show: false
                    // }
                },
                yAxis: {
                    type: 'category',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    data: [],
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        name: '正高级教师',
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: '高级教师',
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            },
            xxxlzbOption: {
                color: ['#5695ff'],
                xAxis: {
                    name: '教龄五年内老师占比(%)',
                    nameTextStyle: {
                        align: 'right',
                        verticalAlign: 'top',
                        lineHeight: '60'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true
                    }
                },
                yAxis: {
                    nameTextStyle: {
                        align: 'left'
                    },
                    name: '5年内退休老师占比(%)',
                    // type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true
                    }
                },
                series: [
                    {
                        symbolSize: 20,
                        data: [
                            [10.0, 10.0],
                            [20.0, 20.0],
                            [30.0, 30.0],
                            [40.0, 40.0]
                        ],
                        type: 'scatter',
                        // 设置平均值线
                        markLine: {
                            label: {
                                position: 'insideStartTop',
                                formatter: '{b}:{c}'
                            },
                            data: [
                                {
                                    name: '平均值',
                                    type: 'average',
                                    valueIndex: 0, // 表示用数据点的第一个值作为平均值
                                    // symbol: ['none', 'none'], // 去掉箭头
                                    label: {
                                        show: true // 不显示标签
                                    },
                                    lineStyle: {
                                        color: 'red',
                                        type: 'dashed'
                                    }
                                },
                                {
                                    name: '平均值',
                                    type: 'average',
                                    valueIndex: 1, // 表示用数据点的第二个值作为平均值
                                    // symbol: ['none', 'none'], // 去掉箭头
                                    label: {
                                        show: true // 不显示标签
                                    },
                                    lineStyle: {
                                        color: 'red',
                                        type: 'dashed'
                                    }
                                }
                            ]
                        },
                    }
                ]
            },
            czlglOption: {
                color: ['#5eb3ff', '#5dfeff'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                },
                legend: {
                    right: '0',
                    top: '15',
                    textStyle: { color: '#fff' }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    // splitLine:{
                    show: false
                    // }
                },
                yAxis: {
                    type: 'category',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    data: [],
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        name: '正高级教师',
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: '高级教师',
                        type: 'bar',
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            },
            zxxlzbOption: {
                color: ['#5695ff'],
                xAxis: {
                    name: '教龄五年内老师占比(%)',
                    nameTextStyle: {
                        align: 'right',
                        verticalAlign: 'top',
                        lineHeight: '60'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true
                    }
                },
                yAxis: {
                    nameTextStyle: {
                        align: 'left'
                    },
                    name: '5年内退休老师占比(%)',
                    // type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true
                    }
                },
                series: [
                    {
                        symbolSize: 20,
                        data: [
                            [10.0, 10.0],
                            [20.0, 20.0],
                            [30.0, 30.0],
                            [40.0, 40.0]
                        ],
                        type: 'scatter',
                        // 设置平均值线
                        markLine: {
                            label: {
                                position: 'insideStartTop',
                                formatter: '{b}:{c}'
                            },
                            data: [
                                {
                                    name: '平均值',
                                    type: 'average',
                                    valueIndex: 0, // 表示用数据点的第一个值作为平均值
                                    // symbol: ['none', 'none'], // 去掉箭头
                                    label: {
                                        show: true // 不显示标签
                                    },
                                    lineStyle: {
                                        color: 'red',
                                        type: 'dashed'
                                    }
                                },
                                {
                                    name: '平均值',
                                    type: 'average',
                                    valueIndex: 1, // 表示用数据点的第二个值作为平均值
                                    // symbol: ['none', 'none'], // 去掉箭头
                                    label: {
                                        show: true // 不显示标签
                                    },
                                    lineStyle: {
                                        color: 'red',
                                        type: 'dashed'
                                    }
                                }
                            ]
                        },
                    }
                ]
            },
            jsnldOption: {
                tooltip: {
                    // trigger: 'item',
                    formatter: '{b} : {c}%'
                },
                color: ['#4a93ff', '#4cd9ff', '#8b7eff', '#a9d5fb', '#688aff', '#e4ca6e'],
                series: [
                    {
                        name: 'Funnel',
                        type: 'funnel',
                        left: '10%',
                        top: 60,
                        bottom: 60,
                        width: '80%',
                        min: 0,
                        max: 100,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'none',
                        gap: 2,
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        labelLine: {
                            length: 0,
                            lineStyle: {
                                width: 0,
                                type: 'solid'
                            }
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        emphasis: {
                            label: {
                                fontSize: 20
                            }
                        },
                        data: [
                            { value: 0, name: '50岁及以上' },
                            { value: 0, name: '45-50岁' },
                            { value: 0, name: '40-45岁' },
                            { value: 0, name: '35-40岁' },
                            { value: 0, name: '30-35岁' },
                            { value: 0, name: '30岁以下' }
                        ]
                    }
                ]
            },
            jsjldOption: {
                tooltip: {
                    // trigger: 'item',
                    formatter: '{c}'
                },
                color: ['#72e3ff'],
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    show: false
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            }
        };
    },
    created() {
    },
    mounted() {
        this.getTeacherArchive()
    },
    activated() {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getTeacherArchive() {
            var xxjsList = {}
            var czjsList = {}
            var gzjsList = {}
            var xxkmZgjList = {}
            var xxkmGjList = {}
            var czkmZgjList = {}
            var czkmGjList = {}
            teacherArchive().then(res => {
                this.formData = res.data.data;
                this.formData.teacherList.forEach(e => {
                    if (e.gender == 0) {
                        this.xbOption.series[0].data[0].value++;
                    }
                    if (e.gender == 1) {
                        this.xbOption.series[0].data[1].value++;
                    }
                    if (e.qualification == 0) {
                        this.xlOption.series[0].data[0].value++;
                    }
                    if (e.qualification == 1) {
                        this.xlOption.series[0].data[1].value++;
                    }
                    if (e.qualification == 2) {
                        this.xlOption.series[0].data[2].value++;
                    }
                    if (e.rankLevel == 5 || e.rankLevel == 4) {
                        this.zcOption.series[0].data[0].value++;
                    }
                    if (e.rankLevel == 3) {
                        this.zcOption.series[0].data[1].value++;
                    }
                    if (e.rankLevel == 2) {
                        this.zcOption.series[0].data[2].value++;
                    }
                    if (e.periodKey == 1) {
                        if (xxjsList[e.orgId]) {
                            xxjsList[e.orgId].push(e)
                        } else {
                            xxjsList[e.orgId] = [e]
                        }
                        if (e.rankLevel == 5) {
                            e.subjectName.split(',').forEach(j => {
                                console.log(j);
                                if (xxkmZgjList[j]) {
                                    xxkmZgjList[j]++;
                                } else {
                                    xxkmZgjList[j] = 1;
                                }
                                this.xxlglOption.yAxis.data.push(j)
                            })
                        }
                        if (e.rankLevel == 4) {
                            e.subjectName.split(',').forEach(j => {
                                console.log(j);
                                if (xxkmGjList[j]) {
                                    xxkmGjList[j]++;
                                } else {
                                    xxkmGjList[j] = 1;
                                }
                                this.xxlglOption.yAxis.data.push(j)
                            })
                        }
                    }

                    if (e.periodKey == 2) {
                        if (czjsList[e.orgId]) {
                            czjsList[e.orgId].push(e)
                        } else {
                            czjsList[e.orgId] = [e]
                        }
                        if (e.rankLevel == 5) {
                            e.subjectName.split(',').forEach(j => {
                                console.log(j);
                                if (czkmZgjList[j]) {
                                    czkmZgjList[j]++;
                                } else {
                                    czkmZgjList[j] = 1;
                                }
                                this.czlglOption.yAxis.data.push(j)
                            })
                        }
                        if (e.rankLevel == 4) {
                            e.subjectName.split(',').forEach(j => {
                                console.log(j);
                                if (czkmGjList[j]) {
                                    czkmGjList[j]++;
                                } else {
                                    czkmGjList[j] = 1;
                                }
                                this.czlglOption.yAxis.data.push(j)
                            })
                        }
                    }
                    if (e.periodKey == 3) {
                        if (gzjsList[e.orgId]) {
                            gzjsList[e.orgId].push(e)
                        } else {
                            gzjsList[e.orgId] = [e]
                        }
                    }
                })
                this.chooseXd('all')
                this.choosejsjldXd('all')
                this.getxxTable(xxjsList)
                this.getczTable(czjsList)
                this.getgzTable(gzjsList)
                this.getxxlglOption(xxkmZgjList, xxkmGjList)
                this.getczlglOption(czkmZgjList,czkmGjList)
                this.xlOption.total = this.xlOption.series[0].data[0].value + this.xlOption.series[0].data[1].value + this.xlOption.series[0].data[2].value
                this.zcOption.total = this.zcOption.series[0].data[0].value + this.zcOption.series[0].data[1].value + this.zcOption.series[0].data[2].value
                this.getGrbkOption()
            })
        },
        chooseXd(type) {
            this.activeType = type;
            this.jsnldOption.series[0].data.forEach(e => {
                e.value = 0
            })
            this.formData.teacherList.forEach(e => {
                if (type == 'all') {
                    this.setjsnldOption(e)
                }
                if (type == 'xx' && e.periodKey == 1) {
                    this.setjsnldOption(e)
                }
                if (type == 'cz' && e.periodKey == 2) {
                    this.setjsnldOption(e)
                }
                if (type == 'gz' && e.periodKey == 3) {
                    this.setjsnldOption(e)
                }
            })
            this.jsnldOption.series[0].data.forEach(e => {
                e.value = e.value * 100 / this.formData.teacherList.length
            })
            this.getjsnldOption()
        },
        choosejsjldXd(type) {
            this.activejsjldType = type;
            this.jsjldOption.xAxis.data = [];
            this.jsjldOption.series[0].data = [];
            var jsjldObj = {}
            this.formData.teacherList.forEach(e => {
                if (type == 'all') {
                    if (e.age) {
                        if (jsjldObj[e.age]) {
                            jsjldObj[e.age]++
                        } else {
                            jsjldObj[e.age] = 1
                        }
                    }
                }
                if (type == 'xx' && e.periodKey == 1) {
                    if (e.age) {
                        if (jsjldObj[e.age]) {
                            jsjldObj[e.age]++
                        } else {
                            jsjldObj[e.age] = 1
                        }
                    }
                }
                if (type == 'cz' && e.periodKey == 2) {
                    if (e.age) {
                        if (jsjldObj[e.age]) {
                            jsjldObj[e.age]++
                        } else {
                            jsjldObj[e.age] = 1
                        }
                    }
                }
                if (type == 'gz' && e.periodKey == 3) {
                    if (e.age) {
                        if (jsjldObj[e.age]) {
                            jsjldObj[e.age]++
                        } else {
                            jsjldObj[e.age] = 1
                        }
                    }
                }
            })
            console.log(jsjldObj);
            Object.keys(jsjldObj).forEach(key => {
                this.jsjldOption.xAxis.data.push(key)
                this.jsjldOption.series[0].data.push(jsjldObj[key])
            })
            this.getjsjldOption()
        },
        setjsnldOption(e) {
            if (e.age >= 50) {
                this.jsnldOption.series[0].data[0].value++;
            }
            if (e.age >= 45 && e.age < 50) {
                this.jsnldOption.series[0].data[1].value++;
            }
            if (e.age >= 40 && e.age < 45) {
                this.jsnldOption.series[0].data[2].value++;
            }
            if (e.age >= 35 && e.age < 40) {
                this.jsnldOption.series[0].data[3].value++;
            }
            if (e.age >= 30 && e.age < 35) {
                this.jsnldOption.series[0].data[4].value++;
            }
            if (e.age < 30) {
                this.jsnldOption.series[0].data[5].value++;
            }
        },
        getxxTable(xxjsList) {
            Object.keys(xxjsList).forEach(key => {
                console.log(key, xxjsList[key]);
                var zgNum = 0
                var yjNum = 0
                var ejNum = 0
                var backboneNum = 0
                var qualiNum = 0
                xxjsList[key].forEach(e => {
                    if (e.rankLevel == 5 || e.rankLevel == 4) {
                        zgNum++;
                    }
                    if (e.rankLevel == 3) {
                        yjNum++;
                    }
                    if (e.rankLevel == 2) {
                        ejNum++;
                    }
                    if (e.isBackbone) {
                        backboneNum++;
                    }
                    if (e.qualification == 0) {
                        qualiNum++;
                    }
                })
                this.xxJszbList.push({ schoolName: xxjsList[key][0].orgName, teacherNum: xxjsList[key].length, zgNum: (zgNum / xxjsList[key].length).toFixed(2) * 100, yjNum: (yjNum / xxjsList[key].length).toFixed(2) * 100, ejNum: (ejNum / xxjsList[key].length).toFixed(2) * 100, backboneNum: (backboneNum / xxjsList[key].length).toFixed(2) * 100, qualiNum: (qualiNum / xxjsList[key].length).toFixed(2) * 100 })
            })
            var pjteacherNum = 0
            var pjzgNum = 0
            var pjbackboneNum = 0
            var pjqualiNum = 0
            this.xxJszbList.forEach(e => {
                if (e.teacherNum) {
                    pjteacherNum += e.teacherNum
                }
                if (e.zgNum) {
                    pjzgNum += e.zgNum
                }
                if (e.backboneNum) {
                    pjbackboneNum += e.backboneNum
                }
                if (e.qualiNum) {
                    pjqualiNum += e.qualiNum
                }
            })
            this.xxJsPj.teacherNum = (pjteacherNum / this.xxJszbList.length).toFixed(2)
            this.xxJsPj.zgNum = (pjzgNum / this.xxJszbList.length).toFixed(2)
            this.xxJsPj.backboneNum = (pjbackboneNum / this.xxJszbList.length).toFixed(2)
            this.xxJsPj.qualiNum = (pjqualiNum / this.xxJszbList.length).toFixed(2)
        },
        getczTable(czjsList) {
            Object.keys(czjsList).forEach(key => {
                console.log(key, czjsList[key]);
                var zgNum = 0
                var yjNum = 0
                var ejNum = 0
                var backboneNum = 0
                var qualiNum = 0
                czjsList[key].forEach(e => {
                    if (e.rankLevel == 5 || e.rankLevel == 4) {
                        zgNum++;
                    }
                    if (e.rankLevel == 3) {
                        yjNum++;
                    }
                    if (e.rankLevel == 2) {
                        ejNum++;
                    }
                    if (e.isBackbone) {
                        backboneNum++;
                    }
                    if (e.qualification == 0) {
                        qualiNum++;
                    }
                })
                this.czJszbList.push({ schoolName: czjsList[key][0].orgName, teacherNum: czjsList[key].length, zgNum: (zgNum / czjsList[key].length).toFixed(2) * 100, yjNum: (yjNum / czjsList[key].length).toFixed(2) * 100, ejNum: (ejNum / czjsList[key].length).toFixed(2) * 100, backboneNum: (backboneNum / czjsList[key].length).toFixed(2) * 100, qualiNum: (qualiNum / czjsList[key].length).toFixed(2) * 100 })
            })
            var pjteacherNum = 0
            var pjzgNum = 0
            var pjbackboneNum = 0
            var pjqualiNum = 0
            this.czJszbList.forEach(e => {
                if (e.teacherNum) {
                    pjteacherNum += e.teacherNum
                }
                if (e.zgNum) {
                    pjzgNum += e.zgNum
                }
                if (e.backboneNum) {
                    pjbackboneNum += e.backboneNum
                }
                if (e.qualiNum) {
                    pjqualiNum += e.qualiNum
                }
            })
            this.czJsPj.teacherNum = (pjteacherNum / this.czJszbList.length).toFixed(2)
            this.czJsPj.zgNum = (pjzgNum / this.czJszbList.length).toFixed(2)
            this.czJsPj.backboneNum = (pjbackboneNum / this.czJszbList.length).toFixed(2)
            this.czJsPj.qualiNum = (pjqualiNum / this.czJszbList.length).toFixed(2)
        },
        getgzTable(gzjsList) {
            Object.keys(gzjsList).forEach(key => {
                console.log(key, gzjsList[key]);
                var zgNum = 0
                var yjNum = 0
                var ejNum = 0
                var backboneNum = 0
                var qualiNum = 0
                gzjsList[key].forEach(e => {
                    if (e.rankLevel == 5 || e.rankLevel == 4) {
                        zgNum++;
                    }
                    if (e.rankLevel == 3) {
                        yjNum++;
                    }
                    if (e.rankLevel == 2) {
                        ejNum++;
                    }
                    if (e.isBackbone) {
                        backboneNum++;
                    }
                    if (e.qualification == 0) {
                        qualiNum++;
                    }
                })
                this.gzJszbList.push({ schoolName: gzjsList[key][0].orgName, teacherNum: gzjsList[key].length, zgNum: (zgNum / gzjsList[key].length).toFixed(2) * 100, yjNum: (yjNum / gzjsList[key].length).toFixed(2) * 100, ejNum: (ejNum / gzjsList[key].length).toFixed(2) * 100, backboneNum: (backboneNum / gzjsList[key].length).toFixed(2) * 100, qualiNum: (qualiNum / gzjsList[key].length).toFixed(2) * 100 })
            })
            var pjteacherNum = 0
            var pjzgNum = 0
            var pjbackboneNum = 0
            var pjqualiNum = 0
            this.gzJszbList.forEach(e => {
                if (e.teacherNum) {
                    pjteacherNum += e.teacherNum
                }
                if (e.zgNum) {
                    pjzgNum += e.zgNum
                }
                if (e.backboneNum) {
                    pjbackboneNum += e.backboneNum
                }
                if (e.qualiNum) {
                    pjqualiNum += e.qualiNum
                }
            })
            this.gzJsPj.teacherNum = (pjteacherNum / this.gzJszbList.length).toFixed(2)
            this.gzJsPj.zgNum = (pjzgNum / this.gzJszbList.length).toFixed(2)
            this.gzJsPj.backboneNum = (pjbackboneNum / this.gzJszbList.length).toFixed(2)
            this.gzJsPj.qualiNum = (pjqualiNum / this.gzJszbList.length).toFixed(2)
        },
        handleExport() {
        },
        getGrbkOption() {
            setTimeout(() => {
                let myChart3 = echarts.init(document.getElementById('grbk'))
                console.log(myChart3);
                echarts.registerMap('DATONG', dtArea, {
                    Alaska: {
                        left: -131,
                        top: 25,
                        width: 15
                    },
                    Hawaii: {
                        left: -110,
                        top: 28,
                        width: 5
                    },
                    'Puerto Rico': {
                        left: -76,
                        top: 26,
                        width: 2
                    }
                });
                myChart3.setOption(this.grbkOption, true)
                this.getXbOption()
                this.getXlOption()
                this.getZcOption()
                this.getxxxlzbOption()
                this.getzxxlzbOption()
            });
        },
        getXbOption() {
            let xbOption = echarts.init(document.getElementById('xbOption'))
            xbOption.setOption(this.xbOption)
        },
        getXlOption() {
            let xlOption = echarts.init(document.getElementById('xlOption'))
            xlOption.setOption(this.xlOption)
        },
        getZcOption() {
            let zcOption = echarts.init(document.getElementById('zcOption'))
            zcOption.setOption(this.zcOption)
        },
        getxxlglOption(xxkmZgjList, xxkmGjList) {
            this.xxlglOption.yAxis.data = Array.from(new Set(this.xxlglOption.yAxis.data))
            this.xxlglOption.yAxis.data.forEach(e => {
                this.xxlglOption.series[0].data.push(0)
                this.xxlglOption.series[1].data.push(0)
            })
            
            Object.keys(xxkmZgjList).forEach(key => {
                this.xxlglOption.series[0].data[this.xxlglOption.yAxis.data.indexOf(key)]=xxkmZgjList[key]
            });
            Object.keys(xxkmGjList).forEach(key => {
                this.xxlglOption.series[1].data[this.xxlglOption.yAxis.data.indexOf(key)]=xxkmGjList[key]
            });
            let xxlglOption = echarts.init(document.getElementById('xxlglOption'))
            xxlglOption.setOption(this.xxlglOption)
        },
        getxxxlzbOption() {
            let xxxlzbOption = echarts.init(document.getElementById('xxxlzbOption'))
            xxxlzbOption.setOption(this.xxxlzbOption)
        },
        getjsnldOption() {
            let jsnldOption = echarts.init(document.getElementById('jsnldOption'))
            jsnldOption.setOption(this.jsnldOption)
        },
        getczlglOption(czkmZgjList,czkmGjList) {
            this.czlglOption.yAxis.data = Array.from(new Set(this.czlglOption.yAxis.data))
            this.czlglOption.yAxis.data.forEach(e => {
                this.czlglOption.series[0].data.push(0)
                this.czlglOption.series[1].data.push(0)
            })
            
            Object.keys(czkmZgjList).forEach(key => {
                this.czlglOption.series[0].data[this.czlglOption.yAxis.data.indexOf(key)]=czkmZgjList[key]
            });
            Object.keys(czkmGjList).forEach(key => {
                this.czlglOption.series[1].data[this.czlglOption.yAxis.data.indexOf(key)]=czkmGjList[key]
            });
            let czlglOption = echarts.init(document.getElementById('czlglOption'))
            czlglOption.setOption(this.czlglOption)
        },
        getzxxlzbOption() {
            let zxxlzbOption = echarts.init(document.getElementById('zxxlzbOption'))
            zxxlzbOption.setOption(this.zxxlzbOption)
        },
        getjsjldOption() {
            let jsjldOption = echarts.init(document.getElementById('jsjldOption'))
            jsjldOption.setOption(this.jsjldOption)
        }
    }
};
</script>
<style scoped lang="scss">
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.bg-kq {
    background: url(../../assets/worldbj.jpg) no-repeat center center/cover;
    padding-bottom: 40px;

    .top-tit {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-family: 微软雅黑;
        font-weight: bold;
        padding: 10px;
    }

    .ct-bt {
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }

    .smtit {
        color: #fff;
        font-size: 14px;
        margin-bottom: 25px;

        .lvt {
            font-size: 18px;
            padding-top: 3px;
            font-weight: bold;
        }

        .col-328 {
            color: #3280ff;
        }

        .col-47e {
            color: #47e7ff;
        }

        .col-6e6 {
            color: #6e66fc;
        }
    }

    .txbox {
        border: 1px solid #3368b5;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 5px;
    }

    .smtitbt {
        position: relative;
        color: #fff;
        padding: 10px 0 10px 25px;
        border-bottom: 1px solid #3368b5;
        font-size: 14px;
    }

    .tb-top {
        padding: 10px 10px 10px 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #5ac0fd;
        text-align: center;
        font-weight: bold;

        div {
            width: 20%;
            white-space: nowrap;
        }
    }

    .tb-ct {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #bee7ef;
        text-align: center;
        background: rgba(255, 253, 253, 0.05);

        div {
            width: 20%;
        }
    }

    .tb-bt-box {
        height: 355px;
        overflow-y: auto;
    }

    .tb-bt {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #a1b0c8;
        text-align: center;
        background: rgba(255, 253, 253, 0.05);

        div {
            width: 20%;
        }
    }

    .tb-bt:nth-child(odd) {
        background: rgba(255, 253, 253, 0.15);
    }

    .szbtn {
        padding: 5px 10px;
        background: #54a5ff;
        color: #fff;
        border-radius: 5px;
        font-size: 14px;
    }

    .szbtn.active {
        background: #4af0ff;
        color: #333;
    }

    .smtitbt::before {
        content: '';
        width: 3px;
        height: 15px;
        background: #4684ee;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .xbtobx {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
        padding-top: 20px;
    }

    .xbNan {
        color: #fff;
        padding: 10px;
        position: relative;
        font-size: 14px;
        width: 80%;
    }

    .xbNan::before {
        content: '';
        width: 3px;
        height: 60%;
        background: #4684ee;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .xbNan2::before {
        background: #49e3ff !important;
    }

    .xbNan3::before {
        background: #6d6af9 !important;
    }
}
</style>