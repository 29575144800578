<!--饼图-->
<template>
    <div class="my-charts">
        <div :id="id"></div>
    </div>
</template>


<script>
import * as echarts from "echarts";


export default {
    name: "pieEcharts",
    props: {
        id:{
            type: String,
            default:"pie_echarts"
        },
        dataList:{
            type: Array,
            default: []
        }
    },
    watch:{
        id(newValue,oldValue){
            this.id = newValue;
        },
        dataList(newValue,oldValue){
            this.dataList = newValue;
            this.init()
        }
    },
    data() {
        return {
            pieChart: null, // 饼图
            chartOption:{
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '-1%',
                    left: 'center'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        color: [
                            '#5470c6',
                            '#91cc75',
                            '#fac858',
                            '#ee6666',
                            '#73c0de',
                            '#3ba272',
                            '#fc8452',
                            '#9a60b4',
                            '#ea7ccc',
                        ],
                        radius: '50%',
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        data: []
                    }
                ]
            }
        };
    },
    computed: {},
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        init() {
            const list = []
            this.dataList.forEach(item => {
                // list.push({'value':item.answerCount,'name':item.itemName+':'+item.rate* 100+'%'});
                list.push({'value':item.answerCount,'name':item.itemName});
            })
            this.pieChart = echarts.init(document.getElementById(this.id));
            this.chartOption.series[0].data = []
            this.chartOption.series[0].data = list;
            this.pieChart.setOption(this.chartOption);
        }
    }
};
</script>


<style scoped lang="scss">
.my-charts {
    display: flex;
    flex-direction: column;
    height: auto;
    div {
            width: 100%;
            height: 360px;
            flex-grow: 1;
        }
}
</style>