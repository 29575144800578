<template>
    <div class="app-container">
        <el-card class="mb15" v-has-btn="'1,2'">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button v-has-btn="'1,2'" type="primary" @click="handleAdd">新增成果展示</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.achieveTitle" placeholder="请输入成果标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table  header-cell-class-name="bgf2" show-overflow-tooltip
                           @current-change="handleCurrentChange"
                           :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2?290:380}px)`" v-loading="loading"
                           :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" align="center" label="序号" width="60" />
                    <el-table-column width="300" label="成果标题" prop="achieveTitle">
                        <template #default="scope">
                            <div :title="scope.row.achieveTitle" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.achieveTitle }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">未发布</div>
                            <div v-if="scope.row.status == 1">已发布</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="类别" align="center" prop="achieveTypeName">
                        <template #default="scope">
                            <div>{{ scope.row.achieveTypeName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="发表人" align="center" prop="userName">
                        <template #default="scope">
                            <div>{{ scope.row.userName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" align="center" label="发表人账号" prop="userAccount">
                        <template #default="scope">
                            <div>{{ scope.row.userAccount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" align="center" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column v-has-btn="'1,2'" fixed="right" width="100" label="操作"
                                     align="center"
                                     class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popover placement="bottom" trigger="click" :width="120" popper-style="padding: 5px 0 0">
                                <template #reference>
                                    <el-button>操作</el-button>
                                </template>
                                <template #default>
                                    <div>
                                        <div class="text-item" v-if="scope.row.status == 0">
                                            <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>
                                        </div>
                                        <div class="text-item" v-if="scope.row.status == 0">
                                            <el-text type="primary" @click="handleRelease(scope.row)">发布</el-text>
                                        </div>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                       icon-color="#626AEF" title="确定删除该成果" @confirm="handleDelete(scope.row)">
                                            <template #reference>
                                                <div class="text-item" v-has-btn="'1,2,3'">
                                                    <el-text type="primary" >删除</el-text>
                                                </div>
                                            </template>
                                        </el-popconfirm>
                                    </div>
                                </template>
                            </el-popover>
                        </template>

                        <!--                        <template #default="scope">-->
                        <!--                            <el-button size="mini" v-if="scope.row.status == 0" type="primary" icon="el-icon-edit"-->
                        <!--                                @click="handleUpdate(scope.row)">编辑-->
                        <!--                            </el-button>-->
                        <!--                            <el-button v-if="scope.row.status == 0" style="margin-left: 12px;" size="mini" type="primary"-->
                        <!--                                icon="" @click="handleRelease(scope.row)">发布-->
                        <!--                            </el-button>-->

                        <!--                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"-->
                        <!--                                icon-color="#626AEF" title="确定删除该成果" @confirm="handleDelete(scope.row)">-->
                        <!--                                <template #reference>-->
                        <!--                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除-->
                        <!--                                    </el-button>-->
                        <!--                                </template>-->
                        <!--                            </el-popconfirm>-->
                        <!--                        </template>-->
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
    </div>
</template>

<script>
import { achieveListAll,achievePage, achieveDel, achieveRelease } from "@/api/admin/achieve";
import { trainListAll, trainRelease, trainStop, trainDel } from "@/api/admin/train"
import { evaluationListAll, evaluationCreate, evaluationDel, evaluationRelease, evaluationStop, evaluationSubmit } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [
                { name: '线上直播', value: 0 },
                { name: '线上录播', value: 1 },
                { name: '线下培训', value: 2 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                achieveTitle: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = []
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            achievePage(this.queryParams).then(res => {
                // if (this.queryParams.status !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.status == this.queryParams.status
                //     })
                // }
                // if (this.queryParams.type !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.type == this.queryParams.type
                //     })
                // }
                // if (this.queryParams.achieveTitle !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.achieveTitle.toString().indexOf(this.queryParams.achieveTitle) != -1
                //     })
                // }
                this.total = res.data.data.total;

                this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                this.dataList.sort(function (a, b) {
                    if (a.releaseTime > b.releaseTime) { return -1; }
                    if (a.releaseTime < b.releaseTime) { return 1; }
                    return 0;
                })
                this.loading = false;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            achieveDel({ achieveId: row.achieveId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/achieveDetail",
                query: {
                    achieveId: row.achieveId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                achieveTitle: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/achieveAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
            });
        },
        handleRelease(row) {
            achieveRelease({ achieveId: row.achieveId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/achieveAdd",
                query: {
                    achieveId: row.achieveId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>