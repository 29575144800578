import request from "@/utils/request";

export const  bookList =(accountId) =>{
    return request({
        url: "/adminservice/book/list",
        method:"get",
    })
}

export function download(url, fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('target', '_blank');
    fileName && a.setAttribute('download', fileName);
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
/*直接下载，不预览*/
export function downloadFile(url, fileName) {
    const x = new window.XMLHttpRequest();
    x.open('GET', url, true);
    x.responseType = 'blob';
    x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    };
    x.send();
}
