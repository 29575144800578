<template>
    <div class="app-container">
        <el-card v-has-btn="'1,2,3'" class="mb15">
            <el-row>
                <el-col v-has-btn="'1,2,3'" :span="16" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增培训视频</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getList()" v-model="queryParams.stageType" placeholder="请选择">
                                <el-option v-for="item in courseClassifyList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.courseName" placeholder="请输入课题名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!--            <el-divider />-->
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                          :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2 && this.$store.state.zdy.role != 3 ? 305 : 365}px)`"
                          v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="260" label="课题名称" prop="courseName">
                        <template #default="scope">
                            <div :title="scope.row.courseName" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.courseName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="课题状态" prop="joinUserList">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">初始</div>
                            <div v-if="scope.row.status == 1">已发布</div>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column width="80" label="学段" prop="periodName" />-->
                    <!--                    <el-table-column width="80" label="年级" prop="gradeName" />-->
                    <!--                    <el-table-column width="100" label="学科" prop="subjectName" />-->
                    <el-table-column width="200" label="学段•年级•学科">
                        <template #default="scope">
                            <el-text>
                                {{scope.row.periodName}}•{{scope.row.gradeName}}•{{scope.row.subjectName}}
                            </el-text>
                        </template>
                    </el-table-column>

                    <el-table-column width="90" label="类型" prop="stageTypeName">
                        <template #default="scope">
                            {{ scope.row.stageTypeName }}
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div class="text-one" :title="timestampToTime(scope.row.releaseTime)">{{
                                    timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" width="100" label="操作"
                                     class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popover placement="bottom" trigger="click"
                                        :width="80" popper-style="padding: 5px 0 0">
                                <template #reference>
                                    <el-button>操作</el-button>
                                </template>
                                <template #default>
                                    <!--                                    <div v-if="scope.row.status !== 2" v-has-btn="'1,2,3'" class="text-item">-->
                                    <!--                                        <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>-->
                                    <!--                                    </div>-->
                                    <div v-has-btn="'1,2,3'"  class="text-item" >
                                        <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>
                                    </div>
                                    <div v-has-btn="'1,2,3'"  class="text-item">
                                        <el-text type="primary"  @click="handleUploadVideo(scope.row)">上传视频</el-text>
                                    </div>
                                    <div v-has-btn="'1,2,3,4'" v-if="scope.row.status == 1" class="text-item">
                                        <el-text type="primary" @click="handleLookVideo(scope.row)">查看视频</el-text>
                                    </div>
                                    <div v-has-btn="'1,2,3'" v-if="scope.row.status == 0" class="text-item" style="margin-left: 12px;">
                                        <el-text type="primary" @click="handleRelease(scope.row)">发布视频</el-text>
                                    </div>

                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                   icon-color="#626AEF" title="确定确定删除该课程及其视频附件" @confirm="handleDelete(scope.row)">
                                        <template #reference>
                                            <div v-has-btn="'1,2,3'" class="text-item">
                                                <el-text>删除</el-text>
                                            </div>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
    </div>
</template>

<script>
import { trainCourseRelease,trainCourseInfo, trainCourseDel, trainPageCourses } from "@/api/admin/traCourse"
import { trainStop } from "@/api/admin/train"
import {lessonSubmit, delLessonFile } from "@/api/admin/lesson"
import { exportData } from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict } from "@/api/admin/public";

export default {
    name: "traCourse",
    components: {
    },
    data() {
        return {
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            courseClassifyList: [],
            statusList: [
                { name: '初始', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                courseName: '',
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                stageType: '',
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx === 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'train_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type === 'application/pdf' ||
              rawFile.type === 'application/vnd.ms-powerpoint' ||
              rawFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
        },
        handleCurrentChange(val) {
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            trainPageCourses(this.queryParams).then(res => {
                this.total = res.data.data.total;

                res.data.data.list.forEach(e => {
                    this.courseClassifyList.forEach(j => {
                        if (e.stageType === j.dictKey) {
                            e.stageTypeName = j.dictValue
                        }
                    })
                })
                this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                this.loading = false;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            trainCourseDel({ courseId: row.courseId }).then(res => {
                if (res.data.code === 1) {
                    this.$message.success("删除成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/traCourseDetail",
                query: {
                    courseId: row.courseId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/traCourseAdd",
            });
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
            });
        },
        handleRelease(row) {
            this.isType = 1;
            this.isVideo = 0;
            console.log(row);
            var isz = false;
            var isj = false;
            trainCourseInfo({ courseId: row.courseId }).then(res => {
                row = res.data.data;
                if (row.videoList && row.videoList.length !== 0) {
                    this.isVideo = 1;
                } else {
                    this.isVideo = 0;
                }
                if (row.childrenList) {
                    row.childrenList.forEach(j => {
                        if (j.videoList && j.videoList.length !== 0) {
                            this.isVideo = 2;
                        }
                        if (j.childrenList) {
                            j.childrenList.forEach(m => {
                                if (m.videoList && m.videoList.length != 0) {
                                    this.isVideo = 3;
                                }
                                isj = true;
                            })
                        } else {
                            isz = true;
                        }

                    })
                } else {

                }
                if (isj === true) {
                    this.isType = 3;
                } else {
                    if (isz === true) {
                        this.isType = 2;
                    }
                }
                console.log(this.isType, this.isVideo);
                if (this.isVideo === 0) {
                    this.$message.error('请上传视频')
                    return;
                }
                trainCourseRelease({ courseId: row.courseId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("发布成功");
                        this.getList();
                    } else {
                        this.$message.error("发布失败");
                    }
                })
            })

        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length === 0 && row.kjFiles.length === 0 && row.xtFiles.length === 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code === 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code === 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/traCourseAdd",
                query: {
                    courseId: row.courseId,
                }
            });
        },
        handleLookVideo(row) {
            this.$router.push({
                path: "/trainCourseLookVideo",
                query: {
                    courseId: row.courseId,
                    isLook: 1,
                    isList: 1,
                    isTraVourse: 1
                }
            });
        },
        handleUploadVideo(row) {
            this.$router.push({
                path: "/traCourseUploadVideo",
                query: {
                    courseId: row.courseId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}



.text-item {
    height: 45px;
    margin: 0 10px;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
}

.text-item .el-text {
    color: #606266;
    cursor: pointer;
    user-select: none;

}

.text-item:last-child {
    border: none;
}

</style>