<!--协同备课、听评课总数统计饼图-->
<template>
    <div class="my-charts">
        <div :id="id"></div>
    </div>
</template>


<script>
import * as echarts from "echarts";

export default {
    name: "pieEcharts",
    props: {
        id:{
            type: String,
            default:"pie_echarts"
        },
        statsType:{
            type: String,
            default:"0"
        },
        title:{
            type: String,
            default: "统计"
        },
        totalCount:{
            type: Number,
            default: 0
        },
        teamCount:{
            type: Number,
            default: 0
        },
        userCount:{
            type: Number,
            default: 0
        },
        echartsTitle:{
            type: String,
            default: "数据统计"
        },
        yearCode:{
            type: String,
            default: "202X"
        },
        onlineCount:{
            type: Number,
            default: 0
        },
        offlineCount:{
            type: Number,
            default: 0
        },
    },
    watch:{
        id(newValue,oldValue){
            this.id = newValue;
        },
        statsType(newValue,oldValue){
            this.statsType = newValue;
            this.init()
        },
        title(newValue,oldValue){
            this.title = newValue;
            this.init()
        },
        totalCount(newValue,oldValue){
            this.totalCount = newValue;
            this.init()
        },
        teamCount(newValue,oldValue){
            this.teamCount = newValue;
            this.init()
        },
        userCount(newValue,oldValue){
            this.userCount = newValue;
            this.init()
        },
        echartsTitle(newValue,oldValue){
            this.echartsTitle = newValue;
            this.init()
        },
        yearCode(newValue,oldValue){
            this.yearCode = newValue;
            this.init()
        },
        onlineCount(newValue,oldValue){
            this.onlineCount = newValue;
            this.init()
        },
        offlineCount(newValue,oldValue){
            this.offlineCount = newValue;
            this.init()
        },
    },
    data() {
        return {
            pieChart: null, // 饼图
            chartOption:{
                title: {
                    text: '',
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: true,
                    bottom:0,
                },
                graphic: [
                    {
                        type: "text",
                        left: "center",
                        top: "center",
                        style: {
                            text: '',
                            textAlign: "center",
                            fill: "#333333",
                            lineHeight:30,
                            fontSize: 20,
                            fontWeight: 'bold'
                        },
                    },
                ],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['55%', '75%'],
                        avoidLabelOverlap: false,
                        color: [
                            '#91cc75',
                            '#fac858',
                            '#ee6666',
                            '#3ba272',
                            '#fc8452',
                            '#9a60b4',
                            '#ea7ccc',
                        ],
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 10,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: []
                    }
                ]
            }
        };
    },
    watch: {},
    computed: {},
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        init() {
            const list = []
            if (this.statsType == 'prepare_lesson'){
                list.push({'value':this.userCount,'name':"个人备课"});
                list.push({'value':this.teamCount,'name':"协同备课"});
            }
            if (this.statsType == 'eva_lesson'){
                list.push({'value':this.onlineCount,'name':"线上听评"});
                list.push({'value':this.offlineCount,'name':"推门听评"});
            }

            this.pieChart = echarts.init(document.getElementById(this.id));
            this.chartOption.title.text = this.yearCode+'年'+this.echartsTitle
            this.chartOption.series[0].data = []
            this.chartOption.series[0].data = list;
            this.chartOption.graphic[0].style.text = this.title+'\n'+this.totalCount
            this.pieChart.setOption(this.chartOption);
        }
    }
};
</script>

<style scoped lang="scss">
.my-charts {
    flex-direction: column;
    padding: 10px 10px 10px 0;
    margin-left: 10px;
    box-shadow: var(--el-box-shadow-light);
    div {
        width: 100%!important;
        height: 300px;
    }
}
</style>