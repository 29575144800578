import request from "@/utils/request";

// ----------------数据统计-师资培训------------------//
export const statsTrain =(data) =>{
    return request({
        url: "/businessservice/dataStats/train",
        method:"post",
        data: data
    })
}

// 师资培训其他量表
export const statsTrainRate =(query) =>{
    return request({
        url: "/businessservice/dataStats/trainRate",
        method:"get",
        params: query
    })
}

// 师资培训满意度调查
export const statsSatisfiedRate =(query) =>{
    return request({
        url: "/businessservice/dataStats/trainSatisfied",
        method:"get",
        params: query
    })
}

// ----------------数据统计-协同备课------------------//
export const statsPrepareLesson =(data) =>{
    return request({
        url: "/businessservice/dataStats/prepareLessonStats",
        method:"post",
        data: data
    })
}

// ----------------数据统计-听评课------------------//
export const statsEvaLesson =(data) =>{
    return request({
        url: "/businessservice/dataStats/evaLessonStats",
        method:"post",
        data: data
    })
}

// 师资培训其他量表
export const statsEvaLessonRate =(query) =>{
    return request({
        url: "/businessservice/dataStats/evaLessonRate",
        method:"get",
        params: query
    })
}