<!--选择培训课程组件-->
<template>
    <div class="" style="width: 100%;">
        <!--连接-->
        <div style="width: 100%;" class="">
            <el-link @click="openSelectCourse" type="primary" :underline="false">
                +{{ showTitle }}
            </el-link>
        </div>
        <!--已选文件-->
        <div>
            <el-tag type="info" style="margin-right: 10px;margin-bottom: 5px;"
                    class="mx-1" closable v-if="courseName" @close="tagClose(tag)">
                {{courseName}}
            </el-tag>
        </div>
        <el-dialog :title="showTitle" v-model="isShow" width="660px" style="padding: 5px 5px">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="40px">
                <el-row>
                    <el-col :span="8" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getTrainCourseList" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt10">
                    <el-col :span="8" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getTrainCourseList" v-model="queryParams.stageType"  placeholder="请选择">
                                <el-option v-for="item in courseClassifyList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.courseName" placeholder="请输入课题名称" style="height: 30px" class="input-with-select">
                                <template #append>
                                    <el-button @click="getTrainCourseList" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-row>
                <el-col :span="24">
                    <div style="height: 300px;overflow-y: auto;">
                        <el-empty :image-size="80"
                                  image="https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/data_empty.png"
                                  v-if="trainList.length == 0"
                                  description="暂无数据" />
                        <div style="margin:10px 0 0 10px;display: flex;flex-wrap: wrap;">
                            <div @click="checkChange(item)" v-for="item in trainList" class="cursor"
                                 style="margin:0 0 10px 10px;border-radius: 8px;padding-right: 10px">
                                <div class="disflex-al-ct">
                                    <img v-if="courseId !== item.courseId "
                                         style="width: 18px;height:18px;margin: 0 5px 0 10px;"
                                         src="../assets/img/uncheck.png" alt="">
                                    <img v-if="courseId === item.courseId"
                                         style="width: 18px;height:18px;margin: 0 5px 0 10px;"
                                         src="../assets/img/checked.png" alt="">
                                    <div>{{ item.courseName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>

</template>

<script>
import { queryDict } from "@/api/admin/public";
import {listCourseCondition} from "@/api/admin/traCourse";

export default {
    name: "SelectLessonFile",
    props:{
        showTitle: {
            type: String,
            default: ''
        },
        selCourseId:{
            type: String,
            default: ''
        },
    },
    created() {
        this.courseId = this.selCourseId;
        this.getQueryDict()
        this.getTrainCourseList();
    },
    watch:{
        selCourseId(newValue,oldValue){
            this.courseId = newValue;
            this.getTrainCourseList()
        }
    },
    data(){
        return{
            isShow:false,
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList:[],
            trainList:[],
            courseId:"",
            courseName:"",
            // 查询参数
            queryParams: {
                courseType:0, //0-培训课程 1-教学课程 2-名师优课
                courseName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                stageType: '',
            },
        }
    },
    methods:{
        openSelectCourse(){
            this.isShow = true;
        },
        getQueryDict(){
            queryDict({ code: 'train_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            // 筛选课程
            this.getTrainCourseList();
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            // 筛选课程
            this.getTrainCourseList();
        },
        getTrainCourseList(){
            listCourseCondition(this.queryParams).then(res => {
                this.trainList = res.data.data;
                this.trainList.forEach(e => {
                    if (this.courseId===e.courseId){
                        this.courseName = e.courseName;
                    }
                    e.checked = false;
                })
            })
        },
        checkChange(item){
            this.courseId = item.courseId;
            this.courseName = item.courseName;
        },
        tagClose(){
            this.courseId = '';
            this.courseName = '';
        },


    }
}
</script>

<style>

</style>