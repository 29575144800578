<!--一体化教研 - 一体化教研统计  -->
<template>
    <div class="app-container">
        <el-scrollbar shadow="never" class="stat-scrollbar">
            <el-form ref="queryForm" :model="statsQueryForm" :inline="true" label-width="68px">
                <el-row style="text-align: left;width: 100%" class="mt10">
                    <el-col :span="11" class="first-item">
                        <el-radio-group @change="changeActiveType" v-model="activeType" size="large">
                            <el-radio-button label="协同备课" />
                            <el-radio-button label="听评课" />
                        </el-radio-group>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="年份">
                            <el-select v-model="statsQueryForm.yearCode"
                                       @change="statsFormChange"
                                       placeholder="请选择年份">
                                <el-option v-for="item in yearList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-show="schoolList&&schoolList.length>0">
                        <el-form-item label="学校">
                            <el-select clearable @change="statsFormChange" v-model="statsQueryForm.orgId" placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName"
                                           :value="item.orgId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 协同备课 -->
            <div v-if="activeType=='协同备课'" style="padding-bottom: 10px">
                <el-row :gutter="20" class="mt20" style="width: 100%">
                    <el-col :span="6">
                        <el-card class="first-item" :body-style="{ padding: '10px' }">
                            <div class="stat-title">备课总数</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewLesson(lessonStats.lessonList)">{{lessonStats.totalCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card :body-style="{ padding: '10px' }">
                            <div class="stat-title">协同备课数</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewLesson(lessonStats.teamLessonList)">{{lessonStats.teamCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card :body-style="{ padding: '10px' }">
                            <div class="stat-title">个人备课数</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewLesson(lessonStats.userLessonList)">{{lessonStats.userCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card :body-style="{ padding: '10px' }">
                            <div class="stat-title">参与教师数</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewTeaLesson(lessonStats.teaLessonList)">{{lessonStats.teacherCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <el-row class="mt10" :gutter="20" style="width: 100%"  >
                    <el-col :span="8">
                        <totalPieEcharts
                            :id="'2025_lesson'"
                            title="备课总数"
                            echartsTitle="备课环状统计图"
                            :totalCount="lessonStats.totalCount"
                            :team-count="lessonStats.teamCount"
                            :user-count="lessonStats.userCount"
                            :yearCode="statsQueryForm.yearCode"
                            stats-type="prepare_lesson"/>
                    </el-col>
                    <el-col :span="16">
                        <subBarEcharts
                          :id="'2025_subject'"
                          :subject-list="lessonSubjectStats.subjectList"
                          :count-list="lessonSubjectStats.countList"
                          :yearCode="statsQueryForm.yearCode"
                          echartsTitle="备课总次数分学科统计"/>
                    </el-col>
                </el-row>

                <el-row class="mt20" :gutter="20" style="width: 100%">
                    <el-col :span="24" class="first-item">
                        <yearLineEcharts
                          :id="'teaching_year_lesson'"
                          echartsTitle="协同备课"
                          :month-list="lessonMonthStats.monthList"
                          :one-count-list="lessonMonthStats.oneCountList"
                          :two-count-list="lessonMonthStats.twoCountList"
                          :yearCode="statsQueryForm.yearCode"
                          stats-type="prepare_lesson"/>
                    </el-col>
                </el-row>
            </div>

            <!-- 听评课 -->
            <div v-if="activeType=='听评课'" style="padding-bottom: 10px">
                <el-row :gutter="20" class="mt20" style="width: 100%">
                    <el-col :span="6">
                        <el-card class="first-item" :body-style="{ padding: '10px' }">
                            <div class="stat-title">听评课总数</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewEvaLesson(evaStats.lessonList)">{{evaStats.totalCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card  :body-style="{ padding: '10px' }">
                            <div class="stat-title">线上听评</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewEvaLesson(evaStats.onlineLessonList)">{{evaStats.onlineCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card  :body-style="{ padding: '10px' }">
                            <div class="stat-title">推门听评</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewEvaLesson(evaStats.offlineLessonList)">{{evaStats.offlineCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card  :body-style="{ padding: '10px' }">
                            <div class="stat-title">参与教师</div>
                            <div class="stat-count">
                                <span class="stat-count-span" @click="viewTeaEvaLesson(evaStats.teaLessonList)">{{evaStats.teacherCount}}</span>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <el-row class="mt10" :gutter="20" style="width: 100%">
                    <el-col :span="8">
                        <totalPieEcharts
                            :id="'2025_eva'"
                            title="听评课总数"
                            echartsTitle="教师听评课环状统计图"
                            :totalCount="evaStats.totalCount"
                            :online-count="evaStats.onlineCount"
                            :offline-count="evaStats.offlineCount"
                            :yearCode="statsQueryForm.yearCode"
                            stats-type="eva_lesson"/>
                    </el-col>
                    <el-col :span="16">
                        <subBarEcharts
                          :id="'2025_eva_subject'"
                          :subject-list="evaSubjectStats.subjectList"
                          :count-list="evaSubjectStats.countList"
                          :yearCode="statsQueryForm.yearCode"
                          echartsTitle="教师听评课学科分布图"/>
                    </el-col>
                </el-row>

                <el-row class="mt20" :gutter="20" style="width: 100%">
                    <el-col :span="24" class="first-item">
                        <yearLineEcharts
                          :id="'eva_year_lesson'"
                          echartsTitle="听评课"
                          :month-list="evaMonthStats.monthList"
                          :one-count-list="evaMonthStats.oneCountList"
                          :two-count-list="evaMonthStats.twoCountList"
                          :yearCode="statsQueryForm.yearCode"
                          stats-type="eva_lesson"/>
                    </el-col>
                </el-row>
            </div>

        </el-scrollbar>

        <!--备课列表-->
        <el-dialog title="备课列表" v-model="isLessonShow" width="680px">
            <el-table show-overflow-tooltip height="calc(100vh - 300px)"
                      border
                      :data="tableLessonList"
                      highlight-current-row>
                <el-table-column type="index" align="center" label="序号" width="60" />
                <el-table-column width="" label="备课标题" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                             @click="goDetail(scope.row)">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="80" align="center" label="主备人" prop="trueName">
                </el-table-column>
                <el-table-column width="90" align="center" label="等级" prop="lessonLevelName">
                </el-table-column>
                <el-table-column width="180" align="center" label="所属组织" prop="orgName">
                </el-table-column>

            </el-table>
        </el-dialog>

        <!--备课教师统计-->
        <el-dialog title="教师备课统计" v-model="isTeaLessonShow" width="640px">
            <el-table show-overflow-tooltip height="calc(100vh - 300px)"
                      border
                      :data="teaStatsList"
                      highlight-current-row>
                <el-table-column type="index" align="center" label="序号" width="60" />
                <el-table-column width="120" align="center" label="教师姓名" prop="trueName">
                </el-table-column>
                <el-table-column width="145" align="center" label="教师账号" prop="username">
                </el-table-column>
                <el-table-column width="80" align="center" label="主备数" prop="leadCount">
                </el-table-column>
                <el-table-column width="80" align="center" label="协备数" prop="teamCount">
                </el-table-column>
                <el-table-column width="120" align="center" label="个人备课数" prop="personCount">
                </el-table-column>
            </el-table>
        </el-dialog>

        <!--听评课列表-->
        <el-dialog title="听评课列表" v-model="isEvaLessonShow" width="780px">
            <el-table show-overflow-tooltip height="calc(100vh - 300px)"
                      border
                      :data="tableEvaLessonList"
                      highlight-current-row>
                <el-table-column type="index" align="center" label="序号" width="60" />
                <el-table-column width="" label="听评课标题" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                             @click="goDetail(scope.row)">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="100" align="center" label="授课教师" prop="trueName">
                </el-table-column>
                <el-table-column width="180" align="center" label="所属组织" prop="orgName">
                </el-table-column>
                <el-table-column width="140" align="center" label="量表统计" prop="">
                    <template #default="scope">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <el-button  plain size="small"
                                        style="display: block;margin: 5px 0;"
                                        v-if="scope.row.scaleIds"
                                        @click="handleRateScale(scope.row)">
                                量表统计
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <!--听评课教师统计-->
        <el-dialog title="教师听评课统计" v-model="isTeaEvaLessonShow" width="600px">
            <el-table show-overflow-tooltip height="calc(100vh - 300px)"
                      border
                      :data="teaEvaStatsList"
                      highlight-current-row>
                <el-table-column type="index" align="center" label="序号" width="60" />
                <el-table-column width="120" align="center" label="教师姓名" prop="trueName">
                </el-table-column>
                <el-table-column width="145" align="center" label="教师账号" prop="username">
                </el-table-column>
                <el-table-column width="80" align="center" label="授课数" prop="lectureCount">
                </el-table-column>
                <el-table-column width="80" align="center" label="听课数" prop="listenCount">
                </el-table-column>
                <el-table-column width="80" align="center" label="评课数" prop="rateCount">
                </el-table-column>
            </el-table>
        </el-dialog>
        <!--听评课量表统计-->
        <el-dialog  v-model="isRateScaleShow" width="640px" :show-close="false">
            <el-descriptions :column="3" :title="'【'+evaLessonTitle+'】量表统计'" border style="margin-top: -15px">
                <template #extra>
                    <el-dropdown v-if="scaleStatsList && scaleStatsList.length > 0" slot="dropdown">
                        <el-tag type="primary" style="height: 24px;border: 1px solid #1f89de;line-height: 24px;color: #1f89de">
                            ⇅ {{scaleTitle}}
                        </el-tag>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :disabled="scaleId==item.scaleId" v-for="item in scaleStatsList" @click="changeScale(item)">{{item.title}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-tag style="margin-left: 10px" class="homework_tag cursor" @click="this.isRateScaleShow=!this.isRateScaleShow" >关闭</el-tag>
                </template>
            </el-descriptions>
            <el-tabs v-model="activeTabName" @tab-click="handleTabsClick" type="border-card"
                     style="margin-top: -5px">
                <el-tab-pane label="统计结果" name="0" >
                    <div v-if="questionList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <div v-for="(item,index) in questionList" :style="index==0?'margin-top:-10px;':''">
                        <h3 style="margin: 10px 0 10px 0;">
                            <span>
                                {{ index + 1 }}.{{item.questionName }}
                            </span>
                            <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                [{{item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容' : '' }}]
                            </span>
                        </h3>
                        <el-table v-if="item.type==1 || item.type==2"
                                  :data="item.answerList"
                                  :row-class-name="hideStat"
                                  border fit>
                            <el-table-column  align="center" label="选项" prop="itemName"/>
                            <el-table-column  align="center" label="小计" prop="answerCount"/>
                            <el-table-column  align="center" label="比例" prop="rate">
                                <template #default="scope">
                                    <div>{{scope.row.rate * 100}}%</div>
                                </template>
                            </el-table-column>
                            <template #append>
                                <div style="display: flex">
                                    <div style="width: 100%;height: 40px;line-height: 40px;font-weight: bold;text-align: center;">
                                        本题有效填写人次：{{item.count}}
                                    </div>
                                </div>
                            </template>
                        </el-table>
                        <div class="chart-tab" v-if="item.type!=3 && item.type!=4">
                            <ul class="fr">
                                <li @click="handlePieQuestion(item.questionId)" :class="this.pieQuIds.includes(item.questionId)?'liSel':''">
                                    饼状
                                </li>
                                <li @click="handleBarQuestion(item.questionId)" :class="this.barQuIds.includes(item.questionId)?'liSel':''">
                                    柱状
                                </li>
                            </ul>
                        </div>

                        <pieEcharts v-if="item.type!=3 && item.type!=4 && this.pieQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_pie'"
                                    :dataList="item.answerList"/>

                        <barEcharts v-if="item.type!=3 && item.type!=4 && this.barQuIds.includes(item.questionId)"
                                    :id="item.questionId+'_bar'"
                                    :dataList="item.answerList"/>


                        <el-table v-if="item.type==3" :data="item.aveScore" border fit>
                            <el-table-column  align="center" label="打分人数" prop="trueName">
                                <div>{{item.count}}</div>
                            </el-table-column>
                            <el-table-column  align="center" label="平均分" >
                                <template #default="scope">
                                    <div>{{scope.row}}</div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-table v-if="item.type==4" :data="item.userAnswerList" border fit>
                            <el-table-column  align="center" label="姓名" prop="trueName">
                                <template #default="scope">
                                    <div>{{scope.row.isHide==0?scope.row.trueName:'匿名'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column  align="center" label="内容" prop="answerName" />
                        </el-table>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="详细数据" name="1" >
                    <div v-if="userRecordList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                    <el-collapse v-if="userRecordList.length!==0" v-model="activeNames" accordion>
                        <el-collapse-item v-for="(item,index) in userRecordList" :name="index+1"
                                          @click.native="handleChange(index)">
                            <template #title>
                                <h3 style="margin: 0 0;" :class="activeIndex==index?'user-record-title active':'user-record-title'">
                                    {{item.isHide==0?(index+1)+'.'+item.trueName:(index+1) +'.匿名'}} 提交时间: {{timestampToTime(item.submitTime)}}
                                </h3>
                            </template>
                            <div v-for="(q,i) in item.questionList">
                                <h3 style="margin: 10px 0 10px 0;color:#909399 ">
                                    <span>
                                        {{ i + 1 }}.{{q.questionName }}
                                    </span>
                                    <span style="height:22px;line-height:22px;color: #999;font-weight: normal;font-size: 14px;">
                                        [{{q.type == 1 ? '单选' : q.type == 2 ? '多选' : q.type == 3 ? '评分' : q.type == 4 ? '文字内容' : '' }}]
                                    </span>
                                </h3>
                                <div style="margin-left: 10px">
                                    {{q.questionAnswer}}
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>

<script>
import totalPieEcharts from "@/components/echart/totalPieEcharts";
import subBarEcharts from "@/components/echart/subBarEcharts";
import yearLineEcharts from "@/components/echart/yearLineEcharts";
import {statsPrepareLesson, statsEvaLesson, statsEvaLessonRate} from "@/api/admin/stats"
import {timestampToTime} from "@/utils/date";
import {orgListUserSchool} from "@/api/admin/public";
import pieEcharts from "@/components/echart/pieEcharts.vue";
import barEcharts from "@/components/echart/barEcharts.vue";

export default {
    name: "teachingStat",
    components: {barEcharts, pieEcharts, totalPieEcharts,subBarEcharts,yearLineEcharts},
    data() {
        return {
            timestampToTime,
            loading: true,
            isLessonShow:false,
            isEvaLessonShow:false,
            isTeaLessonShow:false,
            isTeaEvaLessonShow:false,
            activeType: '协同备课',
            statsQueryForm:{
                yearCode:'2025',
                orgId:'',
                beginTime:null,
                endTime:null,
                userRole:this.$storage.get("role"),
            },
            yearList: [
                { name: '2025年', value: 2025 },
                { name: '2024年', value: 2024 },
                { name: '2023年', value: 2023 },
            ],
            schoolList: [],
            //协同备课
            lessonStats:{}, //协同备课
            lessonSubjectStats:{},
            lessonMonthStats:{},
            teaStatsList:[],
            tableLessonList:[],
            //听评课
            evaStats:{}, //听评课
            evaSubjectStats:{},
            evaMonthStats:{},
            teaEvaStatsList:[],
            tableEvaLessonList:[],
            //听评课量表统计
            isRateScaleShow:false,
            activeTabName:'0',
            activeIndex:null,
            activeNames:'',
            scaleId:'',
            scaleTitle:'',
            evaLessonTitle:'', //当前听评课标题
            userRecordList:[], //当前量表填写记录
            questionList:[],  //当前量表问题统计
            scaleStatsList:[], //量表 统计数据
            pieQuIds:'', //饼图问题ID
            barQuIds:'', //柱状图问题ID
        };
    },
    created() {
        this.prepareLessonStats();
        this.evaLessonStats();
        this.getSchoolList();
    },
    mounted() {},
    activated() {},
    computed:{
        timestamp(){
            return new Date().getTime();
        }
    },
    methods: {
        getSchoolList() {
            orgListUserSchool().then(res => {
                this.schoolList = res.data.data;
            })
        },
        changeActiveType(){
            if (this.activeType=='协同备课'){
                this.prepareLessonStats();
            }
            if (this.activeType=='听评课'){
                this.evaLessonStats();
            }
        },
        prepareLessonStats(){
            statsPrepareLesson(this.statsQueryForm).then(res => {
                if (res.data.code == 1){
                    this.lessonStats = res.data.data;
                    this.lessonSubjectStats = this.lessonStats.subjectStat;
                    this.lessonMonthStats = this.lessonStats.monthStat;
                }
            })
        },
        viewLesson(lessonList){
            this.tableLessonList = lessonList;
            this.isLessonShow = true;
        },
        viewTeaLesson(list){
            this.teaStatsList = list;
            this.isTeaLessonShow = true;
        },
        statsFormChange(){
            if (this.activeType=='协同备课'){
                this.prepareLessonStats();
            }
            if (this.activeType=='听评课'){
                this.evaLessonStats();
            }
        },
        evaLessonStats(){
            statsEvaLesson(this.statsQueryForm).then(res => {
                if (res.data.code == 1){
                    this.evaStats = res.data.data;
                    this.evaSubjectStats = this.evaStats.subjectStat;
                    this.evaMonthStats = this.evaStats.monthStat;
                }
            })
        },
        viewEvaLesson(lessonList){
            this.tableEvaLessonList = lessonList;
            this.isEvaLessonShow = true;
        },
        viewTeaEvaLesson(list){
            this.teaEvaStatsList = list;
            this.isTeaEvaLessonShow = true;
        },
        handleRateScale(row){
            this.evaLessonTitle = row.title;
            this.isRateScaleShow = true;
            this.activeIndex = null
            this.userRecordList = []
            this.questionList = []
            this.pieQuIds = ''
            this.barQuIds = ''
            statsEvaLessonRate({lessonId:row.lessonId}).then(res => {
                if (res.data.code == 1){
                    this.scaleStatsList = res.data.data;
                    if (this.scaleStatsList){
                        this.scaleId = this.scaleStatsList[0].scaleId;
                        this.scaleTitle = this.scaleStatsList[0].title;
                        this.userRecordList = this.scaleStatsList[0].userRecordList;
                        this.questionList = this.scaleStatsList[0].questionList;
                        this.pieQuIds = ''
                        this.barQuIds = ''
                        this.questionList.forEach(q  => {
                            this.pieQuIds = this.pieQuIds+q.questionId
                        })
                    }
                    this.isRateScaleShow = true;
                }else {
                    this.$message.error("请求出错")
                }
            })
        },
        handleTabsClick(item){
            this.activeTab = item.name;
        },
        changeScale(item){
            this.scaleId = item.scaleId;
            this.scaleTitle = item.title;
            this.userRecordList = item.userRecordList;
            this.questionList = item.questionList;
            this.pieQuIds = ''
            this.barQuIds = ''
            this.questionList.forEach(q  => {
                this.pieQuIds = this.pieQuIds+q.questionId
            })
            console.log("饼图显示："+this.pieQuIds)
        },
        handlePieQuestion(qId){
            if (!this.pieQuIds.includes(qId)){
                this.pieQuIds = this.pieQuIds+qId;
            }
            if (this.barQuIds.includes(qId)){
                this.barQuIds = this.barQuIds.replace(qId,'');
            }
        },
        handleBarQuestion(qId){
            if (!this.barQuIds.includes(qId)){
                this.barQuIds = this.barQuIds +qId;
            }
            if (this.pieQuIds.includes(qId)){
                this.pieQuIds = this.pieQuIds.replace(qId,'');
            }
        },
        handleChange(index){
            this.activeIndex = index
        },
        hideStat({ row, rowIndex }){
            if (row.answerCount===0){
                // return 'hidden-row'
                return '';
            }
            return '';
        },
    }
};
</script>
<style scoped>
.stat-scrollbar{
    height: calc(100vh - 100px);
    width: 96%;
    padding: 10px 20px 30px 20px;
    background-color: #FFFFFF;
    overflow-x: hidden!important;

}
.first-item{
    //padding: 10px 10px 10px 0;
    margin-left: 10px;
}
.stat-title{
    height: 40px;
    line-height: 40px;
    color: #333;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}
.stat-count{
    height: 30px;
    line-height: 20px;
    color: #333333;
    font-weight: bold;
    text-align: center;
}
.stat-count-span{
    color: green;
    cursor: pointer;
    text-decoration: underline;
}

.chart-tab{
    display: flex;
    text-align: center;
    margin: 5px 0;
    color: #333;
    //float: right;
}
.chart-tab ul{
    background: #ebebeb;
    padding: 0 2px;
    list-style: none;
    border: none;
    margin: 0;
    display: block;
}

.chart-tab ul li{
    float: left;
    height: 28px;
    line-height: 26px;
    text-align: center;
    background: #fff;
    border-radius: 2px;
    margin: 2px 1px;
    cursor: pointer;
    padding: 0 4px
}

.fr{
    float: right;
}
</style>
