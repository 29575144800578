<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>专题活动</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!activityId">新增专题活动</el-breadcrumb-item>
                <el-breadcrumb-item v-if="activityId">编辑专题活动</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动名称" prop="activityName">
                            <el-input v-model="form.activityName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动性质" prop="activityNature">
                            <el-select @change="changeActivityNature" style="width: 100%;" v-model="form.activityNature"
                                       placeholder="请选择活动性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.activityNature != '1'">
                    <el-col :span="12">
                        <el-form-item :label="form.activityNature == 4 ? '群组' : '工作室'" prop="groupId">
                            <el-select @change="changeQun" style="width: 100%;" v-model="form.groupId" placeholder="请选择工作室/群组">
                                <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                                           :value="item.groupId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.activityNature && form.activityNature == '1'">
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动级别" prop="activityLevel">
                            <el-select @change="getOrgListTypeOrg" v-model="form.activityLevel" placeholder="请选择活动级别">
                                <el-option v-for="item in activityLevelList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织单位" prop="orgIdsList">
                            <el-select style="width: 100%;" multiple filterable v-model="form.orgIdsList"
                                       placeholder="请选择组织单位">
                                <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName"
                                           :value="item.orgId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专题活动类型" prop="activityType">
                            <el-radio-group v-model="form.activityType">
                                <el-radio :label="0">文字展示</el-radio>
                                <el-radio :label="1">图片展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.activityType == 0">
                    <el-col :span="12">
                        <el-form-item label="活动内容" prop="activityContent">
                            <el-input type="textarea" :rows="5" maxlength="2000" v-model="form.activityContent" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动规则" prop="activityRule">
                            <el-input type="textarea" :rows="5" maxlength="2000" v-model="form.activityRule" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="评审标准" prop="reviewCriterion">
                            <el-input type="textarea" :rows="5" maxlength="2000" v-model="form.reviewCriterion" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动奖励" prop="activityAwards">
                            <el-input type="textarea" :rows="5" maxlength="2000" v-model="form.activityAwards" />
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申报起止时间" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm" :disabled-date="disabledDate"
                                                style="width: 100%;" v-model="form.beginTime" type="datetime" placeholder="开始时间"
                                                value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                                :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                                type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="审核人员" prop="checkUsersList">
                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.checkUsersList"
                                placeholder="请选择审核人员">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                            <choosePeople :showTitle="'选择人员'" ref="checkUsersList" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="专家评委" prop="judgesUsersList">
                            <el-select style="width: 100%;" multiple filterable v-model="form.judgesUsersList"
                                placeholder="请选择专家评委">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="其他" prop="remarks">
                            <el-input type="textarea" maxlength="1000" v-model="form.remarks" />
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-col :span="12" v-if="form.activityType != 1">
                    <el-form-item label="专题活动量表" prop="scaleIds">
                        <el-select style="width:100%" multiple filterable v-model="form.scaleList" placeholder="请选择专题活动量表">
                            <el-option v-for="item in rateScaleList" :key="item.scaleId" :label="item.title"
                                       :value="item.scaleId">
                                <span style="float: left">{{ item.title }}</span>
                                <span @click.stop="preview(item)" style="float: right; color: #8492a6; font-size: 13px">
                                    <el-icon>
                                        <View />
                                    </el-icon></span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                       :action="serviceUrl+'/businessservice/activity/uploadFile'"
                                       :on-change="handleJAChange" :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx"
                                       :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.activityType == 1">
                    <el-col :span="12">
                        <el-form-item label="导入图片" prop="imgUrl">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/activity/uploadImg'" :show-file-list="false"
                                       :on-success="handleAvatarSuccess" :headers="{ token }" :before-upload="beforeAvatarUpload">
                                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:60px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                  : ''
                            }}]</span><span v-if="item.type == 4 && item.totalScore"
                                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                                item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                            }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                    item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                      '文字内容'
                                      : ''
                                }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                    item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { queryDict, userList, listHeadmasterGroup, listTeacherGroup,groupListAuthGroup } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleList, rateScaleInfo } from "@/api/admin/evaluation";
import { activityDeleteFile, activityCreate, activityUpdate, activityInfo } from "@/api/admin/activity";
import { serviceUrl } from "@/utils/global"

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            serviceUrl,
            activityId: '',
            classifyName: '',
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            topicClassifyList: [],
            orgList: [],
            activityLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                groupId: '',
                activityNature: '1',
                activityName: '',
                activityLevel: '',
                activityType: 0,
                activityContent: '',
                checkUsersList: [],
                // judgesUsersList:[],
                orgIdsList: [],
                beginTime: '',
                endTime: '',
                // remarks: '',
                fileUrls: '',
                imgUrl: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                scaleIds: '',
                scaleList: [],
            },
            groupList: [],
            natureList: [],
            // 表单校验
            rules: {
                activityNature: [
                    { required: true, message: "请选择活动性质", trigger: "change" },
                ],
                groupId: [
                    { required: true, message: "请选择工作室/群组", trigger: "change" },
                ],
                // periodKey: [
                //     { required: true, message: "请选择学段", trigger: "change" },
                // ],
                // gradeKey: [
                //     { required: true, message: "请选择年级", trigger: "change" },
                // ],
                // subjectKey: [
                //     { required: true, message: "请选择学科", trigger: "change" },
                // ],
                checkUsersList: [
                    { required: true, validator: this.checkcheckUsersList },
                ],
                judgesUsersList: [
                    { required: true, message: "请选择专家评委", trigger: "change" },
                ],
                orgIdsList: [
                    { required: true, message: "请选择组织单位", trigger: "change" },
                ],
                activityName: [
                    { required: true, message: "请输入活动名称", trigger: "blur" },
                ],
                activityContent: [
                    { required: true, message: "请输入活动内容", trigger: "blur" },
                ],
                activityRule: [
                    { required: true, message: "请输入活动规则", trigger: "blur" },
                ],
                reviewCriterion: [
                    { required: true, message: "请输入评审标准", trigger: "blur" },
                ],
                activityAwards: [
                    { required: true, message: "请输入活动奖励", trigger: "blur" },
                ],
                activityLevel: [
                    { required: true, message: "请选择活动级别", trigger: "change" },
                ],
                activityType: [
                    { required: true, message: "请选择展示类型", trigger: "change" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
                imgUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            rateScaleList: [],
            previewData: {},
            previewShow: false,
        };
    },
    created() {
        this.getRateScaleList();
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.activityId) {
            this.activityId = this.$route.query.activityId
            this.getlessonInfo()
        } else {
            if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
                this.form.activityNature = ''
            }
            this.isShow = true
        }
    },
    unmounted() {
        if (this.activityId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        activityDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    activityDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        checkcheckUsersList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.checkUsersList.checkList.length == 0) {
                callback(new Error('请选择审核人员'))
            } else {
                callback()
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getRateScaleList() {
            rateScaleList({ scaleType: 3 }).then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        changeActivityNature(e) {
            console.log(e);
            this.groupList = [];
            // if (!this.activityId) {
            this.form.groupId = '';
            // }
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 1) {
                this.sxKj()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 2) {
                groupListAuthGroup({ groupNature: 2 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 3) {
                groupListAuthGroup({ groupNature: 3 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 4){
                this.sxKj()
                groupListAuthGroup({ groupNature: 1 }).then(res => {
                    this.groupList = res.data.data;
                })
            }
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.imgUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.imgUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        changeQun(e) {
            console.log(this.form.groupId);
            // if (this.form.activityNature == 4) {
            this.sxKj()
            this.$refs.checkUsersList.getQunPeople(this.form.groupId)
            // }
        },
        sxKj() {
            this.$refs.checkUsersList.checkList = [];
        },
        getlessonInfo() {
            this.form.scaleList = [];
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                activityInfo({ activityId: this.activityId }).then(res => {
                    this.changeActivityNature(res.data.data.activityNature)
                    // leadInfo({ raceId: this.raceId }).then(res => {
                    this.form = res.data.data;
                    this.$refs.checkUsersList.checkList = this.form.checkUserList;
                    console.log(this.form);
                    if (this.form.activityNature == 2 || this.form.activityNature == 3 ||this.form.activityNature == 4) {
                        this.$refs.checkUsersList.getQunPeople(this.form.groupId)
                    }
                    // this.form.checkUsersList = this.form.checkUsers.split(',')
                    // this.form.judgesUsersList = this.form.judgesUsers.split(',')
                    this.form.activityLevel = this.form.activityLevel
                    this.form.orgIdsList = this.form.orgIds.split(',')
                    this.form.scaleList = this.form.scaleIds && this.form.scaleIds.split(',')
                    orgListTypeOrg({ orgType: this.form.activityLevel }).then(res => {
                        this.orgList = res.data.data;
                    })
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.isShow = true;
                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e >= this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e >= this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e >= this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            this.form.orgIdsList = [];
            orgListTypeOrg({ orgType: this.form.activityLevel }).then(res => {
                this.orgList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'topic_classify' }).then(res => {
                this.topicClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.activityLevelList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
                // if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
                //     this.natureList.splice(0, 1)
                // }
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            this.form.beginTime = this.setHms(this.form.beginTime);
            this.form.endTime = this.setHms(this.form.endTime);
            console.log(this.form)
            var fileList = [];
            this.form.checkUsersList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs.checkUsersList.checkList.forEach(e => {
                this.form.checkUsersList.push(e.userId)
            })
            this.form.checkUsers = this.form.checkUsersList.toString()
            // this.form.judgesUsers = this.form.judgesUsersList.toString()
            this.form.orgIds = this.form.orgIdsList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    obj.scaleIds = obj.scaleList.toString();
                    if (obj.activityType == 1) {
                        obj.scaleIds = '';
                    }
                    delete obj.checkUsersList
                    delete obj.judgesUsersList
                    delete obj.orgIdsList
                    if (this.activityId) {
                        activityUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    activityDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/activity', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        activityCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    activityDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/activity', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/activity')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>