<template>
    <div class="app-container">
        <el-card class="mb15" v-has-btn="'1,2,3'">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleAdd">新增培训任务</el-button>
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleLbAdd(6)">新增满意度调查表</el-button>
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleLbAdd(2)">新增其他量表</el-button>
                </el-col>
                <el-col :span="8" class="mb0" v-if="role == 1 || role == 2">
                    <div class="pull-right"
                         style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox @change="getList()" v-model="isSHowMine" size="large" />
                        <div class="ml5" style="white-space: nowrap;">我参与的</div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                     @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getList()" v-model="queryParams.type" placeholder="请选择">
                                <el-option v-for="item in typeList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入培训任务标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!--            <el-divider />-->
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                          :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2 && this.$store.state.zdy.role != 3 ? 240 : 320}px)`"
                          v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" align="center" label="序号" width="60" />
                    <el-table-column width="200" label="培训任务标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                 @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" align="center" label="任务状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">初始</div>
                            <div v-if="scope.row.status == 1">进行中</div>
                            <div v-if="scope.row.status == 2">已结束</div>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column v-show="isSHowMine" min-width="90" label="参与角色" prop="roleType">-->
                    <!--                        <template #default="scope">-->
                    <!--                            <el-tag style="color:#409efe;border: 1px solid #409efe;" v-if="scope.row.roleType && scope.row.roleType.includes('4')">发起人</el-tag>-->
                    <!--                            <el-tag style="color:#409efe;border: 1px solid #409efe;" v-if="scope.row.roleType && scope.row.roleType.includes('1')">班主任</el-tag>-->
                    <!--                            <el-tag style="color:#409efe;border: 1px solid #409efe;" v-if="scope.row.roleType && scope.row.roleType.includes('2')">副班主任</el-tag>-->
                    <!--                            <el-tag style="color:#409efe;border: 1px solid #409efe;" v-if="scope.row.roleType && scope.row.roleType.includes('3')">参与人</el-tag>-->
                    <!--                            <el-tag style="color:#409efe;border: 1px solid #409efe;" v-if="scope.row.roleType === ''">未参与</el-tag>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                    <el-table-column width="160" label="培训参与人" prop="joinUserList">
                        <template #default="scope">
                            <div :title="scope.row.joinUserText" class="text-one">{{ scope.row.joinUserText }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" align="center" label="类型" prop="type">
                        <template #default="scope">
                            <div v-if="scope.row.type == 0">直播直播</div>
                            <div v-if="scope.row.type == 1">录播培训</div>
                            <div v-if="scope.row.type == 2">现场培训</div>
                            <div v-if="scope.row.type == 3">录播培训</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" align="center" label="任务时间" prop="time">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-show="!isSHowMine" align="center" width="150" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" align="center" label="附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.fileList.length != 0">
                                        <div :title="item.fileName" style="display: flex;align-items:center;"
                                             v-for="(item, index) in scope.row.fileList">
                                            <div @click="goFileDetail(item)" :title="item.fileName"
                                                 class="filtxt text-one cursor">
                                                {{ index + 1 }}. {{ item.fileName }}
                                            </div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.fileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="100" label="操作"
                                     align="center"
                                     class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popover placement="bottom" trigger="click" :width="80" popper-style="padding: 5px 0 0">
                                <template #reference>
                                    <el-button>操作</el-button>
                                </template>
                                <template #default>
                                    <div v-has-btn="'1,2,3'" class="text-item">
                                        <el-text type="primary" @click="handleUpdate(scope.row)">编辑</el-text>
                                    </div>
                                    <div v-has-btn="'1,2,3,4'" v-if="scope.row.status === 0" class="text-item">
                                        <el-text type="primary" @click="handleRelease(scope.row)">发布培训</el-text>
                                    </div>

                                    <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                        <div>
                                            <div v-if="scope.row.satisfiedScaleList && scope.row.satisfiedScaleList.length != 0">
                                                <div @click="handleComplete(item, scope.row)" :title="item.title"
                                                     class="filtxt text-one cursor" v-for="(item, index) in scope.row.satisfiedScaleList">
                                                    {{ index + 1 }}. {{ item.title }}
                                                </div>
                                            </div>
                                        </div>
                                        <template #reference>
                                            <div v-if="scope.row.satisfiedScaleList
                                              && (scope.row.status === 1 || scope.row.status === 2)
                                              && scope.row.satisfiedScaleList.length !== 0"
                                                 class="text-item">
                                                <el-text type="primary">满意度调查</el-text>
                                            </div>
                                        </template>
                                    </el-popover>

                                    <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                        <div>
                                            <div v-if="scope.row.scaleList && scope.row.scaleList.length != 0">
                                                <div @click="handleComplete(item, scope.row)" :title="item.title"
                                                     class="filtxt text-one cursor" v-for="(item, index) in scope.row.scaleList">
                                                    {{ index + 1 }}. {{ item.title }}
                                                </div>
                                            </div>
                                        </div>
                                        <template #reference>
                                            <div v-if="scope.row.scaleList
                                              && (scope.row.status === 1 || scope.row.status === 2)
                                              && scope.row.scaleList.length !== 0"
                                                 class="text-item">
                                                <el-text type="primary">{{ scope.row.status === 1 ? '其他量表' : '查看量表' }}</el-text>
                                            </div>
                                        </template>
                                    </el-popover>

                                    <div v-has-btn="'1,2,3'" v-if="scope.row.status === 1" class="text-item">
                                        <el-text type="primary" @click="handleStop(scope.row)">停止培训</el-text>
                                    </div>
                                    <!--                                    <div  v-if="scope.row.status !== 0"  v-has-btn="'1,2,'" class="text-item">-->
                                    <!--                                        <el-text type="primary" @click="goDkqkDetail(scope.row)">打卡情况</el-text>-->
                                    <!--                                    </div>-->
                                    <div v-if="scope.row.roleType && (scope.row.roleType.includes('1') || scope.row.roleType.includes('2') || scope.row.roleType.includes('4')) " class="text-item">
                                        <el-text type="primary" @click="handleHomework(scope.row.trainId)">作业管理</el-text>
                                    </div>
                                    <div v-if="scope.row.roleType && scope.row.roleType.includes('3')&& scope.row.homeworkList && scope.row.homeworkList.length !== 0 " class="text-item">
                                        <el-text type="primary" @click="handleHomeworkFinish(scope.row)">作业提交</el-text>
                                    </div>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                   icon-color="#626AEF" title="确定删除该任务" @confirm="handleDelete(scope.row)">
                                        <template #reference>
                                            <div  v-has-btn="'1,2,3'" class="text-item">
                                                <el-text type="primary">删除</el-text>
                                            </div>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-popover>

                        </template>

                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy" v-if="!isSHowMine">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                            :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>

        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <previewData :disabled="disabled" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
            </previewData>
            <template #footer v-if="!disabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitPreviewForm">确 定</el-button>
                    <el-button @click="closePreview">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog :title="'打卡情况'" v-model="dkqkOpen" width="650px">
            <el-table :span-method="objectSpanMethod" show-overflow-tooltip class="" height="calc(100vh - 500px)" border
                      :data="dkqkList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column width="" :label="'姓名'" prop="title">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签到时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signInTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签退时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signOutTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="" label="状态" prop="signStatus">
                    <template #default="scope">
                        <div class="" v-if="scope.row.signStatus == 1">
                            签到未签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 2">
                            未签到签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 3">
                            正常打卡
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="'作业管理'" v-model="isHomeworkShow" width="800px">
            <el-table :data="homeworkList"
                      v-loading="loading"
                      show-overflow-tooltip
                      highlight-current-row>
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column width="150" label="作业标题" align="center" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="200" label="作业内容" align="center" prop="content"></el-table-column>
                <el-table-column width="140" label="截止时间" align="center" prop="endTime">
                    <template #default="scope">
                        <div>{{ timestampToTime(scope.row.endTime) }}</div>
                    </template>
                </el-table-column>
                <el-table-column width="100" label="作业附件" align="center" prop="" >
                    <template #default="scope">
                        <el-popover placement="bottom-start" :width="125"
                                    trigger="click" :min-width="'auto'">
                            <div v-if="scope.row.fileList.length != 0">
                                <div>
                                    <div style="display: flex;align-items:center;"
                                         v-for="(item, index) in scope.row.fileList">
                                        <div :title="item.fileName"
                                             class="filtxt text-one cursor">
                                            {{ index + 1 }}. {{ item.fileName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template #reference>
                                <div style="color: #409efe;" class="cursor">
                                    {{ scope.row.fileList.length }}
                                </div>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column width="100" label="操作" align="center" prop="">
                    <template #default="scope">
                        <el-popover placement="bottom" trigger="click" :width="80" popper-style="padding: 5px 0 0">
                            <template #reference>
                                <el-button>操作</el-button>
                            </template>
                            <template #default>
                                <div class="text-item">
                                    <el-text type="primary" @click="handleFinish(scope.row)">完成情况</el-text>
                                </div>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                               icon-color="#626AEF" title="确定删除该作业?" @confirm="handleDeleteHomework(scope.row)">
                                    <template #reference>
                                        <div class="text-item">
                                            <el-text type="primary">删除</el-text>
                                        </div>
                                    </template>
                                </el-popconfirm>

                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <el-row style="margin-top:20px" justify="center">
                <el-button type="primary" @click="arrangeHomework">布置作业</el-button>
            </el-row>
        </el-dialog>

        <el-dialog title="发布作业" v-model="isHomeworkEditShow" width="650px">
            <el-form ref="homeworkForm" :model="homeworkForm" :rules="homeworkFormRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业标题" prop="title">
                            <el-input
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写作业标题"
                              v-model="homeworkForm.title"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业内容" prop="content">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写作业内容"
                              v-model="homeworkForm.content"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item class="pull-right" label-width="80px" label="截止时间" prop="endTime">
                        <el-date-picker
                          v-model="homeworkForm.endTime"
                          type="datetime"
                          style="width: 160px;"
                          format="YYYY-MM-DD HH:mm"
                          placeholder="作业截止时间"
                          clearable/>
                    </el-form-item>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业附件" prop="">
                            <el-upload v-model:file-list="homeworkFileList"
                                       class="upload-demo"
                                       style="width: 260px"
                                       :on-remove="handleRemoveHomeworkFile"
                                       :action="serviceUrl+'/businessservice/homework/annexUpload'"
                                       :on-change="handleChangeHomeworkFile" :headers="{ token }"
                                       :before-upload="beforeUploadHomeworkFile">
                                <el-button type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitHomeworkForm">确 定</el-button>
                    <el-button @click="arrangeHomework">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog title="提交作业" v-model="isHomeworkFinishShow" width="650px">
            <el-form ref="homeworkFinishForm" :model="homeworkFinishForm" :rules="homeworkFinishFormRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="培训作业" prop="homeworkId">
                            <el-select style="width: 100%;"
                                       v-model="homeworkFinishForm.homeworkId"
                                       @change="homeworkFinishInfo($event)"
                                       placeholder="请选择作业">
                                <el-option v-for="item in selHomeworkList" :key="item.homeworkId" :label="item.title"
                                           :value="item.homeworkId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业内容" prop="content">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写作业内容"
                              v-model="homeworkFinishForm.content"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业附件" prop="">
                            <el-upload v-model:file-list="homeworkFinishFileList"
                                       class="upload-demo"
                                       style="width: 260px"
                                       :on-remove="handleRemoveHomeworkFile"
                                       :on-preview="handlePreviewHomeworkFile"
                                       :action="serviceUrl+'/businessservice/homework/annexUpload'"
                                       :on-change="handleChangeHomeworkFile" :headers="{ token }"
                                       :before-upload="beforeUploadHomeworkFile">
                                <el-button type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>

                </el-row>

            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitHomeworkFinishForm">确 定</el-button>
                    <el-button @click="arrangeHomeworkFinish">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog title="作业完成情况" v-model="isShowHomework" width="580px">
            <el-table show-overflow-tooltip class=""
                      height="calc(100vh - 240px)" border
                      :data="homeworkUserFinishList" >
                <el-table-column width="100" align="center" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="200" align="center" label="作业标题" prop="homeworkTitle">
                </el-table-column>
                <el-table-column width="100" align="center" label="完成状态" prop="isFinish">
                    <template #default="scope">
                        <el-tag class="homework_tag" v-if="scope.row.isFinish == 1">
                            已完成
                        </el-tag>
                        <el-tag class="homework_tag_no" v-if="scope.row.isFinish == 0">
                            未完成
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column width=""  align="center" label="操作" prop="">
                    <template #default="scope">
                        <div class="cursor" style="color: #409efe" @click="checkHomework(scope.row,0)" v-if="scope.row.isFinish == 1">
                            查看&批阅
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="" v-model="isHomeworkDetailShow" width="650px" :show-close="false">
            <el-descriptions :column="3" class="mt-10" title="作业查看&批阅" border>
                <template #extra>
                    <el-tag class="homework_tag cursor"
                            @click="this.isHomeworkDetailShow=!this.isHomeworkDetailShow" >关闭</el-tag>
                </template>
                <el-descriptions-item align="center" width="80px">
                    <template #label>
                        <div class="cell-item">
                            姓名
                        </div>
                    </template>
                    {{homeworkInfo.trueName}}
                </el-descriptions-item>
                <el-descriptions-item align="center">
                    <template #label>
                        <div class="cell-item">
                            手机号
                        </div>
                    </template>
                    {{homeworkInfo.username}}
                </el-descriptions-item>
                <el-descriptions-item align="center">
                    <template #label>
                        <div class="cell-item">
                            完成时间
                        </div>
                    </template>
                    {{timestampToTime(homeworkInfo.finishTime)}}
                </el-descriptions-item>
                <el-descriptions-item :span="3">
                    <template #label>
                        <div class="cell-item">
                            提交内容
                        </div>
                    </template>
                    {{homeworkInfo.finishContent}}
                </el-descriptions-item>
                <el-descriptions-item align="center" :span="3">
                    <template #label>
                        <div class="cell-item">
                            作业附件
                        </div>
                    </template>
                    {{homeworkInfo.finishFileList.length!=0?homeworkInfo.finishFileList.length!=0:'无'}}
                </el-descriptions-item>
                <el-descriptions-item align="center" :span="1">
                    <template #label>
                        <div class="cell-item">
                            批阅状态
                        </div>
                    </template>
                    <el-tag v-if="homeworkInfo.isAssess==0">未批阅</el-tag>
                    <el-tag v-if="homeworkInfo.isAssess==1">已批阅</el-tag>
                </el-descriptions-item>
                <el-descriptions-item align="center" :span="1" v-if="homeworkInfo.isAssess==1">
                    <template #label>
                        <div class="cell-item">
                            批阅评分
                        </div>
                    </template>
                    <el-tag v-if="homeworkInfo.assessLevel==1">优</el-tag>
                    <el-tag v-if="homeworkInfo.assessLevel==2">良</el-tag>
                    <el-tag v-if="homeworkInfo.assessLevel==3">中</el-tag>
                    <el-tag v-if="homeworkInfo.assessLevel==4">差</el-tag>
                    <el-tag v-if="homeworkInfo.assessLevel==5">不及格</el-tag>
                </el-descriptions-item>
                <el-descriptions-item :span="3" v-if="homeworkInfo.isAssess==1">
                    <template #label>
                        <div class="cell-item">
                            批阅评语
                        </div>
                    </template>
                    {{homeworkInfo.comment}}
                </el-descriptions-item>
            </el-descriptions>
            <el-row style="margin-top:20px" justify="center" v-if="homeworkInfo.isAssess==0">
                <el-button type="primary" @click="readHomework">批阅</el-button>
            </el-row>
        </el-dialog>

        <el-dialog title="批阅作业" v-model="isHomeworkReadShow" width="400px">
            <el-form ref="homeworkReadForm" :model="homeworkReadForm" :rules="homeworkReadFormRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业标题" prop="homeworkTitle">
                            <el-input
                              disabled
                              v-model="homeworkReadForm.homeworkTitle"
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业所属人" prop="userName">
                            <el-input
                              disabled
                              v-model="homeworkReadForm.userName"
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="评价等级" prop="assessLevel">
                            <el-select v-model="homeworkReadForm.assessLevel" placeholder="请选择评价等级">
                                <el-option v-for="item in assessList" :key="item.value" :label="item.name"
                                           :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="80px" label="作业评语" prop="comment">
                            <el-input
                              type="textarea"
                              @focus="focusInput"
                              resize="none"
                              placeholder="请填写评语"
                              v-model="homeworkReadForm.comment"
                              autofocus
                              class="discuss-info-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="readOverHomeworkSubmit">确 定</el-button>
                    <el-button @click="isHomeworkReadShow=!isHomeworkReadShow">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { trainPageTrain, trainListJoin, trainListAll, trainRelease, trainStop, trainDel, trainInfo } from "@/api/admin/train"
import { rateScaleInfo, rateRecordScore, rateRecordScoreInfo } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import { statsTrainSign } from "@/api/admin/statistics";
import {exportData} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";
import previewData from "@/components/previewData.vue";
import { download } from "@/api/adminservice/public";
import {homeworkCreate,homeworkDel,homeworkList,homeworkDelAnnex,
    homeworkFinishInfo,homeworkFinish,homeworkFinishList,
    listHomeworkFinish,readOverHomework } from "@/api/admin/homework"
import { serviceUrl } from "@/utils/global"

export default {
    name: "scenarioMain",
    components: {
        previewData
    },
    data() {
        return {
            serviceUrl,
            dkqkOpen: false,
            dkqkList: [],
            role: this.$storage.get("role"),
            isSHowMine: false,
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '初始', value: 0 },
                { name: '进行中', value: 1 },
                { name: '已结束', value: 2 },
            ],
            typeList: [
                { name: '直播培训', value: 0 },
                { name: '录播培训', value: 1 },
                { name: '现场培训', value: 2 },
                // { name: '录播培训[视频]', value: 3 },
            ],
            assessList:[
                { name: '优', value: 1 },
                { name: '良', value: 2 },
                { name: '中', value: 3 },
                { name: '差', value: 4 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            previewData: {},
            previewShow: false,
            lessonId: '',
            isHide: false,
            disabled: false,
            isHomeworkShow:false,
            isHomeworkEditShow:false,
            isHomeworkFinishShow:false,
            isShowHomework:false,
            isHomeworkDetailShow:false,
            isCheckHomework:false,//查看或批阅
            isHomeworkReadShow:false,//批阅作业
            homeworkFileList: [],
            homeworkList:[],
            selHomeworkList:[],
            homeworkFormRules:{
                title: [{ required: true, message: "请填写作业标题", trigger: "blur" },],
                content: [{ required: true, message: "请填写作业内容", trigger: "blur" },],
                endTime: [{ required: true, validator: this.checkTimeValidator }],
            },
            homeworkForm:{
                relationId:'',
                title:'',
                content:'',
                endTime:'',
                fileUrls:''
            },
            homeworkFinishFileList: [],
            homeworkFinishForm:{
                homeworkId:'',
                content:'',
                fileUrls:'',
            },
            homeworkFinishFormRules:{
                homeworkId: [{ required: true, message: "请选择作业", trigger: "change" },],
                content: [{ required: true, message: "请填写作业内容", trigger: "blur" },],
            },
            homeworkReadFormRules:{
                assessLevel: [{ required: true, message: "请选择评价等级", trigger: "change" },],
            },
            homeworkUserFinishList:[],
            homeworkInfo:{},
            homeworkReadForm:{
                homeworkTitle:'',
                userName:'',
                finishId:'',
                assessLevel:null,
                comment:'',
            },

        };
    },
    created() {
        if (this.$storage.get("role") == 6) {
            this.isSHowMine = true;
        }
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        goDkqkDetail(row) {
            this.dkqkList = [];
            console.log(row);
            var userId = ''
            if (this.$storage.get("role") == 6) {
                userId = JSON.parse(this.$storage.get("userInfo")).userId
            } else {
                userId = ''
            }
            statsTrainSign({ trainId: row.trainId, userId: userId }).then(res => {
                res.data.data.forEach(e => {
                    if (e.signList && e.signList.length != 0) {
                        e.signList.forEach((j, n) => {
                            if (n == 0) {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: e.signList.length })
                            } else {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: 0 })
                            }
                        })
                    }else{
                        this.dkqkList.push({ trueName: e.trueName, signStatus: '', signInTime: '', signOutTime: '', col: 1 })
                    }

                })
                this.dkqkOpen = true;
            })
        },
        xz(e) {
            download(e.fileUrl, e.fileName)
        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (!row.row.isMine) {
                return 'hidden-row';
            }
            return '';
        },
        submitPreviewForm() {
            console.log(this.previewData);
            var obj = {};
            obj.scaleId = this.$refs.editData.previewData.scaleId;
            obj.lessonId = this.lessonId;
            obj.answerList = [];
            if (this.$refs.editData.isHide) {
                obj.isHide = 1;
            } else {
                obj.isHide = 0;
            }
            var totalScore = 0;
            this.$refs.editData.previewData.questionList.forEach(e => {
                if (e.type == 2) {
                    var txt = e.questionAnswer.toString()
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: txt
                    })
                    e.questionAnswer.forEach(m => {
                        e.itemList.forEach(j => {
                            if (m == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    })
                } else {
                    if (e.type == 1) {
                        e.itemList.forEach(j => {
                            if (e.questionAnswer == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    }
                    if (e.type == 3) {
                        totalScore += Number(e.questionAnswer)
                    }
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: e.questionAnswer
                    })
                }

            })
            obj.totalScore = totalScore;
            rateRecordScore(obj).then(res => {
                if (res.data.code == 1) {
                    this.previewShow = false;
                    this.isHide = false;
                    this.$message.success('提交成功')
                    this.getList();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
            console.log(this.previewData);
            console.log(obj);

        },
        closePreview() {
            this.previewShow = false;
        },
        handleComplete(item, row) {
            this.disabled = false;
            if (row.status == 2) {
                this.disabled = true
            }
            this.lessonId = row.trainId;
            this.isHide = false;
            if (item.isSub == 1) {
                rateRecordScoreInfo({ lessonId: this.lessonId, scaleId: item.scaleId }).then(res => {
                    if (res.data.data.info.isHide == 1) {
                        this.isHide = true;
                    }
                    this.previewData = res.data.data.info;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        } else {
                            // e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            } else {
                rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = [];
                        } else {
                            e.questionAnswer = '';
                        }

                    })
                    this.previewShow = true;
                })
            }

        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = [];
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (!this.isSHowMine) {
                trainPageTrain(this.queryParams).then(res => {
                    this.total = res.data.data.total;
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.list.forEach(e => {
                        var isMine = false;
                        var textList = [];
                        e.joinUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                isMine = true;
                            }
                        })
                        e.joinUserText = textList.join('、')
                        e.isMine = isMine;
                    })
                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.loading = false;
                });
            } else {
                trainListJoin().then(res => {
                    if (this.queryParams.status !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == this.queryParams.status
                        })
                    }
                    if (this.queryParams.type !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.type == this.queryParams.type
                        })
                    }
                    if (this.queryParams.title !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.title.toString().indexOf(this.queryParams.title) != -1
                        })
                    }
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.forEach(e => {
                        var isMine = false;
                        var textList = [];
                        e.joinUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                isMine = true;
                            }
                        })
                        e.joinUserText = textList.join('、')
                        e.isMine = isMine;
                    })
                    this.dataList = JSON.parse(JSON.stringify(res.data.data));
                    this.loading = false;
                })
            }

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            trainDel({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/trainDetail",
                query: {
                    trainId: row.trainId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
              .then(function () {
                  return exportData(queryParams);
              })
              .then(response => {
                  this.download(response.msg);
              });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/trainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd(type) {
            this.$router.push({
                path: "/rateScale",
                query: {
                    scaleType: type
                }
            });
        },
        handleRelease(row) {
            trainRelease({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/trainAdd",
                query: {
                    trainId: row.trainId,
                }
            });
        },
        handleHomework(trainId){
            homeworkList({relationId:trainId}).then(res =>{
                if (res.data.code == 1) {
                    this.homeworkForm.relationId = trainId;
                    this.homeworkList = res.data.data;
                    this.isHomeworkShow = true;
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        arrangeHomework(){
            this.isHomeworkEditShow = !this.isHomeworkEditShow;
        },
        arrangeHomeworkFinish(){
            this.isHomeworkFinishShow = !this.isHomeworkFinishShow;
        },
        focusInput () {
            this.closeInput = false
        },
        submitHomeworkForm(){
            this.$refs["homeworkForm"].validate(valid => {
                if (!valid){
                    return
                }
                const fileList = [];
                this.homeworkFileList.forEach((e) => {
                    if (e.response.code == 1) {
                        fileList.push(e.response.data);
                    }
                })
                this.homeworkForm.fileUrls =fileList.toString();
                this.homeworkForm.endTime = this.setHms(this.homeworkForm.endTime);
                homeworkCreate(this.homeworkForm).then(res => {
                    if (res.data.code == 1) {
                        this.handleHomework(this.homeworkForm.relationId);
                        this.homeworkForm.title = '';
                        this.homeworkForm.content = '';
                        this.homeworkForm.endTime = '';
                        this.homeworkForm.fileUrls = '';
                        this.homeworkFileList = []
                        this.$message.info("发布成功");
                        this.arrangeHomework();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            })

        },
        handleDeleteHomework(row){
            homeworkDel({homeworkId:row.homeworkId}).then(res =>{
                if (res.data.code == 1) {
                    this.handleHomework(row.relationId);
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleRemoveHomeworkFile(file, uploadFiles) {
            if (file.response.code == 1) {
                homeworkDelAnnex({fileUrl:file.response.data}).then(res =>{
                    if (res.data.code == 1) {
                        this.$message.info("文件已移除")
                    }
                })
            }
        },
        handlePreviewHomeworkFile(uploadFile) {},
        handleChangeHomeworkFile(){},
        beforeUploadHomeworkFile(rawFile) {
            // if (!(rawFile.type == 'image/jpeg' ||
            //   rawFile.type == 'image/png' ||
            //   rawFile.type == 'image/jpg')) {
            //     this.$message.error('图片必须是jpeg、png、jpg格式的')
            //     return false
            // } else if (rawFile.size / 1024 / 1024 > 10) {
            //     this.$message.error('图片大小不能超过10M')
            //     return false
            // }
            return true
        },
        checkTimeValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.homeworkForm.endTime === '' || !this.homeworkForm.endTime) {
                callback(new Error('请选择截止时间'))
            } else {
                callback()
            }
        },
        handleHomeworkFinish(row){
            //查询作业
            homeworkList({relationId:row.trainId}).then(res =>{
                if (res.data.code == 1) {
                    this.selHomeworkList = res.data.data;
                    if (this.selHomeworkList.length !== 0) {
                        this.isHomeworkFinishShow = true;
                    }else {
                        this.$message.error("该培训暂未发布作业");
                    }
                } else {
                    this.$message.error("操作失败");
                }
            })

        },
        homeworkFinishInfo(homeworkId){
            homeworkFinishInfo({homeworkId:homeworkId}).then(res =>{
                if (res.data.code == 1) {
                    if (res.data.data!==null && res.data.data!=='') {
                        this.homeworkFinishForm.content = res.data.data.content;
                        this.homeworkFinishFileList = [];
                        const fileList = res.data.data.fileList;
                        fileList.forEach(e => {
                            this.homeworkFinishFileList
                              .push({name:e.fileName, url: e.fileUrl, response:{code:1,data:e.fileUrl,}});
                        })
                    }else {
                        this.homeworkFinishForm.content = '';
                        this.homeworkFinishFileList = [];
                    }
                }
            })
        },
        submitHomeworkFinishForm(){
            this.$refs["homeworkFinishForm"].validate(valid => {
                if (!valid){
                    return
                }
                const fileList = [];
                this.homeworkFinishFileList.forEach((e) => {
                    if (e.response.code == 1) {
                        fileList.push(e.response.data);
                    }
                })
                this.homeworkFinishForm.fileUrls =fileList.toString();
                homeworkFinish(this.homeworkFinishForm).then(res => {
                    if (res.data.code == 1) {
                        this.homeworkFinishForm.homeworkId = '';
                        this.homeworkFinishForm.content = '';
                        this.homeworkFinishForm.fileUrls = '';
                        this.homeworkFinishFileList = [];
                        this.isHomeworkFinishShow = false;
                        this.$message.success("提交成功")
                    }else {
                        this.$message.error("提交失败")
                    }
                })


            })

        },
        handleFinish(row){
            listHomeworkFinish({homeworkId:row.homeworkId}).then(res =>{
                if (res.data.code == 1) {
                    if (res.data.data!==null && res.data.data!=='') {
                        this.homeworkUserFinishList = res.data.data;
                        this.isShowHomework = true;
                    }else {

                    }
                }
            })
        },
        checkHomework(row,type){
            if (type==1){
                this.isCheckHomework = true;
            }
            this.homeworkInfo = row;
            this.isHomeworkDetailShow = true;
        },
        readHomework(){
            this.homeworkReadForm.finishId = this.homeworkInfo.finishId;
            this.homeworkReadForm.homeworkTitle = this.homeworkInfo.homeworkTitle;
            this.homeworkReadForm.userName = this.homeworkInfo.trueName;
            this.isHomeworkReadShow = true;
        },
        readOverHomeworkSubmit(){
            readOverHomework(this.homeworkReadForm).then(res =>{
                if (res.data.code == 1) {
                    this.$message.success('操作成功');
                    this.homeworkInfo.isAssess = 1;
                    this.homeworkInfo.assessLevel = this.homeworkReadForm.assessLevel;
                    this.homeworkInfo.comment = this.homeworkReadForm.comment;
                    this.isHomeworkReadShow = false
                    listHomeworkFinish({homeworkId:this.homeworkInfo.homeworkId}).then(res =>{
                        if (res.data.code == 1) {
                            if (res.data.data!==null && res.data.data!=='') {
                                this.homeworkUserFinishList = res.data.data;
                            }
                        }
                    })
                }else {
                    this.$message.error('操作失败');
                }
            })
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-table /deep/ .hidden-row {
    display: none;
}

.text-item {
    height: 45px;
    margin: 0 10px;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
}

.text-item .el-text {
    color: #606266;
    cursor: pointer;
    user-select: none;

}

.text-item:last-child {
    border: none;
}

</style>