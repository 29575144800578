<!--协同备课学科统计图-->
<template>
    <div class="my-charts">
        <div :id="id"></div>
    </div>
</template>


<script>
import * as echarts from "echarts";


export default {
    name: "subBarEcharts",
    props: {
        id:{
            type: String,
            default:"sub_bar_echarts"
        },
        echartsTitle:{
            type: String,
            default: "统计"
        },
        subjectList:{
            type: Array,
            default:[]
        },
        countList:{
            type: Array,
            default:[]
        },
        yearCode:{
            type: String,
            default: "202X"
        },
    },
    watch:{
        id(newValue,oldValue){
            this.id = newValue;
        },
        echartsTitle(newValue,oldValue){
            this.echartsTitle = newValue;
            this.init()
        },
        subjectList(newValue,oldValue){
            this.subjectList = newValue;
            this.init()
        },
        countList(newValue,oldValue){
            this.countList = newValue;
            this.init()
        },
        yearCode(newValue,oldValue){
            this.yearCode = newValue;
            this.init()
        },
    },
    data() {
        return {
            barChart: null, // 柱状图
            chartOption:{
                title: {
                    text: '',
                    left: 'center',
                    top: 10,
                },
                tooltip: {
                    trigger: 'item'
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        show: true,
                        interval:0,
                        rotate:30,
                    },

                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: true,
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        color: [
                            '#3ba272',
                        ],
                        barMaxWidth: 50,
                        showBackground: true,
                        barCategoryGap: '10',
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            }

        };
    },
    computed: {},
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        init() {
            this.barChart = echarts.init(document.getElementById(this.id));
            this.chartOption.title.text = this.yearCode+'年'+this.echartsTitle
            this.chartOption.series[0].data = []
            this.chartOption.series[0].data = this.countList;
            this.chartOption.xAxis.data = []
            this.chartOption.xAxis.data = this.subjectList
            this.barChart.setOption(this.chartOption);
        }
    }
};
</script>


<style scoped lang="scss">
.my-charts {
    flex-direction: column;
    padding: 10px;
    box-shadow: var(--el-box-shadow-light);
    div {
        width: 100%!important;
        height: 300px;
    }
}
</style>