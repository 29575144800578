<template>
    <div class="app-container">
        <el-card style="height: calc(100vh - 93px);overflow-y: auto;">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px"
                     @submit.native.prevent="handleQuery">
                <div class="">
                    <div class="ml10" style="font-size: 15px;font-weight: bold;">
                        基本信息
                    </div>
                    <el-row class="mt15">
                        <el-col :span="8" class="mb0">
                            <el-form-item label="姓名">
                                <el-input v-model="queryParams.trueName" placeholder="请输入" class="input-with-select">
                                    <template #append>
                                        <el-button @click="getList" type="primary" icon="el-icon-search" />
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="学校">
                                <!--                                <el-input v-model="queryParams.orgName" placeholder="请输入" class="input-with-select">-->
                                <!--                                    <template #append>-->
                                <!--                                        <el-button @click="getList" type="primary" icon="el-icon-search" />-->
                                <!--                                    </template>-->
                                <!--                                </el-input>-->
                                <el-select clearable @change="getList()"
                                           v-model="queryParams.orgId"
                                           placeholder="请选择">
                                    <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName"
                                               :value="item.orgId" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="性别">
                                <el-select clearable @change="getList()" v-model="queryParams.gender" placeholder="请选择">
                                    <el-option :label="'男'" :value="0" />
                                    <el-option :label="'女'" :value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row class="mt15">
                        <el-col :span="8" class="mb0">
                            <el-form-item label="年龄">
                                <div style="display:flex;">
                                    <el-input onkeyup="this.value=this.value.replace(/\D/g,'')"
                                              onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="queryParams.minAge"
                                              placeholder="最小值">
                                    </el-input>
                                    ~
                                    <el-input onkeyup="this.value=this.value.replace(/\D/g,'')"
                                              onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="queryParams.maxAge"
                                              placeholder="最大值">
                                    </el-input>
                                    <el-button @click="getList" type="primary" icon="el-icon-search" />
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="最高学历">
                                <el-select clearable @change="getList()" v-model="queryParams.qualification" placeholder="请选择">
                                    <el-option :key="0" :label="'硕士及以上'" :value="0" />
                                    <el-option :key="1" :label="'本科'" :value="1" />
                                    <el-option :key="2" :label="'专科及以下'" :value="2" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="政治面貌">
                                <el-select clearable @change="getList()"
                                           v-model="queryParams.politicalClimate"
                                           placeholder="请选择">
                                    <el-option v-for="item in politicalClimateList" :key="item.dictKey" :label="item.dictValue"
                                               :value="item.dictKey" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-divider />

                <div class="">
                    <div class="ml10" style="font-size: 15px;font-weight: bold;">
                        工作信息
                    </div>
                    <el-row class="mt15">
                        <el-col :span="8" class="mb0">
                            <el-form-item label="学段">
                                <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                    <el-option v-for="item in archiveXdXkList" :key="item.dictKey" :label="item.dictValue"
                                               :value="item.dictKey" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="学科">
                                <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                    <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                               :value="item.dictKey" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="骨干教师类型">
                                <el-select clearable @change="getList()" v-model="queryParams.backboneType" placeholder="请选择">
                                    <el-option v-for="item in coreTeacherTypeList" :key="item.dictKey" :label="item.dictValue"
                                               :value="item.dictKey" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="mt15">
                        <el-col :span="8" class="mb0">
                            <el-form-item label="职称">
                                <el-select clearable @change="getList()" v-model="queryParams.rankLevel" placeholder="请选择">
                                    <el-option v-for="item in technicalTitleList" :key="item.dictKey" :label="item.dictValue"
                                               :value="item.dictKey + ''" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="mb0">
                            <el-form-item label="教龄">
                                <div style="display:flex;">
                                    <el-input onkeyup="this.value=this.value.replace(/\D/g,'')"
                                              onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="queryParams.teachminAge"
                                              placeholder="最小值">
                                    </el-input>
                                    ~
                                    <el-input onkeyup="this.value=this.value.replace(/\D/g,'')"
                                              onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="queryParams.teachmaxAge"
                                              placeholder="最大值">
                                    </el-input>
                                    <el-button @click="getList" type="primary" icon="el-icon-search" />
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider />
                </div>
                <div class="" v-show="true">
                    <div class="ml10" style="font-size: 15px;font-weight: bold;">
                        档案信息
                    </div>
                    <el-row class="mt15">
                        <el-col :span="6" class="mb0">
                            <el-form-item label="学历工作信息">
                                <el-select clearable @change="getList()" v-model="queryParams.qualificationWork"
                                           placeholder="请选择">
                                    <el-option :label="'全日制毕业学历'" :value="1" />
                                    <el-option :label="'最高学历'" :value="2" />
                                    <el-option :label="'职称信息'" :value="3" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="mb0">
                            <el-form-item label="骨干教师信息">
                                <el-select clearable @change="getList()" v-model="queryParams.isBackbone" placeholder="请选择">
                                    <el-option :label="'否'" :value="0" />
                                    <el-option :label="'是'" :value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="mb0">
                            <el-form-item label="德育工作">
                                <el-select clearable @change="getList()" v-model="queryParams.moralWork" placeholder="请选择">
                                    <el-option :label="'德育称号'" :value="1" />
                                    <el-option :label="'少先队称号'" :value="2" />
                                    <el-option :label="'品质班集体相关荣誉'" :value="3" />
                                    <el-option :label="'育人活动'" :value="4" />
                                    <el-option :label="'育人经验工作分享'" :value="5" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="mb0">
                            <el-form-item label="教学工作获奖">
                                <el-select clearable @change="getList()" v-model="queryParams.teachWork" placeholder="请选择">
                                    <el-option :label="'课堂教学获奖'" :value="1" />
                                    <el-option :label="'指导学生获奖'" :value="2" />
                                    <el-option :label="'其他教学工作获奖'" :value="3" />
                                    <el-option :label="'主讲研究课'" :value="4" />
                                    <el-option :label="'教育教学、学校管理思想研讨会'" :value="5" />
                                    <el-option :label="'主讲教学研讨和交流'" :value="6" />
                                    <el-option :label="'送教下乡'" :value="7" />
                                    <el-option :label="'外出支教'" :value="8" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="mt15">
                        <el-col :span="6" class="mb0">
                            <el-form-item label="指导青年教师">
                                <el-select clearable @change="getList()" v-model="queryParams.leadWork" placeholder="请选择">
                                    <el-option :label="'指导青年教师教学成绩'" :value="1" />
                                    <el-option :label="'指导青年教师教育教学评比活动中获奖'" :value="2" />
                                    <el-option :label="'担任教育教学评比活动的评比专家'" :value="3" />
                                    <el-option :label="'名师工作室'" :value="4" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="mb0">
                            <el-form-item label="科研工作">
                                <el-select clearable @change="getList()" v-model="queryParams.scientificWork" placeholder="请选择">
                                    <el-option :label="'主持课题研究'" :value="1" />
                                    <el-option :label="'叁与课题研究'" :value="2" />
                                    <el-option :label="'论文成里获奖'" :value="3" />
                                    <el-option :label="'教育教学成果获奖'" :value="4" />
                                    <el-option :label="'出版论著'" :value="5" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="mb0">
                            <el-form-item label="继续教育">
                                <el-select clearable @change="getList()" v-model="queryParams.continueEdu" placeholder="请选择">
                                    <el-option :label="'承担培训任务'" :value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

            </el-form>
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                          v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" align="center" label="序号" width="60" />
                    <el-table-column label="教师名称" align="center" prop="trueName">
                        <template #default="scope">
                            <div @click="goCheck(scope.row)" class="cursor"
                                 style="color: #409eff;">
                                {{ scope.row.trueName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200px" align="center" label="学校" prop="orgName">
                        <template #default="scope">
                            {{ scope.row.orgName }}
                        </template>
                    </el-table-column>
                    <el-table-column label="学段" align="center" prop="periodName" />
                    <el-table-column label="学科" align="center" prop="subjectName" />
                    <el-table-column label="年龄" align="center" prop="age" />
                    <el-table-column label="教龄" align="center" prop="teachAge" />
                    <el-table-column label="填报状态" align="center">
                        <template #default="scope">
                            <div v-if="scope.row.fillStatus == 0" class="" style="color: #f56c6c;">未完成</div>
                            <div v-if="scope.row.fillStatus == 1" class="" style="color: #67c23a;">已完成</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" align="center">
                        <template #default="scope">
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 0" class="cursor"
                                 style="color: #f56c6c;">未分配</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 1" class="cursor"
                                 style="color: #409eff;">待审核</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 2" class="cursor"
                                 style="color: #67c23a;">通过</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 3" class="cursor"
                                 style="color: #f80808;">未通过</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import { archiveManagerList, archiveManagerCheckList, archiveManagerCancelDivide, archiveManagerListAll } from '@/api/admin/archive';
import { coursewarePage } from "@/api/admin/famousCourse"
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, orgListUserSchool } from "@/api/admin/public";

export default {
    name: "fileList",
    components: {
    },
    data() {
        return {
            nTb: 0,
            yTb: 0,
            nSh: 0,
            ySh: 0,
            schoolList: [],
            orgId: '',
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            token: this.$storage.get("token"),
            courseClassifyList: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                trueName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                orgName: '',
                orgId: '',
                gender: '',
                minAge: '',
                maxAge: '',
                qualification: '',
                politicalClimate: '',
                rankLevel: '',
                teachminAge: '',
                teachmaxAge: '',
                backboneType: '',
                qualificationWork: '',
                isBackbone: '',
                moralWork: '',
                teachWork: '',
                leadWork: '',
                scientificWork: '',
                continueEdu: ''
            },
            groupList: [],
            selectObj: [],
            selectList: [],
            fillTeacherList: [],
            qualificationList: [],
            technicalTitleList: [],
            archiveXdXkList: [],
            politicalClimateList: [],
            coreTeacherTypeList: [],
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
        this.getSchoolList();
    },
    activated() {
        if (this.$route.query.isSx == 1) {}
    },
    watch: {
        $route(to, from) {},
    },
    methods: {
        getSchoolList() {
            orgListUserSchool().then(res => {
                this.schoolList = res.data.data;
            })
        },
        qxCheck(row) {
            archiveManagerCancelDivide({ userId: row.userId }).then(res => {
                if (res.data.code == 1) {
                    this.getList();
                    this.$message.success('取消成功')
                } else {
                    if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    } else {
                        this.$message.error('操作失败')
                    }
                }
            })
        },
        goCheck(row) {
            let routeUrl = this.$router.resolve({
                path: '/fileFillck',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: row.userId, checkStatus: row.checkStatus, isRead: true }
            })
            window.open(routeUrl.href, '_blank')
        },
        getQueryDict() {
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            queryDict({ code: 'qualification' }).then(res => {
                this.qualificationList = res.data.data;
            })
            queryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
            queryDict({ code: 'political_climate' }).then(res => {
                this.politicalClimateList = res.data.data;
            })
            queryDict({ code: 'core_teacher_type' }).then(res => {
                this.coreTeacherTypeList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.archiveXdXkList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.subjectList = j.childrenList;
                }
            })
            this.getList()
        },
        changeSchool() {
            this.queryParams = {
            }
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.loading = true;
            this.dataList = [];
            archiveManagerListAll().then(res => {
                if (this.queryParams.periodKey && this.queryParams.periodKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.periodKey == this.queryParams.periodKey
                    })
                }
                if (this.queryParams.gradeKey && this.queryParams.gradeKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.gradeKey == this.queryParams.gradeKey
                    })
                }
                if (this.queryParams.subjectKey && this.queryParams.subjectKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.subjectKey.toString().indexOf(this.queryParams.subjectKey) != -1
                    })
                }
                if (this.queryParams.trueName && this.queryParams.trueName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.trueName.toString().indexOf(this.queryParams.trueName) != -1
                    })
                }
                if (this.queryParams.orgName && this.queryParams.orgName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgName.toString().indexOf(this.queryParams.orgName) != -1
                    })
                }
                if (this.queryParams.orgId && this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId.toString().indexOf(this.queryParams.orgId) != -1
                    })
                }
                if (this.queryParams.gender !== '' && this.queryParams.gender != undefined) {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.gender == this.queryParams.gender
                    })
                }
                console.log(this.queryParams.minAge, this.queryParams.maxAge);
                if (this.queryParams.minAge !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return Number(itemA.age) >= Number(this.queryParams.minAge)
                    })
                }
                if (this.queryParams.maxAge !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return Number(itemA.age) <= Number(this.queryParams.maxAge)
                    })
                }
                if (this.queryParams.qualification !== '' && this.queryParams.qualification !== undefined) {
                    res.data.data = res.data.data.filter(itemA => {
                        console.log(itemA.qualification, this.queryParams.qualification);
                        return itemA.qualification == this.queryParams.qualification
                    })
                }
                if (this.queryParams.politicalClimate && this.queryParams.politicalClimate !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.politicalClimate.toString().indexOf(this.queryParams.politicalClimate) != -1
                    })
                }
                if (this.queryParams.rankLevel && this.queryParams.rankLevel !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.rankLevel.toString().indexOf(this.queryParams.rankLevel) != -1
                    })
                }
                if (this.queryParams.teachminAge !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return Number(itemA.teachAge) >= Number(this.queryParams.teachminAge)
                    })
                }
                if (this.queryParams.teachmaxAge !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return Number(itemA.teachAge) <= Number(this.queryParams.teachmaxAge)
                    })
                }
                if (this.queryParams.backboneType && this.queryParams.backboneType !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.backboneType.toString().indexOf(this.queryParams.backboneType) != -1
                    })
                }
                if (this.queryParams.qualificationWork && this.queryParams.qualificationWork !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.qualificationWork.toString().indexOf(this.queryParams.qualificationWork) != -1
                    })
                }
                if (this.queryParams.isBackbone !== '' && this.queryParams.isBackbone != undefined) {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isBackbone == this.queryParams.isBackbone
                    })
                }
                if (this.queryParams.moralWork && this.queryParams.moralWork !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.moralWork.toString().indexOf(this.queryParams.moralWork) != -1
                    })
                }
                if (this.queryParams.teachWork && this.queryParams.teachWork !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.teachWork.toString().indexOf(this.queryParams.teachWork) != -1
                    })
                }
                if (this.queryParams.leadWork && this.queryParams.leadWork !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.leadWork.toString().indexOf(this.queryParams.leadWork) != -1
                    })
                }
                if (this.queryParams.scientificWork && this.queryParams.scientificWork !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.scientificWork.toString().indexOf(this.queryParams.scientificWork) != -1
                    })
                }
                if (this.queryParams.continueEdu && this.queryParams.continueEdu !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.continueEdu.toString().indexOf(this.queryParams.continueEdu) != -1
                    })
                }
                this.loading = false;
                this.dataList = res.data.data;
            });

        },



    }
};
</script>
<style scoped>
.el-progress {
    width: 100%;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>