<template>
    <div class="base-container" style="overflow-y: hidden!important;">
        <div style="height: calc(100vh - 10px);display: flex;;overflow-y: hidden;">
            <div class="left_box">
                <!--                <div class="pd10">-->
                <!--                    <span style="font-weight: bold;">大纲</span>-->
                <!--                </div>-->
                <el-card style="height: calc(100vh - 5px);overflow-y: auto;">
                    <div v-if="bookData.imgUrl">
                        <div :id="'zmd' + index" @click="changeCurrent(index)" class="swbox"
                             v-for="(item, index) in bookData.pages" style="position: relative;">
                            <Canvasbox :bookData="bookData" :imwidth="150"
                                       :imheight="bookData.fileHeight / bookData.fileWidth * 150"
                                       :canvasid="bookData.fileId + '' + index"
                                       :picSrc="bookData.imgUrl + '/' + (index + 1) + '.png'">
                            </Canvasbox>
                            <div :class="current == index ? 'bor-lan' : ''"
                                 :style="'width: 150px;height: ' + bookData.fileHeight / bookData.fileWidth * 150 + 'px;top: 0;position: absolute;border: 1px solid rgb(238, 238, 238);border-radius: 10px;'">

                            </div>
                            <div class="pageNumbox">{{ index + 1 }}</div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="mid_box">
                <el-card style="height: calc(100vh - 5px);">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div style="display: flex;">
                            <div><span style="font-weight: bold;">备课标题：</span>{{ form.title }}</div>
                            <div style="padding-left: 30px;"><span style="font-weight: bold;">主备人：</span>{{ form.leadUserName }}</div>
                        </div>
                    </div>
                    <div style="padding: 15px 0;display: flex;align-items: center;justify-content: space-between;">
                        <el-radio-group v-if="this.form.status != 4" @change="changeLessonType" v-model="lessonType" size="large">
                            <el-radio-button v-show="form.jaFiles && form.jaFiles.length != 0" label="教案" />
                            <el-radio-button v-show="form.xaFiles && form.xaFiles.length != 0" label="学案" />
                            <el-radio-button v-show="form.kjFiles && form.kjFiles.length != 0" label="课件" />
                            <el-radio-button v-show="form.xtFiles && form.xtFiles.length != 0" label="习题" />
                        </el-radio-group>
                        <el-radio-group v-if="this.form.status == 4" @change="changeLessonType" v-model="lessonType" size="large">
                            <el-radio-button v-show="form.ytjaFiles && form.ytjaFiles.length != 0" label="教案" />
                            <el-radio-button v-show="form.ytxaFiles && form.ytxaFiles.length != 0" label="学案" />
                            <el-radio-button v-show="form.ytkjFiles && form.ytkjFiles.length != 0" label="课件" />
                            <el-radio-button v-show="form.ytxtFiles && form.ytxtFiles.length != 0" label="习题" />
                        </el-radio-group>
                        <el-select @change="changeFile" v-model="fileId" placeholder="请选择文件" size="large">
                            <el-option v-for="item in fileList" :key="item.fileId" :label="item.fileName"
                                       :value="item.fileId" />
                        </el-select>
                    </div>
                    <div ref="bjcan" id="bjcan" :style="`height: calc(100vh - 150px);overflow-y: auto;padding-bottom: 15px;`">
                        <div
                          :style="`width: ${zdyWidth}px;position: relative;height:${bookData.fileHeight / bookData.fileWidth * zdyWidth}px`">
                            <img :width="zdyWidth" :src="bookData.imgUrl + '/' + (current + 1) + '.png'" alt=""
                                 style="border: 1px solid #eeeeee;border-radius: 10px;position: absolute;">
                        </div>
                    </div>
                </el-card>
            </div>

            <div class="right_box">
                <div class="discuss-title-box">
                    备课研讨区
                </div>
                <div class="discuss-sub-box" >
                    <div class="discuss-info">
                        <el-input
                          type="textarea"
                          @focus="focusInput"
                          resize="none"
                          placeholder="请输入研讨内容"
                          v-model="discussWordContent"
                          autofocus
                          class="discuss-info-input"
                        >
                        </el-input>
                    </div>
                    <div class="discuss-btn-box">
                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/discuss/uploadAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadFileSuccess"
                                       :headers="{ token }"
                                       accept=".doc,.docx,.ppt,.pptx,.pdf"
                                       :before-upload="beforeFileUpload">

                                <img class="icon-upload-img" alt="上传附件" src="../../assets/icon/img/upload_file1.png">
                            </el-upload>
                        </div>

                        <div class="limit">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/discuss/uploadAnnex'"
                                       :show-file-list="false"
                                       :on-success="handleUploadImageSuccess"
                                       :headers="{ token }"
                                       accept=".png,.jpeg,.jpg"
                                       :before-upload="beforeImageUpload">
                                <img class="icon-upload-img" alt="上传图片" src="../../assets/icon/img/upload_img1.png">
                            </el-upload>
                        </div>
                        <el-button type="primary" style="margin-left: 100px;" round @click="sumbit">
                            发表研讨
                        </el-button>
                    </div>
                </div>
                <el-scrollbar class="discuss-show-box">
                    <div class="discuss-one" v-for="item in discussList">
                        <div class="discuss-user-avatar">
                            <img alt="无" @error="onImageError" :src="item.avatar">
                        </div>
                        <div class="discuss-user-content">
                            <div class="user-info">
                                <div class="flex-item user-true-name">{{item.createUserName}}</div>
                                <div class="flex-item user-role">
                                    <div class="role1" v-show="item.roleType===3">专家</div>
                                    <div class="role2" v-show="item.roleType===0">主备人</div>
                                    <div class="role2" v-show="item.roleType===1">协备人</div>
                                </div>
                                <div class="flex-item content-time">{{timestampToTime(item.createTime)}}</div>
                            </div>
                            <div class="content-info" v-if="item.discussType===1">
                                {{item.content}}
                            </div>
                            <div class="img-info" v-if="item.discussType===2">
                                <el-image alt="" :src="item.imgContent" :preview-src-list="item.imgList" ></el-image>
                            </div>
                            <div class="audio-info" v-if="item.discussType===3">
                                <audio controls controlslist="nodownload noplaybackrate" ref="audio">
                                    <source type="audio/mpeg" :src="item.audioContent" />
                                </audio>
                            </div>
                            <div class="file-info" v-if="item.discussType===4">
                                <div class="file-box">
                                    <div class="file-icon">
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-pdf@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('doc',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-doc@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleFIleStatus(item.file.status)" class="icon-image" v-if="comStr('ppt',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-ppt@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                        <el-image @click="handleUnknow" class="icon-image" v-if="!comStr('ppt',item.file.fileType)&&!comStr('doc',item.file.fileType)&&!comStr('pdf',item.file.fileType)"
                                                  :src="require('@/assets/icon/img/file-unknown@3x.png')"
                                                  :preview-src-list="gainFileImgList(item.file.imgUrl,item.file.pages)">
                                        </el-image>
                                    </div>
                                    <div class="file-detail">
                                        <div class="name-box" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">{{item.file.fileName}}</div>
                                        <div class="size-box">
                                            <div class="size-num">{{item.file.fileSize}}</div>
                                            <div class="file-view">
                                                <img alt="" src="@/assets/icon/img/download.png" @click.stop="handleDownload(item.file.fileUrl,item.file.fileName)">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="discussList.length===0" class="data-empty">
                        <div class="empty-icon"><img src="../../assets/icon/img/data_empty.png" /></div>
                        <div class="empty-title">暂无数据</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
    <!-- 文件转图片查看-->
</template>

<script>
import { lessonInfo, discussCreate, discussDel, discussList} from "@/api/admin/lesson";
import { timestampToTime } from "@/utils/date";
import {Upload} from "@element-plus/icons-vue";
import {downloadFile} from "@/api/adminservice/public";
import { ElLoading } from 'element-plus'
import { serviceUrl } from "@/utils/global"

export default {
    name: "lessonDiscuss",
    computed:{
        inputLength(){
            return this.discussWordContent.length;
        }
    },
    components: {Upload},
    props: {
        teacherBookId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            serviceUrl,
            token: this.$storage.get("token"),
            discussWordContent:'',
            activeDiscussId: '',
            userId: JSON.parse(this.$storage.get("userInfo")).userId,
            rightDown: false,
            isComShow: false,
            commentListto: [],
            isPls: false,
            lessonId: '',
            lessonType: '', // 1教案 2课件 3习题 4学案
            active: 1,
            current: 0,
            isZjShow: false,
            isShow: false,
            timestampToTime,
            bookData: {},
            fileId: '',
            fileList: [],
            strokeList: [],
            // 基础：基数数据表格数据
            dataList: [],
            form: {
                jaFiles:[],
                xaFiles:[],
                kjFiles:[],
                xtFiles:[]
            },
            zdyWidth: 0,
            zdyHeight: 0,
            isStart: false,
            isTime: false,
            isNull: true,
            wordContent: '',//文字评论
            imgContent: '',//图片评论
            fileContent: '',//附件评论
            rightData: {},
            isCreat: false,
            contextMenus: [
                {
                    label: '编辑',
                    icon: 'Edit',
                    handler: this.editCont
                },
                {
                    label: '复制',
                    icon: 'CopyDocument',
                    handler: this.triggerCopy
                },
                {
                    label: '删除',
                    icon: 'Delete',
                    handler: this.delete
                },
            ],
            isRd:'',
            discussList: [],
            discussType:1, //研讨内容类型：1-文字 2-图片 3-音频 4-附件
            isShowFileImg:false,
            showTip: false,
            fileName:'',
            fileImgList:[],
        };
    },
    created() {},
    mounted() {
        if(this.$route.query.isRd){
            this.isRd = this.$route.query.isRd
        }
        this.lessonId = this.$route.query.lessonId
        console.log(this.bookData)
        this.zdyWidth = (document.getElementById("bjcan").clientWidth - 20)
        this.getDetail()
    },
    methods: {
        focusInput () {
            this.closeInput = false
        },
        next() {
            this.x = 0
            this.y = 0
            this.w = this.zdyWidth / 3
            this.h = 300
            this.col = '#333333'
            this.value1 = ''
        },
        editCont() {
            console.log(this.rightData)
            this.x = this.rightData.x0;
            this.y = this.rightData.y0;
            this.w = this.zdyWidth / 3;
            this.h = 300;
            this.value1 = this.rightData.content;
            this.col = this.rightData.contentColor;
            this.isPls = true;
            this.rightDown = false;
        },
        copyToClipboard(textToCopy) {
            // navigator clipboard 需要https等安全上下文
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                return navigator.clipboard.writeText(textToCopy);
            } else {
                // 创建text area
                let textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // 使text area不在viewport，同时设置不可见
                textArea.style.position = "absolute";
                textArea.style.opacity = 0;
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                });
            }
        },
        triggerCopy() {
            this.copyToClipboard(this.rightData.content)
            this.rightDown = false;
        },
        deleteDiscuss() {
            discussDel({ discussId: this.rightData.discussId }).then(res => {
                if (res.data.code == 1) {
                    this.rightDown = false;
                    this.$message.success('删除成功')
                    this.getDisList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        sumbit() {
            const obj = {
                pageNum: this.current,
                fileId: this.fileId,
                content: this.wordContent,
                imgContent: this.imgContent,
                fileContent: this.fileContent,
            };
            //文字评论
            if (this.discussType===1){
                this.wordContent = this.discussWordContent
                if (this.wordContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content=this.wordContent;
                obj.imgContent = '';
                obj.fileContent = ''
            }
            //图片评论
            if (this.discussType===2){
                if (this.imgContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = this.imgContent;
                obj.fileContent = ''
            }
            //附件评论
            if (this.discussType===4){
                if (this.fileContent===''){
                    this.$message.info("研讨内容不能为空")
                    return
                }
                obj.content='';
                obj.imgContent = '';
                obj.fileContent = this.fileContent
            }
            const loading = ElLoading.service({ fullscreen: true })
            discussCreate(obj).then(res => {
                if (res.data.code === 1) {
                    this.$message.success("发表成功");
                    this.wordContent = ''
                    this.discussWordContent = ''
                    this.imgContent = ''
                    this.fileContent = ''
                    this.discussType = 1
                    this.getDisList();
                } else {
                    this.$message.error("操作失败")
                    this.discussType = 1
                }
            })
            loading.close()
        },
        getDisList() {
            this.isPls = false;
            discussList({ fileId: this.fileId, pageNum: this.current }).then(res => {
                this.discussList = res.data.data;
            })
        },
        changeLessonType(e) {
            if (this.form.status != 4) {
                if (this.lessonType == '教案') {
                    this.fileList = this.form.jaFiles
                    if (this.form.jaFiles.length != 0) {
                        this.fileId = this.form.jaFiles[0].fileId
                        this.bookData = this.form.jaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '学案') {
                    this.fileList = this.form.xaFiles
                    if (this.form.xaFiles.length != 0) {
                        this.fileId = this.form.xaFiles[0].fileId
                        this.bookData = this.form.xaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '课件') {
                    this.fileList = this.form.kjFiles
                    if (this.form.kjFiles.length != 0) {
                        this.fileId = this.form.kjFiles[0].fileId
                        this.bookData = this.form.kjFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '习题') {
                    this.fileList = this.form.xtFiles
                    if (this.form.xtFiles.length != 0) {
                        this.fileId = this.form.xtFiles[0].fileId
                        this.bookData = this.form.xtFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                }
            } else {
                if (this.lessonType == '教案') {
                    this.fileList = this.form.ytjaFiles
                    if (this.form.ytjaFiles.length != 0) {
                        this.fileId = this.form.ytjaFiles[0].fileId
                        this.bookData = this.form.ytjaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '学案') {
                    this.fileList = this.form.ytxaFiles
                    if (this.form.ytxaFiles.length != 0) {
                        this.fileId = this.form.ytxaFiles[0].fileId
                        this.bookData = this.form.ytxaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '课件') {
                    this.fileList = this.form.ytkjFiles
                    if (this.form.ytkjFiles.length != 0) {
                        this.fileId = this.form.ytkjFiles[0].fileId
                        this.bookData = this.form.ytkjFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '习题') {
                    this.fileList = this.form.ytxtFiles
                    if (this.form.ytxtFiles.length != 0) {
                        this.fileId = this.form.ytxtFiles[0].fileId
                        this.bookData = this.form.ytxtFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                }
            }

            this.$refs.bjcan.scrollTo({ top: 0 });
            this.current = 0;
            this.getDisList();
        },
        changeFile(e) {
            this.fileList.forEach(j => {
                if (j.fileId == this.fileId) {
                    this.bookData = j;
                }
            })
            this.current = 0;
            this.$refs.bjcan.scrollTo({ top: 0 });
            this.getDisList();
        },
        // 取消按钮
        cancel() {
            this.$emit('isTg', true)
        },
        changeCurrent(index) {
            this.current = index;
            console.log(this.$refs.bjcan)
            this.$refs.bjcan.scrollTo({ top: 0 });
            this.next();
            this.getDisList();
        },
        getDetail() {
            lessonInfo({ lessonId: this.lessonId }).then(res => {
                var textList = [];
                res.data.data.teamUserList.forEach(j => {
                    textList.push(j.trueName)
                })
                console.log(textList)
                res.data.data.teamUserText = textList.join('、')
                this.form = res.data.data;
                if (this.form.status !== 4) {
                    if (this.form.jaFiles.length !== 0) {
                        this.lessonType = '教案'
                        this.fileList = this.form.jaFiles
                        this.bookData = this.form.jaFiles[0]
                        this.fileId = this.form.jaFiles[0].fileId
                    } else {
                        if (this.form.xaFiles.length !== 0) {
                            this.lessonType = '学案'
                            this.bookData = this.form.xaFiles[0]
                            this.fileList = this.form.xaFiles
                            this.fileId = this.form.xaFiles[0].fileId
                        } else {
                            if (this.form.kjFiles.length !== 0) {
                                this.lessonType = '课件'
                                this.bookData = this.form.kjFiles[0]
                                this.fileList = this.form.kjFiles
                                this.fileId = this.form.kjFiles[0].fileId
                            } else {
                                if (this.form.xtFiles.length !== 0) {
                                    this.lessonType = '习题'
                                    this.bookData = this.form.xtFiles[0]
                                    this.fileList = this.form.xtFiles
                                    this.fileId = this.form.xtFiles[0].fileId
                                } else {

                                }
                            }
                        }

                    }
                } else {
                    if (this.form.ytjaFiles.length !== 0) {
                        this.lessonType = '教案'
                        this.fileList = this.form.ytjaFiles
                        this.bookData = this.form.ytjaFiles[0]
                        this.fileId = this.form.ytjaFiles[0].fileId
                    } else {
                        if (this.form.ytxaFiles.length !== 0) {
                            this.lessonType = '学案'
                            this.bookData = this.form.ytxaFiles[0]
                            this.fileList = this.form.ytxaFiles
                            this.fileId = this.form.ytxaFiles[0].fileId
                        } else {
                            if (this.form.ytkjFiles.length !== 0) {
                                this.lessonType = '课件'
                                this.bookData = this.form.ytkjFiles[0]
                                this.fileList = this.form.ytkjFiles
                                this.fileId = this.form.ytkjFiles[0].fileId
                            } else {
                                if (this.form.ytxtFiles.length !== 0) {
                                    this.lessonType = '习题'
                                    this.bookData = this.form.ytxtFiles[0]
                                    this.fileList = this.form.ytxtFiles
                                    this.fileId = this.form.ytxtFiles[0].fileId
                                } else {

                                }
                            }
                        }

                    }
                }

                this.current = 0;
                this.getDisList();

            })
        },
        onImageError(event){
            event.target.src = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/load_fail_default.png';
        },
        handleUploadImageSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.imgContent = uploadFile.response.data;
                this.discussType = 2
                this.sumbit()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeImageUpload(rawFile){
            if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleUploadFileSuccess(response, uploadFile){
            if (uploadFile.response.code === 1) {
                this.fileContent = uploadFile.response.data;
                this.discussType = 4
                this.sumbit()
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeFileUpload(rawFile){
            return true
        },
        comStr(baseStr,type){
            return type!==''&& type.toUpperCase().includes(baseStr.toUpperCase());
        },
        gainFileImgList(baseUrl,page,){
            const list = [];
            for (let i = 1; i <=page ; i++) {
                list.push(baseUrl+'/' + i+'.png');
            }
            return list;
        },
        handleDownload(url,fileName){
            downloadFile(url,fileName)
        },
        handleUnknow(){
            this.$message.info("该文件类型暂不支持预览")
        },
        handleFIleStatus(status){
            if (status === 1) {

            }else {
                this.$message.info("文件正在处理，请稍后刷新预览")
            }
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;

}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}
.base-container {
    padding: 0 0 0 0;
}
.left_box {
    padding: 15px 0 15px 0;
    width: 190px;
    height: 100%;
    margin-right: 10px;
}
.mid_box {
    padding: 15px 0 15px 0;
    flex: 1;
}
.right_box {
    padding: 15px 5px 15px 0px;
    width: 260px;
    height: 100%;
    margin-left: 10px;
}

.discuss-title-box{
    margin-bottom: 10px;
    height: 20px;
}

.discuss-sub-box{
    width: 100%;
    background-color: rgba(245, 246, 247, 0.9);
    border-radius: 8px;
    margin-top: 5px;
    padding-bottom: 10px;
    height: 80px;
    .discuss-info{
        display: inline-block;
        width: 100%;
        >>>.el-textarea__inner::placeholder{
            font-size: 12px;
        }
        >>>.el-textarea__inner{
            font-size: 12px;
        }
    }

    .discuss-btn-box{
        display: flex;
        padding: 0 6px;
        margin-top: 10px;
        .el-button--primary{
            width: 66px;
            height: 26px;
            font-size: 12px;
            line-height: 30px!important;
            text-align: center;
            &:hover{
                background-color: #1f89de;
            }
        }
        .limit{
            width: 26px;
            height: 26px;
            background-color: #1f89de;
            font-size: 12px;
            color: #999aaa;
            border-radius: 13px;
            margin-right: 10px;
            .icon-upload-img{
                width: 20px;
                height: 20px;
                margin: 3px 0 0 3px;
            }
        }
    }
}

.discuss-show-box{
    width: 260px!important;
    margin: auto;
    //height: auto;
    height: calc(100vh - 150px)!important;
}
/deep/ .el-scrollbar__thumb {
    background-color: #ec623e;
}
/deep/.el-scrollbar__wrap {
    overflow-x: hidden;
}
.discuss-one{
    display: flex;
    margin-bottom: 10px;
    .discuss-user-avatar{
        width: 40px;
        img{
            width: 30px!important;
            height: 30px;
            border-radius: 15px!important;
        }
    }
    .discuss-user-content{
        width: 220px;
        font-size: 14px;
        .user-info{
            display: flex;
            height: 25px;
            padding-right: 10px;
            .flex-item:last-child{
                margin-left: auto;
            }
            .user-true-name{
                font-size: 12px;
                //font-weight: bold;
                //width: 40px;
                height: 25px;
                line-height: 25px;
                text-align: left;
                margin-right: 10px;
                align-content: flex-start;
            }
            .user-role{
                font-size: 10px;
                div{
                    text-align: center;
                }
                .role1{
                    padding: 1px 4px 1px 4px;
                    background-color: #1f89de;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #FFFFFF;
                }
                .role2{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1f1f1;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #666666;
                }
                .role3{
                    padding: 1px 4px 1px 4px;
                    background-color: #f1a792;
                    margin-top: 4px;
                    border-radius: 4px;
                    color: #1f89de;
                }
            }
            .content-time{
                line-height: 25px;
                font-size: 12px;
                align-content: flex-end;
            }
        }
        .content-info{
            padding: 2px 10px 0 0;
            font-size: 11px;
            color: #525B68;
            line-height: 16px;
            word-wrap:break-word;
            word-break:break-all;

        }
        .img-info{
            padding: 2px 10px 0 0;
            display: flex;
            width: 180px;
            flex-wrap: wrap;
            .el-image{
                width: 120px;
                height: auto;
                margin: 0 5px 5px 0;
            }
        }
        .audio-info{
            padding: 2px 10px 0 0;
            audio{
                width: 210px;
                height: 40px;
            }
        }
        .file-info{
            padding: 2px 10px 0 0;
            .file-box{
                width: 210px;
                height: 44px;
                background-color: #f1f3f4;
                border-radius: 5px;
                display: flex;
                .file-icon{
                    width: 40px;
                    height: 40px;
                    img{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                    .icon-image{
                        width: 24px;
                        height: 30px;
                        margin-top: 6px;
                        margin-left: 6px;
                    }
                }
                .file-detail{
                    width: 160px;
                    height: 40px;
                    padding: 2px 0 2px 0;
                    div{
                        width: 160px;
                        height: 20px;
                    }
                    .name-box{
                        line-height: 24px;
                        font-size: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        &:hover{
                            color: #1f89de;
                        }
                    }
                    .size-box{
                        display: flex;
                        font-size: 10px;
                        line-height: 20px;
                        .file-view{
                            width: 15px;
                            height: 15px;
                            //background-color: #1f89de;
                            border-radius: 5px;
                            margin-top: 5px;
                            img{
                                width: 13px;
                                height: 13px;
                                margin: 1px 0 0 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

    }

}














.bor-lan {
    border: 1px solid #fbac97 !important;
}


.swbox {
    margin-bottom: 10px;
    flex: 1;
}

.pageNumbox {
    text-align: center;
    padding: 10px 0 0 0;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

#bjcan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inputStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
}

.inputStyle /deep/ .el-textarea__inner {
    height: 100% !important;
    color: unset !important;
    text-decoration: none !important;
    padding: 0 !important;
}


.inputStyle .el-input__prefix {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-left: 30px;
    left: 0;
    visibility: hidden;
}

.inputStyle .el-input__inner {
    position: absolute;
}

.inputStyle /deep/ .el-textarea__inner {
    /* background: transparent; */
    background: rgba(220, 234, 247, 0.5);
    border-radius: 10px;
    min-height: 300px;
}

.df {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 145px;
    height: 50px;
    right: 0;
    /* justify-content: end; */
    justify-content: space-between;
}

.lblitem {
    padding: 5px;
}

.tst /deep/ .el-input__wrapper {
    padding: 0 !important;
}

</style>