import request from "@/utils/request";
// 添加资讯分类
export const  studioAddClassify =(query) =>{
    return request({
        url: "/businessservice/studio/addClassify",
        method:"get",
        params: query
    })
}
// 创建工作室资讯
export const studioCreate =(data) =>{
    return request({
        url: "/businessservice/studio/create",
        method:"post",
        data: data
    })
}
//查询资讯分类
export const  studioQueryClassify =(query) =>{
    return request({
        url: "/businessservice/studio/queryClassify",
        method:"get",
        params: query
    })
}
// 用户管理的工作室列表
export const  manageStudioList =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/manageStudioList",
        method:"get",
        params: query
    })
}
// 关注或取消关注工作室
export const  studioCareOrCancel =(query) =>{
    return request({
        url: "/businessservice/studio/careOrCancel",
        method:"get",
        params: query
    })
}
// 删除工作室资讯
export const  studioDelete =(query) =>{
    return request({
        url: "/businessservice/studio/delete",
        method:"get",
        params: query
    })
}
// 查询工作室资讯详情
export const  studioInfo =(query) =>{
    return request({
        url: "/businessservice/studio/info",
        method:"get",
        params: query
    })
}
// 名校长工作室首页详情
export const  studioPrincipalHome =(query) =>{
    return request({
        url: "/businessservice/studio/principalHome",
        method:"get",
        params: query
    })
}
// 名师工作室首页详情
export const  studioTeacherHome =(query) =>{
    return request({
        url: "/businessservice/studio/teacherHome",
        method:"get",
        params: query
    })
}
// 更新工作室资讯
export const studioUpdate =(data) =>{
    return request({
        url: "/businessservice/studio/update",
        method:"post",
        data: data
    })
}
// 上传工作室资讯文件
export const studioUpload =(data) =>{
    return request({
        url: "/businessservice/studio/upload",
        method:"post",
        data: data
    })
}
// 工作室详情
export const  infoStudio =(query) =>{
    return request({
        url: "/businessservice/studio/infoStudio",
        method:"get",
        params: query
    })
}
// 查询名师，名校长工作室工作简报列表
export const studiobriefList =(query) =>{
    return request({
        url: "/businessservice/studio/brief/list",
        method:"get",
        params: query
    })
}
// 工作简报置顶或取消置顶
export const  studioBriefTopOrCancelTop =(query) =>{
    return request({
        url: "/businessservice/studio/brief/topOrCancelTop",
        method:"get",
        params: query
    })
}
// 阅读工作室资讯(阅读数+1)
export const  studioRead =(query) =>{
    return request({
        url: "/businessservice/studio/read",
        method:"get",
        params: query
    })
}
// 删除成果附件
export const studioDeleteFile = (query) => {
    return request({
        url: "/businessservice/studio/deleteFile",
        method: "get",
        params: query
    })
}