<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课件管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!coursewareId">新增课件</el-breadcrumb-item>
                <el-breadcrumb-item v-if="coursewareId">编辑课件</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                           :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="查看组织" prop="orgIdList">
                            <el-tree-select style="width: 100%;" clearable multiple @change="changeOrg"
                                            :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                                            v-model="form.orgIdList" default-expand-all :data="treeData" :render-after-expand="false"
                                            show-checkbox check-strictly check-on-click-node />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="优课类型" prop="stageType">
                            <el-select style="width: 100%;" v-model="form.stageType" placeholder="请选择优课类型">
                                <el-option v-for="item in courseClassifyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="封面" prop="cover">
                            <el-upload class="avatar-uploader"
                                       :action="serviceUrl+'/businessservice/famousCourse/uploadCover'"
                                       :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                       :before-upload="beforeAvatarUpload">
                                <img v-if="form.cover" :src="form.cover" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件名称" prop="name">
                            <el-input v-model="form.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="简介" prop="recommend">
                            <el-input type="textarea" maxlength="1000" v-model="form.recommend" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="介绍图片" prop="recommendImgList">
                            <div style="display: flex;flex-wrap:wrap;">
                                <div v-for="(item, index) in recommendImgList" class="csbbb">
                                    <el-icon @click="delImg(index)" :size="20" class="eicon"
                                             style="cursor: pointer;z-index: 99;color: red;">
                                        <Delete />
                                    </el-icon>
                                    <img :src="item.response.data" v-if="item.response"
                                         style="width: 148px;height: 148px;margin-right: 5px;margin-bottom: 5px;">
                                </div>
                            </div>
                            <el-upload v-model:file-list="recommendImgList" :headers="{ token }" multiple
                                       :show-file-list="false"
                                       :action="serviceUrl+'/businessservice/courseware/recommendImg'"
                                       :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg" list-type="picture-card"
                                       :on-remove="handleRemove">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { orgListTree } from "@/api/admin/system";
import { coursewareInfo, coursewareUpdate, coursewareCreate } from "@/api/admin/famousCourse"
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup, groupListAuthGroup } from "@/api/admin/public";
import { serviceUrl } from "@/utils/global"
export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            serviceUrl,
            treeData: [],
            recommendImgList: [],
            groupList: [],
            coursewareId: '',
            fileList: [],
            jAFileList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            commonSwitch: true,
            // 表单参数
            form: {
                isCommon: true,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                cover: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/msyk.png',
                teamuseList: [],
                groupId: '',
                orgIdList: []
            },
            // 表单校验
            rules: {
                orgIdList: [
                    { required: true, message: "请选择组织", trigger: "change" },
                ],
                cover: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                stageType: [
                    { required: true, message: "请选择课程类型", trigger: "change" },
                ],
                name: [
                    { required: true, message: "请输入课件名称", trigger: "blur" },
                ],
                userId: [
                    { required: true, message: "请选择主讲人", trigger: "change" },
                ],
                recommend: [
                    { required: true, message: "请输入简介", trigger: "blur" },
                ]
            },
            delFileList: [],
        };
    },
    created() {
        this.getOrgListTree();
        this.getGroup();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.coursewareId) {
            this.coursewareId = this.$route.query.coursewareId
            console.log(this.form)
            this.getfamousCourseInfo()
        } else {
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        getOrgListTree() {
            orgListTree().then(res => {
                res.data.data.forEach(e => {
                    e.value = e.orgId
                    e.orgName = e.orgName
                    if (e.childrenList && e.childrenList != null) {
                        e.children = e.childrenList;
                        e.children.forEach(m => {
                            m.value = m.orgId
                            m.orgName = m.orgName
                            if (m.childrenList && m.childrenList != null) {
                                m.children = m.childrenList;
                                m.children.forEach(j => {
                                    j.value = j.orgId
                                    j.orgName = j.orgName
                                })
                            }
                        })
                    }
                })
                this.treeData = res.data.data;
            })
        },
        getGroup() {
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            groupListAuthGroup({ groupNature: 2 }).then(res => {
                res.data.data.forEach(e => {
                    this.groupList.push(e)
                })
                groupListAuthGroup({ groupNature: 3 }).then(response => {
                    response.data.data.forEach(j => {
                        this.groupList.push(j)
                    })
                })
            })
            // listTeacherGroup().then(res => {
            //     res.data.data.forEach(j => {
            //         j.memberList.forEach(m => {
            //             if (m.memberRole == 0 || m.memberRole == 1) {
            //                 if (m.memberUserId == userId) {
            //                     this.groupList.push(j)
            //                     // throw new Error();
            //                 }
            //             }
            //         })
            //     })
            //     listHeadmasterGroup().then(res => {
            //         res.data.data.forEach(j => {
            //             j.memberList.forEach(m => {
            //                 if (m.memberRole == 0 || m.memberRole == 1) {
            //                     if (m.memberUserId == userId) {
            //                         this.groupList.push(j)
            //                         // throw new Error();
            //                     }
            //                 }
            //             })
            //         })
            //     })
            // })
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.cover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles, this.recommendImgList)
            if (file.response) {
                this.delFileList.push(file.response.data)
            }
        },
        delImg(index) {
            this.recommendImgList.splice(index, 1)
        },
        getfamousCourseInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                coursewareInfo({ coursewareId: this.coursewareId }).then(res => {
                    this.form = res.data.data;
                    this.recommendImgList = []
                    if (res.data.data.recommendImg) {
                        res.data.data.recommendImgList = res.data.data.recommendImg.split(',')
                        res.data.data.recommendImgList.forEach(e => {
                            this.recommendImgList.push({ response: { data: e } })
                        })
                    }

                    if (this.form.orgIds) {
                        this.form.orgIdList = this.form.orgIds.split(',')
                    } else {
                        this.form.orgIdList = [];
                    }
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.cover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        changeOrg(e) {
            console.log(e);
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var recommendImgList = [];
                    this.recommendImgList.forEach(e => {
                        recommendImgList.push(e.response.data)
                    })
                    this.form.recommendImg = recommendImgList.toString();

                    if (this.form.stageType != 1 && this.form.stageType != 2 && this.form.stageType != 3) {
                        this.form.groupId = ''
                    }
                    if (this.form.orgIdList) {
                        this.form.orgIds = this.form.orgIdList.toString();
                    }
                    var Obj = JSON.parse(JSON.stringify(this.form))
                    delete Obj.groupId
                    delete Obj.groupId
                    delete Obj.isCommon
                    delete Obj.leadUser
                    delete Obj.lessonLevelKey
                    delete Obj.orgIdList
                    delete Obj.teamUsers
                    delete Obj.teamuseList
                    delete Obj.title
                    delete Obj.userId
                    if (this.coursewareId) {
                        coursewareUpdate(Obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/courseware', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        coursewareCreate(Obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/courseware', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/courseware')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}
</style>