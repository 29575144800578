import request from "@/utils/request";
// 整体提交档案填报
export const archiveFillBatch = (data) => {
    return request({
        url: "/businessservice/archive/fill/batch",
        method: "post",
        data: data
    })
}
// 删除档案填报
export const archiveFillDelete = (query) => {
    return request({
        url: "/businessservice/archive/fill/delete",
        method: "get",
        params: query
    })
}
// 单条档案填报（修改或更新）
export const archiveFillSingle = (data) => {
    return request({
        url: "/businessservice/archive/fill/single",
        method: "post",
        data: data
    })
}
// 上传档案文件
export const archiveFillUploadFile = (data) => {
    return request({
        url: "/businessservice/archive/fill/uploadFile",
        method: "post",
        data: data
    })
}
// 查询教师档案
export const archiveQueryArchive = (query) => {
    return request({
        url: "/businessservice/archive/query/archive",
        method: "get",
        params: query
    })
}
// 档案审核-保存审核结果
export const archiveManagerCheckArchive = (data) => {
    return request({
        url: "/businessservice/archiveManager/check/archive",
        method: "post",
        data: data
    })
}
// 档案审核-查询审核列表
export const archiveManagerCheckList = (query) => {
    return request({
        url: "/businessservice/archiveManager/check/list",
        method: "get",
        params: query
    })
}
// 填报管理-取消分配
export const archiveManagerCancelDivide = (query) => {
    return request({
        url: "/businessservice/archiveManager/manager/cancelDivide",
        method: "get",
        params: query
    })
}
// 填报管理-查询组织档案审核人列表
export const archiveManagerCheckUserList = (query) => {
    return request({
        url: "/businessservice/archiveManager/manager/checkUserList",
        method: "get",
        params: query
    })
}
// 填报管理-分配审核人
export const archiveManagerDivide = (data) => {
    return request({
        url: "/businessservice/archiveManager/manager/divide",
        method: "post",
        data: data
    })
}
// 填报管理-查询填报管理列表
export const archiveManagerList = (query) => {
    return request({
        url: "/businessservice/archiveManager/manager/list",
        method: "get",
        params: query
    })
}
// 填报管理-查询所有已填报人员
export const archiveManagerListAll = (query) => {
    return request({
        url: "/businessservice/archiveManager/manager/listAll",
        method: "get",
        params: query
    })
}
// 教师档案驾驶舱统计
export const teacherArchive = (query) => {
    return request({
        url: "/businessservice/cockpit/teacher/archive",
        method: "get",
        params: query
    })
}

export const allList = [
    {
        smTit: '第一部分:教师基础信息',
        smId: 'part1',
        smList: [
            { topCategory: 1, secondCategory: 1, key: 'gender', value: '', title: '性别'},
            { topCategory: 1, secondCategory: 2, key: 'birthDate', value: '', title: '出生日期' },
            { topCategory: 1, secondCategory: 3, key: 'politicalClimate', value: '', title: '政治面貌' },
            { topCategory: 1, secondCategory: 4, key: 'nation', value: '', title: '民族' },
            {
                topCategory: 1, secondCategory: 5, key: '', tsList: [
                    { key: 'qualification', title: '全日制学校毕业学历' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'orgName', title: '毕业院校' },
                    { key: 'major', title: '毕业专业' },
                    { key: 'isSelect', title: '是否师范类专业' },
                    { key: 'annex1UrlList', title: '毕业证书' },
                    { key: 'annex2UrlList', title: '学位证书' },
                ], value: [], title: '全日制学校毕业学历(备注:只填写入职前全日制学校毕业的最高学历)', bgTit: '学历和工作信息', bgShow: true,isOne:false
            },
            {
                topCategory: 1, secondCategory: 6, key: '', tsList: [
                    { key: 'qualification', title: '全日制学校毕业学历' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'orgName', title: '毕业院校' },
                    { key: 'major', title: '毕业专业' },
                    { key: 'isSelect', title: '是否师范类专业' },
                    { key: 'annex1UrlList', title: '毕业证书' },
                    { key: 'annex2UrlList', title: '学位证书' },
                ], value: [], title: '最高学历', bgTit: '学历和工作信息', bgShow: false,isOne:true
            },
            { topCategory: 1, secondCategory: 7, key: 'teachAge', value: '', title: '教龄' },
            { topCategory: 1, secondCategory: 8, key: 'workAge', value: '', title: '工龄' },
            { topCategory: 1, secondCategory: 9, key: 'isSelect', value: '', title: '是否从事一线教学', optionList: ['正在从事一线教学', '目前未从事一线教学'] },
            {
                topCategory: 1, secondCategory: 10, key: '', tsList: [
                    { key: 'name', title: '职称', optionName: 'technical_title' },
                    { key: 'startTime', title: '评定时间' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '现专业技术职称(请填写目前最高职称)', bgTit: '', bgShow: false,isOne:true
            },
            {
                topCategory: 1, secondCategory: 11, key: '', tsList: [
                    { key: 'periodSubjectList', title: '学段/学科' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请上传持有的教师资格证，如有多个，请逐一上传', bgTit: '', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 12, key: '', tsList: [
                    { key: 'prizeLevel', title: '等级', optionName: 'mandarin_level' },
                    { key: 'startTime', title: '通过时间' },
                    { key: 'annex1UrlList', title: '合格证书' },
                ], value: [], title: '请上传普通话水平测试等级证书', bgTit: '', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 13, key: '', tsList: [
                    { key: 'orgName', title: '工作单位' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【主要工作经历】分阶段填写毕业至今的主要工作经历(包括本校的工作经历，截止时间到本月)', bgTit: '主要工作经历和管理工作', bgShow: true
            },
            {
                topCategory: 1, secondCategory: 14, key: '', tsList: [
                    { key: 'orgName', title: '工作单位' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'annex1UrlList', title: '证明材料' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【担任外单位管理工作】履现职以来，若在外单位担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料，若在外单位担任过管理工作，需原单位提供组织机构沿革表。', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 15, key: '', tsList: [
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'annex1UrlList', title: '证明材料' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【担任本校管理工作】履现职以来，若在本校担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料。', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 16, key: '', tsList: [
                    { key: 'orgName', title: '兼职单位名称' },
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'annex1UrlList', title: '证明材料' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【担任外单位兼职工作】履现职以来，若在外单位担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 17, key: '', tsList: [
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'annex1UrlList', title: '证明材料' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【担任本校兼职工作】履现职以来，若在本校担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
            }
        ]
    },
    {
        smTit: '第二部分:教师基础信息',
        smId: 'part2',
        smList: [
            {
                topCategory: 2, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '骨干教师类型', optionName: 'core_teacher_type' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请对应您所获得的每种类型的骨干教师称号，添加具体资料', bgTit: '骨干教师信息', bgShow: true
            },
        ]
    },
    {
        smTit: '第三部分:德育工作',
        smId: 'part3',
        smList: [
            {
                topCategory: 3, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '德育称号', optionName: 'moral_edu_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'relationOrgName', title: '颁发部门', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【德育称号】参加工作以来，若有获得德育称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 2, key: '', tsList: [
                    { key: 'typeName', title: '少先队称号', optionName: 'young_pioneers_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【少先队称号】参加工作以来，若有获得少先队称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '班主任/副班主任', optionName: 'bzrType' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'typeName', title: '荣誉称号', optionName: 'class_honorary_title' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【品质班集体相关荣誉】近5学年，若担任班主任/副班主任时，所带班级曾被评为品质班级或先进班集体班级，请提供具体资料。【班主任、副班主任所填报的荣营需在任期内】(含区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 4, key: '', tsList: [
                    { key: 'typeName', title: '活动类型', placeholder: '如主题班会、主题队会、主题教育实践活动、社会实践活动等' },
                    { key: 'name', title: '活动名称' },
                    { key: 'address', title: '活动地点' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【育人活动】近 5 年内，若有参与示范展示类育人活动(含校级、区级、市级、省级、国家级)，请添加具体资料【代表校区/学校进行展示的活动】', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'typeName', title: '分享主题/名称' },
                    { key: 'address', title: '活动地点' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【育人经验工作分享】近 5 年内，若有承担各级育人经验工作分享(含校级、区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 6, key: 'isSelect', value: '', noShowLabel: true, optionList: ['符合', '不符合'], title: '遵守师德规范，提高道德修养，勤勉敬业，乐于奉献，廉洁从教，树立良好的师德形象。', bgTit: '师德师风', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 7, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任班主任工作情况', bgTit: '班主任工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 8, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任班主任具体情况', bgTit: '班主任工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 9, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任副班主任工作情况', bgTit: '副班主任工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 10, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任副班主任具体情况', bgTit: '副班主任工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 11, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任辅导员工作情况', bgTit: '辅导员工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 12, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任辅导员工作具体情况', bgTit: '辅导员工作', bgShow: false
            },
        ]
    },
    {
        smTit: '第四部分:教学工作',
        smId: 'part4',
        smList: [
            {
                topCategory: 4, secondCategory: 1, key: '', tsList: [
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'periodSubjectList', title: '学科' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'isSelect', title: '是否担任兴趣班课程' },
                ], value: [], title: '当前所教年级、班级和学科', bgTit: '教学基本信息', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 2, key: '', tsList: [
                    { key: 'name', title: '课例名称' },
                    { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'typeName', title: '类型', optionName: 'class_teach_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【课堂教学获奖】参加工作以来，若有课堂教学(含录像课例、现场课例、微课、说课、教学技能)获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '学生姓名', placeholder: '可填多个学生姓名，分号分隔' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【指导学生获奖】近 5 年内，若指导学生在活动(仅包括教育局统一发文、学校统一组织的活动)中获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '荣誉名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【其他教学工作获奖】参加工作以来，若在教学工作方面有上述未提及的重要荣誉奖项(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 5, key: '', tsList: [
                    { key: 'isSelect', title: '研究课/示范课', optionList: ['研究课', '示范课'] },
                    { key: 'name', title: '课例名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主讲研究课】近5年内，若主讲过研究课/示范课，请添加具体资料:(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 6, key: '', tsList: [
                    { key: 'name', title: '研讨会名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【教育教学、学校管理思想研讨会】近5年内，若组织过教育教学、学校管理思想研讨会，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 7, key: '', tsList: [
                    { key: 'name', title: '研讨名称' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'startTime', title: '时间' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主讲教学研讨和交流】近 5 年内，若主讲过教学研讨和交流(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 8, key: '', tsList: [
                    { key: 'address', title: '地点' },
                    { key: 'periodSubjectList', title: '上课科目' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【送教下乡】近 5 年内，若有过送教下乡，请添加具体资料', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 9, key: '', tsList: [
                    { key: 'name', title: '支教名称' },
                    { key: 'levelName', title: '级别', optionName: 'teach_support_level' },
                    { key: 'startTime', title: '支教起始时间' },
                    { key: 'schoolTerm', title: '支教总学期数', isNum: true },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '近5年内，若有外出支教、且单次支教时间达到或超过一学期(含区内支教、区外市内支教、市外省内支教、省外支教)，请添加具体资料', bgTit: '外出支教', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 10, key: '', tsList: [
                    { key: 'schoolYear', title: '年份' },
                    { key: 'typeName', title: '履行情况', optionList: ['合格', '良好', '优秀'] },
                ], value: [], title: '请埴写近 5 年来的履职考核结果', bgTit: '五年履职考核', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 11, key: '', tsList: [
                    { key: 'schoolYear', title: '年份' },
                    { key: 'classInfo', title: '平均课时' },
                ], value: [], title: '请埴写近 5 年来的平均课量', bgTit: '五年学科任课量', bgShow: true
            },
        ]
    },
    {
        smTit: '第五部分:指导青年教师',
        smId: 'part5',
        smList: [
            {
                topCategory: 5, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '帮扶性质', optionName: 'assistance_nature' },
                    { key: 'num', title: '指导人数' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近 5 年内，若有常规指导青年教师(含校内、区内、区外帮扶师徒结对)', bgTit: '指导青年教师', bgShow: true
            },
            {
                topCategory: 5, secondCategory: 2, key: '', tsList: [
                    { key: 'relationName', title: '被指导教师' },
                    { key: 'startTime', title: '指导起始时间' },
                    { key: 'typeName', title: '教学成绩趋势', optionList: ['上升', '持平', '下降'] },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '成绩变化证明' },
                ], value: [], title: '请填报近 5 学年内常规指导的青年教师，其教学成绩的具体情况(3 名及以下需全部填报，3 名以上可选填)，证明材料需体现成绩排名变化情况，由教务处加盖公章', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'relationName', title: '被指导教师' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '近 5 年内，若有指导青年教师在各级教育教学评比活动中获奖(含校级、区级、市级、省级、国家级)，请添加具体资料(指导的每位青年教师仅需选取最高的三个奖项填写即可)', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【担任评比专家】近5年内，若担任过教育教学评比活动的评比专家，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '工作室名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '组建时间' },
                    { key: 'num', title: '成员数量' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【名师工作室】近5年内，若有主持名师工作室/名校校长工作室，请添加具体资料(含区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
            },
        ]
    },
    {
        smTit: '第六部分:科研工作',
        smId: 'part6',
        smList: [
            {
                topCategory: 6, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '课题名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'startTime', title: '开题时间' },
                    { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主持课题研究】参加工作以来，若有主持课题研究，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: true
            },
            {
                topCategory: 6, secondCategory: 2, key: '', tsList: [
                    { key: 'name', title: '课题名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'startTime', title: '开题时间' },
                    { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【叁与课题研究】参加工作以来，若有参与课题研究，请添加具体资料 (含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 3, key: '', tsList: [
                    { key: 'typeName', title: '成果类型', optionName: 'archive_achieve_type' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'name', title: '成果名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【论文成里获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '成果名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【教育教学成果获奖】参加工作以来，若有教育教学成果获奖，请提供具体资料', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '论著名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'typeName', title: '论著类别', optionName: 'opus_type' },
                    { key: 'relationName', title: '编著身份', optionName: 'opus_role' },
                    { key: 'startTime', title: '出版时间' },
                    { key: 'publisher', title: '出版社' },
                    { key: 'publishNo', title: '版号' },
                    { key: 'annex1UrlList', title: '论著封面、目录页、姓名页' },
                ], value: [], title: '参加工作以来，若有出版论著，请添加具体资料', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 6, key: '', tsList: [
                    { key: 'name', title: '作品名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '发表时间' },
                    { key: 'publishType', title: '期刊性质', optionName: 'archive_area_level' },
                    { key: 'publishNo', title: '期刊刊号' },
                    { key: 'publisher', title: '期刊名称' },
                    { key: 'annex1UrlList', title: '发表复印件' },
                ], value: [], title: '【论文成果获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
        ]
    },
    {
        smTit: '第七部分:学习提高',
        smId: 'part7',
        smList: [
            {
                topCategory: 7, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '培训名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近5年内，若有承担各级培训任务，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '继续教育', bgShow: true
            },
            {
                topCategory: 7, secondCategory: 2, key: '', tsList: [
                    { key: 'schoolYear', title: '学年度' },
                    { key: 'annex1UrlList', title: '学年度学时单', accept: '.pdf' },
                ], value: [], title: '近5学年，若有参加各级研修，请上传学时网的学时单', bgTit: '继续教育', bgShow: false
            },
            {
                topCategory: 7, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '研修名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近 5 年内，若有参加各级校外研修(含区级、市级、省级、国家级)，请添加具体资料(依据个人学时单填写)', bgTit: '校外研修', bgShow: true
            },
            {
                topCategory: 7, secondCategory: 4, key: '', tsList: [
                    { key: 'isSelect', title: '来源', optionList: ['校本培训', '校本研修'] },
                    { key: 'annex1UrlList', title: '证明材料(合格证)' },
                ], value: [], title: '本学年若参与了校本研修或培训，请添加具体资料(依据个人学时单填写)', bgTit: '校本研修', bgShow: true
            },
        ]
    },
    {
        smTit: '第八部分:其他荣誉类称号',
        smId: 'part8',
        smList: [
            {
                topCategory: 8, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '称号名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请对应您所获得的每种类型的其他荣誉类称号，添加具体资料', bgTit: '其他荣誉类称号', bgShow: true
            },
        ]
    },
]

export const dtArea = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "adcode": 140212,
                "name": "新荣区",
                "center": [113.141044, 40.258269],
                "centroid": [113.182695, 40.263077],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.488106, 40.333716],
                            [113.457631, 40.330455],
                            [113.432132, 40.326286],
                            [113.422219, 40.324151],
                            [113.404821, 40.323024],
                            [113.387643, 40.319141],
                            [113.372614, 40.318182],
                            [113.363039, 40.318535],
                            [113.344527, 40.322184],
                            [113.333718, 40.321847],
                            [113.323427, 40.318771],
                            [113.315982, 40.320116],
                            [113.309334, 40.326454],
                            [113.302745, 40.334674],
                            [113.296136, 40.349347],
                            [113.29118, 40.365075],
                            [113.285069, 40.376549],
                            [113.278659, 40.385636],
                            [113.273982, 40.390087],
                            [113.265163, 40.393833],
                            [113.259152, 40.400298],
                            [113.257659, 40.409584],
                            [113.251349, 40.413395],
                            [113.247746, 40.412975],
                            [113.227203, 40.404798],
                            [113.21936, 40.40285],
                            [113.199276, 40.401524],
                            [113.194777, 40.400214],
                            [113.170751, 40.395965],
                            [113.144854, 40.389012],
                            [113.128312, 40.385099],
                            [113.115354, 40.381152],
                            [113.083126, 40.374869],
                            [113.06539, 40.373441],
                            [113.052412, 40.371039],
                            [113.033123, 40.368922],
                            [113.006788, 40.359967],
                            [112.989829, 40.35743],
                            [112.962379, 40.349784],
                            [112.950555, 40.344624],
                            [112.912854, 40.333985],
                            [112.898701, 40.329312],
                            [112.892948, 40.326403],
                            [112.890102, 40.317964],
                            [112.89046, 40.305151],
                            [112.886459, 40.298575],
                            [112.88206, 40.286718],
                            [112.87979, 40.27691],
                            [112.873839, 40.262575],
                            [112.880666, 40.256652],
                            [112.890161, 40.252428],
                            [112.897845, 40.247783],
                            [112.904573, 40.23972],
                            [112.906703, 40.226202],
                            [112.906544, 40.216605],
                            [112.912456, 40.209617],
                            [112.93061, 40.205777],
                            [112.935765, 40.203504],
                            [112.939647, 40.204885],
                            [112.941896, 40.202257],
                            [112.941538, 40.193213],
                            [112.944822, 40.182516],
                            [112.943508, 40.177175],
                            [112.956785, 40.17453],
                            [112.966738, 40.174445],
                            [112.968112, 40.16966],
                            [112.963036, 40.163645],
                            [112.955034, 40.158909],
                            [112.960408, 40.148662],
                            [112.969505, 40.145729],
                            [112.966639, 40.149049],
                            [112.97474, 40.155016],
                            [112.97896, 40.15451],
                            [112.98109, 40.151359],
                            [112.996557, 40.149724],
                            [112.993671, 40.146471],
                            [112.99801, 40.142543],
                            [112.997333, 40.139728],
                            [113.000896, 40.136609],
                            [112.995343, 40.137874],
                            [112.996159, 40.132765],
                            [113.0044, 40.134704],
                            [113.011645, 40.132108],
                            [113.016522, 40.139121],
                            [113.019727, 40.14079],
                            [113.025679, 40.138986],
                            [113.034955, 40.130051],
                            [113.037324, 40.123104],
                            [113.045764, 40.132007],
                            [113.049784, 40.128888],
                            [113.048809, 40.142341],
                            [113.050242, 40.146791],
                            [113.067341, 40.138278],
                            [113.070586, 40.141734],
                            [113.083206, 40.138464],
                            [113.085197, 40.142847],
                            [113.090929, 40.139576],
                            [113.099807, 40.146437],
                            [113.103629, 40.145577],
                            [113.103968, 40.152437],
                            [113.101579, 40.154426],
                            [113.102853, 40.159145],
                            [113.105202, 40.158387],
                            [113.110855, 40.165195],
                            [113.121843, 40.164234],
                            [113.125744, 40.156314],
                            [113.129447, 40.159027],
                            [113.134901, 40.157982],
                            [113.145909, 40.163931],
                            [113.147521, 40.172356],
                            [113.155384, 40.17458],
                            [113.16892, 40.166273],
                            [113.178554, 40.165768],
                            [113.17945, 40.158791],
                            [113.181838, 40.158033],
                            [113.190537, 40.160325],
                            [113.193244, 40.154274],
                            [113.208432, 40.149353],
                            [113.209288, 40.154696],
                            [113.214882, 40.157089],
                            [113.219619, 40.155758],
                            [113.219918, 40.160375],
                            [113.222864, 40.163072],
                            [113.241177, 40.165515],
                            [113.249637, 40.163864],
                            [113.248562, 40.167824],
                            [113.243725, 40.171109],
                            [113.250115, 40.175675],
                            [113.276052, 40.167116],
                            [113.274857, 40.163965],
                            [113.279655, 40.162785],
                            [113.281207, 40.166593],
                            [113.304039, 40.158454],
                            [113.304895, 40.142189],
                            [113.311424, 40.142847],
                            [113.31656, 40.145982],
                            [113.316221, 40.158454],
                            [113.318291, 40.163695],
                            [113.331509, 40.160207],
                            [113.330693, 40.154746],
                            [113.333101, 40.150516],
                            [113.340606, 40.147886],
                            [113.340984, 40.14401],
                            [113.34821, 40.142493],
                            [113.355555, 40.151055],
                            [113.374326, 40.148072],
                            [113.379382, 40.14556],
                            [113.383602, 40.146757],
                            [113.385274, 40.142105],
                            [113.387264, 40.147954],
                            [113.390808, 40.152353],
                            [113.40293, 40.158757],
                            [113.411987, 40.16206],
                            [113.417362, 40.165801],
                            [113.418795, 40.169559],
                            [113.416864, 40.173232],
                            [113.420009, 40.178017],
                            [113.417103, 40.182448],
                            [113.419293, 40.184891],
                            [113.426459, 40.184588],
                            [113.429544, 40.186879],
                            [113.434063, 40.194964],
                            [113.440492, 40.201264],
                            [113.455381, 40.211537],
                            [113.468778, 40.222515],
                            [113.477954, 40.225798],
                            [113.484802, 40.229569],
                            [113.492087, 40.230479],
                            [113.498398, 40.234165],
                            [113.498437, 40.237515],
                            [113.505225, 40.240579],
                            [113.511635, 40.246268],
                            [113.523817, 40.2547],
                            [113.519259, 40.272654],
                            [113.512252, 40.287121],
                            [113.505325, 40.29501],
                            [113.497761, 40.301552],
                            [113.492326, 40.310111],
                            [113.488485, 40.322083],
                            [113.486912, 40.330102],
                            [113.488106, 40.333716]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140213,
                "name": "平城区",
                "center": [113.298027, 40.075667],
                "centroid": [113.313703, 40.085893],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.374385, 39.984716],
                            [113.378227, 39.993146],
                            [113.380696, 40.001677],
                            [113.385453, 40.008551],
                            [113.394411, 40.013618],
                            [113.397098, 40.016557],
                            [113.399447, 40.026419],
                            [113.402393, 40.032244],
                            [113.400721, 40.036414],
                            [113.39027, 40.049801],
                            [113.38828, 40.05873],
                            [113.391285, 40.065531],
                            [113.39234, 40.072838],
                            [113.396302, 40.072771],
                            [113.396978, 40.076027],
                            [113.402592, 40.078508],
                            [113.39664, 40.089339],
                            [113.3966, 40.092915],
                            [113.403866, 40.108939],
                            [113.402512, 40.114791],
                            [113.385672, 40.128938],
                            [113.383761, 40.135935],
                            [113.385274, 40.142105],
                            [113.383602, 40.146757],
                            [113.379382, 40.14556],
                            [113.374326, 40.148072],
                            [113.355555, 40.151055],
                            [113.34821, 40.142493],
                            [113.340984, 40.14401],
                            [113.340606, 40.147886],
                            [113.333101, 40.150516],
                            [113.330693, 40.154746],
                            [113.331509, 40.160207],
                            [113.318291, 40.163695],
                            [113.316221, 40.158454],
                            [113.31656, 40.145982],
                            [113.311424, 40.142847],
                            [113.304895, 40.142189],
                            [113.304039, 40.158454],
                            [113.281207, 40.166593],
                            [113.279655, 40.162785],
                            [113.274857, 40.163965],
                            [113.276052, 40.167116],
                            [113.250115, 40.175675],
                            [113.243725, 40.171109],
                            [113.248562, 40.167824],
                            [113.249637, 40.163864],
                            [113.241177, 40.165515],
                            [113.222864, 40.163072],
                            [113.219918, 40.160375],
                            [113.219619, 40.155758],
                            [113.214882, 40.157089],
                            [113.209288, 40.154696],
                            [113.208432, 40.149353],
                            [113.210443, 40.118518],
                            [113.237395, 40.111654],
                            [113.249657, 40.100186],
                            [113.243645, 40.094687],
                            [113.240839, 40.089609],
                            [113.241456, 40.085189],
                            [113.218982, 40.083299],
                            [113.227303, 40.068434],
                            [113.234469, 40.058511],
                            [113.257321, 40.042239],
                            [113.278042, 40.033004],
                            [113.28491, 40.026571],
                            [113.297092, 40.01171],
                            [113.300516, 40.008957],
                            [113.301551, 40.003957],
                            [113.306348, 40.004211],
                            [113.313912, 39.999616],
                            [113.336406, 39.995934],
                            [113.346956, 39.999397],
                            [113.364453, 39.986236],
                            [113.366344, 39.982299],
                            [113.374385, 39.984716]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140214,
                "name": "云冈区",
                "center": [113.149693, 40.005405],
                "centroid": [113.114195, 40.021423],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.208432, 40.149353],
                            [113.193244, 40.154274],
                            [113.190537, 40.160325],
                            [113.181838, 40.158033],
                            [113.17945, 40.158791],
                            [113.178554, 40.165768],
                            [113.16892, 40.166273],
                            [113.155384, 40.17458],
                            [113.147521, 40.172356],
                            [113.145909, 40.163931],
                            [113.134901, 40.157982],
                            [113.129447, 40.159027],
                            [113.125744, 40.156314],
                            [113.121843, 40.164234],
                            [113.110855, 40.165195],
                            [113.105202, 40.158387],
                            [113.102853, 40.159145],
                            [113.101579, 40.154426],
                            [113.103968, 40.152437],
                            [113.103629, 40.145577],
                            [113.099807, 40.146437],
                            [113.090929, 40.139576],
                            [113.085197, 40.142847],
                            [113.083206, 40.138464],
                            [113.070586, 40.141734],
                            [113.067341, 40.138278],
                            [113.050242, 40.146791],
                            [113.048809, 40.142341],
                            [113.049784, 40.128888],
                            [113.045764, 40.132007],
                            [113.037324, 40.123104],
                            [113.034955, 40.130051],
                            [113.025679, 40.138986],
                            [113.019727, 40.14079],
                            [113.016522, 40.139121],
                            [113.011645, 40.132108],
                            [113.0044, 40.134704],
                            [112.996159, 40.132765],
                            [112.995343, 40.137874],
                            [113.000896, 40.136609],
                            [112.997333, 40.139728],
                            [112.99801, 40.142543],
                            [112.993671, 40.146471],
                            [112.996557, 40.149724],
                            [112.98109, 40.151359],
                            [112.97896, 40.15451],
                            [112.97474, 40.155016],
                            [112.966639, 40.149049],
                            [112.969505, 40.145729],
                            [112.973128, 40.146167],
                            [112.979159, 40.141801],
                            [112.972053, 40.124251],
                            [112.964867, 40.113257],
                            [112.961145, 40.111385],
                            [112.96009, 40.102142],
                            [112.956527, 40.096188],
                            [112.929754, 40.088833],
                            [112.929594, 40.081494],
                            [112.927106, 40.077698],
                            [112.911918, 40.073463],
                            [112.908833, 40.070746],
                            [112.911958, 40.060435],
                            [112.91146, 40.051742],
                            [112.920338, 40.042441],
                            [112.925832, 40.038069],
                            [112.925195, 40.032852],
                            [112.926509, 40.02213],
                            [112.915939, 40.015375],
                            [112.914386, 40.011507],
                            [112.918447, 40.005967],
                            [112.933376, 40.003856],
                            [112.944384, 40.004143],
                            [112.952784, 40.006575],
                            [112.958457, 40.005613],
                            [112.967136, 40.001052],
                            [112.974661, 39.995106],
                            [112.976333, 39.990342],
                            [112.975337, 39.981894],
                            [112.971735, 39.972279],
                            [112.967116, 39.963914],
                            [112.962001, 39.95815],
                            [112.955074, 39.954144],
                            [112.942891, 39.949445],
                            [112.929614, 39.947179],
                            [112.917412, 39.945979],
                            [112.902901, 39.947298],
                            [112.889186, 39.936089],
                            [112.886359, 39.93149],
                            [112.890241, 39.921445],
                            [112.900632, 39.911027],
                            [112.911858, 39.907931],
                            [112.920776, 39.9092],
                            [112.923424, 39.906172],
                            [112.930092, 39.903313],
                            [112.940085, 39.895159],
                            [112.942871, 39.897934],
                            [112.939049, 39.907204],
                            [112.939448, 39.9126],
                            [112.944563, 39.917166],
                            [112.949201, 39.917116],
                            [112.961005, 39.910096],
                            [112.973745, 39.909809],
                            [112.985848, 39.913056],
                            [113.006868, 39.913716],
                            [113.023111, 39.912329],
                            [113.038339, 39.916287],
                            [113.051914, 39.916456],
                            [113.070247, 39.914342],
                            [113.077214, 39.912786],
                            [113.122778, 39.908337],
                            [113.14149, 39.909132],
                            [113.160101, 39.913124],
                            [113.168442, 39.916727],
                            [113.183789, 39.927364],
                            [113.199475, 39.936258],
                            [113.210104, 39.94532],
                            [113.223839, 39.951203],
                            [113.228517, 39.955158],
                            [113.235524, 39.958285],
                            [113.253777, 39.95575],
                            [113.265721, 39.9522],
                            [113.285945, 39.94843],
                            [113.296097, 39.942615],
                            [113.305054, 39.934398],
                            [113.325238, 39.932623],
                            [113.338456, 39.920363],
                            [113.346338, 39.913936],
                            [113.352629, 39.906612],
                            [113.357127, 39.905885],
                            [113.371917, 39.907187],
                            [113.381193, 39.906054],
                            [113.387205, 39.893586],
                            [113.39037, 39.891556],
                            [113.39672, 39.892402],
                            [113.39236, 39.900539],
                            [113.385951, 39.907373],
                            [113.383542, 39.918147],
                            [113.388618, 39.926856],
                            [113.38398, 39.934077],
                            [113.38408, 39.937898],
                            [113.37773, 39.946114],
                            [113.372554, 39.948769],
                            [113.368235, 39.957068],
                            [113.36931, 39.963271],
                            [113.369011, 39.972904],
                            [113.374385, 39.984716],
                            [113.366344, 39.982299],
                            [113.364453, 39.986236],
                            [113.346956, 39.999397],
                            [113.336406, 39.995934],
                            [113.313912, 39.999616],
                            [113.306348, 40.004211],
                            [113.301551, 40.003957],
                            [113.300516, 40.008957],
                            [113.297092, 40.01171],
                            [113.28491, 40.026571],
                            [113.278042, 40.033004],
                            [113.257321, 40.042239],
                            [113.234469, 40.058511],
                            [113.227303, 40.068434],
                            [113.218982, 40.083299],
                            [113.241456, 40.085189],
                            [113.240839, 40.089609],
                            [113.243645, 40.094687],
                            [113.249657, 40.100186],
                            [113.237395, 40.111654],
                            [113.210443, 40.118518],
                            [113.208432, 40.149353]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140215,
                "name": "云州区",
                "center": [113.61244, 40.040295],
                "centroid": [113.579015, 39.993649],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.39672, 39.892402],
                            [113.413719, 39.878104],
                            [113.41778, 39.872604],
                            [113.439616, 39.852174],
                            [113.424687, 39.848721],
                            [113.413022, 39.845216],
                            [113.405199, 39.840746],
                            [113.396819, 39.837631],
                            [113.381014, 39.837394],
                            [113.373211, 39.834498],
                            [113.360113, 39.821391],
                            [113.353047, 39.813244],
                            [113.349165, 39.812618],
                            [113.344388, 39.805842],
                            [113.334654, 39.805385],
                            [113.32924, 39.80159],
                            [113.330195, 39.795474],
                            [113.339929, 39.776563],
                            [113.34819, 39.767886],
                            [113.363517, 39.761462],
                            [113.378526, 39.751155],
                            [113.393156, 39.743678],
                            [113.406732, 39.731147],
                            [113.420208, 39.738014],
                            [113.421741, 39.729162],
                            [113.424926, 39.725024],
                            [113.430499, 39.721819],
                            [113.43874, 39.719529],
                            [113.44724, 39.723379],
                            [113.449728, 39.721225],
                            [113.461234, 39.722209],
                            [113.472381, 39.72499],
                            [113.475884, 39.732859],
                            [113.477138, 39.745272],
                            [113.477735, 39.772326],
                            [113.473794, 39.781173],
                            [113.47672, 39.789035],
                            [113.489798, 39.802166],
                            [113.498796, 39.80718],
                            [113.522045, 39.811618],
                            [113.531083, 39.81504],
                            [113.541732, 39.823068],
                            [113.548878, 39.829876],
                            [113.555945, 39.838291],
                            [113.564962, 39.843083],
                            [113.575791, 39.846774],
                            [113.587316, 39.84889],
                            [113.596074, 39.853004],
                            [113.608874, 39.860655],
                            [113.615801, 39.865868],
                            [113.624102, 39.867459],
                            [113.645898, 39.867324],
                            [113.658001, 39.863752],
                            [113.669725, 39.861535],
                            [113.689213, 39.859995],
                            [113.702908, 39.861264],
                            [113.718454, 39.85363],
                            [113.730378, 39.853715],
                            [113.743018, 39.858607],
                            [113.752314, 39.864328],
                            [113.770726, 39.873467],
                            [113.783486, 39.874669],
                            [113.812608, 39.867747],
                            [113.823357, 39.863752],
                            [113.834245, 39.877664],
                            [113.83948, 39.881167],
                            [113.844616, 39.887072],
                            [113.843342, 39.893857],
                            [113.844138, 39.899524],
                            [113.855285, 39.91808],
                            [113.854987, 39.927059],
                            [113.850687, 39.938152],
                            [113.848916, 39.946047],
                            [113.849831, 39.959452],
                            [113.856141, 39.964015],
                            [113.858908, 39.970657],
                            [113.866532, 39.973699],
                            [113.869956, 39.976605],
                            [113.866433, 39.982958],
                            [113.854051, 39.991322],
                            [113.848438, 39.993467],
                            [113.843024, 39.990646],
                            [113.839958, 39.992825],
                            [113.832135, 39.992656],
                            [113.828174, 39.999701],
                            [113.831319, 40.006322],
                            [113.850229, 40.006322],
                            [113.852956, 40.008754],
                            [113.85427, 40.017334],
                            [113.858112, 40.023531],
                            [113.851683, 40.032312],
                            [113.845313, 40.036397],
                            [113.84382, 40.042627],
                            [113.840496, 40.046121],
                            [113.835997, 40.059101],
                            [113.83325, 40.071269],
                            [113.829747, 40.07078],
                            [113.821565, 40.078137],
                            [113.815574, 40.08723],
                            [113.802297, 40.095176],
                            [113.791488, 40.097925],
                            [113.787089, 40.095648],
                            [113.774767, 40.097166],
                            [113.760296, 40.09693],
                            [113.757171, 40.098195],
                            [113.749885, 40.096677],
                            [113.746382, 40.10312],
                            [113.74041, 40.106679],
                            [113.734558, 40.107506],
                            [113.728885, 40.114488],
                            [113.720644, 40.118366],
                            [113.706551, 40.121469],
                            [113.698688, 40.119968],
                            [113.690586, 40.122143],
                            [113.678782, 40.120474],
                            [113.677568, 40.123256],
                            [113.668431, 40.126392],
                            [113.664609, 40.132647],
                            [113.659076, 40.133996],
                            [113.65601, 40.140251],
                            [113.641499, 40.145088],
                            [113.629456, 40.151022],
                            [113.615044, 40.164133],
                            [113.615443, 40.172238],
                            [113.611481, 40.188378],
                            [113.616139, 40.197693],
                            [113.613432, 40.204885],
                            [113.621952, 40.212766],
                            [113.627008, 40.213641],
                            [113.62241, 40.219232],
                            [113.601111, 40.22568],
                            [113.592471, 40.22526],
                            [113.59078, 40.231034],
                            [113.584211, 40.234839],
                            [113.57376, 40.233172],
                            [113.563111, 40.229367],
                            [113.557278, 40.229334],
                            [113.544638, 40.235765],
                            [113.534666, 40.253926],
                            [113.523817, 40.2547],
                            [113.511635, 40.246268],
                            [113.505225, 40.240579],
                            [113.498437, 40.237515],
                            [113.498398, 40.234165],
                            [113.492087, 40.230479],
                            [113.484802, 40.229569],
                            [113.477954, 40.225798],
                            [113.468778, 40.222515],
                            [113.455381, 40.211537],
                            [113.440492, 40.201264],
                            [113.434063, 40.194964],
                            [113.429544, 40.186879],
                            [113.426459, 40.184588],
                            [113.419293, 40.184891],
                            [113.417103, 40.182448],
                            [113.420009, 40.178017],
                            [113.416864, 40.173232],
                            [113.418795, 40.169559],
                            [113.417362, 40.165801],
                            [113.411987, 40.16206],
                            [113.40293, 40.158757],
                            [113.390808, 40.152353],
                            [113.387264, 40.147954],
                            [113.385274, 40.142105],
                            [113.383761, 40.135935],
                            [113.385672, 40.128938],
                            [113.402512, 40.114791],
                            [113.403866, 40.108939],
                            [113.3966, 40.092915],
                            [113.39664, 40.089339],
                            [113.402592, 40.078508],
                            [113.396978, 40.076027],
                            [113.396302, 40.072771],
                            [113.39234, 40.072838],
                            [113.391285, 40.065531],
                            [113.38828, 40.05873],
                            [113.39027, 40.049801],
                            [113.400721, 40.036414],
                            [113.402393, 40.032244],
                            [113.399447, 40.026419],
                            [113.397098, 40.016557],
                            [113.394411, 40.013618],
                            [113.385453, 40.008551],
                            [113.380696, 40.001677],
                            [113.378227, 39.993146],
                            [113.374385, 39.984716],
                            [113.369011, 39.972904],
                            [113.36931, 39.963271],
                            [113.368235, 39.957068],
                            [113.372554, 39.948769],
                            [113.37773, 39.946114],
                            [113.38408, 39.937898],
                            [113.38398, 39.934077],
                            [113.388618, 39.926856],
                            [113.383542, 39.918147],
                            [113.385951, 39.907373],
                            [113.39236, 39.900539],
                            [113.39672, 39.892402]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140221,
                "name": "阳高县",
                "center": [113.749871, 40.364927],
                "centroid": [113.804802, 40.22211],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.952644, 40.516017],
                            [113.947787, 40.516772],
                            [113.946951, 40.511927],
                            [113.943666, 40.509395],
                            [113.943428, 40.50569],
                            [113.930907, 40.493382],
                            [113.913768, 40.483857],
                            [113.8901, 40.466462],
                            [113.874295, 40.459449],
                            [113.863427, 40.456463],
                            [113.855664, 40.457084],
                            [113.850986, 40.460607],
                            [113.837629, 40.481693],
                            [113.832732, 40.487043],
                            [113.810159, 40.502136],
                            [113.794792, 40.517979],
                            [113.794255, 40.509999],
                            [113.790712, 40.502504],
                            [113.777574, 40.485366],
                            [113.768935, 40.477064],
                            [113.763421, 40.473961],
                            [113.717897, 40.457335],
                            [113.695742, 40.449902],
                            [113.688755, 40.448174],
                            [113.680534, 40.443962],
                            [113.673388, 40.436057],
                            [113.671994, 40.430367],
                            [113.667974, 40.42377],
                            [113.647809, 40.41551],
                            [113.63712, 40.408811],
                            [113.626012, 40.398921],
                            [113.619284, 40.394219],
                            [113.599757, 40.382395],
                            [113.584151, 40.371224],
                            [113.578916, 40.366267],
                            [113.572566, 40.357581],
                            [113.559866, 40.348624],
                            [113.537293, 40.34138],
                            [113.526783, 40.339061],
                            [113.500109, 40.334506],
                            [113.488106, 40.333716],
                            [113.486912, 40.330102],
                            [113.488485, 40.322083],
                            [113.492326, 40.310111],
                            [113.497761, 40.301552],
                            [113.505325, 40.29501],
                            [113.512252, 40.287121],
                            [113.519259, 40.272654],
                            [113.523817, 40.2547],
                            [113.534666, 40.253926],
                            [113.544638, 40.235765],
                            [113.557278, 40.229334],
                            [113.563111, 40.229367],
                            [113.57376, 40.233172],
                            [113.584211, 40.234839],
                            [113.59078, 40.231034],
                            [113.592471, 40.22526],
                            [113.601111, 40.22568],
                            [113.62241, 40.219232],
                            [113.627008, 40.213641],
                            [113.621952, 40.212766],
                            [113.613432, 40.204885],
                            [113.616139, 40.197693],
                            [113.611481, 40.188378],
                            [113.615443, 40.172238],
                            [113.615044, 40.164133],
                            [113.629456, 40.151022],
                            [113.641499, 40.145088],
                            [113.65601, 40.140251],
                            [113.659076, 40.133996],
                            [113.664609, 40.132647],
                            [113.668431, 40.126392],
                            [113.677568, 40.123256],
                            [113.678782, 40.120474],
                            [113.690586, 40.122143],
                            [113.698688, 40.119968],
                            [113.706551, 40.121469],
                            [113.720644, 40.118366],
                            [113.728885, 40.114488],
                            [113.734558, 40.107506],
                            [113.74041, 40.106679],
                            [113.746382, 40.10312],
                            [113.749885, 40.096677],
                            [113.757171, 40.098195],
                            [113.760296, 40.09693],
                            [113.774767, 40.097166],
                            [113.787089, 40.095648],
                            [113.791488, 40.097925],
                            [113.802297, 40.095176],
                            [113.815574, 40.08723],
                            [113.821565, 40.078137],
                            [113.829747, 40.07078],
                            [113.83325, 40.071269],
                            [113.835997, 40.059101],
                            [113.840496, 40.046121],
                            [113.84382, 40.042627],
                            [113.845313, 40.036397],
                            [113.851683, 40.032312],
                            [113.858112, 40.023531],
                            [113.85427, 40.017334],
                            [113.852956, 40.008754],
                            [113.850229, 40.006322],
                            [113.831319, 40.006322],
                            [113.828174, 39.999701],
                            [113.832135, 39.992656],
                            [113.839958, 39.992825],
                            [113.843024, 39.990646],
                            [113.848438, 39.993467],
                            [113.854051, 39.991322],
                            [113.866433, 39.982958],
                            [113.869956, 39.976605],
                            [113.866532, 39.973699],
                            [113.858908, 39.970657],
                            [113.856141, 39.964015],
                            [113.849831, 39.959452],
                            [113.848916, 39.946047],
                            [113.850687, 39.938152],
                            [113.854987, 39.927059],
                            [113.855285, 39.91808],
                            [113.844138, 39.899524],
                            [113.843342, 39.893857],
                            [113.844616, 39.887072],
                            [113.83948, 39.881167],
                            [113.834245, 39.877664],
                            [113.823357, 39.863752],
                            [113.827736, 39.857659],
                            [113.843641, 39.838291],
                            [113.850727, 39.831806],
                            [113.869219, 39.837699],
                            [113.887254, 39.840611],
                            [113.908832, 39.849516],
                            [113.914365, 39.847214],
                            [113.921273, 39.83885],
                            [113.932878, 39.838884],
                            [113.937257, 39.841745],
                            [113.941795, 39.848264],
                            [113.954415, 39.848518],
                            [113.958377, 39.854443],
                            [113.954415, 39.862974],
                            [113.945498, 39.876987],
                            [113.949917, 39.886937],
                            [113.955271, 39.895312],
                            [113.962716, 39.90443],
                            [113.969683, 39.910096],
                            [113.987857, 39.91928],
                            [113.997412, 39.92268],
                            [114.006489, 39.929156],
                            [114.020781, 39.929359],
                            [114.026414, 39.926045],
                            [114.037342, 39.917538],
                            [114.041801, 39.915137],
                            [114.047375, 39.916135],
                            [114.034894, 39.943071],
                            [114.032923, 39.953958],
                            [114.028265, 39.959299],
                            [114.027708, 39.967125],
                            [114.02944, 39.985374],
                            [114.021259, 39.991795],
                            [114.016521, 39.991068],
                            [114.007981, 39.993366],
                            [114.000935, 39.99171],
                            [113.99566, 39.994244],
                            [113.980213, 39.99693],
                            [113.972848, 39.999076],
                            [113.960467, 40.000917],
                            [113.947329, 40.004802],
                            [113.945677, 40.007454],
                            [113.932022, 40.009464],
                            [113.92382, 40.003332],
                            [113.914644, 40.005933],
                            [113.910663, 40.011727],
                            [113.911618, 40.018938],
                            [113.916157, 40.024426],
                            [113.922487, 40.026604],
                            [113.939765, 40.025828],
                            [113.946971, 40.027297],
                            [113.954853, 40.030826],
                            [113.963094, 40.036718],
                            [113.970519, 40.043589],
                            [113.975575, 40.05105],
                            [113.977625, 40.066392],
                            [113.981009, 40.073209],
                            [113.978242, 40.086927],
                            [113.978282, 40.091903],
                            [113.973863, 40.097133],
                            [113.975277, 40.102867],
                            [113.979835, 40.112278],
                            [113.972211, 40.122599],
                            [113.974261, 40.12941],
                            [113.985946, 40.136221],
                            [113.996257, 40.145156],
                            [114.007942, 40.144094],
                            [114.003124, 40.149892],
                            [114.001751, 40.156196],
                            [114.003144, 40.164049],
                            [114.006528, 40.174496],
                            [114.001433, 40.176501],
                            [113.986682, 40.190787],
                            [113.98296, 40.196918],
                            [113.973943, 40.202123],
                            [113.966976, 40.202695],
                            [113.964786, 40.205221],
                            [113.948742, 40.206131],
                            [113.946891, 40.208842],
                            [113.950673, 40.212227],
                            [113.959551, 40.215746],
                            [113.959213, 40.217514],
                            [113.948066, 40.224367],
                            [113.945239, 40.229435],
                            [113.949459, 40.238744],
                            [113.940362, 40.252848],
                            [113.937237, 40.266614],
                            [113.938909, 40.271846],
                            [113.946871, 40.285204],
                            [113.950414, 40.301148],
                            [113.949061, 40.306008],
                            [113.94932, 40.316753],
                            [113.933793, 40.323579],
                            [113.92824, 40.324655],
                            [113.919859, 40.329429],
                            [113.915659, 40.333531],
                            [113.910842, 40.343061],
                            [113.912315, 40.35664],
                            [113.916475, 40.366755],
                            [113.919401, 40.379136],
                            [113.922527, 40.381672],
                            [113.918685, 40.386896],
                            [113.922646, 40.398468],
                            [113.92818, 40.404832],
                            [113.940103, 40.410121],
                            [113.948245, 40.409349],
                            [113.957103, 40.410961],
                            [113.961124, 40.421924],
                            [113.974222, 40.425382],
                            [113.981925, 40.430518],
                            [113.985727, 40.436174],
                            [113.985428, 40.444415],
                            [113.983557, 40.451295],
                            [113.979278, 40.457772],
                            [113.973485, 40.477634],
                            [113.973246, 40.492158],
                            [113.970579, 40.503695],
                            [113.961562, 40.507618],
                            [113.955968, 40.514358],
                            [113.952644, 40.516017]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140222,
                "name": "天镇县",
                "center": [114.09112, 40.421336],
                "centroid": [114.156244, 40.384966],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.979835, 40.112278],
                            [113.989489, 40.112363],
                            [114.010669, 40.106814],
                            [114.020144, 40.102176],
                            [114.023309, 40.093337],
                            [114.034575, 40.080718],
                            [114.034376, 40.070763],
                            [114.036148, 40.063726],
                            [114.043831, 40.056874],
                            [114.051157, 40.059371],
                            [114.05685, 40.063658],
                            [114.067161, 40.068248],
                            [114.080537, 40.070003],
                            [114.08633, 40.071657],
                            [114.091366, 40.076129],
                            [114.091784, 40.086758],
                            [114.101717, 40.099831],
                            [114.101199, 40.108754],
                            [114.089097, 40.121469],
                            [114.084678, 40.129646],
                            [114.081732, 40.138042],
                            [114.081334, 40.146589],
                            [114.074924, 40.164942],
                            [114.068037, 40.17977],
                            [114.078826, 40.186037],
                            [114.093874, 40.189894],
                            [114.097756, 40.193566],
                            [114.110913, 40.193381],
                            [114.117841, 40.188513],
                            [114.123394, 40.188698],
                            [114.122936, 40.178506],
                            [114.135935, 40.175389],
                            [114.145071, 40.177377],
                            [114.180046, 40.191545],
                            [114.193343, 40.194998],
                            [114.204928, 40.196127],
                            [114.215717, 40.193819],
                            [114.224395, 40.193078],
                            [114.233552, 40.195756],
                            [114.235861, 40.198316],
                            [114.236896, 40.212059],
                            [114.240161, 40.22196],
                            [114.249616, 40.234485],
                            [114.255229, 40.236202],
                            [114.266655, 40.234148],
                            [114.272706, 40.231977],
                            [114.29311, 40.230108],
                            [114.303222, 40.231926],
                            [114.316459, 40.239754],
                            [114.33517, 40.245409],
                            [114.355713, 40.249499],
                            [114.36258, 40.250105],
                            [114.38159, 40.24546],
                            [114.400461, 40.24514],
                            [114.406393, 40.246167],
                            [114.430339, 40.254834],
                            [114.446363, 40.258082],
                            [114.45759, 40.261919],
                            [114.469931, 40.268061],
                            [114.488225, 40.284346],
                            [114.496047, 40.292016],
                            [114.510937, 40.302981],
                            [114.526443, 40.323562],
                            [114.530564, 40.336187],
                            [114.530604, 40.345112],
                            [114.515197, 40.351968],
                            [114.499332, 40.354018],
                            [114.491987, 40.350036],
                            [114.481317, 40.347801],
                            [114.470807, 40.3497],
                            [114.459819, 40.357766],
                            [114.451996, 40.369762],
                            [114.446542, 40.37287],
                            [114.439097, 40.371593],
                            [114.430857, 40.367108],
                            [114.413957, 40.362235],
                            [114.410772, 40.358286],
                            [114.403725, 40.353817],
                            [114.390388, 40.351279],
                            [114.383541, 40.354018],
                            [114.381212, 40.362874],
                            [114.375141, 40.363613],
                            [114.367676, 40.360925],
                            [114.363854, 40.364671],
                            [114.358918, 40.364419],
                            [114.353842, 40.368317],
                            [114.344446, 40.369544],
                            [114.326272, 40.365495],
                            [114.31841, 40.366704],
                            [114.314429, 40.369594],
                            [114.307501, 40.38866],
                            [114.29508, 40.409181],
                            [114.294443, 40.415359],
                            [114.287098, 40.423451],
                            [114.286262, 40.427346],
                            [114.290004, 40.43265],
                            [114.295956, 40.435587],
                            [114.299659, 40.440118],
                            [114.298723, 40.445606],
                            [114.286561, 40.450355],
                            [114.284252, 40.459986],
                            [114.274458, 40.458392],
                            [114.269362, 40.463811],
                            [114.26976, 40.468257],
                            [114.267212, 40.474212],
                            [114.274438, 40.481626],
                            [114.273682, 40.490179],
                            [114.28248, 40.494908],
                            [114.279773, 40.500794],
                            [114.286103, 40.511089],
                            [114.28676, 40.521382],
                            [114.285705, 40.526427],
                            [114.296394, 40.53598],
                            [114.296852, 40.540756],
                            [114.293428, 40.551413],
                            [114.290064, 40.550291],
                            [114.279614, 40.551095],
                            [114.273443, 40.554798],
                            [114.27615, 40.56567],
                            [114.279196, 40.56696],
                            [114.276469, 40.573375],
                            [114.283117, 40.586003],
                            [114.282958, 40.590792],
                            [114.277961, 40.596184],
                            [114.27183, 40.599951],
                            [114.258235, 40.610666],
                            [114.254015, 40.611268],
                            [114.249596, 40.608423],
                            [114.241255, 40.606665],
                            [114.23614, 40.607016],
                            [114.224475, 40.611938],
                            [114.21916, 40.619805],
                            [114.210879, 40.624123],
                            [114.209068, 40.628408],
                            [114.216135, 40.634382],
                            [114.214443, 40.640122],
                            [114.208172, 40.645342],
                            [114.206699, 40.652453],
                            [114.200071, 40.662155],
                            [114.18325, 40.671689],
                            [114.177239, 40.680954],
                            [114.175766, 40.689164],
                            [114.165813, 40.69948],
                            [114.16235, 40.713571],
                            [114.160399, 40.716613],
                            [114.151222, 40.723098],
                            [114.148436, 40.726791],
                            [114.147281, 40.733458],
                            [114.139518, 40.739941],
                            [114.127714, 40.735213],
                            [114.110038, 40.733208],
                            [114.106415, 40.733809],
                            [114.093237, 40.731888],
                            [114.084917, 40.729398],
                            [114.072257, 40.721527],
                            [114.067061, 40.715661],
                            [114.064095, 40.707103],
                            [114.072734, 40.679131],
                            [114.071222, 40.671773],
                            [114.070465, 40.660298],
                            [114.061269, 40.641728],
                            [114.059219, 40.632977],
                            [114.051595, 40.619939],
                            [114.041801, 40.608674],
                            [114.042458, 40.605342],
                            [114.048091, 40.595916],
                            [114.05476, 40.589721],
                            [114.066842, 40.583709],
                            [114.076875, 40.575804],
                            [114.081951, 40.565436],
                            [114.081652, 40.554211],
                            [114.080179, 40.54776],
                            [114.072874, 40.538125],
                            [114.061926, 40.528824],
                            [114.049763, 40.525438],
                            [114.039572, 40.524399],
                            [114.011405, 40.515867],
                            [114.006508, 40.516403],
                            [114.00197, 40.520761],
                            [113.986682, 40.523075],
                            [113.978561, 40.523443],
                            [113.960984, 40.519622],
                            [113.952644, 40.516017],
                            [113.955968, 40.514358],
                            [113.961562, 40.507618],
                            [113.970579, 40.503695],
                            [113.973246, 40.492158],
                            [113.973485, 40.477634],
                            [113.979278, 40.457772],
                            [113.983557, 40.451295],
                            [113.985428, 40.444415],
                            [113.985727, 40.436174],
                            [113.981925, 40.430518],
                            [113.974222, 40.425382],
                            [113.961124, 40.421924],
                            [113.957103, 40.410961],
                            [113.948245, 40.409349],
                            [113.940103, 40.410121],
                            [113.92818, 40.404832],
                            [113.922646, 40.398468],
                            [113.918685, 40.386896],
                            [113.922527, 40.381672],
                            [113.919401, 40.379136],
                            [113.916475, 40.366755],
                            [113.912315, 40.35664],
                            [113.910842, 40.343061],
                            [113.915659, 40.333531],
                            [113.919859, 40.329429],
                            [113.92824, 40.324655],
                            [113.933793, 40.323579],
                            [113.94932, 40.316753],
                            [113.949061, 40.306008],
                            [113.950414, 40.301148],
                            [113.946871, 40.285204],
                            [113.938909, 40.271846],
                            [113.937237, 40.266614],
                            [113.940362, 40.252848],
                            [113.949459, 40.238744],
                            [113.945239, 40.229435],
                            [113.948066, 40.224367],
                            [113.959213, 40.217514],
                            [113.959551, 40.215746],
                            [113.950673, 40.212227],
                            [113.946891, 40.208842],
                            [113.948742, 40.206131],
                            [113.964786, 40.205221],
                            [113.966976, 40.202695],
                            [113.973943, 40.202123],
                            [113.98296, 40.196918],
                            [113.986682, 40.190787],
                            [114.001433, 40.176501],
                            [114.006528, 40.174496],
                            [114.003144, 40.164049],
                            [114.001751, 40.156196],
                            [114.003124, 40.149892],
                            [114.007942, 40.144094],
                            [113.996257, 40.145156],
                            [113.985946, 40.136221],
                            [113.974261, 40.12941],
                            [113.972211, 40.122599],
                            [113.979835, 40.112278]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140223,
                "name": "广灵县",
                "center": [114.279252, 39.763051],
                "centroid": [114.15158, 39.762615],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [114.41121, 39.646659],
                            [114.408124, 39.651956],
                            [114.406213, 39.659715],
                            [114.408065, 39.67141],
                            [114.407089, 39.6904],
                            [114.407846, 39.712168],
                            [114.407706, 39.735725],
                            [114.408901, 39.746679],
                            [114.414514, 39.749731],
                            [114.410234, 39.761699],
                            [114.410652, 39.766445],
                            [114.416783, 39.776157],
                            [114.415231, 39.783681],
                            [114.408821, 39.782359],
                            [114.409597, 39.790611],
                            [114.402252, 39.793],
                            [114.398351, 39.800607],
                            [114.397395, 39.811381],
                            [114.390707, 39.818597],
                            [114.393275, 39.823508],
                            [114.402312, 39.825523],
                            [114.406711, 39.833449],
                            [114.397933, 39.844928],
                            [114.398311, 39.856457],
                            [114.399664, 39.856677],
                            [114.395425, 39.867222],
                            [114.387184, 39.86685],
                            [114.381948, 39.869592],
                            [114.380157, 39.867916],
                            [114.358997, 39.865428],
                            [114.349443, 39.862821],
                            [114.339171, 39.862652],
                            [114.327467, 39.86558],
                            [114.322411, 39.864192],
                            [114.316539, 39.860147],
                            [114.308636, 39.859995],
                            [114.302206, 39.862415],
                            [114.294941, 39.861247],
                            [114.289029, 39.85793],
                            [114.285108, 39.85969],
                            [114.286819, 39.871047],
                            [114.281445, 39.874263],
                            [114.27625, 39.874178],
                            [114.273284, 39.870979],
                            [114.264346, 39.867798],
                            [114.254314, 39.866359],
                            [114.245515, 39.861891],
                            [114.237832, 39.862297],
                            [114.228436, 39.860367],
                            [114.225231, 39.857033],
                            [114.224893, 39.851717],
                            [114.21924, 39.863025],
                            [114.215756, 39.862043],
                            [114.201205, 39.873433],
                            [114.199434, 39.87939],
                            [114.20451, 39.885177],
                            [114.213607, 39.890304],
                            [114.22774, 39.895887],
                            [114.229392, 39.899524],
                            [114.225092, 39.909132],
                            [114.223141, 39.907339],
                            [114.220633, 39.914274],
                            [114.216095, 39.917674],
                            [114.212293, 39.915221],
                            [114.211994, 39.918705],
                            [114.205883, 39.911044],
                            [114.20021, 39.908236],
                            [114.19187, 39.907238],
                            [114.182952, 39.89944],
                            [114.174154, 39.897613],
                            [114.143519, 39.902282],
                            [114.110356, 39.911669],
                            [114.102872, 39.912853],
                            [114.089933, 39.910198],
                            [114.084001, 39.911923],
                            [114.079562, 39.916338],
                            [114.067778, 39.922307],
                            [114.053944, 39.921513],
                            [114.047375, 39.916135],
                            [114.041801, 39.915137],
                            [114.037342, 39.917538],
                            [114.026414, 39.926045],
                            [114.020781, 39.929359],
                            [114.006489, 39.929156],
                            [113.997412, 39.92268],
                            [113.987857, 39.91928],
                            [113.969683, 39.910096],
                            [113.962716, 39.90443],
                            [113.955271, 39.895312],
                            [113.949917, 39.886937],
                            [113.945498, 39.876987],
                            [113.954415, 39.862974],
                            [113.958377, 39.854443],
                            [113.954415, 39.848518],
                            [113.941795, 39.848264],
                            [113.937257, 39.841745],
                            [113.932878, 39.838884],
                            [113.921273, 39.83885],
                            [113.914365, 39.847214],
                            [113.908832, 39.849516],
                            [113.887254, 39.840611],
                            [113.869219, 39.837699],
                            [113.850727, 39.831806],
                            [113.855086, 39.826624],
                            [113.880924, 39.811601],
                            [113.884387, 39.806181],
                            [113.88586, 39.798642],
                            [113.884029, 39.792187],
                            [113.884029, 39.784172],
                            [113.891653, 39.779292],
                            [113.895355, 39.775258],
                            [113.894281, 39.764462],
                            [113.901845, 39.753172],
                            [113.908971, 39.745305],
                            [113.916834, 39.734029],
                            [113.920198, 39.731825],
                            [113.931962, 39.727738],
                            [113.936998, 39.720072],
                            [113.937436, 39.716629],
                            [113.945677, 39.711336],
                            [113.948225, 39.706807],
                            [113.950693, 39.696102],
                            [113.957501, 39.681373],
                            [113.969464, 39.66423],
                            [113.975675, 39.657287],
                            [113.976471, 39.651583],
                            [113.97243, 39.639799],
                            [113.978004, 39.634195],
                            [113.98089, 39.628761],
                            [113.987698, 39.622069],
                            [113.994983, 39.618111],
                            [114.006787, 39.615733],
                            [114.01885, 39.617856],
                            [114.038835, 39.626009],
                            [114.050082, 39.627572],
                            [114.064056, 39.626213],
                            [114.071042, 39.619878],
                            [114.079323, 39.614493],
                            [114.096621, 39.614867],
                            [114.114277, 39.609821],
                            [114.118597, 39.609447],
                            [114.120747, 39.61558],
                            [114.12218, 39.625313],
                            [114.128669, 39.630714],
                            [114.136174, 39.631868],
                            [114.171566, 39.633906],
                            [114.190436, 39.6211],
                            [114.198976, 39.609736],
                            [114.203733, 39.595159],
                            [114.208531, 39.592015],
                            [114.21496, 39.594377],
                            [114.220773, 39.599033],
                            [114.232198, 39.602839],
                            [114.245217, 39.616276],
                            [114.2613, 39.617924],
                            [114.276449, 39.61541],
                            [114.286182, 39.615053],
                            [114.293766, 39.616514],
                            [114.299897, 39.619402],
                            [114.300256, 39.631291],
                            [114.308059, 39.642788],
                            [114.321595, 39.648934],
                            [114.33105, 39.650445],
                            [114.353264, 39.652143],
                            [114.361844, 39.654554],
                            [114.370722, 39.655369],
                            [114.377868, 39.654095],
                            [114.387761, 39.648272],
                            [114.40056, 39.645691],
                            [114.41121, 39.646659]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140224,
                "name": "灵丘县",
                "center": [114.23576, 39.438867],
                "centroid": [114.207844, 39.372378],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [114.41121, 39.646659],
                            [114.40056, 39.645691],
                            [114.387761, 39.648272],
                            [114.377868, 39.654095],
                            [114.370722, 39.655369],
                            [114.361844, 39.654554],
                            [114.353264, 39.652143],
                            [114.33105, 39.650445],
                            [114.321595, 39.648934],
                            [114.308059, 39.642788],
                            [114.300256, 39.631291],
                            [114.299897, 39.619402],
                            [114.293766, 39.616514],
                            [114.286182, 39.615053],
                            [114.276449, 39.61541],
                            [114.2613, 39.617924],
                            [114.245217, 39.616276],
                            [114.232198, 39.602839],
                            [114.220773, 39.599033],
                            [114.21496, 39.594377],
                            [114.208531, 39.592015],
                            [114.203733, 39.595159],
                            [114.198976, 39.609736],
                            [114.190436, 39.6211],
                            [114.171566, 39.633906],
                            [114.136174, 39.631868],
                            [114.128669, 39.630714],
                            [114.12218, 39.625313],
                            [114.120747, 39.61558],
                            [114.118597, 39.609447],
                            [114.114277, 39.609821],
                            [114.096621, 39.614867],
                            [114.079323, 39.614493],
                            [114.071042, 39.619878],
                            [114.064056, 39.626213],
                            [114.050082, 39.627572],
                            [114.038835, 39.626009],
                            [114.01885, 39.617856],
                            [114.006787, 39.615733],
                            [113.994983, 39.618111],
                            [113.987698, 39.622069],
                            [113.98089, 39.628761],
                            [113.978004, 39.634195],
                            [113.97243, 39.639799],
                            [113.967056, 39.632327],
                            [113.958715, 39.624107],
                            [113.955192, 39.622867],
                            [113.939466, 39.629372],
                            [113.932141, 39.634467],
                            [113.920815, 39.635129],
                            [113.914067, 39.633142],
                            [113.906562, 39.627011],
                            [113.901148, 39.613372],
                            [113.900491, 39.606712],
                            [113.901725, 39.59803],
                            [113.910583, 39.590894],
                            [113.915918, 39.583433],
                            [113.91769, 39.575242],
                            [113.913191, 39.56115],
                            [113.913848, 39.551306],
                            [113.917988, 39.543382],
                            [113.922148, 39.52743],
                            [113.92191, 39.509773],
                            [113.924497, 39.496077],
                            [113.930369, 39.489559],
                            [113.939148, 39.493218],
                            [113.945995, 39.49269],
                            [113.955729, 39.486275],
                            [113.960128, 39.480199],
                            [113.960507, 39.469884],
                            [113.958038, 39.455839],
                            [113.959651, 39.444414],
                            [113.965543, 39.421642],
                            [113.967732, 39.410228],
                            [113.966239, 39.394365],
                            [113.958297, 39.384549],
                            [113.938809, 39.381788],
                            [113.920138, 39.373794],
                            [113.903059, 39.371339],
                            [113.901407, 39.369975],
                            [113.901387, 39.366276],
                            [113.897923, 39.356217],
                            [113.898162, 39.345082],
                            [113.903119, 39.339028],
                            [113.916615, 39.32888],
                            [113.923084, 39.32291],
                            [113.931484, 39.317912],
                            [113.942193, 39.30875],
                            [113.946891, 39.295799],
                            [113.958257, 39.287027],
                            [113.967175, 39.281531],
                            [113.96819, 39.276803],
                            [113.966757, 39.261199],
                            [113.958576, 39.222893],
                            [113.93861, 39.204971],
                            [113.936898, 39.20005],
                            [113.939526, 39.196085],
                            [113.943447, 39.17672],
                            [113.946234, 39.159539],
                            [113.951808, 39.146492],
                            [113.951509, 39.134024],
                            [113.952504, 39.127455],
                            [113.958337, 39.114145],
                            [113.961761, 39.10085],
                            [113.965961, 39.099276],
                            [113.984373, 39.095374],
                            [113.995103, 39.095477],
                            [114.000457, 39.100748],
                            [114.006469, 39.122871],
                            [114.02934, 39.133425],
                            [114.043413, 39.137342],
                            [114.050818, 39.135871],
                            [114.052988, 39.13156],
                            [114.055695, 39.117174],
                            [114.06527, 39.093509],
                            [114.071082, 39.091232],
                            [114.075661, 39.09166],
                            [114.078228, 39.09498],
                            [114.085136, 39.093457],
                            [114.084578, 39.089452],
                            [114.09682, 39.083702],
                            [114.101399, 39.075982],
                            [114.102891, 39.062251],
                            [114.108704, 39.052268],
                            [114.126639, 39.050916],
                            [114.135975, 39.05333],
                            [114.144972, 39.057337],
                            [114.157194, 39.061155],
                            [114.167485, 39.05946],
                            [114.180902, 39.049083],
                            [114.1897, 39.048278],
                            [114.196965, 39.050436],
                            [114.218424, 39.061001],
                            [114.226346, 39.066514],
                            [114.242609, 39.066703],
                            [114.251527, 39.073191],
                            [114.258573, 39.07403],
                            [114.270238, 39.072643],
                            [114.282878, 39.074783],
                            [114.29102, 39.075177],
                            [114.300116, 39.079217],
                            [114.307661, 39.077505],
                            [114.320798, 39.070486],
                            [114.325994, 39.075126],
                            [114.334075, 39.076683],
                            [114.341998, 39.074544],
                            [114.348706, 39.076461],
                            [114.361864, 39.094142],
                            [114.364571, 39.103434],
                            [114.369667, 39.107541],
                            [114.369746, 39.121416],
                            [114.367477, 39.127968],
                            [114.360789, 39.133972],
                            [114.36268, 39.140677],
                            [114.379799, 39.168874],
                            [114.388179, 39.176994],
                            [114.417997, 39.171643],
                            [114.432568, 39.175028],
                            [114.443616, 39.174139],
                            [114.445706, 39.181506],
                            [114.444353, 39.186019],
                            [114.447617, 39.191505],
                            [114.453171, 39.192633],
                            [114.462885, 39.188155],
                            [114.470429, 39.194069],
                            [114.473514, 39.202545],
                            [114.471623, 39.207739],
                            [114.476002, 39.215872],
                            [114.474848, 39.219254],
                            [114.467861, 39.223645],
                            [114.467363, 39.225883],
                            [114.461093, 39.22689],
                            [114.456694, 39.225404],
                            [114.452832, 39.228838],
                            [114.446343, 39.230477],
                            [114.43635, 39.229623],
                            [114.423631, 39.235738],
                            [114.424686, 39.238027],
                            [114.415947, 39.24286],
                            [114.417321, 39.24578],
                            [114.42805, 39.249776],
                            [114.437007, 39.25939],
                            [114.436987, 39.262787],
                            [114.431135, 39.265553],
                            [114.426915, 39.272313],
                            [114.424845, 39.28433],
                            [114.427632, 39.289075],
                            [114.433623, 39.294451],
                            [114.433703, 39.29829],
                            [114.430379, 39.306549],
                            [114.433763, 39.309705],
                            [114.434459, 39.315967],
                            [114.4386, 39.319413],
                            [114.448911, 39.320812],
                            [114.448811, 39.325724],
                            [114.454743, 39.329341],
                            [114.459779, 39.32772],
                            [114.466627, 39.329682],
                            [114.467403, 39.33734],
                            [114.474828, 39.341296],
                            [114.47865, 39.345662],
                            [114.479705, 39.351102],
                            [114.469473, 39.355194],
                            [114.471723, 39.362986],
                            [114.47019, 39.382896],
                            [114.468319, 39.389065],
                            [114.469274, 39.400977],
                            [114.470887, 39.40878],
                            [114.485, 39.425168],
                            [114.493977, 39.433719],
                            [114.496824, 39.438555],
                            [114.502517, 39.45715],
                            [114.504706, 39.460776],
                            [114.504806, 39.467671],
                            [114.50184, 39.472642],
                            [114.50194, 39.476761],
                            [114.512509, 39.479552],
                            [114.514918, 39.478752],
                            [114.522184, 39.484539],
                            [114.532674, 39.486207],
                            [114.536595, 39.492163],
                            [114.539044, 39.500824],
                            [114.536197, 39.512376],
                            [114.539999, 39.515353],
                            [114.547742, 39.525985],
                            [114.557436, 39.532465],
                            [114.557078, 39.539981],
                            [114.559009, 39.543263],
                            [114.557576, 39.551119],
                            [114.563189, 39.554995],
                            [114.564582, 39.565383],
                            [114.568922, 39.57395],
                            [114.566394, 39.578845],
                            [114.556998, 39.581411],
                            [114.534983, 39.572403],
                            [114.530305, 39.567559],
                            [114.529429, 39.5637],
                            [114.525349, 39.565485],
                            [114.515356, 39.565009],
                            [114.509384, 39.577842],
                            [114.504686, 39.591132],
                            [114.505085, 39.595346],
                            [114.50188, 39.601955],
                            [114.495809, 39.608207],
                            [114.481855, 39.612879],
                            [114.474768, 39.613745],
                            [114.459182, 39.611078],
                            [114.444432, 39.610467],
                            [114.437903, 39.611316],
                            [114.431653, 39.614051],
                            [114.430578, 39.617007],
                            [114.418814, 39.636301],
                            [114.41121, 39.646659]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140225,
                "name": "浑源县",
                "center": [113.698091, 39.699099],
                "centroid": [113.704854, 39.628272],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.406732, 39.731147],
                            [113.40512, 39.727806],
                            [113.410773, 39.721768],
                            [113.410096, 39.714033],
                            [113.402014, 39.705161],
                            [113.404184, 39.70187],
                            [113.393893, 39.696543],
                            [113.396341, 39.692997],
                            [113.38615, 39.682544],
                            [113.385771, 39.677724],
                            [113.392659, 39.670341],
                            [113.394152, 39.6635],
                            [113.382985, 39.653569],
                            [113.383184, 39.649223],
                            [113.391007, 39.640495],
                            [113.39865, 39.636131],
                            [113.41352, 39.622306],
                            [113.429405, 39.605761],
                            [113.430798, 39.600987],
                            [113.429225, 39.589262],
                            [113.431933, 39.581768],
                            [113.444394, 39.581615],
                            [113.458726, 39.57876],
                            [113.463384, 39.574273],
                            [113.46832, 39.562935],
                            [113.473993, 39.554587],
                            [113.48514, 39.54981],
                            [113.507037, 39.528995],
                            [113.511535, 39.520388],
                            [113.507514, 39.510437],
                            [113.50636, 39.50307],
                            [113.508549, 39.493269],
                            [113.518522, 39.481118],
                            [113.53586, 39.469135],
                            [113.55905, 39.458904],
                            [113.570237, 39.452979],
                            [113.574596, 39.447802],
                            [113.577264, 39.438113],
                            [113.586141, 39.441263],
                            [113.589804, 39.439986],
                            [113.593546, 39.435439],
                            [113.59914, 39.424027],
                            [113.605131, 39.42229],
                            [113.620081, 39.428013],
                            [113.632382, 39.430278],
                            [113.649521, 39.434638],
                            [113.664072, 39.430466],
                            [113.673249, 39.424487],
                            [113.690646, 39.41936],
                            [113.69833, 39.418849],
                            [113.70874, 39.420382],
                            [113.7172, 39.422801],
                            [113.723769, 39.422852],
                            [113.739256, 39.420876],
                            [113.754145, 39.415254],
                            [113.766506, 39.405236],
                            [113.772757, 39.390735],
                            [113.780221, 39.383509],
                            [113.791289, 39.378464],
                            [113.798116, 39.376623],
                            [113.812687, 39.37468],
                            [113.829428, 39.373436],
                            [113.840595, 39.370913],
                            [113.855425, 39.372413],
                            [113.887294, 39.374493],
                            [113.899556, 39.373027],
                            [113.901407, 39.369975],
                            [113.903059, 39.371339],
                            [113.920138, 39.373794],
                            [113.938809, 39.381788],
                            [113.958297, 39.384549],
                            [113.966239, 39.394365],
                            [113.967732, 39.410228],
                            [113.965543, 39.421642],
                            [113.959651, 39.444414],
                            [113.958038, 39.455839],
                            [113.960507, 39.469884],
                            [113.960128, 39.480199],
                            [113.955729, 39.486275],
                            [113.945995, 39.49269],
                            [113.939148, 39.493218],
                            [113.930369, 39.489559],
                            [113.924497, 39.496077],
                            [113.92191, 39.509773],
                            [113.922148, 39.52743],
                            [113.917988, 39.543382],
                            [113.913848, 39.551306],
                            [113.913191, 39.56115],
                            [113.91769, 39.575242],
                            [113.915918, 39.583433],
                            [113.910583, 39.590894],
                            [113.901725, 39.59803],
                            [113.900491, 39.606712],
                            [113.901148, 39.613372],
                            [113.906562, 39.627011],
                            [113.914067, 39.633142],
                            [113.920815, 39.635129],
                            [113.932141, 39.634467],
                            [113.939466, 39.629372],
                            [113.955192, 39.622867],
                            [113.958715, 39.624107],
                            [113.967056, 39.632327],
                            [113.97243, 39.639799],
                            [113.976471, 39.651583],
                            [113.975675, 39.657287],
                            [113.969464, 39.66423],
                            [113.957501, 39.681373],
                            [113.950693, 39.696102],
                            [113.948225, 39.706807],
                            [113.945677, 39.711336],
                            [113.937436, 39.716629],
                            [113.936998, 39.720072],
                            [113.931962, 39.727738],
                            [113.920198, 39.731825],
                            [113.916834, 39.734029],
                            [113.908971, 39.745305],
                            [113.901845, 39.753172],
                            [113.894281, 39.764462],
                            [113.895355, 39.775258],
                            [113.891653, 39.779292],
                            [113.884029, 39.784172],
                            [113.884029, 39.792187],
                            [113.88586, 39.798642],
                            [113.884387, 39.806181],
                            [113.880924, 39.811601],
                            [113.855086, 39.826624],
                            [113.850727, 39.831806],
                            [113.843641, 39.838291],
                            [113.827736, 39.857659],
                            [113.823357, 39.863752],
                            [113.812608, 39.867747],
                            [113.783486, 39.874669],
                            [113.770726, 39.873467],
                            [113.752314, 39.864328],
                            [113.743018, 39.858607],
                            [113.730378, 39.853715],
                            [113.718454, 39.85363],
                            [113.702908, 39.861264],
                            [113.689213, 39.859995],
                            [113.669725, 39.861535],
                            [113.658001, 39.863752],
                            [113.645898, 39.867324],
                            [113.624102, 39.867459],
                            [113.615801, 39.865868],
                            [113.608874, 39.860655],
                            [113.596074, 39.853004],
                            [113.587316, 39.84889],
                            [113.575791, 39.846774],
                            [113.564962, 39.843083],
                            [113.555945, 39.838291],
                            [113.548878, 39.829876],
                            [113.541732, 39.823068],
                            [113.531083, 39.81504],
                            [113.522045, 39.811618],
                            [113.498796, 39.80718],
                            [113.489798, 39.802166],
                            [113.47672, 39.789035],
                            [113.473794, 39.781173],
                            [113.477735, 39.772326],
                            [113.477138, 39.745272],
                            [113.475884, 39.732859],
                            [113.472381, 39.72499],
                            [113.461234, 39.722209],
                            [113.449728, 39.721225],
                            [113.44724, 39.723379],
                            [113.43874, 39.719529],
                            [113.430499, 39.721819],
                            [113.424926, 39.725024],
                            [113.421741, 39.729162],
                            [113.420208, 39.738014],
                            [113.406732, 39.731147]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 140226,
                "name": "左云县",
                "center": [112.70641, 40.012873],
                "centroid": [112.772184, 39.99543],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 140200
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 140000, 140200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.873839, 40.262575],
                            [112.870713, 40.254599],
                            [112.864543, 40.243238],
                            [112.857994, 40.227297],
                            [112.855127, 40.218424],
                            [112.848678, 40.207057],
                            [112.845035, 40.203908],
                            [112.828533, 40.197676],
                            [112.81024, 40.192623],
                            [112.794933, 40.186592],
                            [112.764855, 40.172474],
                            [112.750742, 40.168026],
                            [112.744452, 40.167385],
                            [112.730319, 40.168076],
                            [112.720784, 40.178573],
                            [112.712324, 40.178809],
                            [112.705557, 40.185716],
                            [112.69425, 40.190821],
                            [112.687741, 40.196564],
                            [112.677928, 40.201196],
                            [112.667855, 40.209027],
                            [112.647691, 40.223542],
                            [112.63937, 40.227482],
                            [112.629915, 40.235765],
                            [112.624302, 40.236842],
                            [112.626093, 40.22302],
                            [112.622391, 40.220932],
                            [112.615922, 40.22137],
                            [112.609691, 40.218457],
                            [112.603899, 40.210493],
                            [112.603202, 40.202948],
                            [112.607561, 40.194004],
                            [112.622052, 40.183392],
                            [112.626452, 40.177428],
                            [112.627248, 40.170098],
                            [112.622072, 40.163729],
                            [112.609213, 40.162684],
                            [112.599957, 40.16297],
                            [112.589785, 40.161454],
                            [112.58047, 40.154392],
                            [112.580947, 40.150482],
                            [112.585346, 40.148578],
                            [112.579634, 40.144566],
                            [112.578519, 40.141346],
                            [112.584291, 40.134923],
                            [112.581963, 40.128432],
                            [112.583794, 40.124504],
                            [112.589845, 40.123459],
                            [112.595558, 40.114521],
                            [112.585088, 40.110761],
                            [112.592313, 40.099123],
                            [112.601112, 40.097217],
                            [112.607322, 40.094484],
                            [112.612836, 40.095867],
                            [112.620241, 40.091988],
                            [112.622331, 40.088766],
                            [112.611761, 40.084767],
                            [112.610547, 40.079351],
                            [112.614429, 40.073547],
                            [112.618589, 40.070577],
                            [112.619763, 40.062797],
                            [112.624979, 40.056401],
                            [112.632742, 40.054038],
                            [112.636603, 40.039183],
                            [112.642635, 40.034473],
                            [112.644028, 40.027145],
                            [112.6415, 40.018482],
                            [112.635966, 40.00595],
                            [112.632742, 40.001424],
                            [112.62898, 39.987791],
                            [112.627646, 39.979511],
                            [112.624063, 39.973884],
                            [112.622988, 39.966516],
                            [112.617395, 39.948667],
                            [112.613035, 39.940958],
                            [112.600694, 39.926789],
                            [112.596573, 39.920464],
                            [112.597011, 39.912464],
                            [112.600853, 39.897003],
                            [112.603879, 39.880608],
                            [112.602824, 39.867273],
                            [112.599897, 39.855492],
                            [112.591139, 39.835938],
                            [112.591358, 39.83206],
                            [112.59514, 39.823237],
                            [112.600017, 39.816056],
                            [112.605212, 39.813719],
                            [112.62036, 39.809399],
                            [112.62259, 39.801454],
                            [112.620042, 39.7828],
                            [112.619405, 39.77292],
                            [112.620997, 39.760377],
                            [112.635668, 39.754223],
                            [112.642555, 39.748273],
                            [112.644287, 39.743746],
                            [112.648348, 39.740677],
                            [112.669249, 39.746204],
                            [112.680475, 39.753698],
                            [112.684895, 39.765767],
                            [112.693693, 39.769055],
                            [112.713778, 39.761953],
                            [112.721461, 39.757851],
                            [112.7344, 39.753325],
                            [112.745029, 39.752206],
                            [112.751578, 39.757919],
                            [112.752793, 39.774241],
                            [112.765811, 39.776021],
                            [112.782273, 39.774479],
                            [112.809046, 39.774614],
                            [112.82081, 39.776801],
                            [112.817307, 39.781274],
                            [112.817506, 39.788849],
                            [112.823438, 39.794745],
                            [112.83132, 39.797964],
                            [112.838745, 39.797947],
                            [112.861577, 39.79976],
                            [112.870057, 39.802047],
                            [112.878855, 39.810145],
                            [112.877004, 39.817191],
                            [112.881801, 39.82261],
                            [112.891455, 39.826116],
                            [112.903617, 39.827166],
                            [112.911739, 39.829181],
                            [112.921214, 39.839155],
                            [112.934292, 39.84183],
                            [112.936979, 39.850295],
                            [112.938731, 39.861146],
                            [112.945001, 39.856829],
                            [112.954038, 39.853055],
                            [112.958398, 39.85385],
                            [112.958676, 39.857439],
                            [112.966838, 39.868153],
                            [112.965783, 39.871589],
                            [112.956467, 39.883823],
                            [112.947091, 39.886886],
                            [112.942115, 39.890422],
                            [112.940085, 39.895159],
                            [112.930092, 39.903313],
                            [112.923424, 39.906172],
                            [112.920776, 39.9092],
                            [112.911858, 39.907931],
                            [112.900632, 39.911027],
                            [112.890241, 39.921445],
                            [112.886359, 39.93149],
                            [112.889186, 39.936089],
                            [112.902901, 39.947298],
                            [112.917412, 39.945979],
                            [112.929614, 39.947179],
                            [112.942891, 39.949445],
                            [112.955074, 39.954144],
                            [112.962001, 39.95815],
                            [112.967116, 39.963914],
                            [112.971735, 39.972279],
                            [112.975337, 39.981894],
                            [112.976333, 39.990342],
                            [112.974661, 39.995106],
                            [112.967136, 40.001052],
                            [112.958457, 40.005613],
                            [112.952784, 40.006575],
                            [112.944384, 40.004143],
                            [112.933376, 40.003856],
                            [112.918447, 40.005967],
                            [112.914386, 40.011507],
                            [112.915939, 40.015375],
                            [112.926509, 40.02213],
                            [112.925195, 40.032852],
                            [112.925832, 40.038069],
                            [112.920338, 40.042441],
                            [112.91146, 40.051742],
                            [112.911958, 40.060435],
                            [112.908833, 40.070746],
                            [112.911918, 40.073463],
                            [112.927106, 40.077698],
                            [112.929594, 40.081494],
                            [112.929754, 40.088833],
                            [112.956527, 40.096188],
                            [112.96009, 40.102142],
                            [112.961145, 40.111385],
                            [112.964867, 40.113257],
                            [112.972053, 40.124251],
                            [112.979159, 40.141801],
                            [112.973128, 40.146167],
                            [112.969505, 40.145729],
                            [112.960408, 40.148662],
                            [112.955034, 40.158909],
                            [112.963036, 40.163645],
                            [112.968112, 40.16966],
                            [112.966738, 40.174445],
                            [112.956785, 40.17453],
                            [112.943508, 40.177175],
                            [112.944822, 40.182516],
                            [112.941538, 40.193213],
                            [112.941896, 40.202257],
                            [112.939647, 40.204885],
                            [112.935765, 40.203504],
                            [112.93061, 40.205777],
                            [112.912456, 40.209617],
                            [112.906544, 40.216605],
                            [112.906703, 40.226202],
                            [112.904573, 40.23972],
                            [112.897845, 40.247783],
                            [112.890161, 40.252428],
                            [112.880666, 40.256652],
                            [112.873839, 40.262575]
                        ]
                    ]
                ]
            }
        }
    ]
}
