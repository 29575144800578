<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>教程库管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>课本详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item width="33%" label="课时名称"><span>{{ form.className }} ({{ form.periodName + '-' +
                form.gradeName + '-' + form.subjectName }})</span></el-descriptions-item>
                <el-descriptions-item label="学制">{{ form.studyIsm }}</el-descriptions-item>
                <el-descriptions-item label="版本">{{ form.versionTxt }}</el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :model="form" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label-width="70px" label="书本封面" prop="coverUrl">
                            <img style="width: 250px;" v-if="form.coverUrl" :src="form.coverUrl" class="avatar" />
                        </el-form-item>
                    </el-col>
                </el-row>


                <!-- <el-row>
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            {{ form.periodName }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            {{ form.gradeName }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            {{ form.subjectName }}
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div style="color: #999;">课时管理</div>
                            <div class="disnone" style="display: flex;">
                                <el-button v-has-btn="'1,2'" type="primary" size="small" @click="newaddTitle">
                                    新增单元
                                </el-button>
                                <el-button v-has-btn="'1,2'" type="primary" size="small" @click="downloadTemplate">
                                    下载模板
                                </el-button>
                                <el-upload v-model:file-list="filesList" class="upload-demo"
                                           :action="serviceUrl+'/businessservice/classLibrary/batchImport'"
                                           :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                           :data="{ classId: form.classId }" accept=".xlsx" :before-upload="beforeUpload">
                                    <el-button v-has-btn="'1,2'" class="ml15" type="primary" size="small">
                                        导入课程
                                    </el-button>
                                </el-upload>

                            </div>
                        </div>
                        <el-table :data="tableData" :span-method="objectSpanMethod" border
                                  style="width: 100%; margin-top: 20px">
                            <el-table-column prop="ptit" label="单元名" width="180">
                                <template #default="scope">
                                    <div style="display: flex;justify-content: space-between;">
                                        <div :title="scope.row.ptit" style="color: #409efe;" class="text-one cursor">
                                            {{ scope.row.ptit }}
                                        </div>
                                        <div>
                                            <el-icon class="cursor mr5" v-has-btn="'1,2'"
                                                     @click.stop="newaddKc(scope.row.pid)" title="新增课程">
                                                <Plus></Plus>
                                            </el-icon>
                                            <el-icon class="cursor mr5" v-has-btn="'1,2'"
                                                     @click.stop="handleUpdate(scope.row.pid, scope.row.ptit)" title="编辑">
                                                <Edit />
                                            </el-icon>
                                            <el-popconfirm v-has-btn="'1,2'" confirm-button-text="是" cancel-button-text="否"
                                                           :icon="InfoFilled" icon-color="#626AEF" title="确定删除该单元"
                                                           @confirm="handleDelete(scope.row.pid)">
                                                <template #reference>
                                                    <el-icon v-has-btn="'1,2'" class="cursor mr5" title="删除">
                                                        <Delete />
                                                    </el-icon>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="title" label="课程名">
                                <template #default="scope">
                                    <div style="display: flex;justify-content: space-between;" v-if="scope.row.id">
                                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor">
                                            {{ scope.row.title }}
                                        </div>
                                        <div>
                                            <el-icon class="cursor mr5" v-has-btn="'1,2'"
                                                     @click.stop="handleUpdate(scope.row.id, scope.row.title)" title="编辑">
                                                <Edit />
                                            </el-icon>
                                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                           v-has-btn="'1,2'" icon-color="#626AEF" title="确定删除该课程"
                                                           @confirm="handleDelete(scope.row.id)">
                                                <template #reference>
                                                    <el-icon class="cursor mr5" title="删除" v-has-btn="'1,2'">
                                                        <Delete />
                                                    </el-icon>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="版本" prop="versionKey">
                            <el-select style="width: 100%;" v-model="form.versionKey" placeholder="请选择版本">
                                <el-option v-for="item in versionKeyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->

            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <el-dialog width="530px" :title="titShow" v-model="isaddTit">
            <div>
                <el-form>
                    <el-input v-model="addPeoFrom.className" placeholder="请输入名称" maxlength="32" />
                </el-form>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="titleAddSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isaddTit = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog width="530px" :title="titShow" v-model="iseditTit">
            <div>
                <el-form>
                    <el-input v-model="addPeoFrom.className" placeholder="请输入名称" maxlength="32" />
                </el-form>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="titleEditSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="iseditTit = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { classLibraryCreate, classLibraryUpdate, classLibraryListTree, classLibraryCreateUnit, classLibraryDelete } from "@/api/admin/classLibrary";
import { queryDict, userList } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";
import { serviceUrl } from "@/utils/global"

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            serviceUrl,
            titShow: '',
            token: this.$storage.get("token"),
            classId: '',
            jAFileList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            versionKeyList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                className: '',
                unitName: '',
                versionKey: '',
                coverUrl: '',
            },
            // 表单校验
            rules: {
                coverUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                versionKey: [
                    { required: true, message: "请选择版本", trigger: "change" },
                ],
                className: [
                    { required: true, message: "请输入课时名称", trigger: "blur" },
                ],
                unitName: [
                    { required: true, message: "请输入单元名称", trigger: "blur" },
                ]
            },
            delFileList: [],
            tableData: [],
            isaddTit: false,
            iseditTit: false,
            addPeoFrom: {
                classId: '',
                className: '',
            }
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.data) {
            this.form = JSON.parse(this.$route.query.data)
            this.classId = this.form.classId
            console.log(this.form)
            this.getfamousCourseInfo()
            this.getTableData()
            this.getQueryDict();

        } else {
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        handleDelete(id) {
            classLibraryDelete({ classId: id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('删除成功')
                    this.getTableData()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },

        handleUpdate(id, tit) {
            this.titShow = '修改名称'
            this.addPeoFrom.classId = id;
            this.addPeoFrom.className = tit;
            this.iseditTit = true;
        },
        newaddTitle() {
            this.titShow = '新增单元'
            this.addPeoFrom = {
                classId: '',
                className: '',
            }
            this.addPeoFrom.classId = this.form.classId;
            this.isaddTit = true;
        },
        newaddKc(id) {
            this.titShow = '新增课程'
            this.addPeoFrom = {
                classId: '',
                className: '',
            }
            this.addPeoFrom.classId = id;
            this.isaddTit = true;
        },
        titleEditSubmitForm() {
            if (this.addPeoFrom.className == '') {
                this.$message.error('请输入名称')
                return;
            }
            classLibraryUpdate(this.addPeoFrom).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.iseditTit = false;
                    this.getTableData()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        titleAddSubmitForm() {
            if (this.addPeoFrom.className == '') {
                this.$message.error('请输入名称')
                return;
            }
            classLibraryCreateUnit(this.addPeoFrom).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.isaddTit = false;
                    this.getTableData()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        newaddPeo() {
            this.addPeoFrom = {
                classId: '',
                className: '',
            }
            this.isaddTit = true;
        },
        objectSpanMethod(e) {
            console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        getTableData() {
            this.tableData = []
            classLibraryListTree().then(res => {
                res.data.data.forEach(e => {
                    if (this.form.classId == e.classId) {
                        if (e.childrenList) {
                            e.childrenList.forEach(j => {
                                if (j.childrenList) {
                                    j.childrenList.forEach((m, n) => {
                                        if (n == 0) {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: j.childrenList.length })
                                        } else {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: 0 })
                                        }
                                    })
                                } else {
                                    this.tableData.push({ pid: j.classId, ptit: j.className, id: '', title: '', col: 1 })
                                }
                            })
                        }
                    }
                    this.tableData[0].oneCol = 13

                })
                console.log(this.tableData);
            })
        },
        downloadTemplate() {
            download('https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/excel/%E8%AF%BE%E6%97%B6%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '课时导入模板')
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getTableData()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        handleRemove(file, uploadFiles) {
        },
        getfamousCourseInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                // famousCourseInfo({ courseId: this.courseId }).then(res => {
                //     this.form = res.data.data;
                this.periodList.forEach(j => {
                    if (this.form.periodKey == j.dictKey) {
                        this.gradeList = j.childrenList;
                    }
                })
                this.periodList.forEach(e => {
                    if (this.form.periodKey == e.dictKey) {
                        e.childrenList.forEach(j => {
                            if (this.form.gradeKey == j.dictKey) {
                                this.subjectList = j.childrenList;
                            }
                        })
                    }
                })
                // })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'class_version' }).then(res => {
                this.versionKeyList = res.data.data;
                this.versionKeyList.forEach(e => {
                    if (e.dictKey == this.form.versionKey) {
                        this.form.versionTxt = e.dictValue;
                    }
                })
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.classId) {
                        classLibraryUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classLibrary', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        classLibraryCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classLibrary', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/classLibrary')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.disnone /deep/ .el-upload-list {
    display: none;
}</style>