/**
 * 时间戳转换为日期格式(年月日时分秒)
 */
export const timestampToTime = (timestamp) => {
	if(!timestamp){return ''}
	// 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return year + '-' + month + '-' + date + ' ' + hours + ':' + minute
};


/**
 * 时间戳转换为日期格式
 */
export const timestampToDate = (timestamp) => {

	if(!timestamp){return ''}
	// 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return hours + ':' + minute +":"+second
};
